<template lang="pug">
div.container.main-breadcrumb-container.d-none.d-md-block.font-weight-semibold.px-0
  b-breadcrumb.bg-transparent
    b-breadcrumb-item.p-0(:to="rootUrl")
      .d-flex.text-gray-600.root.align-items-center
        n-icon.mr-1(:name="rootIcon", :size="1.25", :pathCount="rootIconPathCount")
        .font-lg {{ rootName }}
    b-breadcrumb-item.p-0(v-for="level in levels.slice(0, levels.length - 1)", :to="level.url")
      .d-flex.text-gray-600.levels.align-items-center
        n-icon.mr-1.text-gray-600(name="chevron-right")
        .font-lg {{ "..." }}
    b-breadcrumb-item.p-0(v-if="levels[levels.length-1]", :to="levels[levels.length-1].url")
      .d-flex.text-gray-600.root.align-items-center
        n-icon.mr-1.text-gray-600(name="chevron-right")
        .font-lg {{ levels[levels.length-1].name }}
    b-breadcrumb-item.p-0(v-if="currentName", active)
      .d-flex.text-tiber.align-items-center
        n-icon.mr-1.text-gray-600(name="chevron-right")
        .font-lg.text-tiber {{ currentName }}

</template>

<script>
import NIcon from "@/components/NovaIcon";

export default {
  name: "NBreadcrumb",
  components: {
    NIcon,
  },
  props: {
    rootName: {
      type: String,
      default: null,
    },
    rootUrl: {
      type: String,
      default: null,
    },
    levels: {
      type: Array,
      default: () => [],
    },
    currentName: {
      type: String,
      default: null,
    },
    rootIcon: {
      type: String,
      default: null,
    },
    rootIconPathCount: {
      type: Number,
      default: 2,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

.main-breadcrumb-container {
  .breadcrumb-item {
    & > a:hover {
      text-decoration: none;
    }
    .levels {
      cursor: default;
    }
    .root:hover {
      color: $tiber;
      span {
        text-decoration: underline;
      }
    }
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: "";
  }
  ol {
    align-items: center;
  }
}
</style>
