import { getListQuery, getSingleQuery } from "../queries";
import hospitalsSchema from "../schemas/hospitals";
import hospitalsMutations from "../mutations/hospitals";

export default {
  gqlType: "Hospital",
  fields: ["name", "address", "city", "pincode"],
  singleQuery: getSingleQuery("Hospital"),
  listQuery: getListQuery("Hospital"),
  ...hospitalsSchema,
  ...hospitalsMutations,
  transform: (n) => {
    return { ...n };
  },
}
