<template lang="pug">
n-overlay
  div
    .d-flex.flex-column.w-100.h-100.overflow-hidden
      .shadow.fixed-top.bg-white
        .container
          .row.d-flex.align-items-center.py-3.justify-content-between
            n-icon.d-md-none.ml-3(name="arrow", variant="gray-900", @click="goBack")
            .d-flex.align-items-center 
              n-icon.d-none.d-md-block.mr-2.ml-2(name="document-new", variant="gray-900")
              span.font-lg.font-weight-semibold Documents Upload
            n-icon.d-md-none.mr-3(name="more", variant="gray-900")
            n-button.d-none.d-md-block.mr-4.px-4(variant="success", buttonText="Save & Exit", @click="saveAndExit", size="sm")
      .overflow-scroll.pt-5.my-5
        .container
          .row.d-none.d-md-flex(v-if="!isMobile")
            .col-md-8
              .d-flex.shadow-sm.border.rounded.mb-1(v-for="type of Object.keys(types)")
                .col-md-4.pt-4
                    span.font-weight-semibold {{type}}
                    b-link.d-block.mt-3(v-if="type === 'Insurance Claim Forms' && claimFormsLink" @click.prevent="downloadForms")
                      .d-flex 
                        i.icon-download.mr-2
                        | Download Forms(s)
                .col-md-8
                  batch-uploader-wrapper.mb-5(v-model="uploadedFiles", :types="types[type]")
            .col-md-4
              .bg-white.border.shadow-sm.rounded.px-4.py-4
                span.font-weight-semibold.text-gray-900.text-nowrap.font-xl {{totalUploadedDocuments}}/{{totalDocuments}} 
                span.font-weight-medium.text-gray-700 Documents uploaded
                .d-flex.align-items-center.justify-content-between.mt-2
                  .progress.progress-bar-container
                    .progress-bar.pb(
                      role="'progressbar'",
                      :aria-valuenow="totalUploadedDocuments",
                      aria-valuemin="0",
                      :aria-valuemax="totalDocuments",
                      :style="'width: '+(100/totalDocuments)*totalUploadedDocuments+'%;'")
                hr
                span.font-lg.font-weight-semibold.text-gray-900 Let’s get started! ☘️
                .mt-2
                  span.font-weight-medium.text-gray-700 P.S: Users who upload all their documents at this stage see
                  span.font-weight-medium.text-teal-700.ml-1 40% faster claim settlements
              .bg-malibu-100.mt-3.shadow-sm.rounded.px-4.py-4
                span.d-block.font-xl.mb-2 👋
                span.font-weight-semibold.text-gray-900 It’s okay if you don’t have all the documents on you right now
                .mt-2
                  span.font-sm.text-gray-700 Upload the documents that are available with you right now and you can upload the rest at a later stage. 
                  
          .d-flex.flex-column.d-md-none(v-else)
            .d-flex.justify-content-between
              span.font-weight-semibold.mb-3 {{ segment }}
              .d-flex.mr-2
                span.font-weight-semibold.mb-3(:class="totalFilesInSegment === 0?'text-danger':'text-gray-900'") {{totalFilesInSegment}}/{{types[segment].length}}
                span.font-weight-semibold.text-gray-500 &nbsp;uploaded 
            b-link.d-block(v-if="segment === 'Insurance Claim Forms' && claimFormsLink" @click.prevent="downloadForms")
              .d-flex 
                i.icon-download.mr-2
                | Download Forms(s)
            batch-uploader-wrapper.mb-5(v-model="uploadedFiles", :types="types[segment]")
      .p-5
      .fixed-bottom.bg-white.border.shadow-lg.px-4.py-4.d-md-none
        .container
          .d-flex.align-items-center.justify-content-between
            span.font-weight-semibold.text-gray-900.text-nowrap Section {{currentStep}}/{{totalSteps}}
            .progress.progress-bar-container.ml-2
              .progress-bar.pb(
                role="'progressbar'",
                :aria-valuenow="currentStep",
                aria-valuemin="0",
                :aria-valuemax="totalSteps",
                :style="'width: '+(100/totalSteps)*currentStep+'%;'")
          .mt-4
            span.font-weight-medium.text-gray-700 {{ bottomSheet.content }} 
            span.font-weight-medium.text-teal-700 {{ bottomSheet.highlight }}
          .mt-4
            n-button.w-100(variant="success", :buttonText="bottomSheet.actionText", @click="saveAndProceed")
  
    special-modal(
      id="congrats-modal",
      headerIcon="icon",
      :heroImage="require('@/assets/images/yellow-diamonds-cover.svg')",
      logoIcon="charge", 
      centered,
      @hide="gotoDetailsPage")
      template(v-slot:title)
        h3.hb3.d-inline.font-weight-semibold.text-gray-900 Documents Saved! ✨
      template(v-slot:modal-content)
        .text-center
          | Your documents are saved and sound. 
          br
          | Redirecting you to ‘Claim Details’...
      template(v-slot:buttons-area)
        .rounded-circle
</template>
<script>
import userDefs from "../definitions";
import adminDefs from "../../admin/definitions";
import NButton from "@/components/NovaButton";
import NOverlay from "@/components/Overlay";
import BatchUploaderWrapper from "./components/BatchUploaderWrapper.vue";
import SpecialModal from "@/components/SpecialModal";
import { getContentFromPolicyClaimContent, getContentFromOldClaimContent } from "../../../../utils/misc";
import { claimDocumentSegments, claimDocumentsList } from "../../admin/constants";
import utils from "@/utils";
export default {
  components: {
    NButton,
    NOverlay,
    SpecialModal,
    BatchUploaderWrapper,
  },
  data() {
    return {
      claimId: null,
      uploadedFiles: [],
      currentStep: 1,
      totalSteps: 4,
      types: claimDocumentSegments,
      claimFormsLink: null,
    };
  },
  created() {
    this.claimId = this.$route.params.id;
  },
  computed: {
    isMobile() {
      return utils.mobileCheck();
    },
    segmentedFiles() {
      const files = {};
      this.uploadedFiles.forEach((file) => {
        if (!file.docType) file.docType = "Other";
        if (!(file.docType in files)) files[file.docType] = [];
        files[file.docType].push(file);
      });
      return files;
    },
    totalFilesInSegment() {
      let totalUploadedFiles = 0;
      this.types[this.segment].forEach((key) => {
        if (key in this.segmentedFiles) {
          totalUploadedFiles = totalUploadedFiles + 1;
        }
      });
      return totalUploadedFiles;
    },
    bottomSheet() {
      const actionText = this.currentStep === this.totalSteps ? "Save & Exit" : "Save & Proceed";
      let content = "P.S: Users who upload all their documents at this stage see",
        highlight = "40% faster claim settlements";
      if (this.currentStep === 1) {
        content = "It’s okay if you don’t have all the documents right now.";
        highlight = "Just upload the available ones!";
      } else if (this.currentStep === this.totalSteps) {
        content = `You’ve uploaded total ${this.totalUploadedDocuments} out of ${this.totalDocuments} documents.`;
        highlight = "";
      }
      return {
        content,
        highlight,
        actionText,
      };
    },
    totalUploadedDocuments() {
      return Object.keys(this.segmentedFiles).length;
    },
    totalDocuments() {
      return claimDocumentsList.length;
    },
    segment() {
      const segments = Object.keys(claimDocumentSegments);
      return segments[this.currentStep - 1];
    },
  },
  methods: {
    async saveAndProceed() {
      if (this.currentStep === this.totalSteps) {
        await this.saveAndExit();
      } else {
        await this.saveData();
      }
      this.currentStep = this.currentStep < this.totalSteps ? this.currentStep + 1 : this.totalSteps;
    },
    async saveAndExit() {
      await this.saveData();
      this.$apollo.queries.claim.refetch();
      this.$bvModal.show("congrats-modal");
      setTimeout(() => {
        this.gotoDetailsPage();
      }, 3000);
    },
    async saveData() {
      const meta = {
        ...this.claim.meta,
        files: this.uploadedFiles,
      };
      try {
        await this.$apollo.mutate({
          mutation: userDefs.claims.upsertMutation,
          variables: {
            status: this.claim.status,
            meta,
            id: this.claim.id,
            userId: this.claim.user.id,
            type: this.claim.type,
          },
        });
      } catch (err) {
        console.log(err);
      }
    },
    gotoDetailsPage() {
      this.$router.push({
        name: "claim-details",
        params: { id: this.claim.id },
      });
    },
    goBack() {
      if (this.currentStep === 1) {
        this.gotoDetailsPage();
      } else {
        this.currentStep = this.currentStep - 1;
      }
    },
    downloadForms() {
      if (this.claimFormsLink) {
        window.open(this.claimFormsLink, "_blank");
      }
    },
  },
  apollo: {
    claim: {
      query: userDefs.claims.singleQuery,
      variables() {
        return { id: this.$route.params.id };
      },
      update(data) {
        let claim = userDefs.claims.transform(data.node);
        if (claim?.meta?.files && claim.meta.files.length) {
          this.uploadedFiles = JSON.parse(JSON.stringify(claim.meta.files));
        }
        return claim;
      },
    },
    policy: {
      query: adminDefs.policies.singleQuery,
      skip() {
        return !this.claim?.policy;
      },
      variables() {
        return { id: this.claim.policy.id };
      },
      update(data) {
        // To maintain backwards compatibility with old claim content types
        // and to handle cases where claimFormLink is in only one of the three
        const reimbursement = getContentFromPolicyClaimContent(data.node?.policyClaimContent, "Reimbursement");
        const cashless = getContentFromPolicyClaimContent(data.node?.policyClaimContent, "Cashless");
        const emergency = getContentFromPolicyClaimContent(data.node?.policyClaimContent, "Emergency");
        const reimbursementLink = getContentFromOldClaimContent(data.node, "reimbursementContent");
        const cashlessLink = getContentFromOldClaimContent(data.node, "cashlessContent");
        const emergencyLink = getContentFromOldClaimContent(data.node, "emergencyContent");
        let link;
        if (cashless) {
          link = cashless.claimFormLink;
        } else if (reimbursement) {
          link = reimbursement.claimFormLink;
        } else if (emergency) {
          link = emergency.claimFormLink;
        } else if (cashlessLink) {
          link = cashlessLink;
        } else if (reimbursementLink) {
          link = reimbursementLink;
        } else if (emergencyLink) {
          link = emergencyLink;
        } else {
          link = data.node.content?.dynamicContent[0]?.claimFormsLink;
        }
        this.claimFormsLink = link;
        return data.node;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.progress-bar-container {
  width: 100%;
  height: 5px;
  .pb {
    background-color: $blue-500;
    border-radius: 32px;
  }
}
</style>
