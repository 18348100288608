import gql from "graphql-tag";
import {
  userSegmentFragment,
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertUserSegment(
      $id: ID
      $userId: ID
      $segmentId: ID
      $meta: JSONObject
    ) {
      upsertUserSegment(
        input: { id: $id, userId: $userId, segmentId: $segmentId, meta: $meta }
      ) {
        userSegment {
          ...UserSegment
        }
      }
    }
    ${userSegmentFragment}
  `,
  createMutation: gql`
    mutation createUserSegmentByUserIds(
      $orgId: ID
      $userIds: [ID]
      $segmentId: ID
      $emails: [String]
    ) {
      createUserSegmentByUserIds(
        input: { orgId: $orgId, userIds: $userIds, segmentId: $segmentId, emails: $emails }
      ) {
        success
      }
    }
  `,
  deleteMutation: gql`
    mutation deleteUserSegment(
      $userId: ID
      $segmentId: ID
    ) {
      deleteUserSegment(
        input: { userId: $userId, segmentId: $segmentId }
      ) {
        success
      }
    }
  `,
  bulkDeleteMutation: gql`
    mutation bulkDeleteUserSegments(
      $userId: ID
      $segmentId: ID
    ) {
      bulkDeleteUserSegments(
        input: { userId: $userId, segmentId: $segmentId }
      ) {
        success
      }
    }
  `,
}
