export default {
  schema: {
    fields: [
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Content Name",
      },
      {
        model: "slug",
        type: "input",
        inputType: "text",
        label: "Content Slug",
      },
      {
        model: "type",
        type: "select",
        values: ["html", "markdown"],
        label: "Content Type",
      },
      {
        model: "content",
        type: "html",
        label: "Content",
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
      },
    ],
  },
}
