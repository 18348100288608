import { getListQuery, getSingleQuery } from "../queries";
import challengeTemplatesSchema from "../schemas/challengeTemplates";
import challengeTemplatesMutations from "../mutations/challengeTemplates";

export default {
  gqlType: "ChallengeTemplate",
  fields: ["name", "slug", "activityTypes", "duration", "points"],
  listQuery: getListQuery("ChallengeTemplate"),
  singleQuery: getSingleQuery("ChallengeTemplate"),
  ...challengeTemplatesSchema,
  ...challengeTemplatesMutations,
  transform: (n) => {
    if (n.challenge?.meta?.id) {
      n.challenge = {
        ...n.challenge,
        id: n.challenge?.meta?.id,
        name: `${n.challenge?.slug} (${n.challenge?.meta?.id})`,
      };
    }
    return {
      ...n,
      challengeContentId: n.challenge?.meta?.id || n.challenge?.id,
      meta: {
        ...n.meta,
        name: n.challenge?.name,
        templateType: n.templateType,
      },
      slug: n.challenge?.name,
      templateType: n.meta?.templateType,
      challengeUnit: n.challengeUnit || n.meta?.challengeUnit,
      topScorerPoints: n.topScorerPoints || n.meta?.topScorerPoints,
      goalValueByUnit: n.goalValueByUnit || n.meta?.goalValueByUnit,
    };
  },
}
