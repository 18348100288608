import gql from "graphql-tag";
import {
  segmentFragment,
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertSegment(
      $id: ID
      $name: String
      $type: String
      $category: String
      $meta: JSONObject
      $orgId: ID
    ) {
      upsertSegment(
        input: { id: $id, type: $type, category: $category, name: $name, meta: $meta, orgId: $orgId }
      ) {
        segment {
          ...Segment
        }
      }
    }
    ${segmentFragment}
  `,
  deleteMutation: gql`
    mutation deleteSegment($id: ID!) {
      deleteSegment( input: {id: $id}) {
        status
      }
    }
  `,
};
