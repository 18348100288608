import gql from "graphql-tag";

export const insurerFragment = gql`
  fragment Insurer on Insurer {
    id
    name
    type
    photoUrl
    slug
    meta
    hospitalContent {
      id
      name
      slug
    }
  }
`;
