import gql from "graphql-tag";

export const orgOnboardingStageTaskUpdateFragment = gql`
  fragment OrgOnboardingStageTaskUpdate on OrgOnboardingStageTaskUpdate {
    id
    meta
    user {
      id
      name
    }
    orgOnboardingStageTask {
      id
      stage
      task
      status
      org {
        id
        name
      }
      benefit {
        id
        name
        isPolicy
      }
    }
    updatedAt
  }
`;
