export const Base = require("./Base.vue").default;
export const routes = [
  {
    path: "",
    component: () => import(/* webpackChunkName: "admin" */ "./Home.vue"),
    name: "admin_home",
  },
  {
    path: "clitools",
    component: () => import(/* webpackChunkName: "admin" */ "./CliTools.vue"),
    name: "admin_cli_tools",
  },
  {
    path: "compare-policy",
    component: () => import(/* webpackChunkName: "admin" */ "./ComparePolicy.vue"),
    name: "admin_compare_policy",
  },
  {
    path: "review",
    component: () => import(/* webpackChunkName: "org_admin" */ "./ReviewChanges.vue"),
    name: "admin_review",
  },
  {
    path: "hr-onboarding",
    component: () => import(/* webpackChunkName: "admin" */ "./HrOnboarding.vue"),
    name: "admin_hr_onboarding",
  },
  {
    path: "review/:orgId/changes",
    component: require("../endorsements/Base.vue").default,
    children: require("../endorsements").routes,
    name: "admin_review_endorsements",
  },
  {
    path: "orgs/:orgId/documents",
    component: () => import(/* webpackChunkName: "admin" */ "./OrgDocument.vue"),
    name: "admin_org_document",
  },
  {
    path: "review/:orgId/changes/premium-details/:batchId",
    component: require("../endorsements/PremiumDetails.vue").default,
    children: require("../endorsements").routes,
    name: "admin_premium_details",
  },
  {
    path: "orgProperties/create",
    component: () => import(/* webpackChunkName: "admin" */ "./OrgPropertySingle.vue"),
    name: "admin_org_property_create",
  },
  {
    path: "orgProperties/:id/edit",
    component: () => import(/* webpackChunkName: "admin" */ "./OrgPropertySingle.vue"),
    name: "admin_org_property_edit",
  },
  {
    path: "userSegments/create",
    component: () => import(/* webpackChunkName: "admin" */ "./UserSegmentSingle.vue"),
    name: "admin_user_segment_create",
  },
  {
    path: "userSegments/:id/edit",
    component: () => import(/* webpackChunkName: "admin" */ "./UserSegmentSingle.vue"),
    name: "admin_user_segment_edit",
  },
  {
    path: "claimsV2/create",
    component: () => import(/* webpackChunkName: "admin" */ "./Claims.vue"),
    name: "claim_create",
    meta: { featureFlags: ["SUPER_ADMIN_CLAIM_FORM"] },
  },
  {
    path: "CXPodOnboarding",
    component: () => import(/* webpackChunkName: "admin" */ "./CXPodOnboardingForm.vue"),
    name: "cxpod_onboardingform_create",
  },
  {
    path: "claimsV2/:id/edit",
    component: () => import(/* webpackChunkName: "admin" */ "./Claims.vue"),
    name: "claim_edit",
    meta: { featureFlags: ["SUPER_ADMIN_CLAIM_FORM"] },
  },
  {
    path: "ngageCalendar",
    component: () => import(/* webpackChunkName: "admin" */ "./NgageCalendarList.vue"),
    name: "ngage_calendar_list",
  },
  {
    path: "ngageCalendar/create",
    component: () => import(/* webpackChunkName: "admin" */ "./NgageCalendarSingle.vue"),
    name: "ngage_calendar_create",
  },
  {
    path: "ngageCalendar/:id/edit",
    component: () => import(/* webpackChunkName: "admin" */ "./NgageCalendarSingle.vue"),
    name: "ngage_calendar_edit",
  },
  {
    path: ":resource",
    component: () => import(/* webpackChunkName: "admin" */ "./ResourceList.vue"),
    name: "admin_res_list",
  },
  {
    path: ":resource/create",
    component: () => import(/* webpackChunkName: "admin" */ "./ResourceSingle.vue"),
    name: "admin_res_create",
  },
  {
    path: ":resource/:id",
    component: () => import(/* webpackChunkName: "admin" */ "./ResourceSingle.vue"),
    name: "admin_res_show",
  },
  {
    path: ":resource/:id/edit",
    component: () => import(/* webpackChunkName: "admin" */ "./ResourceSingle.vue"),
    name: "admin_res_edit",
  },
  {
    path: ":resource/:id/delete",
    component: () => import(/* webpackChunkName: "admin" */ "./ResourceSingle.vue"),
    name: "admin_res_delete",
  },
];
