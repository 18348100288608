<template lang="pug">
.row.mx-0.px-0
  .link.text-left.pb-1.px-0(v-if="fieldLabel", :class="'col-12'")
    template(v-if="chip && chip.text")
      .d-flex.justify-content-between.align-items-center
        span.field-label(:class="'font-sm'") {{ fieldLabel }}
        nova-chip(:variant="chip.variant") {{ chip.text }}
    template(v-else)
      span(:class="'font-sm'") {{ fieldLabel }}
  b-input-group.combo-datepicker(v-if="!includeTimePicker" :class="state")
    b-form-input(
      :id="id",
      v-model="displayValue",
      :placeholder="placeholder || `DD-MM-YYYY`",
      class="pl-3",
      type="text",
      :formatter="formatter",
      lazy-formatter,
      @keydown.enter="onEnter",
      :disabled ="disabled",
      :readonly="readonly",
      :required="required")
    b-input-group-append
      b-form-datepicker(
        locale="en-GB",
        v-bind:value="value",
        :disabled="disabled || readonly",
        :required="required",
        :readonly="readonly",
        :date-format-options="{}",
        :hide-header="true",
        :show-decade-nav="true",
        button-only,
        button-variant="outline-secondary",
        @context="onContext",
        :min="min",
        :max="max")

  //- This is a bootstrap date-time-picker imported from the vue-bootstrap-datetimepicker library.
  //- Unfortunately, this takes majority of the config parameters as part of the :config binded expression.
  //- The setup of the config is done as part of the data() method.
  //- TODO: We're going forward with this date picker due to time constraints. Come back to this in the future
  //- and use a better lib. Probably something like ant-design-vue.
  date-picker(v-else
    v-model="dateTimePickerValue"
    :placeholder="placeholder"
    :disabled="disabled || readonly"
    :required="required"
    :config="dateTimePickerConfig"
    @dp-change="onDateTimeChange"
  )

  .input-sub-text.text-left.pt-2.w-100(v-if="validationMessage", :class="state")
    n-icon.pr-1.sub-text-icon.align-middle(v-if="state && state !== 'disabled'", :name="stateIconMap[state] || ''", :size="1")
    span.align-middle {{ validationMessage }}
  .d-block.input-sub-text.text-left.py-2.w-100(v-if="subText")
    span.py-5 {{ subText }}
</template>

<script>
import moment from "moment";
import NovaChip from "../NovaChip.vue";
import NIcon from "../NovaIcon.vue";

/** 3rd party date picker that includes time selection */
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import datePicker from "vue-bootstrap-datetimepicker";

// TODO: Confirm we have suitable types
export default {
  components: {
    NovaChip,
    NIcon,
    datePicker,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    value: {
      type: String | Date,
      default: null,
    },
    includeTimePicker: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: "1900-01-01",
    },
    max: {
      type: String,
      default: "2100-01-01",
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
    subText: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: null,
    },
    chip: {
      type: Object,
      default: () => ({}),
    },
    fieldLabel: {
      type: String,
      default: null,
    },
    state: {
      type: String,
      default: null,
    },
    validationMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    const stateIconMap = {
      success: "check-circle",
      error: "error",
      warning: "warning",
    };
    return {
      displayValue: "",
      displayFormat: this.$props.includeTimePicker ? "DD/MM/YYYY HH:mm:ss" : "DD/MM/YYYY",
      stateIconMap,
      dateTimePickerConfig: {
        format: "DD/MM/YYYY HH:mm:ss",
        minDate: this.min || "1900-01-01",
        maxDate: this.max || "2100-12-31",
        locale: "en-gb",
      },
    };
  },
  computed: {
    dateTimePickerValue: {
      get() {
        return moment(this.value);
      },
      set(value) {
        return;
      },
    },
  },
  watch: {
    value: function () {
      this.$emit("input", this.value);
    },
  },
  methods: {
    onDateTimeChange($event) {
      this.$emit("input", $event.date.toISOString());
    },
    onContext(ctx) {
      const value = ctx.selectedYMD;
      if (value) this.displayValue = ctx.selectedFormatted;
      else this.displayValue = null;
      this.$emit("input", value);
    },
    onEnter(e) {
      this.displayValue = this.formatter(this.displayValue);
    },
    formatter(val) {
      const date = moment(val, this.displayFormat);
      if (!date.isValid()) {
        this.$emit("input", null);
        return null;
      }
      let value = date.toDate();
      if (this.min) {
        const minDate = moment(this.min).toDate();
        if (value < minDate) value = minDate;
      }
      if (this.max) {
        const maxDate = moment(this.max).toDate();
        if (value > maxDate) value = maxDate;
      }
      this.$emit("input", date.format("YYYY-MM-DD"));
      return date.format(this.displayFormat);
    },
  },
};
</script>

<style scoped>
::-webkit-input-placeholder {
  font-size: 0.65rem;
}
</style>
