import gql from "graphql-tag";
import { strapiContentFragment } from "./strapi";
import { tpaFragment } from "./tpa";

export const benefitFragment = gql`
  fragment Benefit on Benefit {
    id
    name
    tag
    type
    meta
    coverageParameters
    isPolicy
    restrictedFields
    chargesConfig
    insurer {
      id
      slug
      name
      photoUrl
      s3Url
    }
    tpa {
      ...Tpa
    }
    content {
      ...StrapiContent
    }
    cashlessContent {
      ...StrapiContent
    }
    reimbursementContent {
      ...StrapiContent
    }
    emergencyContent {
      ...StrapiContent
    }
    policyClaimContent {
      ...StrapiContent
    }
  }
  ${tpaFragment}
  ${strapiContentFragment}
`;

export const benefitFragmentWithoutContent = gql`
  fragment BenefitWithoutContent on Benefit {
    id
    name
    type
    meta
    isPolicy
    restrictedFields
    chargesConfig
    insurer {
      id
      slug
      name
      photoUrl
    }
    tpa {
      ...Tpa
    }
  }
  ${tpaFragment}
`;

export const dependentBenefitFragment = gql`
  fragment DependentBenefitEdge on DependentBenefitEdge {
    node {
      ...Benefit
    }
    meta
  }
  ${benefitFragment}
`;

export const dependentBenefitFragmentWithoutContent = gql`
  fragment DependentBenefitEdgeWithoutContent on DependentBenefitEdge {
    node {
      ...BenefitWithoutContent
    }
    meta
  }
  ${benefitFragmentWithoutContent}
`;
