<template lang="pug">
b-avatar(v-if="loading", :variant="variant", :size="size+'rem'")
  b-spinner(small)
b-avatar(
  v-else-if="icon!==null",
  :variant="variant",
  :size="size+'rem'",
  :button="button",
  @click="handleClick",
  :class="[bgColor ? `bg-${bgColor}` : '']",
)
  .w-100.h-100.d-flex.justify-content-center.align-items-center.rounded-circle
    i.ic-small(:class="[`icon-${icon}`, `text-${iconColor}`]")
b-avatar.avatar-border.shadow-sm(
  v-else,
  :size="size+'rem'",
  :button="button",
  @click="handleClick",
  style="border-color: white !important; background-color: white !important",
  v-b-tooltip.hover="{placement: tooltipPlacement}",
  :title="tooltipContent",
)
  b-avatar(
    v-if="image!==null || avatar!==null",
    :variant="variant",
    :size="innerAvatarSize+'rem'",
    :button="button",
    @click="handleClick",
    :class="[bgColor ? `bg-${bgColor}` : '']",
  )
    img.img-fluid.rounded-sides(
      v-if="image",
      :src="image"
      :style="`${avatarSizeStyles}`",
    )
    img.img-fluid(
      v-else="avatar",
      :src="require(`@/assets/images/avatar-${avatar}.svg`)",
    )
  b-avatar.font-weight-medium(
    v-else,
    :text="avatarText",
    :variant="variant",
    :style="`${avatarSizeStyles} ${variant?'':avatarColorStyles}`",
  )
</template>

<script>
import ColorHash from "color-hash";
import utils from "@/utils";
export default {
  props: {
    image: {
      type: String,
      default: null,
    },
    avatar: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: "white",
    },
    name: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: null,
    },
    button: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 2.125,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    extraNames: {
      type: String,
      default: null,
    },
    tooltipPlacement: {
      type: String,
      default: "top",
    },
  },
  data() {
    return {};
  },
  computed: {
    avatarText() {
      if (this.name?.charAt(0) === "+") return this.name;
      return utils.getNameInitials(this.name || "");
    },
    tooltipContent() {
      if (this.extraNames) return this.extraNames;
      return this.name;
    },
    avatarColorStyles() {
      const textHash = new ColorHash({ lightness: 0.2 });
      const bgHash = new ColorHash({ lightness: 0.9 });
      const textHex = textHash.hex(this.name);
      const bgHex = bgHash.hex(this.name);
      return `color: ${textHex}; background-color: ${bgHex} !important; border-color: ${bgHex} !important;`;
    },
    avatarSizeStyles() {
      // Required instead of size prop of b-avatar as b-avatar group overrides size of inner avatar
      return `height: ${this.innerAvatarSize}rem !important; width: ${
        this.innerAvatarSize
      }rem !important; font-size: ${this.size * 0.4}rem !important;`;
    },
    innerAvatarSize() {
      return this.size * 0.85;
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss"></style>
