import gql from "graphql-tag";

export const integrationFragment = gql`
  fragment Integration on Integration {
    id
    org {
      id
      name
    }
    integrationType
    meta
    secrets
    status
  }
`;
