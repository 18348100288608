import { getListQuery } from "../queries";
import { claimDocumentsList, claimStatusList, claimStatusListAsCrm, allClaimStageOptions } from "../constants";
import employeeDetailsSchema from "./ClaimForm/employeeDetails";
import applicationDetailsSchema from "./ClaimForm/applicationDetails";
import escalationDetailsSchema from "./ClaimForm/escalationDetailsSchema";
import claimInfoAndTrackingSchema from "./ClaimForm/claimInfoAndTrackingSchema";
import claimDocUploadSchema from "./ClaimForm/claimDocUploadSchema";
import claimTimelineDetailsSchema from "./ClaimForm/timelineDetails";
import claimSupportDetailsSchema from "./ClaimForm/supportDetails";
import claimOwnershipDetailsSchema from "./ClaimForm/ownershipDetails";
import hospitalDetailsSchema from "./ClaimForm/hospitalDetails";

export default {
  schema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getListQuery("Org"),
        label: "Organization",
        required: true,
      },
      {
        model: "policy",
        type: "id-selector",
        queryName: "benefits",
        // TODO: Find a way to pass an argument here
        gqlQuery: getListQuery("Benefit"),
        getSubTitle: (u) => u.name,
        queryVariables: () => ({
          filter: {
            userId: "",
            isPolicy: true,
          },
        }),
        label: "Policy",
        required: true,
      },
      {
        model: "user",
        type: "id-selector",
        queryName: "users",
        gqlQuery: getListQuery("User"),
        getSubTitle: (u) => u.name,
        label: "User",
        required: true,
      },
      {
        model: "status",
        type: "select",
        values: claimStatusList,
        label: "Status",
        required: true,
      },
      {
        model: "type",
        type: "select",
        values: ["cashless", "reimbursement"],
        label: "Claim type",
        required: true,
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        schema: {
          fields: [
            {
              type: "checkbox",
              label: "This is an emergency situation",
              model: "emergency",
            },
            {
              type: "input",
              inputType: "text",
              label: "Tpa Claim Number",
              model: "claimNumber",
              placeholder: "Claim ID on TPA's portal",
            },
            {
              type: "select",
              label: "Category",
              model: "category",
              values: [
                { id: "daycare", name: "Daycare Procedure" },
                { id: "hospitalization", name: "Hospitalization" },
              ],
              required: true,
            },
            {
              type: "input",
              inputType: "text",
              label: "City",
              model: "city",
              placeholder: "City where the patient is being/was treated",
              required: true,
            },
            {
              type: "textArea",
              label: "Hospital Name and Address",
              model: "hospital",
              placeholder: "Name and address of the hospital involved",
              required: true,
            },
            {
              type: "input",
              inputType: "contact",
              label: "Mobile number",
              model: "contact",
              placeholder: "Your contact number",
              required: true,
            },
            {
              type: "input",
              inputType: "text",
              label: "Diagnosis/Procedure",
              model: "diagnosis",
              placeholder: "The diagnosis the patient received or the procedure/operation performed in this claim",
              required: true,
            },
            {
              type: "input",
              inputType: "text",
              label: "Treating Doctor",
              model: "doctor",
              placeholder: "Treating Doctor's Name",
            },
            {
              model: "doa",
              type: "datepicker",
              label: "Date of Admission",
              min: "1900-01-01",
              max: "2100-12-31",
            },
            {
              model: "dod",
              type: "datepicker",
              label: "Date of Discharge",
              min: "1900-01-01",
              max: "2100-12-31",
            },
            {
              type: "input",
              inputType: "number",
              label: "Claim Amount",
              model: "claimAmount",
              placeholder: "Amount claimed",
            },
            {
              type: "input",
              inputType: "number",
              label: "Approved Amount",
              model: "approvedAmount",
              placeholder: "Amount approved",
            },
            {
              model: "expectedApprovalDate",
              type: "datepicker",
              label: "Expected date of approval",
              min: "1900-01-01",
              max: "2100-12-31",
            },
            {
              type: "input",
              inputType: "text",
              label: "Account Holder",
              model: "accountHolder",
              placeholder: "Enter Account Holder’s Name",
            },
            {
              type: "input",
              inputType: "text",
              label: "IFSC Code",
              model: "ifsc",
              placeholder: "Enter IFSC Code",
            },
            {
              type: "input",
              inputType: "text",
              label: "Account Number",
              model: "accountNumber",
              placeholder: "Enter Account Number",
            },
            {
              type: "textArea",
              inputType: "text",
              label: "Details",
              model: "details",
              placeholder: "Any other details you want to share",
            },
            {
              type: "textArea",
              inputType: "text",
              label: "Note",
              model: "note",
              placeholder: "Any note for the user",
            },
            {
              type: "files-uploader",
              model: "files",
              modal: true,
              options: claimDocumentsList,
              placeholder: "Response will come here...",
            },
            {
              model: "queries",
              type: "array",
              itemContainerComponent: "form-container",
              label: "Queries",
              items: {
                type: "object",
                schema: {
                  fields: [
                    {
                      model: "status",
                      type: "select",
                      label: "Query Status",
                      default: "open",
                      values: ["open", "draft", "submitted", "resolved"],
                    },
                    {
                      type: "textArea",
                      inputType: "text",
                      label: "Query Content",
                      model: "content",
                      placeholder: "Insert your query here.",
                    },
                    {
                      model: "response",
                      type: "object",
                      schema: {
                        fields: [
                          {
                            type: "textArea",
                            inputType: "text",
                            label: "Response",
                            model: "details",
                            disabled: true,
                            placeholder: "Response will come here...",
                          },
                          {
                            type: "files-uploader",
                            model: "files",
                            modal: true,
                            options: claimDocumentsList,
                            placeholder: "Response will come here...",
                          },
                        ],
                      },
                    },
                  ],
                },
              },
            },
          ],
        },
      },
    ],
  },
  employeeDetailsSchema,
  applicationDetailsSchema,
  escalationDetailsSchema,
  claimInfoAndTrackingSchema,
  claimDocUploadSchema,
  claimTimelineDetailsSchema,
  claimOwnershipDetailsSchema,
  claimSupportDetailsSchema,
  hospitalDetailsSchema,
  filterSchema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getListQuery("Org"),
        placeholder: "Organization filter (none)",
        showSelected: false,
      },
      {
        model: "claimOwner",
        type: "id-selector",
        queryName: "users",
        gqlQuery: getListQuery("User"),
        queryVariables: () => ({
          limit: 10,
          offset: 0,
          filter: { roles: ["account_admin"] },
        }),
        placeholder: "Claim Owner (none)",
        showSelected: false,
      },
      {
        model: "insurer",
        type: "id-selector",
        queryName: "insurers",
        gqlQuery: getListQuery("Insurer"),
        placeholder: "Insurer (none)",
        showSelected: false,
      },
      {
        model: "tpa",
        type: "id-selector",
        queryName: "tpas",
        gqlQuery: getListQuery("Tpa"),
        placeholder: "TPA (none)",
        showSelected: false,
      },
      {
        model: "status",
        label: "Status",
        type: "inline-select",
        placeholder: "Status",
        options: claimStatusListAsCrm,
      },
      {
        model: "claimType",
        label: "Type",
        type: "inline-select",
        reduce: (v) => v.value,
        options: [
          { label: "Cashless", value: "cashless" },
          { label: "Reimbursement", value: "reimbursement" },
        ],
      },
      {
        model: "claimStage",
        type: "inline-select",
        label: "Claim Stage",
        options: allClaimStageOptions,
      },
      {
        model: "orderBy",
        type: "inline-select",
        placeholder: "Order By",
        label: "Order By",
        reduce: (v) => v.value,
        options: [
          {
            label: "Created",
            value: "created_at",
          },
          {
            label: "Updated",
            value: "updated_at",
          },
        ],
      },
    ],
  },
};
