<template lang="pug">
  .py-2(v-if="types.length > 0")
    batch-uploader.mt-2(v-for="type of types" :type="type" v-model="segmentedFiles[type]" :key="type")
</template>
<script>
import BatchUploader from "./BatchUploader.vue";
export default {
  components: {
    BatchUploader,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filesArray: [],
    };
  },
  watch: {
    filesArray: {
      handler(newVal, oldVal) {
        let allFiles = [];
        Object.keys(newVal).forEach((key) => {
          allFiles = [...allFiles, ...newVal[key]];
        });
        if (JSON.stringify(this.value) !== JSON.stringify(allFiles)) {
          this.$emit("input", allFiles);
        }
      },
      deep: true,
    },
  },
  computed: {
    segmentedFiles() {
      const files = {};
      this.value.forEach((file) => {
        if (!file.docType) file.docType = "Other";
        if (!(file.docType in files)) files[file.docType] = [];
        files[file.docType].push(file);
      });
      this.filesArray = files;
      return files;
    },
  },
};
</script>
