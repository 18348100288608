import { getListQuery, getSingleQuery } from "../queries";
import integrationsSchema from "../schemas/integrations";
import integrationsMutations from "../mutations/integrations";

export default {
  gqlType: "Integration",
  fields: ["orgName", "integrationType"],
  listQuery: getListQuery("Integration"),
  singleQuery: getSingleQuery("Integration"),
  ...integrationsSchema,
  ...integrationsMutations,
  transform: (n) => {
    return {
      ...n,
      orgName: n.org.name,
      orgId: n.org.id,
    };
  },
}
