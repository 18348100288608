<template lang="pug">
pills-group(
  v-if="item.type",
  :itemId="item.id",
  :popoversData="popoversData",
  :normalItems="filterBenefitsByType(item.meta).policies",
  :plusItems="filterBenefitsByType(item.meta).benefits",
  :triggers="triggers",
  :changesType="item.type",
  plusItemChipText="Benefits",
  :statusText="statusText",
  :statusChipVariant="statusChipVariant")
</template>

<script>
import resDefs from "../../orgAdmin/definitions";
import PillsGroup from "./PillsGroup.vue";
import PlusNChip from "./PlusNChip";
import NButton from "@/components/NovaButton.vue";
import NChip from "@/components/NovaChip.vue";

export default {
  name: "CoveragePillsGroupWrapper",
  components: {
    NButton,
    PlusNChip,
    NChip,
    PillsGroup,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    triggers: {
      type: String,
      default: "",
    },
    statusChipVariant: {
      type: String,
    },
    statusText: {
      type: String,
    },
    showDependentPopovers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popoversData: this.showDependentPopovers ? resDefs.userChanges.dependentPopovers : resDefs.userChanges.popovers,
    };
  },
  methods: {
    filterBenefitsByType(benefitsArray = []) {
      const benefitsAndPolicies = {
        policies: [],
        benefits: [],
      };
      benefitsArray.forEach((benefit) => {
        const type = benefit.isPolicy ? "policies" : "benefits";
        benefitsAndPolicies[type].push(benefit);
      });
      return benefitsAndPolicies;
    },
  },
};
</script>

<style lang="scss" scoped></style>
