import Vue from "vue";
import Vuex from "vuex";
import { v4 as uuid } from "uuid";
import { isEqual } from "lodash-es";
import utils, { getJWTExpiry, unset } from "../utils";
import { logoutActiveUser } from "../common/auth";
import mobileApp from "./mobileApp";
import { apolloClient } from "./apollo";

Vue.use(Vuex);
export const store = new Vuex.Store({
  state: {
    user: {},
    token: null,
    refreshToken: null,
    redirectTo: null,
    alertsShown: false,
    alerts: [],
    navigation: {
      sectionHeader: "",
      navHeader: {
        icon: "",
        name: "",
        backButton: false,
      },
    },
    alertsCountMap: {},
    currentIntroVersion: 1,
    prospectId: "",
    topupProspectState: {},
    navBottomBorder: true,
    userFitnessData: {},
    navType: "top",
    showVersioningModal: false,
    isSwLoading: false,
    showSupportContainer: false,
    adminFilterRes: {
      filterData: {},
      searchQuery: {},
    },
    orgAdminFilterRes: {
      filterData: {},
      searchQuery: {},
    },
    userLoginToken: null,
    deepLinkTarget: null,
    scrollPositions: {},
    hrmsSyncWarningModal: {
      modalVisible: false,
      toPath: {},
      modalRequired: false,
    },
    bulkUploadWarningModal: {
      modalVisible: false,
      toPath: {},
      modalRequired: false,
    },
    bulkUploadJob: {
      jobProgress: 0,
      jobStatus: "",
      jobId: "",
    },
    novaSideNavExpanded: true,
  },
  getters: {
    user(state) {
      return state.user;
    },
    isLoggedIn(state) {
      return Boolean(state.user && state.token);
    },
    isAdmin(state) {
      return Boolean(
        state.user &&
          state.token &&
          (state.user.roles.indexOf("admin") !== -1 || state.user.roles.indexOf("account_admin") !== -1)
      );
    },
    isOrgAdmin(state) {
      return Boolean(state.user && state.token && state.user.roles.indexOf("org_admin") !== -1);
    },
    isAccountAdmin(state) {
      return Boolean(state.user && state.token && state.user.roles.indexOf("account_admin") !== -1);
    },
    isProspect(state) {
      return Boolean(state.user.roles.indexOf("prospect") !== -1);
    },
    isImposter(state) {
      return Boolean(state.user && localStorage.getItem("imposterToken"));
    },
    getAlertsCount(state) {
      return (message) => state.alertsCountMap[message]?.count || 0;
    },
    getCurrentIntroVersion(state) {
      return state.currentIntroVersion;
    },
    getFeatureFlags(state) {
      const gmcPolicy = utils.getActivePolicyByType(state.user.benefits, "gmc");
      const superTopupPolicy = utils.getActivePolicyByType(state.user.benefits, "super-topup");

      // (temp) this is to avoid showing topup purchase option to people who have already bought it
      const topupPolicy = utils.getActivePolicyByType(state.user.benefits, "topup");

      // temporarily showing topups for specific subset of users only
      const SUPER_TOPUPS =
        state.user?.org?.featureFlags?.SUPER_TOPUPS &&
        gmcPolicy &&
        !superTopupPolicy &&
        !(topupPolicy && topupPolicy.node?.name?.includes("Care Super Topup"));

      return {
        ...(state.user?.org?.featureFlags || {}),
        SUPER_TOPUPS,
      };
    },
    isTopNav(state) {
      return state.navType === "top";
    },
    showVersioningModal(state) {
      return state.showVersioningModal;
    },
    isSwLoading(state) {
      return state.isSwLoading;
    },
    showSupportContainer(state) {
      return state.showSupportContainer;
    },
    getFilterData(state) {
      return state.adminFilterRes.filterData;
    },
    getSearchQueryText(state) {
      return state.adminFilterRes.searchQuery;
    },
    getOrgAdminSearchQueryText(state) {
      return state.orgAdminFilterRes.searchQuery;
    },
    getOrgAdminFilterData(state) {
      return state.orgAdminFilterRes.filterData;
    },
    getUserLoginToken(state) {
      return state.userLoginToken;
    },
    getDeepLinkTarget(state) {
      if (!state.deepLinkTarget) {
        state.deepLinkTarget = localStorage.getItem("deepLinkTarget") || "app";
      }
      return state.deepLinkTarget;
    },
    getSyncWarningModalVisibility(state) {
      return state.hrmsSyncWarningModal.modalVisible;
    },
    getBulkUploadWarningModalVisibility(state) {
      return state.bulkUploadWarningModal.modalVisible;
    },
  },
  actions: {
    async loadLocalStore(ctx) {
      const userRaw = localStorage.getItem("user");
      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refreshToken");
      if (userRaw && token && ctx.state.token !== token) {
        const user = JSON.parse(userRaw);
        ctx.commit("loginUser", { user, token, refreshToken });
        return true;
      }
      return false;
    },
    async loadTopupLocalStore(ctx) {
      const prospectId = localStorage.getItem("prospectId");
      const localData = localStorage.getItem("topupProspectState");
      if (prospectId && localData) {
        ctx.commit("saveProspectId", prospectId);
        ctx.commit("saveTopUpProspectState", localData);
        return true;
      }
      return false;
    },
  },
  mutations: {
    loginUser(state, { user, token, refreshToken }) {
      if (!token || !refreshToken || refreshToken === "null") {
        logoutActiveUser(false);
        return;
      }

      const accessTokenExpiry = getJWTExpiry(token);
      const refreshTokenExpiry = getJWTExpiry(refreshToken);

      // vuex state
      state.user = user;
      state.token = token;
      state.refreshToken = refreshToken;
      state.accessTokenExpiry = accessTokenExpiry;
      state.refreshTokenExpiry = refreshTokenExpiry;

      // local storage
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("accessTokenExpiry", accessTokenExpiry);
      localStorage.setItem("refreshTokenExpiry", refreshTokenExpiry);

      apolloClient.cache.data.clear();
      if (user?.id) {
        const isImposter = this.getters.isImposter;
        const isAppUser = mobileApp.isApp;
        window.posthog.identify(user.id, { roles: user.roles, isImposter, isAppUser });
        window.posthog.alias(user.id, utils.fromGlobalId(user.id).id);
        window.posthog.people.set({
          email: user.email,
          name: user.displayName,
          userId: user.id,
          orgId: user.org.id,
          org: user.org.name,
        });
      }
    },
    refreshAccessToken(state, { user, token }) {
      const accessTokenExpiry = getJWTExpiry(token);

      // vuex state
      state.user = user;
      state.token = token;
      state.accessTokenExpiry = accessTokenExpiry;

      // local storage
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("accessTokenExpiry", accessTokenExpiry);
    },
    logoutUser(state) {
      state.user = {};
      state.token = null;
      state.refreshToken = null;
      state.accessTokenExpiry = null;
      state.refreshTokenExpiry = null;

      unset(["user", "token", "refreshToken", "accessTokenExpiry", "refreshTokenExpiry", "apollo-cache-persist"]);
      apolloClient.cache.data.clear();
      if (window.$zoho && window.$zoho.salesiq) {
        window.$zoho.salesiq.reset();
      }
    },
    /**
     * Function that adds an alert to the state.
     * @param {*} state
     * @param {*} item: {
     * message: the message to be shown.
     * variant: If it's an warning/success/danger.
     * id: the generated UUID.
     * title: the title for the toast. If this is null, the alert title will fallback to the default title in Alerts.vue
     * }
     * @returns
     */
    addAlert(state, { message, variant, id, title }) {
      if (!id) id = uuid();
      const oldAlert = state.alerts.find((alert) => alert.message === message);
      if (!oldAlert) state.alerts.push({ variant, message, id, title });
      if (!state.alertsCountMap[message]) {
        Vue.set(state.alertsCountMap, message, { count: 0 });
      }
      state.alertsCountMap[message].count++;
      return id;
    },
    clearAlert(state, id) {
      const idx = state.alerts.findIndex((alert) => alert.id === id);
      state.alertsCountMap[state.alerts[idx].message].count = 0;
      state.alerts.splice(idx, 1);
    },
    clearAlerts(state) {
      state.alerts = [];
      state.alertsCountMap = {};
    },
    updateSectionHeader(state, heading) {
      if (state.navigation.sectionHeader !== heading) {
        state.navigation.sectionHeader = heading;
      }
    },
    updateNavHeader(state, { name, icon, backButton }) {
      if (backButton && backButton !== state.navigation.navHeader.backButton) {
        state.navigation.navHeader.backButton = backButton;
      }
      if (name && name !== state.navigation.navHeader.name) {
        state.navigation.navHeader.name = name;
      }
      if (icon && icon !== state.navigation.navHeader.icon) {
        state.navigation.navHeader.icon = icon;
      }
    },
    updateUser(state, user) {
      const storedUser = JSON.parse(localStorage.getItem("user"));
      if (!isEqual(storedUser, user)) {
        state.user = user;
        localStorage.setItem("user", JSON.stringify(user));
      }
    },
    updateUserProfileData(state, { displayName, meta }) {
      state.user.displayName = displayName;
      state.user.meta = meta;
    },
    saveProspectId(state, prospectId) {
      state.prospectId = prospectId;
      localStorage.setItem("prospectId", prospectId);
    },
    saveTopUpProspectState(state, topupProspectState) {
      state.topupProspectState = topupProspectState;
      localStorage.setItem("topupProspectState", topupProspectState);
    },
    updateUserFitnessData(state, userFitnessData) {
      state.userFitnessData = userFitnessData;
    },
    toggleNavBottomBorder(state, toggleState) {
      state.navBottomBorder = toggleState;
    },
    addRedirectUrl(state, redirectUrl) {
      state.redirectTo = redirectUrl;
      localStorage.setItem("redirectTo", redirectUrl);
    },
    removeRedirectUrl(state) {
      state.redirectTo = null;
      localStorage.removeItem("redirectTo");
    },
    setNavType(state, type) {
      state.navType = type;
    },
    enableVersioningModal(state, flag) {
      state.showVersioningModal = flag || false;
    },
    setSwLoadingState(state, flag) {
      state.isSwLoading = flag || false;
    },
    toggleSupportContainer(state) {
      state.showSupportContainer = !state.showSupportContainer;
    },
    setFilterData(state, { tabName, filter }) {
      state.adminFilterRes.filterData[tabName] = filter;
    },
    setSearchQueryText(state, { tabName, searchQueryText }) {
      state.adminFilterRes.searchQuery[tabName] = searchQueryText;
    },
    setOrgAdminSearchQueryText(state, { tabName, searchQueryText }) {
      state.orgAdminFilterRes.searchQuery[tabName] = searchQueryText;
    },
    setOrgAdminFilterData(state, { tabName, filter }) {
      state.orgAdminFilterRes.filterData[tabName] = filter;
    },
    setUserLoginToken(state, userLoginToken) {
      state.userLoginToken = userLoginToken;
    },
    setDeepLinkTarget(state, { deepLinkTarget }) {
      state.deepLinkTarget = deepLinkTarget;
      localStorage.setItem("deepLinkTarget", deepLinkTarget);
    },
    setScrollPositions(state, { routeName, scrollPosition }) {
      state.scrollPositions[routeName] = scrollPosition;
    },
    allowHrmsSyncWarningModalToDisplay(state) {
      state.hrmsSyncWarningModal.modalRequired = true;
    },
    openHrmsSyncWarningModal(state, RedirectionPath) {
      state.hrmsSyncWarningModal.modalVisible = true;
      state.hrmsSyncWarningModal.toPath = RedirectionPath;
    },
    closeHrmsSyncWarningModal(state) {
      state.hrmsSyncWarningModal.modalVisible = false;
    },
    clearHrmsSyncWarningModal(state, resetPath = false) {
      state.hrmsSyncWarningModal.modalVisible = false;
      state.hrmsSyncWarningModal.modalRequired = false;
      if (resetPath) {
        state.hrmsSyncWarningModal.toPath = {};
      }
    },
    allowBulkUploadWarningModalToDisplay(state) {
      state.bulkUploadWarningModal.modalRequired = true;
    },
    openBulkUploadWarningModal(state, RedirectionPath) {
      state.bulkUploadWarningModal.modalVisible = true;
      state.bulkUploadWarningModal.toPath = RedirectionPath;
    },
    closeBulkUploadWarningModal(state) {
      state.bulkUploadWarningModal.modalVisible = false;
    },
    clearBulkUploadWarningModal(state, resetPath = false) {
      state.bulkUploadWarningModal.modalVisible = false;
      state.bulkUploadWarningModal.modalRequired = false;
      if (resetPath) {
        state.bulkUploadWarningModal.toPath = {};
      }
    },
    updateBulkUploadProgress(state, progress) {
      state.bulkUploadJob.jobProgress = progress;
    },
    updateBulkUploadStatus(state, status) {
      state.bulkUploadJob.jobStatus = status;
    },
    updateBulkUploadJobId(state, jobId) {
      state.bulkUploadJob.jobId = jobId;
    },
    toggleNovaSideNavState(state, isExpanded) {
      state.novaSideNavExpanded = isExpanded;
    },
  },
});
