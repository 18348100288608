import { render, staticRenderFns } from "./AddOnCard.vue?vue&type=template&id=3682dc94&scoped=true&lang=pug&"
import script from "./AddOnCard.vue?vue&type=script&lang=js&"
export * from "./AddOnCard.vue?vue&type=script&lang=js&"
import style0 from "./AddOnCard.vue?vue&type=style&index=0&id=3682dc94&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3682dc94",
  null
  
)

export default component.exports