import { getListQuery } from "../../queries";

export default {
  schema: {
    fields: [
      {
        model: "meta.estimatedDoa",
        type: "datepicker",
        label: "Estimated Date of Admission (client added)",
        min: "1900-01-01",
        max: "2100-12-31",
        styleClasses: "d-inline-block col-md-6",
        required: false,
      },
      {
        model: "si",
        styleClasses: "d-inline-block col-md-6",
        label: "Total Sum Insured",
        type: "inline-input",
        inputType: "number",
        imageIcon: "rupee",
        readonly: true,
      },
      {
        model: "meta.doa",
        styleClasses: "d-inline-block col-md-6",
        label: "Date of Admission",
        type: "datepicker",
        min: "1900-01-01",
        max: "2100-12-31",
      },
      {
        model: "meta.estimatedClaimAmount",
        styleClasses: "d-inline-block col-md-6",
        label: "Estimated Claim Amount (client added)",
        type: "inline-input",
        inputType: "number",
        imageIcon: "rupee",
      },
      {
        model: "meta.dod",
        styleClasses: "d-inline-block col-md-6",
        label: "Date of Discharge",
        type: "datepicker",
        min: "1900-01-01",
        max: "2100-12-31",
      },
      {
        model: "meta.claimAmount",
        styleClasses: "d-inline-block col-md-6",
        label: "Claimed Amount",
        type: "inline-input",
        inputType: "number",
        imageIcon: "rupee",
      },
      {
        model: "meta.hospital",
        type: "editable-dropdown",
        queryName: "hospitals",
        gqlQuery: getListQuery("Hospital"),
        styleClasses: "d-inline-block col-md-6",
        label: "Hospital Name",
        required: false,
        taggable: true,
      },
      {
        model: "meta.approvedAmount",
        styleClasses: "d-inline-block col-md-6",
        label: "Insurer Approved Amount",
        type: "inline-input",
        inputType: "number",
        imageIcon: "rupee",
      },
      {
        model: "meta.diagnosis",
        type: "input",
        inputType: "text",
        label: "Treatment Name",
        styleClasses: "d-inline-block col-md-6",
        placeholder: "-",
      },
      {
        model: "meta.pincode",
        type: "inline-input",
        inputType: "text",
        label: "Pincode",
        validator: "regexp",
        pattern: "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$",
        styleClasses: "d-inline-block col-md-6",
        placeholder: "-",
      },
      {
        model: "meta.doctor",
        type: "inline-input",
        inputType: "text",
        label: "Doctor's Name",
        validator: "regexp",
        pattern: "^[A-Za-z. ]+$",
        imageIcon: "dr-title",
        styleClasses: "d-inline-block col-md-6",
      },
      {
        model: "meta.city",
        type: "inline-input",
        inputType: "text",
        label: "City",
        styleClasses: "d-inline-block col-md-6",
        placeholder: "-",
      },
      {
        model: "meta.ailmentCode",
        type: "inline-input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: "Ailment Code",
        placeholder: "-",
      },
      {
        model: "meta.state",
        type: "inline-input",
        inputType: "text",
        label: "State",
        styleClasses: "d-inline-block col-md-6",
        placeholder: "-",
      },
      {
        model: "meta.ailmentGroup",
        type: "inline-input",
        inputType: "text",
        styleClasses: "d-inline-flex flex-column col-md-6",
        label: "Ailment Group",
        placeholder: "-",
      },

      {
        model: "meta.details",
        type: "textArea",
        inputType: "text",
        styleClasses: "d-inline-flex flex-column col-md-6",
        label: "Other Details",
        placeholder: "-",
      },
    ],
  },
};
