import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { onError as onApolloError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";

import utils from "@/utils";
import { store } from "./store";

const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_NOVA_API_URL,
});

const authMiddleware = new ApolloLink((op, forward) => {
  const token = localStorage.getItem("token");
  if (token) {
    op.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }
  return forward(op);
});

const errorMiddleware = onApolloError((error) => {
  // handling version mismatch error specifically
  // TODO: custom error code is not sent from BE middleware, try to find more graceful soln
  if (error.graphQLErrors?.[0]?.errorMessage === "VERSION_MISMATCH") {
    store.commit("enableVersioningModal", true);
  } else {
    utils.handleApolloErrors(error, store);
  }
});

const versioningMiddleware = setContext((operation, previousContext) => {
  // sending package version in header for verification
  previousContext.headers = {
    ...previousContext?.headers,
    "x-version": process.env.VUE_APP_VERSION,
  };
  return previousContext;
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorMiddleware, authMiddleware, versioningMiddleware, httpLink]),
  cache: new InMemoryCache({
    dataIdFromObject: (o) => o.id,
  }),
  connectToDevTools: true,
});
