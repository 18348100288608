<template lang="pug">
b-modal(
  id="update-premium-modal",
  size="md",
  centered,
  header-class="py-2",
  cancel-variant="light",
  button-size="sm",
)
  template(v-slot:modal-title)
    .w-100.font-lg.text-gray-900.font-weight-semibold.text-center.py-0
      span Update premium and CD Balance
  .p-3
    vue-form-generator(
      :schema="getUpdatedSchema()",
      :model="model",
      :options="formOptions",
      ref="formData",
    )
    n-button.mt-2(
      buttonText="Existing premium details",
      rightImageIcon="download",
      variant="dark",
      size="xs",
      :disabled="!batch.premiumDetailsUrl",
      :href="batch.premiumDetailsUrl",
    )
  .text-center(v-if="isUploading")
    b-spinner(small)
    div Uploading File, Please Wait...
  template(v-slot:modal-footer)
    .d-flex.justify-content-end
      n-button.font-sm(
        variant="light-secondary",
        buttonText="Close",
        :disabled="isUploading",
        @click="handleClose",
      )
      n-button.font-sm(
        variant="dark",
        buttonText="Update",
        :disabled="isUploading",
        type="submit",
        @click="saveDetails",
      )
</template>

<script>
import { mapGetters } from "vuex";
import adminDefs from "../../admin/definitions";
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";
import { getFileExtension } from "@/utils/misc";
import { uploadFile } from "@/common/queriesAndMutations/file";
import { FileAction } from "@/common/enums";
const FORM_FIELDS_TO_DISABLE = ["id", "premiumAddition", "premiumDeduction"];
export default {
  name: "PremiumUpdateModal",
  components: {
    NButton,
  },
  props: {
    batch: {
      type: Object,
      default: null,
    },
    orgId: {
      type: String,
      default: null,
    },
    isDynamicPremiumEnabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      model: {},
      batchId: this.$route.params.batchId,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
      premiumDetailsFile: null,
      isUploading: false,
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    getSchema(thisComponent) {
      return {
        fields: [
          {
            model: "premiumData",
            type: "object",
            label: "Premium data for batch",
            schema: {
              fields: [
                {
                  model: "isPaid",
                  type: "select",
                  label: "Is Paid ?",
                  values: function () {
                    return [true, false];
                  },
                  required: true,
                },
                {
                  model: "policyWisePremiumEstimation",
                  type: "array",
                  itemContainerComponent: "form-container",
                  label: "Premium Estimations",
                  items: {
                    getTitle: (idx, item) => "Estimated premium for policy",
                    type: "object",
                    schema: {
                      fields: [
                        {
                          model: "id",
                          type: "select",
                          values: this.orgPolicies,
                          required: true,
                          label: "Select Policy",
                        },
                        {
                          model: "premiumAddition",
                          type: "input",
                          inputType: "number",
                          label: "Estimated additional premium",
                        },
                        {
                          model: "premiumDeduction",
                          type: "input",
                          inputType: "number",
                          label: "Estimated deletion refund",
                        },
                      ],
                    },
                  },
                },
                {
                  model: "premiumDetailsUrl",
                  type: "upload",
                  label: "Upload Premium Details",
                  async onChanged(model, schema, event) {
                    const fileExt = getFileExtension(event.target.files[0].name);
                    if (![".xls", ".xlsx"].includes(fileExt)) {
                      this.$store.commit("addAlert", {
                        variant: "danger",
                        message: "File extension not supported, please upload xls or xlsx extensions only.",
                      });
                      return;
                    }
                    thisComponent.premiumDetailsFile = await event.target.files[0];
                  },
                },
              ],
            },
          },
        ],
      };
    },
  },
  created() {
    if (this.batch?.meta?.premiumData) {
      const premiumData = utils.deepClone(this.batch.meta.premiumData);
      premiumData.policyWisePremiumEstimation = premiumData.policyWisePremiumEstimation
        ? Object.entries(premiumData.policyWisePremiumEstimation).map(([id, estimate]) => ({ id, ...estimate }))
        : [];
      this.model = {
        premiumData: premiumData,
      };
    }
  },
  methods: {
    handleClose() {
      this.premiumDetailsFile = null;
      this.$emit("modal-closed");
    },
    getUpdatedSchema() {
      return this.updateSchemaWithDisabledFields(this.getSchema, this.isDynamicPremiumEnabled);
    },
    updateSchemaWithDisabledFields(schema, disabled) {
      FORM_FIELDS_TO_DISABLE?.forEach((model) => {
        const fieldIndex = schema.fields
          .find((field) => field.model === "premiumData")
          .schema.fields.find((field) => field.model === "policyWisePremiumEstimation")
          .items.schema.fields.findIndex((field) => field.model === model);
        if (fieldIndex !== -1) {
          schema.fields
            .find((field) => field.model === "premiumData")
            .schema.fields.find((field) => field.model === "policyWisePremiumEstimation")
            .items.schema.fields.splice(fieldIndex, 1, {
              ...schema.fields
                .find((field) => field.model === "premiumData")
                .schema.fields.find((field) => field.model === "policyWisePremiumEstimation").items.schema.fields[
                fieldIndex
              ],
              disabled,
            });
        }
      });
      return schema;
    },
    async saveDetails() {
      if (
        !this.model.premiumData ||
        !Object.keys(this.model.premiumData).length ||
        this.model.premiumData.isPaid === undefined ||
        !this.model.premiumData.policyWisePremiumEstimation?.length
      ) {
        this.$store.commit("addAlert", {
          variant: "danger",
          message: "Please provide premium estimations and details",
        });
        return;
      }

      // cloning so we do not end up changing the actual form data
      const premiumData = utils.deepClone(this.model.premiumData);
      premiumData.policyWisePremiumEstimation = {};
      this.model.premiumData.policyWisePremiumEstimation.forEach((obj) => {
        premiumData.policyWisePremiumEstimation[obj.id] = {
          premiumAddition: obj.premiumAddition,
          premiumDeduction: obj.premiumDeduction,
        };
      });
      if (this.premiumDetailsFile) {
        premiumData.premiumDetailsUrl = await this.uploadFile(this.premiumDetailsFile);
      }

      await this.$apollo.mutate({
        mutation: adminDefs.userChanges.updateBatchPremiumData,
        variables: {
          batchId: this.batchId,
          premiumData,
        },
      });

      // refetch batch data for updating UI, currently for download premium details link
      this.$emit("refetchBatch", this.batchId);
      this.handleClose();
    },
    async uploadFile(file) {
      this.isUploading = true;
      try {
        if (file) {
          const result = await uploadFile({
            file: file,
            useAwsS3: true,
            uploadingUserId: this.$store.state.user.id,
            action: FileAction.PremiumDetails,
          });
          this.$store.commit("addAlert", {
            variant: "success",
            message: "Successfully uploaded.",
          });
          return result.data.upsertFile.file.url;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isUploading = false;
      }
    },
  },
  apollo: {
    orgPolicies: {
      skip() {
        return !this.orgId;
      },
      query: adminDefs.benefits.listQuery,
      variables() {
        return {
          filter: {
            isPolicy: true,
            orgId: this.orgId,
          },
        };
      },
      update(data) {
        return adminDefs.benefits.getEdgesFromData(data).map(({ node }) => node);
      },
    },
  },
};
</script>
