export const Base = require("./Base.vue").default;
export const routes = [
  {
    path: "/",
    name: "homepage",
    component: () => import("./LandingPage.vue"),
  },
  {
    path: "/community",
    name: "community",
    component: () => import("./Community.vue"),
  },
  {
    path: "/getintouch",
    name: "getintouch",
    component: () => import("./components/GetInTouch.vue"),
  },
  {
    path: "/page/preview",
    name: "previewPage",
    component: () => import("./PreviewPage.vue"),
  },
  {
    path: "/page/:resource",
    name: "dynamicPage",
    component: () => import("./DynamicPage.vue"),
  },
];
