import gql from "graphql-tag";
import {
  hospitalFragment,
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation m0(
      $id: ID
      $address: String!
      $name: String!
      $pincode: String!
      $city: String
      $state: String
      $contact: String
    ) {
      upsertHospital(
        input: {
          id: $id
          name: $name
          address: $address
          city: $city
          state: $state
          contact: $contact
          pincode: $pincode
        }
      ) {
        hospital {
          ...Hospital
        }
      }
    }
    ${hospitalFragment}
  `,
}
