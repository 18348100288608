import gql from "graphql-tag";
import {
  integrationFragment,
  jobFragment,
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertIntegration(
      $id: ID
      $orgId: ID!
      $integrationType: IntegrationWithEnum!
      $meta: JSONObject!
      $secrets: JSONObject!
    ) {
      upsertIntegration(
        input: {
          id: $id
          orgId: $orgId
          integrationType: $integrationType
          meta: $meta
          secrets: $secrets
        }
      ) {
        integration {
          ...Integration
        }
      }
    }
    ${integrationFragment}
  `,
  fetchHRMSDataMutation: gql`
    mutation fetchHrmsData($orgId: ID!, $integrationType: String!) {
      fetchHrmsData(
        input: { orgId: $orgId, integrationType: $integrationType }
      ) {
        job {
          ...Job
        }
      }
    }
    ${jobFragment}
  `,
  authorizeGsuiteIntegration: gql`
    mutation authorizeGsuiteIntegration($id: ID!, $code: String) {
      authorizeGsuiteIntegration(input: { id: $id, code: $code }) {
        authUrl
        integration {
          ...Integration
        }
      }
    }
    ${integrationFragment}
  `,
  unauthorizeIntegration: gql`
    mutation unauthorizeIntegration($id: ID!, $orgId: ID!) {
      unauthorizeIntegration(input: { id: $id, orgId: $orgId }) {
        integration {
          ...Integration
        }
      }
    }
    ${integrationFragment}
  `,
  parseEmployeeData: gql`
    mutation parseEmployeeData($id: ID!, $orgId: ID!, $type: String!) {
      parseEmployeeData(input: { id: $id, orgId: $orgId, type: $type }) {
        users
      }
    }
  `,
}
