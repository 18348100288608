<template lang="pug">
div
  .row.no-gutters
    .col-12.text-center
      .font-hc.text-gray-700 Proudly serving
      .py-2
        .row.no-gutters.d-flex.justify-content-center.w-100
          .col-6.col-md-3.col-lg-2.py-1(v-for="logo in logos", :key="logo")
            img.d-block.mx-auto(:src="require(`@/assets/logos/${logo}.svg`)")
</template>

<script>
export default {
  name: "Logos",
  props: {
    logos: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  height: 1.5rem;
}
</style>
