import gql from "graphql-tag";

export const challengeTemplateFragment = gql`
  fragment ChallengeTemplate on ChallengeTemplate {
    id
    name
    challenge
    activityTypes
    duration
    baseGoalValue
    minGoalValue
    incrementFactor
    decrementFactor
    points
    meta
    updatedAt
  }
`;

export const orgChallengeFragment = gql`
  fragment OrgChallenge on OrgChallenge {
    id
    challengeTemplate {
      ...ChallengeTemplate
    }
    org {
      id
      name
      segments {
        id
        name
        type
      }
    }
    user {
      id
      name
    }
    parentChallenge {
      id
    }
    userContributions {
      id
      currentTotal
      user {
        id
        name
      }
    }
    goalValue
    currentTotal
    startDate
    startTime
    expiryDate
    expiryTime
    challengeStatus
    points
    meta
    updatedAt
  }
  ${challengeTemplateFragment}
`;

export const userChallengeFragment = gql`
  fragment UserChallenge on UserChallenge {
    id
    challengeTemplate {
      ...ChallengeTemplate
    }
    org {
      id
      name
      segments {
        id
        name
        type
      }
    }
    user {
      id
      name
    }
    goalValue
    currentTotal
    startDate
    startTime
    expiryDate
    expiryTime
    challengeStatus
    points
    meta
    updatedAt
  }
  ${challengeTemplateFragment}
`;
