import gql from "graphql-tag";

export const calendarEventFragment = gql`
  fragment CalendarEvent on CalendarEvent {
    id
    segment {
      id
      type
      name
    }
    eventDate
    name
    schedule
    meta
    updatedAt
  }
`;
