<template lang="pug">
div
  b-card.nova-pec-card.overflow-hidden(
    :class="[variant?`${variant}-custom-card`:'', variant=='testimonial'?'gradient-border-wrap':'']")
    div(v-if="cardType=='catalog'")
      i.h3.mr-2.align-middle(
        v-if="imageIcon",
        :class="[`icon-${imageIcon}`, `text-${variant}`]")
      span.h5(v-if="cardTitle") {{ cardTitle }}
      .font-sm
        br
        span {{ cardText }}
      hr.hr-line(v-if="!variant.includes('solid')")
      br(v-else)
      span.font-lg {{ termPrice | inr }} / Employee
      br
      .font-sm.text-gray-500.pt-1
        span {{ termText }}
      br
      span(v-if="variant.includes('solid') && !disableButton")
        br
        br

    div(v-else-if="cardType=='testimonial'", align="center")
      .h5.text-gray-800
        span(v-html="cardText")
      br
      .testimonial-divider-line
      br
      .font-lg.text-gray-900
        span {{ testimonialSource }}
      .testimonial-org.font-hc
        span {{ testimonialOrg.toUpperCase() }}

    div(v-else-if="cardType=='schedule'")
      .col-md-8.d-inline-block
        .pb-2.h3 {{ cardTitle }}
        .pb-3 {{ cardText }}
        .pb-2
          n-button.col-md-8(
            :disabled="false",
            :buttonText="'Schedule a call'",
            :variant="'warning'",
            @click="$emit('buttonClick')",
            :imageIcon="'calendar'")
      .col-md-4.d-inline-block
        img.schedule-img(:src="require(`@/assets/images/${imageIcon}`)")

    div(v-else)
      .break-spaces.font-sm.font-weight-medium.text-gray-800
        span {{ cardText }}

    n-button(
      v-if="cardType!='testimonial' && cardType!='schedule' && !disableButton",
      :variant="variant",
      block,
      @click="$emit('buttonClick')",
      :buttonText="buttonText",
      :rightImageIcon="buttonRightImageIcon")
  img.policy-comparison-card-bookmark-img(
    v-if="termName && termName.split('-')[1] === 'selected'",
    :src="require(`@/assets/images/bookmark.svg`)")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
export default {
  name: "NCard",
  components: {
    NButton,
  },
  props: {
    cardType: {
      type: String,
      default: "catalog",
    },
    buttonText: {
      type: String,
      default: "Click",
    },
    buttonType: {
      type: String,
      default: "button",
    },
    variant: {
      type: String,
      default: null,
    },
    termName: {
      type: String,
      default: null,
    },
    cardTitle: {
      type: String,
      default: null,
    },
    cardText: {
      type: String,
      default: null,
    },
    termPrice: {
      type: Number,
      default: null,
    },
    termText: {
      type: String,
      default: "Annually",
    },
    imageIcon: {
      type: String,
      default: null,
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
    testimonialSource: {
      type: String,
      default: null,
    },
    testimonialOrg: {
      type: String,
      default: null,
    },
    buttonRightImageIcon: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    submit() {},
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.nova-pec-card {
  &.card {
    height: 100%;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
  }
  .hr-line {
    border-top: 1px solid #e2e8f0;
  }
  .testimonial-org {
    color: $mustard-700;
  }
  .testimonial-divider-line {
    position: relative;
    width: 25px;
    border: 1px solid $gray-400;
    background: $gray-400;
    transform: rotate(90deg);
    top: -0.25rem;
  }
  .schedule-img {
    position: absolute;
    float: left;
    margin-right: 10px;
    height: 210px;
    width: 210px;
    top: -150px;
    left: -1rem;
    z-index: 0;
  }
}
.nova-pec-card > .card-body {
  background-color: rgb(255, 255, 255);
  border-radius: 5px;
}
.gradient-border-wrap > .card-body {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.primary-custom-card {
  border-top: 5px solid $primary !important;
}
.success-custom-card {
  border-top: 5px solid $success !important;
}
.secondary-custom-card {
  border-top: 5px solid $secondary !important;
}
.popover-custom-card {
  border-top: 5px solid $tiber !important;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
}
.gradient-border-wrap {
  background: linear-gradient(270deg, #309a92, #196761);
  background-size: 100% 10px;
  background-repeat: no-repeat;
  padding-top: 5px;
  border-radius: 5px;
}

//Specific styling for PEC

@media (min-width: 992px) and (max-width: 1200px) {
  .solid-primary-custom-card,
  .solid-success-custom-card,
  .solid-dark-custom-card,
  .selected-solid-primary-custom-card,
  .selected-solid-success-custom-card,
  .selected-solid-dark-custom-card {
    border-radius: 0px !important;
    & > .card-body {
      border-radius: 0px;
    }
  }
}
.solid-primary-custom-card {
  & > .card-body {
    background-color: $primary;
    color: white;
  }
  .btn-solid-primary {
    color: white;
    background-color: $blue-400;
  }
}
.solid-success-custom-card {
  & > .card-body {
    background-color: $teal-800;
    color: white;
  }
  .btn-solid-success {
    color: white;
    background-color: $teal-700;
  }
}
.solid-dark-custom-card {
  & > .card-body {
    background-color: $dark;
    color: white;
  }
  .btn-solid-dark {
    color: white;
    background-color: $gray-800;
  }
}

.selected-solid-primary-custom-card {
  & > .card-body {
    background-color: $primary;
    color: white;
  }
  .btn-selected-solid-primary {
    color: $blue-800;
    background-color: white;
  }
}
.selected-solid-success-custom-card {
  & > .card-body {
    background-color: $teal-800;
    color: white;
  }
  .btn-selected-solid-success {
    color: $teal-900;
    background-color: white;
  }
}
.selected-solid-dark-custom-card {
  & > .card-body {
    background-color: $dark;
    color: white;
  }
  .btn-selected-solid-dark {
    color: $gray-900;
    background-color: white;
  }
}
.policy-comparison-card-bookmark-img {
  position: absolute;
  right: 2rem;
  top: -13.5px;
}
</style>
