import gql from "graphql-tag";
import { benefitFragment } from "./benefit";
import { dependentFragment } from "./dependent";
import { userBenefitFragmentWithoutContent } from "./userBenefit";

export const userChangeFragment = gql`
  fragment UserChange on UserChange {
    id
    user {
      id
      email
      name
      displayName
      gender
      dob
      meta
      status
      roles
      org {
        id
        name
      }
      dependents {
        ...Dependent
      }
      benefits {
        ...UserBenefitEdgeWithoutContent
      }
    }
    changedUserInfo
    org {
      id
    }
    status
    type
    meta
    dependent {
      ...Dependent
    }
    changedDependentInfo
    benefit {
      ...Benefit
    }
    changedBenefitInfo
  }
  ${dependentFragment}
  ${userBenefitFragmentWithoutContent}
  ${benefitFragment}
`;

export const userChangesFragment = gql`
  fragment UserChanges on User {
    id
    userChanges {
      ...UserChange
    }
  }
  ${userChangeFragment}
`;

export const userChangeBatchFragment = gql`
  fragment UserChangeBatch on UserChangeBatch {
    id
    status
    meta
    startingAt
    endingAt
    users
    dependents
    finished
    premiumDetailsUrl
  }
`;
