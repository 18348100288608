<template lang="pug">
n-overlay.get-started.bg-alabaster(v-if="onboardingScreens[currentStep]")
  .h-100.d-flex.flex-column
    n-spinner(
      :active="loading",
      is-full-screen,
      spinner="ring",
      size="50",
      background-color="rgba(11,57,72,0.3)",
      duration="1")
    .custom-onboarding-container(v-if="isCustomOnboardingScreen")
      EmbedScreen(:link="generateCustomOnboardingLink()", heading="Customize Your Coverage", @back="nextStep(-1)", @messageFromEmbededPage="recordIncomingEmbedFormData")
    .p-0.m-0(v-else)
      n-navbar#nova-navbar.sticky-md.d-md-none
        template(v-slot:middle)
          b-nav-item.d-block.d-md-none
            .font-weight-semibold.text-gray-900.d-flex
              span.font-lg.font-weight-semibold.mr-5.py-3 Coverage Activation
      .container
        .mt-md-3
        .row
          .col-md-7.col-sm-12.mt-md-5.left-container(:class="isDepScreen ? 'col-md-12 col-lg-7': ''")
            .font-xl.text-gray-700.font-weight-medium.d-none.d-md-block Coverage Activation
            .col-md-7
              progress-card.mt-md-6.mt-3(
                :showDepCountBadge="isDepScreen",
                :imageIcon="onboardingScreens[currentStep].imageIcon",
                :currentStep="currentStep",
                :totalSteps="Object.keys(onboardingScreens).length",
                :stepTitle="onboardingScreens[currentStep].stepTitle",
                :dependentCount="dependentsModel.length",
                :areDependentsCovered="areDependentsCovered",
                @handleLeftClick="nextStep(-1)",
                @handleRightClick="nextStep(+1)")
              p.text-gray-800.d-none.d-sm-block(v-for="paragraph in onboardingScreens[currentStep].paragraphs")
                | {{ paragraph }}
              p(v-if="dependentsModel.length===0 && isDepScreen") 
                span.font-weight-semibold 
                  | We strongly recommend adding dependents at the earliest,
                  | to account for approval delay from the Insurer.  
            template(v-if="isDepScreen") 
              .dependents-scroll(v-if="!reviewPolicies")
                dependent-details-wrapper(
                  v-if="!reviewPolicies && arePoliciesLoaded",
                  :dependentsModel="dependentsModel",
                  :policies="policies",
                  :coverPOrPil="coverPOrPil",
                  @clicked="showData")

                n-button.w-auto.my-3.ml-2.border.rounded(
                  v-if="isDepScreen",
                  variant="outline-primary",
                  buttonText="Review Policies",
                  :hidden="checkIfCurrentScreen($options.screens.POLICY)"
                  @click="showPolicies()")
              n-button.w-auto.my-3.ml-2.border.rounded(
                v-if="isDepScreen && reviewPolicies",
                variant="dark",
                buttonText="Resume adding Dependents",
                rightImageIcon="chevron-right",
                :hidden="checkIfCurrentScreen($options.screens.POLICY)"
                @click="toggleReviewPolicy()")
            template(v-if="reviewPolicies && isDepScreen")
              img.d-none.d-xl-block.mb-0.group-img(:src="require('@/assets/images/group.svg')")
          .col-md-5.col-sm-12.mt-lg-5
            b-form(@submit.prevent="continueFlow()")
              vue-form-generator(
                v-if="[1, getScreenIndex($options.screens.CONTACT)].includes(currentStep)",
                :schema="formSchema",
                :options="formOptions",
                :model="profileModel",
                ref="formData")

              add-dependents-card.d-none.d-lg-block(
                v-if="isDepScreen && !reviewPolicies && arePoliciesLoaded",
                :user="user",
                :selectedDependent="selectedDependent",
                :dependentsModel="dependentsModel",
                :policies="policies",
                @pPilCoverage="updateCoveredPOrPil",
                @modelUpdated="saveDependents",
                ref="addDependentsCard")
              template(v-else-if="isDepScreen && reviewPolicies")
                .policy-scroll.pr-3
                  template(v-if="policies.length > 0")
                    .my-3(v-for="policy in policies", :key="policy.id")
                      n-policy-card(:policy="policy", :user="user", :edgeMeta="policy.meta", :previewMode="true")
                  template(v-else)
                    h3.policies-wrapper No benefits found that you are linked to currently.
              template(v-else-if="checkIfCurrentScreen($options.screens.POLICY)")
                card-button.mt-2.p-1(ref="gtlCard", v-if="isGTLPolicyPresent", :state="gtlModelState", :validationMessage="$options.stateMessageMap[gtlModelState]")
                  template.pt-2(v-slot:header)
                    h5.font-weight-semibold {{ getGTLCardHeading }}
                  template(v-slot:body)
                    p.text-gray-700.align-items-center.text-left.font-sm {{ getGTLCardContent }}
                  template(v-slot:button)
                    n-button.btn-block(:buttonText="getGTLCardButtonText", :variant="getGTLCardButtonVariant", size="md", @click.prevent="$bvModal.show('gtl-modal')")
                template(v-if="policies.length > 0")
                  .my-3(v-for="policy in policies", :key="policy.id")
                    n-policy-card(:policy="policy", :user="user", :edgeMeta="policy.meta", :previewMode="true")
                template(v-else)
                  h3.policies-wrapper No benefits found that you are linked to currently.
                b-form-checkbox.mt-3(
                  v-if="checkIfCurrentScreen($options.screens.POLICY)",
                  v-model="confirmationCheckbox")
                  .text-gray.font-sm
                    | The information I have provided is accurate and matches with
                  .text-gray.font-sm
                    | the latest government records (Aadhar/ PAN/ Passport/ VoterID).
              n-button.w-100.mt-4.d-none.d-lg-block(
                v-if="isDepScreen && !reviewPolicies",
                variant="outline-primary",
                :buttonText="buttonTextForCurrentStep",
                @click="validateForm()",
                @keydown.enter="validateForm()")
              n-button.w-100.my-4(
                v-else-if="!reviewPolicies",
                :variant="isDepScreen ? 'outline-primary' : 'dark'",
                :buttonText="buttonTextForCurrentStep",
                :disabled="checkIfCurrentScreen($options.screens.POLICY) ? !confirmationCheckbox : false",
                @click="validateForm()",
                @keydown.enter="validateForm()")
              .w-100.text-center.d-lg-none.mb-2(v-if="!reviewPolicies && isDepScreen")
                n-button.mt-4.d-lg-block(
                  variant="dark",
                  buttonText="Add dependents",
                  rightImageIcon="plus-circle",
                  @click="$bvModal.show('add-dependents-modal-mobile')")
                n-button.px-5.mt-4.ml-3.d-lg-block(
                  variant="outline-primary",
                  :buttonText="dependentsModel.length ? 'Next' : 'Skip'",
                  @click="continueFlow()")
      .watermark.d-none.d-md-block
        img(:src="require('@/assets/images/insignia-watermark.svg')")

      special-modal(
        id="dependent-verification-modal",
        headerIcon="icon",
        :bgColor="'mustard-200'",
        :iconColor="'mustard-600'",
        logoIcon="warning", 
        centered)
        template(v-slot:title)
          h3.hb3.d-inline.font-weight-semibold.text-gray-900(v-if="areNoDependentsAdded") No Dependent added!
          h3.hb3.d-inline.font-weight-semibold.text-gray-900(v-else) Add more Dependents
        template(v-slot:modal-content)
          .text-center(v-if="areNoDependentsAdded")
            | Your health insurance will not cover your 
            br
            | dependents, if you don't add them now.
          .text-center.px-5(v-else)
            | Your health insurance covers your {{ dependentContent }}
            | Sure you don't want to add them?
        template(v-slot:buttons-area)
          .d-flex.align-items-center.mt-3.mb-2
            n-button.px-3(variant="dark", button-text="Add Dependents", @click="$bvModal.hide('dependent-verification-modal')")
            n-button.px-5.ml-2(variant="light", button-text="Skip ->", @click="skipAddition")

      n-modal(
        id="gtl-modal",
        centered,
        isScrollable=true,
        no-close-on-backdrop,
        modal-class="gtl-modal")
        template(v-slot:modal-header)
          .hb5.text-gray-900.mx-auto.my-1 Life Insurance Policy

        template.justify-content-center.align-items-center.flex-column(v-slot:default)
          progress-card.my-2(
            :imageIcon="gtlModalScreens[currentGTLModalStep].imageIcon",
            :currentStep="currentGTLModalStep",
            :totalSteps="Object.keys(gtlModalScreens).length",
            :stepTitle="gtlModalScreens[currentGTLModalStep].title",
            disabledClicks=true)
          b-form
            vue-form-generator(
              :schema="getGTLSchema()",
              :options="formOptions",
              ref="formData",
              :model="profileModel")
        
        template(v-slot:modal-footer)
          n-button.col-md-4(variant="light", :buttonText="getButtonTextGTLModal('cancel')", @click="handleCancel")
          n-button.col-md-4(variant="dark", :buttonText="getButtonTextGTLModal('submit')", @click="handleContinue", @keydown.enter="validateForm()")

      b-modal(id="add-dependents-modal-mobile", centered, no-close-on-backdrop, hide-footer, body-class="p-0 h-75", @hidden="selectedDependent={}")
        template(v-slot:modal-header)
          h5.hb5.mx-auto Adding Dependents
          n-icon.cursor-pointer(name="cross", variant="gray-600",  @click="$bvModal.hide('add-dependents-modal-mobile')")
        add-dependents-card.h-100(
          v-if="arePoliciesLoaded"
          :user="user",
          :isInsideModal="true",
          :coverageInfo="onboardingScreens[currentStep].paragraphs",
          :selectedDependent="selectedDependent",
          :dependentsModel="dependentsModel",
          :policies="policies",
          @pPilCoverage="updateCoveredPOrPil",
          @modelUpdated="saveDependents",
          ref="addDependentsCardMobile")
      b-modal(id="policies-modal-mobile", centered, no-close-on-backdrop, hide-footer, body-class="p-0 h-75")
        template(v-slot:modal-header)
          h5.hb5.mx-auto Insurance for your dependents
          n-icon.cursor-pointer(name="cross", variant="gray-600",  @click="$bvModal.hide('policies-modal-mobile')")      
        .container.overflow-auto.d-flex.flex-column.policy-list
          template
            .my-2.mx-0(v-for="policy in policies", :key="policy.id")
              mini-policy-card(:policy="policy", :user="user", :edgeMeta="policy.meta")
            .h3(v-if="policies.length===0") No benefits found that you are linked to currently.
            hr.w-100
            n-button.w-100.border.rounded.mb-3(
              variant="dark",
              buttonText="Resume adding Dependents",
              rightImageIcon="chevron-right",
              :hidden="checkIfCurrentScreen($options.screens.POLICY)"
              @click="$bvModal.hide('policies-modal-mobile')")
</template>

<script>
import gql from "graphql-tag";
import moment from "moment";
import { zipObject, isEmpty } from "lodash";
import { mapGetters } from "vuex";
import NPolicyCard from "../../components/PolicyCard.vue";
import adminDefs from "../../admin/definitions";
import { userFragment, orgBenefitFragment } from "../../admin/fragments";
import userDefs, { getCitiesQuery } from "../definitions";
import { apolloClient } from "../../../apollo";
import CardLink from "../claims/components/CardLink.vue";
import NModal from "../../../../components/NovaModal.vue";
import { dependentDropdownOptions } from "../constants";
import { AcceptedRelations, DependentAddSource, DependentStatus } from "../../../../common/enums";
import ProgressCard from "./components/ProgressCard.vue";
import DependentDetailsWrapper from "./components/DependentDetailsWrapper.vue";
import AddDependentsCard from "./components/AddDependentsCard.vue";
import MiniPolicyCard from "./components/MiniPolicyCard.vue";
import CardButton from "./components/CardButton.vue";
import NNavbar from "@/layout/TopNavbar.vue";
import SpecialModal from "@/components/SpecialModal.vue";
import utils, { validateEmployeeAge, isVfgErrorPresent, validateName } from "@/utils";
import AddDependentsWrapper from "@/components/AddDependents/AddDependentsWrapper.vue";
import NFooter from "@/layout/Footer.vue";
import NOverlay from "@/components/Overlay";
import NButton from "@/components/NovaButton.vue";
import EmbedScreen from "@/components/EmbedScreen.vue";

export default {
  components: {
    NButton,
    NFooter,
    ProgressCard,
    NOverlay,
    AddDependentsWrapper,
    NPolicyCard,
    SpecialModal,
    DependentDetailsWrapper,
    AddDependentsCard,
    MiniPolicyCard,
    NNavbar,
    CardLink,
    NModal,
    CardButton,
    EmbedScreen,
  },
  data() {
    return {
      arePoliciesLoaded: false,
      selectedDependent: {},
      reviewPolicies: false,
      defaultRoutes: [],
      confirmationCheckbox: false,
      currentStep: 1,
      currentGTLModalStep: 1,
      user: null,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
      depObj: {},
      gtlModelState: null,
      contactSchema: {
        fields: [
          {
            model: "meta.altEmail",
            type: "inline-input",
            inputType: "email",
            label: "Secondary Email",
            imageIcon: "at-the-rate",
            placeholder: "Enter a secondary mail",
            hint: "Adding a secondary mail allows you to receive updates related to your claims or policy on a non-work mail in an emergency situation when you can’t access your work mail.",
            validator: "email",
          },
          {
            model: "meta.city",
            type: "editable-dropdown",
            label: "Where do you live?",
            imageIcon: "city",
            placeholder: "Select a city",
            gqlQuery: getCitiesQuery(),
            transform: (value) => value.name,
            queryName: "dropdownCities",
            taggable: true,
            required: true,
          },
          {
            model: "meta.pincode",
            type: "inline-input",
            inputType: "text",
            placeholder: "Pincode",
            imageIcon: "pin",
            validator: "regexp",
            pattern: "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$",
            hint: "City and Pincode helps us direct you to the nearest hospital that accepts your insurance",
          },
        ],
      },
      gtlModel: {},
      gtlSchema: {
        fields: [
          {
            model: "meta.vaccination",
            type: "select-cards",
            label: "Which COVID-19 vaccination dose did you take?<span style='color:red'>*</span>",
            required: true,
            validator: ["required"],
            cardsData: [
              {
                name: "second",
                label: "Second",
                card_value: "second",
              },
              {
                name: "first",
                label: "First",
                card_value: "first",
              },
              {
                name: "none",
                label: "None",
                card_value: "none",
              },
            ],
            styleClasses: "mt-0 pt-0 mb-2",
          },
          {
            model: "meta.wasCovidInfected",
            type: "select-cards",
            label: "Have you been infected with COVID-19 in the past?<span style='color:red'>*</span>",
            required: true,
            validator: ["required"],
            cardsData: [
              {
                name: "yes",
                label: "Yes",
                card_value: "yes",
              },
              {
                name: "no",
                label: "No",
                card_value: "no",
              },
            ],
            styleClasses: "mt-0 pt-0 mb-2",
          },
        ],
      },
      nomineeSchema: {
        fields: [
          {
            model: "meta.nominee.added",
            type: "select-cards",
            label: "Do you want to add a nominee?<span style='color:red'>*</span>",
            required: true,
            validator: ["required"],
            cardsData: [
              {
                name: "yes",
                label: "Yes",
                card_value: "yes",
              },
              {
                name: "no",
                label: "No",
                card_value: "no",
              },
            ],
            styleClasses: "mt-0 pt-0 mb-2",
          },
        ],
      },
      gtlModalScreens: {
        1: {
          imageIcon: "covid",
          title: "Vaccination Details",
        },
        2: {
          imageIcon: "users",
          title: "Add Nominee",
        },
      },
      benefitsRelatedToUser: [],
      benefitMappingDetails: {},
      profileModel: {},
      avoidExtractingDependents: false,
      coverPOrPil: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    profileSchema() {
      return {
        fields: [
          {
            model: "name",
            type: "inline-input",
            inputType: "text",
            label: `Full Name<span style="color:red">*</span>`,
            disabled: false,
            required: true,
            min: 2,
            max: 50,
            validator: ["required", "string", validateName],
          },
          {
            model: "email",
            type: "inline-input",
            styleClasses: "d-inline-block w-75",
            inputType: "email",
            label: "Work Email",
            disabled: true,
          },
          {
            model: "meta.employeeId",
            type: "inline-input",
            styleClasses: "d-inline-block w-25 pl-2",
            inputType: "text",
            label: this.transformLabel("Employee ID"),
            disabled: !this.isUserOrgAdmin,
            required: true,
            validator: ["required"],
          },
          {
            model: "meta.contactNumber",
            type: "inline-input",
            inputType: "tel",
            label: `Phone Number<span style="color:red">*</span>`,
            validator: [utils.validateIndianMobileNumbersForVfg, "required"],
            required: true,
            placeholder: "Add a phone number",
          },
          {
            model: "meta.whatsappUserConsent",
            type: "contact-consent",
            isHighlighted: true,
          },
          {
            model: "dob",
            type: "datepicker",
            label: `Date of Birth<span style="color:red">*</span>`,
            min: "1900-01-01",
            max: moment().format("YYYY-MM-DD"),
            hint: "Your date of birth and age is required by your insurer",
            disabled: false,
            required: true,
            validator: [validateEmployeeAge],
            styleClasses: "d-inline-block w-50 pr-2",
          },
          {
            model: "meta.dateOfJoining",
            type: "datepicker",
            label: this.transformLabel("Date of Joining"),
            min: "1900-01-01",
            max: moment().format("YYYY-MM-DD"),
            hint: "This is date when you joined the company",
            disabled: !this.isUserOrgAdmin,
            required: true,
            styleClasses: "d-inline-block w-50 pl-2 align-top",
            validator: ["required"],
          },
          {
            model: "gender",
            type: "select-cards",
            label: `Gender<span style="color:red">*</span>`,
            disabled: false,
            hint:
              "We understand there are more gender identities. At present however, " +
              "we are still learning and trying to understand the impact of other gender identities " +
              "on health and come up with more friendly policies.",
            cardsData: [
              {
                name: "gender",
                icon: "female",
                label: "Female",
                card_value: "female",
              },
              {
                name: "gender",
                icon: "male",
                label: "Male",
                card_value: "male",
              },
            ],
            required: true,
            validator: "required",
          },
        ],
      };
    },
    isGTLPolicyPresent() {
      return this.policies.some((policy) => policy.type === "gtl");
    },
    getGTLCardHeading() {
      return this.areAllCovidDetailsAdded()
        ? "COVID-19 Vaccination Status ✅"
        : "COVID-19 Vaccination & Nominee Details 💉";
    },
    getGTLCardContent() {
      return this.areAllCovidDetailsAdded()
        ? "Details saved successfully!"
        : "To cover you under your group term life policy, we need your COVID-19 vaccination & nominee details.";
    },
    getGTLCardButtonText() {
      return this.areAllCovidDetailsAdded() ? "Edit Details" : "Add Details";
    },
    getGTLCardButtonVariant() {
      return this.areAllCovidDetailsAdded() ? "outline-primary" : "primary";
    },
    formSchema() {
      if (this.currentStep === 1) return this.profileSchema;
      return this.contactSchema;
    },
    dependentContent() {
      if (!this.avoidExtractingDependents) this.extractCoveredDependents();
      return utils.getContent(this.depObj);
    },
    areDependentsCovered() {
      return Boolean(this.depObj.coveredDependents?.length || this.depObj.pOrILFlag);
    },
    areNoDependentsAdded() {
      return this.areDependentsCovered && this.dependentsModel.length === 0;
    },
    isDepScreen() {
      return this.checkIfCurrentScreen(this.$options.screens.DEPENDENTS);
    },
    policies() {
      if (this.user) {
        return this.benefitsRelatedToUser.filter((benefit) => benefit.isPolicy === true);
      } else {
        return [];
      }
    },
    buttonTextForCurrentStep() {
      return this.onboardingScreens[this.currentStep]?.buttonText || "Continue";
    },
    routes() {
      if (!this.areDependentsCovered && this.getFeatureFlags.SHOW_ELIGIBLE_DEP_ONLY) {
        return this.defaultRoutes.filter((route) => route !== this.$options.baseRoute + "dependents");
      }
      return this.defaultRoutes;
    },
    onboardingScreens() {
      const aboutScreen = {
        key: this.$options.screens.ABOUT,
        imageIcon: "user-circle",
        stepTitle: "About you",
        paragraphs: [
          "These are details required by the insurer to activate your coverage benefits.",
          "Make sure these details are correct in order to avoid any complications during the claims process.",
        ],
        postHogEvent: "emp_signup_about_you_continue",
      };
      const customOnboardingScreen = {
        key: this.$options.screens.CUSTOM_ONBOARDING,
      };
      const dependentScreen = {
        key: this.$options.screens.DEPENDENTS,
        imageIcon: "family",
        stepTitle: "Adding Dependents",
        paragraphs: this.areDependentsCovered
          ? [
              `All together, your policies cover your ${this.dependentContent.replace(
                /Children/g,
                "Children (upto the age of 25)"
              )}`,
            ]
          : ["Ensure your family is covered by adding your dependents; it is not possible to add them later."],
        buttonText:
          this.dependentsModel.length > 0
            ? `Continue with ${utils.getSingularOrPlural("dependent", this.dependentsModel.length)}`
            : "Skip addition (not recommended) ->",
        postHogEvent: "emp_signup_dependent_continue",
      };
      const contactScreen = {
        key: this.$options.screens.CONTACT,
        imageIcon: "hospital",
        stepTitle: "Emergency Contact Details",
        paragraphs: [
          "These are details we need in order to reach you during emergencies.",
          "These details come in handy when we’re trying to assist you during claims or hospitalization. ",
        ],
        posthogEvent: "emp_signup_emergency_info_continue",
      };
      const policyScreenObject = {
        key: this.$options.screens.POLICY,
        imageIcon: "health-shield",
        stepTitle: "Policy Overview",
        paragraphs: [
          this.policies.length > 0
            ? `${this.user.org.name} has added you to ${this.policies.length} ` +
              (this.policies.length > 1 ? "policies" : "policy") +
              " ."
            : `Addition of policies is pending.`,
          "You'll receive a notification from us once the coverage options are activated.",
          "You'll find additional policy details inside the Nova Portal✨",
        ],
      };
      const onboardingScreensArray = [aboutScreen];
      if (this.isCustomOnboardingEnabled) {
        onboardingScreensArray.push(customOnboardingScreen);
      }
      // only add dependent Screen when ff true and no dependent covered in policies
      if (
        (this.areDependentsCovered || !this.getFeatureFlags.SHOW_ELIGIBLE_DEP_ONLY) &&
        !this.user?.hideDependentsScreen
      ) {
        onboardingScreensArray.push(dependentScreen);
      }

      onboardingScreensArray.push(contactScreen);
      if (this.user) {
        onboardingScreensArray.push(policyScreenObject);
      }
      const onboardingScreenIndices = Array.from(Array(onboardingScreensArray.length), (e, i) => i + 1);
      const onboardingScreens = zipObject(onboardingScreenIndices, onboardingScreensArray);
      return onboardingScreens;
    },
    dependentsModel() {
      if (this.user) return this.user.dependents;
      else return [];
    },
    isMobileView() {
      // checking for lg
      return window.innerWidth < 992;
    },
    isUserOrgAdmin() {
      return this.user?.roles?.includes("org_admin");
    },
    isCustomOnboardingEnabled() {
      return this.user?.org?.meta?.customOnboardingUrl;
    },
    isCustomOnboardingScreen() {
      if (!this.isCustomOnboardingEnabled) {
        return false;
      }
      return this.checkIfCurrentScreen(this.$options.screens.CUSTOM_ONBOARDING);
    },
  },
  watch: {
    $route: {
      handler: function () {
        const currentStep = this.routes.indexOf(this.$route.path);
        this.currentStep = currentStep === -1 ? 1 : currentStep;
      },
    },
    depObj() {
      if (this.depObj.pOrILFlag && !this.coverPOrPil) {
        const pAndPilCount = {
          parent: 0,
          "parent-in-law": 0,
        };
        this.dependentsModel.forEach((dep) => {
          if (dep.relation === AcceptedRelations.PARENT) pAndPilCount.parent++;
          if (dep.relation === AcceptedRelations.PARENT_IN_LAW) pAndPilCount["parent-in-law"]++;
        });
        this.user.dependents = this.dependentsModel.map((dep) => {
          if (dep.relation.includes(AcceptedRelations.PARENT)) {
            if (this.depObj.pOrILFlag && pAndPilCount[`parent${dep.relation === "parent" ? "-in-law" : ""}`] === 0) {
              dep.meta.isPreferred = true;
            }
            this.coverPOrPil = dep.relation;
          }
          return dep;
        });
      }
    },
  },
  async created() {
    this.$options.screens = {
      ABOUT: "about",
      CUSTOM_ONBOARDING: "custom-onboarding",
      DEPENDENTS: "dependents",
      CONTACT: "contact",
      POLICY: "policy",
    };
    this.showLoader();
    this.$options.stateMessageMap = {
      error: "This is required!",
    };
    this.$options.baseRoute = "/user/get-started/";
    const userData = await this.$apollo.queries.user.refetch();
    if (userData.data) {
      this.user = utils.deepClone({
        ...userData.data.me,
        dependents: userData.data.me.dependents?.filter((dep) => dep.status !== DependentStatus.DELETE_PENDING),
      });
      this.profileModel = utils.deepClone(userData.data.me);
      if (!["male", "female", "other"].includes(this.profileModel?.gender)) delete this.profileModel.gender;
    }
    let routes = ["home", "about", "dependents", "contact", "policies"];
    // add a new route for custom-onboarding , if enable
    if (this.isCustomOnboardingEnabled) {
      routes.splice(2, 0, "custom-onboarding");
    }
    routes = routes.map((route) => "" + this.$options.baseRoute + route);
    this.defaultRoutes = routes;
    if (!this.user.meta?.showOnboardingScreens) {
      this.$router.push({ name: "error", params: { type: "unauthorized" } });
    }
    this.dependentsModel.forEach((dep) => {
      if (dep.meta?.isPreferred && dep.relation.includes(AcceptedRelations.PARENT)) {
        this.coverPOrPil = dep.relation;
      }
    });
    this.profileModel.meta.whatsappUserConsent =
      this.profileModel.meta?.whatsappUserConsent === false ? this.profileModel.meta?.whatsappUserConsent : true;
  },
  mounted() {
    const currentStep = this.routes.indexOf(this.$route.path);
    this.currentStep = currentStep === -1 ? 1 : currentStep;
  },
  methods: {
    transformLabel(label) {
      if (this.isUserOrgAdmin) {
        return `${label}<span style="color:red">*</span>`;
      }
      return label;
    },
    async validateForm() {
      await this.$refs?.formData?.validate();
    },
    handleCancel() {
      if (this.currentGTLModalStep === 1) this.$bvModal.hide("gtl-modal");
      else this.currentGTLModalStep--;
    },
    async handleContinue() {
      await this.validateForm();
      if (isVfgErrorPresent(this.$refs.formData?.errors)) {
        this.$store.commit("addAlert", {
          variant: "danger",
          message: "All fields are mandatory!",
        });
        return;
      }
      this.$store.commit("clearAlerts");
      if (this.currentGTLModalStep === 1) this.currentGTLModalStep++;
      else {
        await this.saveUserDetails();
        this.$bvModal.hide("gtl-modal");
      }
    },
    areAllCovidDetailsAdded() {
      if (
        this.user.meta?.vaccination &&
        this.user.meta?.wasCovidInfected &&
        (this.user.meta?.nominee?.added === "no" ||
          (this.user.meta?.nominee?.added === "yes" &&
            this.user.meta?.nominee.name &&
            this.user.meta?.nominee.relation &&
            this.user.meta?.nominee.relation !== "None selected"))
      ) {
        this.resetGTLModalState();
        return true;
      }
      return false;
    },
    resetGTLModalState() {
      this.gtlModelState = null;
      setTimeout(() => {
        this.currentGTLModalStep = 1;
      }, 500);
    },
    getNomineeSchema() {
      if (this.profileModel.meta?.nominee?.added === "yes") {
        return {
          fields: [
            ...this.nomineeSchema.fields,
            {
              model: "meta.nominee.name",
              type: "inline-input",
              inputType: "text",
              label: `Nominee Name<span style="color:red">*</span>`,
              required: true,
              min: 2,
              max: 50,
              validator: ["required", "string", validateName],
            },
            {
              model: "meta.nominee.relation",
              type: "inline-select",
              label: `Relationship<span style="color: red">*</span>`,
              reduce: (v) => v.value,
              options: dependentDropdownOptions,
              required: true,
              validator: ["required", utils.validateInlineSelect],
            },
          ],
        };
      }
      delete this.profileModel.meta?.nominee?.name;
      delete this.profileModel.meta?.nominee?.relation;
      return this.nomineeSchema;
    },
    getGTLSchema() {
      return this.currentGTLModalStep === 1 ? this.gtlSchema : this.getNomineeSchema();
    },
    showPolicies() {
      if (this.isMobileView) {
        this.$bvModal.show("policies-modal-mobile");
      } else {
        this.toggleReviewPolicy();
      }
    },
    getScreenIndex(screenKey) {
      return Object.values(this.onboardingScreens).findIndex((screen) => screen.key === screenKey) + 1;
    },
    checkIfCurrentScreen(screenKey) {
      return this.currentStep === this.getScreenIndex(screenKey);
    },
    showData(dependent) {
      if (this.isMobileView) this.$bvModal.show("add-dependents-modal-mobile");
      this.selectedDependent = dependent;
    },
    getButtonTextGTLModal(type) {
      switch (type) {
        case "cancel": {
          return this.currentGTLModalStep === 1 ? "Cancel" : "<- Go Back";
        }
        case "submit": {
          return this.currentGTLModalStep === 1 ? "Continue ->" : "Submit";
        }
      }
    },
    async skipAddition() {
      this.$bvModal.hide("dependent-verification-modal");
      await this.saveDependents(this.dependentsModel);
      this.nextStep(1, true);
    },
    showLoader() {
      this.loading = true;
      // auto hide is needed because apollo errors do not return
      // control to the calling method
      setTimeout(() => {
        this.loading = false;
      }, 1500);
    },
    async saveUserDetails() {
      this.showLoader();
      this.$store.commit("clearAlerts");
      try {
        await this.$apollo.mutate({
          mutation: userDefs.userChanges.updateUserOrDependentInfo,
          variables: {
            userId: this.user.id,
            orgId: this.user.org.id,
            changedUserInfo: {
              name: this.profileModel.name,
              gender: this.profileModel?.gender,
              dob: this.profileModel?.dob,
              email: this.profileModel?.email,
              meta: this.profileModel?.meta,
            },
          },
        });
        this.$store.commit("addAlert", {
          variant: "success",
          message: "Successfully updated the details",
        });
        const userData = await this.$apollo.queries.user.refetch();
        this.$store.commit("updateUserProfileData", userData.data.me);
      } catch (err) {
        throw err;
      } finally {
        this.loading = false;
      }
    },
    async saveDependents(updatedDependents) {
      this.showLoader();
      this.$store.commit("clearAlerts");
      window.posthog.capture("emp_signup_save_dependent_click", {
        employee_email: this.user?.email,
        org_name: this.user?.org.name,
        dependent_count: updatedDependents.length,
      });
      try {
        this.avoidExtractingDependents = true;
        await this.$apollo.mutate({
          mutation: userDefs.users.upsertMutation,
          variables: {
            id: this.user.id,
            orgId: this.user.org.id,
            dependents: updatedDependents?.map((dep) => {
              return {
                ...dep,
                meta: {
                  ...(dep?.meta || {}),
                  source: dep?.meta?.source || DependentAddSource.USER_ONBOARD_FLOW,
                },
              };
            }),
          },
        });
        this.$store.commit("addAlert", {
          variant: "success",
          message: "Successfully updated the details",
        });
        await this.$apollo.queries.user.refetch();
        await this.$apollo.queries.benefitsRelatedToUser.refetch();
        this.avoidExtractingDependents = false;

        this.$refs.addDependentsCard?.resetForm();
        this.$refs.addDependentsCard?.setNextChildCoverage();

        this.$refs.addDependentsCardMobile?.resetForm();
        this.$refs.addDependentsCardMobile?.setNextChildCoverage();
        if (this.isMobileView) this.$bvModal.hide("add-dependents-modal-mobile");
      } catch (err) {
        console.error(err);
        throw err;
      } finally {
        this.loading = false;
      }
    },
    extractCoveredDependents() {
      this.depObj = utils.extractCoveredDependents(this.policies);
    },
    // Returns true if dependents added are less than dependents covered
    async verifyDependentCount() {
      let depModel = this.dependentsModel;
      // Getting a count of the number of `children` added
      const childrenAdded = depModel.filter((dep) => dep.relation === "child").length;
      depModel = [...new Set(depModel.map((dep) => dep.relation))];
      if (this.areDependentsCovered) {
        if (!depModel.length) return true;
        else {
          // Checking for the cases when either the `parent` or the `parent-in-law` is covered
          if (this.depObj.pOrILFlag) {
            // Checking if all covered dependent relations are present in the added set of relations
            // Also checking if the no. of `children` added >= no. of `children` covered
            // Finally checking if either `parent` or `parent-in-law` have been added
            return !(
              this.depObj.coveredDependents.every((element) => depModel.includes(element)) &&
              childrenAdded >= this.depObj.childrenCount &&
              (depModel.includes("parent") || depModel.includes("parent-in-law"))
            );
          }
          // If the previous condition was false
          // Checking if all covered relations are added, and
          // Checking if the no. of `children` added >= no. of `children` covered
          else {
            return !(
              this.depObj.coveredDependents.every((element) => depModel.includes(element)) &&
              childrenAdded >= this.depObj.childrenCount
            );
          }
        }
      }
      return false;
    },
    updateCoveredPOrPil(coverPOrPil) {
      this.coverPOrPil = coverPOrPil;
    },
    async nextStep(value, flag = false) {
      if ([1, this.getScreenIndex(this.$options.screens.CONTACT)].includes(this.currentStep) && value > 0) {
        await this.validateForm();
        if (isVfgErrorPresent(this.$refs?.formData?.errors)) {
          this.$store.commit("addAlert", {
            variant: "danger",
            message: "Please enter valid details before continuing to the next step",
          });
          return;
        }
      }
      if (this.isDepScreen && value === 1 && (await this.verifyDependentCount()) && !flag) {
        this.$bvModal.show("dependent-verification-modal");
        return;
      }
      this.currentStep = this.currentStep + value;
      this.$router.push(this.routes[this.currentStep]);
    },
    logStepInPosthog() {
      const user = this.user || this.$store?.state?.user;
      if (!user) return;
      const eventProperties = {
        employee_email: user.email,
        day_of_month: new Date().getDate(),
        org_name: user.org.name,
      };
      if (this.isDepScreen) {
        eventProperties.dependent_count = user.dependents.length;
      }
      window.posthog.capture(this.onboardingScreens[this.currentStep].posthogEvent, eventProperties);
    },
    async continueFlow() {
      await this.$refs.formData?.validate();
      if (isVfgErrorPresent(this.$refs.formData?.errors)) {
        this.$store.commit("addAlert", {
          variant: "danger",
          message: "Please enter valid details before continuing to the next step",
        });
        return;
      }
      this.$store.commit("clearAlerts");
      this.logStepInPosthog();
      if (this.currentStep === 1 || this.checkIfCurrentScreen(this.$options.screens.CONTACT)) {
        await this.saveUserDetails();
      }
      if (this.checkIfCurrentScreen(this.$options.screens.POLICY)) {
        if (this.isGTLPolicyPresent && !this.areAllCovidDetailsAdded()) {
          this.gtlModelState = "error";
          this.$refs.gtlCard.$el?.scrollIntoView({ behavior: "smooth" });
          return;
        }
        await this.markFlowAsCompleted();
        // mark token as used
        await this.markTokenAsUsed();
        apolloClient.cache.data.clear();
        this.$router.push({ name: "dashboard" });
        return;
      }
      this.nextStep(+1);
    },
    toggleReviewPolicy() {
      this.reviewPolicies = !this.reviewPolicies;
    },
    updateBenefitMapping(benefitMapping) {
      this.benefitMappingDetails[benefitMapping.benefitId] = benefitMapping.membersCovered;
    },
    async mapUserAndDependentsToBenefits() {
      this.$store.commit("clearAlerts");
      try {
        await this.$apollo.mutate({
          mutation: userDefs.users.mapUserAndDependentsToBenefits,
          variables: {
            userId: this.user.id,
          },
        });
        this.$store.commit("addAlert", {
          variant: "success",
          message: "Successfully requested addition to the policies/benefits",
        });
      } catch (err) {
        console.log(err);
      }
    },
    async markFlowAsCompleted() {
      // TODO: Put checks to ensure all the required data has been filled
      // before executing below lines
      this.profileModel.meta.showOnboardingScreens = false;
      this.profileModel.meta.autoApproveEndorsements = false;
      if (this.benefitsRelatedToUser.length > 0) {
        await this.mapUserAndDependentsToBenefits();
      }
      await this.saveUserDetails();
    },
    async markTokenAsUsed() {
      const userToken = this.$store.getters.getUserLoginToken;

      if (!userToken) return;

      await this.$apollo.mutate({
        mutation: gql`
          mutation markTokensAsUsed($email: String!, $token: String!) {
            markTokensAsUsed(input: { token: $token, email: $email }) {
              email
            }
          }
        `,
        variables: {
          email: this.$store.getters.user.email,
          token: userToken,
        },
      });
    },
    generateCustomOnboardingLink() {
      if (!this.isCustomOnboardingEnabled) {
        return "";
      }
      const embedAppLink = this.user?.org?.meta?.customOnboardingUrl;
      const urlPrams = new URLSearchParams();
      this.benefitsRelatedToUser.forEach((benefit) => {
        if (!benefit.meta?.isFlexi) {
          return;
        }

        benefit.meta?.requiredFields?.forEach((requiredField) => {
          if (urlPrams.has(requiredField)) {
            return;
          }
          const urlParam = this.user[requiredField] || this.user.meta[requiredField];
          urlPrams.append(requiredField, urlParam);
        });
      });
      const embedUrl = `${embedAppLink}#${urlPrams.toString()}`;
      return embedUrl;
    },
    async recordIncomingEmbedFormData(message) {
      if (message?.type !== "custom-onboarding") {
        return;
      }
      this.$store.commit("clearAlerts");
      if (isEmpty(message?.data)) {
        this.nextStep(+1);
        return;
      }
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation CreateFormResponse($meta: JSONObject!, $formSlug: String) {
              upsertFormResponse(input: { meta: $meta, formSlug: $formSlug }) {
                formResponse {
                  id
                  __typename
                }
                __typename
              }
            }
          `,
          variables: {
            meta: message?.data,
            formSlug: "custom-onboarding",
          },
        });
        if (result.data?.upsertFormResponse?.formResponse?.id) {
          // if succesfully submitted the form, redirect to next screen
          this.nextStep(+1);
          this.$store.commit("addAlert", {
            variant: "success",
            message: "You have successfully customised your benefits",
          });
          return;
        }
        this.$store.commit("addAlert", {
          variant: "danger",
          message: "Benefits customisation failed. Please contact Nova support.",
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
  apollo: {
    user: {
      query: gql`
        query LoggedInUser {
          me {
            ...User
            hideDependentsScreen
          }
        }
        ${userFragment}
      `,
      update(data) {
        this.loading = false;
        return {
          ...data.me,
          dependents: data.me.dependents?.filter((dep) => dep.status !== DependentStatus.DELETE_PENDING),
        };
      },
    },
    benefitsRelatedToUser: {
      skip() {
        if (this.user) {
          return false;
        } else {
          return true;
        }
      },
      fetchPolicy: "no-cache",
      query: gql`
        query getBenefitsRelatedToUser($userId: ID!) {
          getBenefitsRelatedToUser(userId: $userId) {
            ...OrgBenefitEdge
          }
        }
        ${orgBenefitFragment}
      `,
      variables() {
        return { userId: this.user.id };
      },
      update(data) {
        const benefitsRelatedToUser = data.getBenefitsRelatedToUser.map((orgBenefitEdge) =>
          adminDefs.benefits.transform(orgBenefitEdge.node)
        );
        // Initializing keys-value slots for storing the mapping
        // done by benefitMapper
        benefitsRelatedToUser.forEach((benefit) => {
          this.benefitMappingDetails[benefit.id] = {};
        });
        this.arePoliciesLoaded = true;
        return benefitsRelatedToUser;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_variables.scss";
.dependents-wrapper {
  min-height: 400px;
  max-height: 500px;
  overflow: auto;
  width: 100%;
}
.watermark {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.policies-wrapper {
  position: relative;
  min-height: 500px;
  max-height: 600px;
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.group-img {
  position: absolute;
  left: 22rem;
  bottom: 0;
}
.policy-scroll {
  height: calc(100vh - 16rem);
  overflow-y: auto;
}
.dependents-scroll {
  height: 25rem;
  overflow-y: auto;
  overflow-x: none;
  @include media-breakpoint-down(sm) {
    height: calc(100vh - 18rem);
  }
}
.policy-list {
  max-height: 75vh;
}
.left-container {
  height: 91vh;
  @include media-breakpoint-down(sm) {
    height: auto;
  }
}
</style>
