export const Base = require("./Base.vue").default;

export const routes = [
  { path: "", redirect: "plus" },
  {
    path: "plus",
    component: () =>
      import(/* webpackChunkName: "topups" */ "./TopUpInfo.vue"),
    name: "top_up_intro",
  },
  {
    path: "plans/:id",
    component: () =>
      import(/* webpackChunkName: "topups" */ "./SumInsured.vue"),
    name: "top_up_plans",
  },
  {
    path: "add-details/:id",
    component: () =>
      import(/* webpackChunkName: "topups" */ "./ProposalForm.vue"),
    name: "add_member_details",
  },
  {
    path: "order-summary/:id",
    component: () =>
      import(/* webpackChunkName: "topups" */ "./ReviewPlan.vue"),
    name: "order_summary",
  },
];
