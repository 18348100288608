<template lang="pug">
  .main-faq-container.row(v-if="faqsLoaded")
    .col-md-4
      h1.hb1.text-blackpearl Got Questions?
      h1.text-gray-800 We've got answers
    .col-md-8.mt-sm-3.mt-md-0.main-faq-card-container
      b-tabs.main-faq-card-tabs(
        active-nav-item-class="hb5 border-bottom bg-transparent border-dark border-bottom-2",
        content-class="mt-2").border-0
        b-tab.bg-transparent(
          v-for="(faqTopic, i) in Object.keys(faqs)",
          :key="`main-faq-${i}`").main-faq-card-tabs-tab
          template(v-slot:title)
            .faq-tab-title.py-2.text-gray-900.px-lg-3.px-sm-0.hb5 About {{ faqTopic }}
          b-card.shadow-none.mb-1(no-body, v-for="(faq, index) in faqs[faqTopic]", :key="`${faqTopic}-${index}`")
            b-card-header(header-tag="header", role="tab").p-1.bg-white.border-0.faq-accordion-header
              b-button.d-flex.align-items-center.px-0.py-2.text-left.bg-white.shadow-none(
                block,
                v-b-toggle="`accordion-${i}-${index}`",
                variant="light")
                i.fa.fa-minus-circle.fa-lg.when-open.px-2
                i.fa.fa-plus-circle.fa-lg.when-closed.px-2
                span.text-gray-900.font-weight-semibold(v-html="faq.question")
            b-collapse(:id="`accordion-${i}-${index}`", accordion="faq-accordion", role="tabpanel")
              b-card-body.pt-0.px-3
                b-card-text.text-gray-800.font-weight-medium.text-left(v-html="faq.answer")
</template>

<script>
import { isEmpty } from "lodash-es";
import resDefs from "../../policyCalculator/definitions";
export default {
  name: "FAQ",
  data() {
    return {
      faqs: {},
      faqsLoaded: false,
    };
  },
  async mounted() {
    const result = await this.$apollo.query({
      query: resDefs.strapiContents.listQuery,
      variables: {
        filter: {
          collectionType: "Faq",
        },
      },
    });
    result?.data?.strapiContents?.edges.forEach((faq) => {
      faq = faq.node?.meta;
      if (this.faqs[faq.topic]) {
        this.faqs[faq.topic].push(faq);
      } else {
        this.faqs[faq.topic] = [faq];
      }
    });
    if (!isEmpty(this.faqs)) {
      this.faqsLoaded = true;
    }
  },
};
</script>

<style lang="scss">
.faq-accordion-header {
  .btn,
  .btn:focus,
  .btn:active,
  .btn:not(:disabled):not(.disabled):active {
    border: none !important;
  }
}
.main-faq-card-container {
  .nav-tabs > .nav-item > a,
  .nav-tabs > .nav-item > a:focus,
  .nav-tabs > .nav-item > a:hover {
    border: 0;
  }
}
p {
  margin: 0;
}
</style>
