import { getListQuery } from "../queries";

export default {
  schema: {
    fields: [
      {
        model: "segment",
        type: "id-selector",
        queryName: "segments",
        gqlQuery: getListQuery("Segment"),
        queryVariables: () => ({ filter: { segmentCategory: "USER" } }),
        label: "Select Segment",
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getListQuery("Org"),
        placeholder: "Organization filter (none)",
        showSelected: false,
      },
      {
        model: "segment",
        type: "id-selector",
        queryName: "segments",
        gqlQuery: getListQuery("Segment"),
        queryVariables: () => ({ filter: { segmentCategory: "USER" } }),
        placeholder: "Segment filter (none)",
        showSelected: false,
      },
    ],
  },
}
