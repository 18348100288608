import gql from "graphql-tag";
import { userFragment } from "./../../portal/pages/admin/fragments";

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!, $auth_type: String!) {
    authenticateUser(input: { email: $email, password: $password, auth_type: $auth_type }) {
      refreshToken
      token
      passwordResetToken
      user {
        ...User
        isPasswordWeak
      }
    }
  }
  ${userFragment}
`;

export const SSO_USER_TOKEN_QUERY = gql`
  query fetchAuthTokens($ssoGrantToken: String) {
    fetchAuthTokens(ssoGrantToken: $ssoGrantToken)
  }
`;

export const MAGICLINK_MUTATION = gql`
  mutation sendMagicLink($email: String!) {
    sendMagicLink(input: { email: $email }) {
      success
    }
  }
`;

export const VALIDATE_MAGICLINK_MUTATION = gql`
  mutation magicLinkValidation($magicToken: String!) {
    magicLinkValidation(input: { magicToken: $magicToken }) {
      token
      refreshToken
      user {
        ...User
      }
    }
  }
  ${userFragment}
`;

export const MY_INFO = gql`
  query MyInfo {
    me {
      id
      ...User
    }
  }
  ${userFragment}
`;

export const REFRESH_TOKEN = gql`
  mutation AuthenticateWithRefreshToken($refreshToken: String) {
    authenticateWithRefreshToken(input: { refreshToken: $refreshToken }) {
      token
      user {
        ...User
      }
    }
  }
  ${userFragment}
`;

export const LOGIN_CONFIG = gql`
  query fetchLoginConfig($email: String) {
    fetchLoginConfig(email: $email)
  }
`;

export const VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String!, $email: String!) {
    verifyToken(input: { token: $token, email: $email }) {
      user {
        ...User
      }
      token
      refreshToken
      passwordlessOnboarding
    }
  }
  ${userFragment}
`;

export const SET_INVITED_USER_PASSWORD = gql`
  mutation setPassword($token: String!, $email: String!, $newPassword: String!) {
    setPassword(input: { token: $token, email: $email, newPassword: $newPassword }) {
      passwordSet
    }
  }
`;

export const IMPERSONATE_USER = gql`
  query getUserToken($id: ID!) {
    getUserToken(id: $id)
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation changePassword($id: ID!, $currentPassword: String, $newPassword: String!, $isAdminPage: Boolean) {
    changePassword(
      input: { id: $id, currentPassword: $currentPassword, newPassword: $newPassword, isAdminPage: $isAdminPage }
    ) {
      passwordUpdated
    }
  }
`;
