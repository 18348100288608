<template lang="pug">
  b-modal(:id="id", centered)
    template(#modal-header)
      .w-100.h5.text-danger.text-center.pt-2
        span Removing an item
    template(#modal-footer)
      n-button.px-4(variant="light", :buttonText="'Keep Item'", @click="$bvModal.hide(id)")
      n-button.px-4(variant="danger", :buttonText="'Remove'", @click="confirmRemoval")

    .text-center.py-4
      span.h4 Are you sure you want to remove this item?
      AddOn.text-left.mx-auto.my-4.w-60(
        :icon="icon",
        :title="title",
        :description="description",
        :cost="cost",
        :disableButton="true")

</template>

<script>
import NButton from "@/components/NovaButton.vue";
import AddOn from "./AddOn.vue";
export default {
  components: {
    AddOn,
    NButton,
  },
  props: {
    id: {
      type: String,
      default: "addon-deletion-confirmation-modal",
    },
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    cost: {
      type: String,
      default: "",
    },
  },
  methods: {
    confirmRemoval() {
      this.$emit("confirmRemoval", this.title);
      this.$bvModal.hide(this.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.text-danger {
  color: $red-600 !important;
}
.w-60 {
  width: 65%;
}
</style>
