<template lang="pug">
.info-container.px-4.mt-3.d-flex.align-items-center.justify-content-start.position-relative(:class="[`bg-${bannerBackground}`]")
  .icon-container.p-3.d-flex.align-items-center.justify-content-center
    i(:class="[`icon-${icon}`, `text-${iconColor}`]")
  .info-details.d-flex.align-items-start.justify-content-start.flex-column.w-75
    .info-title.font-md.font-inter.font-weight-semibold.text-gray-800.px-3.pb-1 {{title || ""}}
    .info-text.font-sm.font-inter.font-weight-medium.text-gray-700.px-3 {{subtext || ""}}
  .btn-group.ml-auto.mr-1.my-3
    n-button.rounded-8.px-2(
      v-if="buttonText",
      @click="openModal"
      :buttonText="buttonText",
      :disabled="buttonDisabled",
      :rightImageIcon="rightImageIcon",
      variant="dark",
      size="lg",
    )
    n-button.rounded-8.ml-2(
      v-if="buttonTextSecondary && showSecondaryButton",
      @click="handleSecondaryModal('show')",
      :buttonText="buttonTextSecondary",
      :disabled="buttonDisabledSecondary",
      :rightImageIcon="rightImageIconSecondary",
      variant="outline-secondary",
      size="lg",
    )
    n-button.rounded-8.ml-2(
      v-if="exportFlag && !this.$route.path.includes(`org-admin/`)",
      buttonText="Export changes",
      @click="openECModal('export-changes-modal')",
      :variant="exportButtonVariant",
      size="lg",
    )
  reminder-modal(
    :employeeCount="employeeCount",
    :is-selected-batch-overdue="isSelectedBatchOverdue",
  )
  export-changes-modal(:orgId="orgId", :currentTab="currentTab", :selectedBatchId="selectedBatchId")
  review-changes-modal(
    :changes-data="items",
    :total-changes="totalChanges",
    :unapproved-data="unapprovedData",
    :current-batch-id="selectedBatchId",
    @refreshTable="$emit('refreshTable')",
  )
  transfer-batch-modal(
    :id="'transfer-batch-modal-for-batch'",
    :v-if="!this.$route.path.includes(`org-admin/`)",
    @update-batch="$emit('update-batch', $event)",
    @modal-closed="handleSecondaryModal('hide')",
    :batches="batches.filter(batch => batch.id !== selectedBatchId)"
  )
</template>

<script>
import ReminderModal from "./ReminderModal.vue";
import ExportChangesModal from "./ExportChangesModal.vue";
import ReviewChangesModal from "./ReviewChangesModal.vue";
import TransferBatchModal from "./TransferBatchModal.vue";
import NButton from "@/components/NovaButton.vue";

export default {
  name: "Banner",
  components: {
    NButton,
    ReviewChangesModal,
    ReminderModal,
    ExportChangesModal,
    TransferBatchModal,
  },
  props: {
    exportFlag: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subtext: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: "question",
    },
    buttonText: {
      type: String,
      default: null,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: "red-600",
    },
    bannerBackground: {
      type: String,
      default: "red-100",
    },
    employeeCount: {
      type: Number,
      default: 0,
    },
    orgId: {
      type: String,
      default: "",
    },
    currentTab: {
      type: String,
      default: "",
    },
    selectedBatchId: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    totalChanges: {
      type: Number,
      default: 0,
    },
    unapprovedData: {
      type: Object,
      default: () => {},
    },
    modalName: {
      type: String,
      default: "",
    },
    modelNameSecondary: {
      type: String,
      default: "",
    },
    exportButtonVariant: {
      type: String,
      default: "outline-secondary",
    },
    isSelectedBatchOverdue: {
      type: Boolean,
      default: false,
    },
    rightImageIcon: {
      type: String,
      default: null,
    },
    batches: {
      type: Array,
      default: () => [],
    },
    buttonTextSecondary: {
      type: String,
      default: null,
    },
    buttonDisabledSecondary: {
      type: Boolean,
      default: false,
    },
    rightImageIconSecondary: {
      type: String,
      default: null,
    },
    showSecondaryButton: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    openModal() {
      this.$bvModal.show(this.modalName);
    },
    openECModal(param) {
      this.$bvModal.show(param);
    },
    handleSecondaryModal(changeType = "hide") {
      if (changeType === "show") {
        this.$bvModal.show(this.modelNameSecondary);
        return;
      }

      this.$bvModal.hide(this.modelNameSecondary);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.info-container {
  height: 5rem;
  border: $border-width-custom-1 solid $gray-300;
}
.icon-container {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: $white;
}
</style>
