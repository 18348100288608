<template lang="pug">
.summary-container.px-4.mt-4.pt-4.pb-3.d-flex.justify-content-between
  .title
    .h4.info-title.mb-1 {{ title || "" }}
    .info-text {{ subtext || "" }}
  .ml-auto.mr-2.d-flex.text-right(v-if="isPremiumBreakupEnabled")
    .subtitle.mr-3
      template(v-if="batchPremiumStatus().isPaid")
        n-chip.info-text-container.ml-1(variant="light", :isMinimal="true",)
          span.info-text Premium Paid
          template(v-slot:icon)
            i.icon-check-circle.mr-1.text-success
      template(v-else)
        .info-text Estimated Premium
      div.hb4.text-gray-900
        i.icon-rupee.ic-large.align-sub
        | {{ totalEstimatedPremiumAcrossInsurers() }}
    .vertical-separator
      n-button.px-3.ml-3(
        buttonText="View Details",
        rightImageIcon="chevron-right",
        variant="light",
        @click="$router.push({ name: getPremiumDetailsRoute, params: { batchId: batch.id }})")
  b-dropdown.super-admin-dropdown.ml-2(
    v-if="isSuperAdmin",
    no-caret,
    right,
    variant="light",
  )
    template(v-slot:button-content)
      img.img-fluid.mr-3(:src="require(`@/assets/images/three-vertical-dots.svg`)")
      span.font-weight-medium More
    b-dropdown-item-button(
      :disabled="!isMarkNoEndorsementFromHREnabled",
      v-b-modal.basic-modal) Mark No Endorsements
    b-dropdown-item-button(@click="openPremiumUpdateModal") Update Premium
    b-dropdown-item-button(v-b-modal="'resync-premium-modal'", :disabled="!isDynamicPremiumEnabled") Resync Premium
    b-dropdown-item-button(
      :disabled="isCurrentBatch",
      v-b-tooltip.hover.left,
      :title="isCurrentBatch ? 'Not available for current batch' : ''"
      @click="showEndorsementDocUploadModal"
    ) Upload Documents
    b-dropdown-item-button(:disabled="true", v-b-tooltip.hover.left, title="coming soon!" @click="openBatchDelayModal") Mark as Delayed
  b-dropdown.endorsement-documents-dropdown.ml-2(
    v-else-if="!isCurrentBatch",
    no-caret,
    right,
    variant="light",
  )
    template(v-slot:button-content)
      span.font-weight-medium Download Documents
      i.ml-2.icon-chevron-down
    template(v-for="(policyType, index) in getUserPolicyTypes()")
      b-dropdown-group(:header="policyType?.toUpperCase()+' policy'")
        b-dropdown-item.font-sm.font-inter.font-weight-medium.text-gray-900(
          v-for="(docType) in Object.values($options.EndorsementDocumentsEnum)",
          v-if="isDocumentPresent(policyType, docType)",
          @click="downloadDocument(policyType, docType)",
        ) Download {{ getDropdownHeading(docType) }}
        b-dropdown-divider.text-gray-400(v-if="index < (Object.values($options.EndorsementDocumentsEnum).length -1)")
  Premium-update-modal(
    v-if="isSuperAdmin && orgId && batch",
    :orgId="orgId",
    :batch="batch",
    @modal-closed="closePremiumUpdateModal",
    @refetchBatch="$emit('refetchBatch', $event)",
    :isDynamicPremiumEnabled="isDynamicPremiumEnabled",
  )
  basic-modal(
    title="Hold on!",
    :description="`Are you sure you don't have any new employee or dependent related additions, deletions or changes for ${monthOnCalendar} ${yearOfBatch} batch?`",
    proceedBtnText="I am sure ->",
    @cancelBtnClicked="$root.$emit('bv::hide::modal', 'basic-modal')",
    @proceedBtnClicked="markNoEndorsementFromHR"
  )
  n-modal(
    id="endorsement-document-upload-modal",
    centered,
    title="Uploaded Files",
    size="lg",
    :hide-footer="false",
    hide-backdrop,
    no-close-on-backdrop,
    no-close-on-esc,
  )
    template(v-slot:modal-header)
      span Uploaded Files
    file-upload.pt-1.pb-3(
      v-model="documentsData.files",
      :accept="$options.acceptedEndorsementDocsFormat",
      :options="Object.values($options.EndorsementDocumentsEnum)",
      :secondaryOptions="getUserPolicyTypes()",
      :allowSecondarySelector="true",
      :allowZipDownload="false",
      :allowFilePreview="false",
      :useAwsS3="true",
      :uploadFileAction="$options.FileActionEnum.EndorsementDoc",
      @delete-file="deleteUploadedEndorsementDocument",
      @file-uploaded="linkFileToOrg")
    template(v-slot:modal-footer)
      .d-flex.justify-content-end
        n-button.font-sm.mr-2(
          variant="light",
          buttonText="Close",
          @click="closeDocUploadModal",)
        n-button.font-sm(
          variant="dark",
          buttonText="Continue",
          type="submit",
          :disabled="isDocUploadContinueDisabled()",
          @click="openUserSelectionModal")

  special-modal(
    id="resync-premium-modal",
    headerIcon="icon",
    :bgColor="'mustard-200'",
    :iconColor="'mustard-600'",
    logoIcon="warning",
    centered)
    template(v-slot:title)
      h3.hb3.d-inline.font-weight-semibold.text-gray-900 Confirm Premium Resync
    template(v-slot:modal-content)
      .text-center
        | Are you sure you want to resync the premium for this batch?
        br
        b-spinner.mt-3(v-if="loading", background-color="rgba(255,255,255,0.5)")
    template(v-slot:buttons-area)
      .d-flex.align-items-center.mt-3.mb-2
        n-button.px-3(variant="light", button-text="Cancel", @click="$bvModal.hide('resync-premium-modal')")
        n-button.px-5.ml-2(variant="dark", button-text="Continue ->", @click="resyncBatchPremium()")

  entity-selection-modal(
    v-if="isSuperAdmin && orgId && batch && $options.userSelectionModalStaticData[currentUserSelection]"
    :entities="getEntitiesData",
    @close="handleUserSelectionBack",
    @submit="handleUserSelectionContinue",
    :entityName="$options.userSelectionModalStaticData[currentUserSelection].entityName",
    :selectionRequired="$options.userSelectionModalStaticData[currentUserSelection].selectionRequired",
    :closeBtnText="$options.userSelectionModalStaticData[currentUserSelection].closeBtnText",
    :continueBtnText="$options.userSelectionModalStaticData[currentUserSelection].continueBtnText",
    hide-backdrop,
    no-close-on-backdrop,
    no-close-on-esc,
    hide-header-close
  )
    template(v-slot:description)
      span.text-gray-700.font-sm-font-weight-medium {{ $options.userSelectionModalStaticData[currentUserSelection].description }}
  .endorsement-doc-upload-backdrop.modal-backdrop(v-if="showDocUploadBackdrop")

</template>

<script>
import { mapGetters } from "vuex";
import gql from "graphql-tag";
import moment from "moment/moment";
import NModal from "../../../../components/NovaModal.vue";
import adminDefs from "../../admin/definitions";
import SpecialModal from "../../../../components/SpecialModal.vue";
import orgDef from "../../admin/definitions/orgs";
import PremiumUpdateModal from "./PremiumUpdateModal.vue";
import EntitySelectionModal from "@/components/EntitySelectionModal.vue";
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";
import NChip from "@/components/NovaChip.vue";
import FileUpload from "@/components/FileUpload.vue";
import { EndorsementDocuments, FileAction, FileGroupId } from "@/common/enums";
import resDefs from "@/portal/pages/admin/definitions";
import BasicModal from "@/components/BasicModal.vue";

const endorsementDocsDisplayText = {
  active_list: "Active List",
  endorsement_copy: "Endorsement Copy",
  cd_statement: "CD Statement",
  annexure: "Annexure",
  tax_invoice: "Tax Invoice",
};

export default {
  name: "SummaryCard",
  components: {
    NButton,
    PremiumUpdateModal,
    EntitySelectionModal,
    NChip,
    NModal,
    FileUpload,
    SpecialModal,
    BasicModal,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    subtext: {
      type: String,
      default: null,
    },
    orgId: {
      type: String,
      default: null,
    },
    batch: {
      type: Object,
      default: null,
    },
    isCurrentBatch: {
      type: Boolean,
    },
    isDynamicPremiumEnabled: {
      type: Boolean,
    },
    isPremiumBreakupEnabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      documentsData: {
        files: [],
      },
      entitiesData: {
        orgAdmins: [],
        CXAccountOwners: [],
      },
      currentUserSelection: "org-admins",
      showDocUploadBackdrop: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    isSuperAdmin() {
      if (this.$route.path.includes("org-admin/")) {
        return false;
      }
      return true;
    },
    getPremiumDetailsRoute() {
      return this.isSuperAdmin ? "admin_premium_details" : "org_admin_premium_details";
    },
    getEntitiesData() {
      if (this.currentUserSelection === "cx-owners") {
        // if users selected already, pass them to modal
        if (!this.entitiesData.CXAccountOwners.length) {
          return this.CXAccountOwners || [];
        }
        return this.entitiesData.CXAccountOwners;
      }

      if (!this.entitiesData.orgAdmins.length) {
        return this.orgAdmins || [];
      }
      return this.entitiesData.orgAdmins;
    },
    endingDate() {
      return this.batch?.endingAt || utils.getLastDateOfMonth(new Date());
    },
    monthOnCalendar() {
      return utils.getMonthShortForm(utils.getMonth(this.endingDate).toLowerCase());
    },
    yearOfBatch() {
      return moment(this.endingDate).year();
    },
    isMarkNoEndorsementFromHREnabled() {
      return !this.batch?.meta?.markedNoEndorsementByHR && this.batch?.status === "EMPTY";
    },
  },
  created() {
    this.$options.userSelectionModalStaticData = {
      "org-admins": {
        entityName: "Org Admins",
        selectionRequired: true,
        closeBtnText: "<- Go Back",
        continueBtnText: "Continue ->",
        description:
          "Document related emails will be sent to the selected org admins. At least one org admin should be selected.",
      },
      "cx-owners": {
        entityName: "CX Owners",
        selectionRequired: false,
        closeBtnText: "<- Go Back",
        continueBtnText: "Submit",
        description: "Document related emails will be sent to the selected org CX owners.",
      },
    };
    this.$options.acceptedEndorsementDocsFormat =
      ".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    this.$options.FileActionEnum = FileAction;
    this.$options.EndorsementDocumentsEnum = EndorsementDocuments;
  },
  mounted() {
    if (this.batch?.meta?.endorsementDocuments) {
      const uploadedDocuments = [];
      Object.keys(this.batch.meta.endorsementDocuments).forEach((policyType) => {
        for (const [docType, file] of Object.entries(this.batch.meta.endorsementDocuments[policyType])) {
          file.policyType = policyType;
          file.docType = docType;
          uploadedDocuments.push(file);
        }
      });
      if (uploadedDocuments.length > 0) {
        this.documentsData.files = uploadedDocuments;
      }
    }
  },
  methods: {
    totalEstimatedPremiumAcrossInsurers() {
      return utils.getTotalEstimatedPremiumAcrossInsurers(this.batch);
    },
    batchPremiumStatus() {
      const status = {};
      if (this.batch && this.batch.meta.premiumData) {
        status.amount = this.totalEstimatedPremiumAcrossInsurers();
        status.isPaid = this.batch.meta.premiumData.isPaid;
      }
      return status;
    },
    getDropdownHeading(docType) {
      return endorsementDocsDisplayText[docType];
    },
    isDocumentPresent(policyType, docType) {
      return !!this.documentsData.files.find((file) => file.docType === docType && file.policyType === policyType);
    },
    closeDocUploadModal() {
      this.toggleDocUploadBackdrop(false);
      this.$bvModal.hide("endorsement-document-upload-modal");
    },
    downloadDocument(policyType, docType) {
      const document = this.documentsData.files.find(
        (file) => file.policyType === policyType && file.docType === docType
      );
      if (!document) {
        this.$store.commit("addAlert", {
          variant: "danger",
          message: "Error downloading file",
        });
      }
      this.$store.commit("addAlert", {
        variant: "success",
        message: "Downloading",
      });
      window.open(document.url);
    },
    openPremiumUpdateModal() {
      this.$bvModal.show("update-premium-modal");
    },
    closePremiumUpdateModal() {
      this.$bvModal.hide("update-premium-modal");
    },
    toggleDocUploadBackdrop(toggle) {
      this.showDocUploadBackdrop = toggle || !this.showDocUploadBackdrop;
    },
    showEndorsementDocUploadModal() {
      this.toggleDocUploadBackdrop(true);
      this.$bvModal.show("endorsement-document-upload-modal");
    },
    isDocUploadContinueDisabled() {
      // at least one file with docType is required
      return !(
        this.documentsData.files.length && !this.documentsData.files.find((file) => !file.docType || !file.policyType)
      );
    },
    openUserSelectionModal() {
      this.$bvModal.hide("endorsement-document-upload-modal");
      this.$bvModal.show("entity-selection-modal");
    },
    async deleteUploadedEndorsementDocument(File) {
      try {
        // if the document isn't linked to the batch as endorsement doc, simply delete from backend
        if (
          !this.batch.meta.endorsementDocuments ||
          (this.batch.meta.endorsementDocuments[File.policyType] &&
            !this.batch.meta.endorsementDocuments[File.policyType][File.docType])
        ) {
          const result = await this.$apollo.mutate({
            mutation: adminDefs.files?.deleteOrgGroupFileEdges,
            variables: {
              fileId: File.id,
              orgId: this.orgId,
              fileGroupIds: [utils.toGlobalId("FileGroup", FileGroupId.ENDORSEMENT)],
            },
          });
          if (!result?.data?.deleteOrgGroupFileEdges?.success) {
            throw new Error("Could not delete file");
          }
        } else {
          // remove the document by deleting and de-linking from the batch
          const result = await this.$apollo.mutate({
            mutation: adminDefs.userChanges.deleteEndorsementDocument,
            variables: {
              batchId: this.batch.id,
              policyType: File.policyType,
              docType: File.docType,
              fileId: File.id,
            },
          });
          if (!result?.data?.deleteEndorsementDocuments?.success) {
            throw new Error("Could not delete file");
          }
        }
        this.$store.commit("addAlert", {
          variant: "success",
          message: "Endorsement document deleted.",
        });
      } catch (err) {
        console.log(err);
        this.$store.commit("addAlert", {
          variant: "danger",
          message: "Expected error occurred while deleting the endorsement document.",
        });
      }
    },
    async resyncBatchPremium() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: adminDefs.userChanges.resyncPremiumForBatch,
          variables: {
            batchId: this.batch.id,
          },
        });
        this.$emit("refetchBatch", this.batch.id);
        this.$store.commit("addAlert", {
          variant: "success",
          message: `Successfully re-synced premium for the batch`,
        });
        this.loading = false;
        this.$bvModal.hide("resync-premium-modal");
      } catch (err) {
        this.loading = false;
        this.$store.commit("addAlert", {
          variant: "danger",
          message: `Error while re-syncing premium for the batch`,
        });
        throw err;
      }
    },
    async linkFileToOrg(file) {
      if (!this.orgId) {
        this.$store.commit("addAlert", {
          variant: "danger",
          message: "Error uploading endorsement files, please try again",
        });
      }
      try {
        await this.$apollo.mutate({
          mutation: adminDefs.files.upsertOrgGroupFileEdges,
          variables: {
            fileId: file.id,
            orgId: this.orgId,
            fileGroupIds: [utils.toGlobalId("FileGroup", FileGroupId.ENDORSEMENT)],
          },
          update: (store, { data }) => {
            const oldUser = store.readFragment({
              id: this.orgId,
              fragment: adminDefs.orgs.filesFragment,
              fragmentName: adminDefs.orgs.filesFragmentName,
            });
            const fileEdge = data.upsertOrgGroupFileEdges.orgGroupFileEdge;
            oldUser.files.push(fileEdge);
            store.writeFragment({
              id: this.orgId,
              fragment: adminDefs.orgs.filesFragment,
              data: oldUser,
              fragmentName: adminDefs.orgs.filesFragmentName,
            });
          },
        });
      } catch (err) {
        console.error(err);
      }
    },
    handleUserSelectionBack() {
      if (this.currentUserSelection === "org-admins") {
        this.$bvModal.hide("entity-selection-modal");
        this.$bvModal.show("endorsement-document-upload-modal");
      }
      this.currentUserSelection = "org-admins";
    },
    handleUserSelectionContinue(updatedEntitiesData) {
      if (this.currentUserSelection === "cx-owners") {
        this.entitiesData.CXAccountOwners = updatedEntitiesData;
        this.upsertEndorsementData();
        this.$bvModal.hide("entity-selection-modal");
        this.toggleDocUploadBackdrop(false);
        return;
      }
      this.entitiesData.orgAdmins = updatedEntitiesData;
      this.currentUserSelection = "cx-owners";
    },
    async upsertEndorsementData() {
      const endorsementDocuments = {};
      this.documentsData.files.forEach((file) => {
        if (!file.policyType || !file.docType) {
          return;
        }
        endorsementDocuments[file.policyType] = { ...endorsementDocuments[file.policyType] };
        endorsementDocuments[file.policyType][file.docType] = file.id;
      });
      // filter and format the orgAdmin data
      const selectedOrgAdmins = this.entitiesData.orgAdmins
        .filter((orgAdmin) => orgAdmin.isSelected)
        .map((orgAdmin) => ({ name: orgAdmin.name, email: orgAdmin.email, user_id: orgAdmin.userId }));

      // filter and format the cxOwner data
      const selectedCXOwners = this.entitiesData.CXAccountOwners.filter((cxOwner) => cxOwner.isSelected).map(
        (cxOwner) => ({ name: cxOwner.name, user_id: cxOwner.user.id, email: cxOwner.user.email })
      );

      try {
        await this.$apollo.mutate({
          mutation: adminDefs.userChanges.upsertEndorsementDocuments,
          variables: {
            batchId: this.batch.id,
            endorsementDocuments,
            orgAdmins: selectedOrgAdmins,
            cxEntities: selectedCXOwners,
          },
        });
        this.$store.commit("addAlert", {
          variant: "success",
          message: "Successfully uploaded.",
        });
        this.$bvModal.hide("endorsement-document-upload-modal");
        this.$emit("refetchBatch", this.batch.id);
      } catch (err) {
        console.error(err);
      }
    },
    getUserPolicyTypes() {
      const policyTypes = {};
      this.org?.benefits?.forEach((benefit) => {
        if (benefit.node.isPolicy) {
          policyTypes[benefit.node.type] = "";
        }
      });

      return Object.keys(policyTypes);
    },
    openBatchDelayModal() {
      console.log("Open Delay modal");
    },
    async markNoEndorsementFromHR() {
      let result;
      try {
        result = await this.$apollo.mutate({
          mutation: resDefs.userChangeBatches.markNoEndorsementFromHR,
          variables: {
            batchId: this.batch?.id,
          },
        });
      } catch (e) {
        console.error("API returned with an error", e);
      }
      if (result?.data?.markNoEndorsementFromHR?.success) {
        this.$store.commit("addAlert", {
          variant: "success",
          message: `"No endorsement" marked for the ${this.monthOnCalendar} ${this.yearOfBatch} batch`,
        });
      } else {
        this.$store.commit("addAlert", {
          variant: "danger",
          message: `Unable to mark "No Endorsement" for the ${this.monthOnCalendar} ${this.yearOfBatch} batch`,
        });
      }
      this.$bvModal.hide("basic-modal");
      this.$router.go();
    },
  },
  apollo: {
    orgAdmins: {
      skip() {
        return !this.orgId || !this.isSuperAdmin;
      },
      query: gql`
        query getActiveOrgAdmins($orgIds: [ID]!) {
          getActiveOrgAdmins(orgIds: $orgIds) {
            edges {
              node {
                userId
                name
                email
                orgId
              }
            }
            totalCount
          }
        }
      `,
      variables() {
        return {
          orgIds: [this.orgId],
        };
      },
      update(data) {
        return data.getActiveOrgAdmins.edges.map(({ node }) => node);
      },
    },
    CXAccountOwners: {
      skip() {
        return !this.orgId || !this.isSuperAdmin;
      },
      query: adminDefs.CXAccountOwners.listQuery,
      variables() {
        return {
          filter: {
            orgId: this.orgId,
          },
        };
      },
      update(data) {
        return data.CXAccountOwners.edges.map(({ node }) => node);
      },
    },
    org: {
      query: orgDef.singleQuery,
      skip() {
        return !this.orgId;
      },
      variables() {
        return {
          id: this.orgId,
        };
      },
      update(data) {
        return data.node;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
.summary-container {
  height: 5.5rem;
  background-color: $white;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  border: 1px solid $gray-300;

  .info-text-container {
    border: 0;
    padding-right: 0;
  }
  .info-title {
    color: $gray-800;
    @extend .font-weight-semibold;
  }

  .info-text {
    color: $gray-700;
    @extend .font-md;
    @extend .font-weight-medium;
  }
}
.vertical-separator {
  border-left: 1px solid $gray-500;
}
</style>

<style lang="scss">
.endorsement-documents-dropdown,
.super-admin-dropdown {
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.super-admin-dropdown {
  .dropdown-item {
    height: 2.625rem;
  }
}
</style>
