<template lang="pug">
.select-card-container
  .small-select-card.select-card-box(
    :class="{active: activeState===cardValue}",
    v-if="cardType === 'small'",
    @click="updateState")
    label.m-2.text-style.text-common-style(:for="label")
      input(
        type="radio",
        :disabled="readOnly || disabled",
        :name="name",
        :id="`${label}-${uid}`",
        @change="updateState",
        @keydown.space="updateState",
        :checked="this.cardValue === this.value")
      span.d-flex.align-items-center
        i.icon-style.mr-1(v-if="icon", :class="[`icon-${icon}`,`icon-color-${icon}`]")
        | {{label | inr}}
  .medium-select-card.select-card-box(
    :class="{active: activeState===cardValue}",
    v-if="cardType === 'medium'",
    @click="updateState")
    label.m-3.text-style.text-common-style(:for="label")
      input(
        type="radio",
        :disabled="readOnly || disabled",
        :name="name",
        :id="`${label}-${uid}`",
        @change="updateState",
        @keydown.space="updateState",
        :checked="this.cardValue === this.value")
      span.color-teal(v-if="activeState===cardValue") Selected
      span.before-check(v-else) Select
    .pb-3.text-style.text-common-style
      i.icon-style(v-if="icon", :class="[`icon-${icon}`,`icon-color-${icon}`]")
      img.mx-3(v-if="svg", :src="require(`@/assets/images/${svg}.svg`)")
      span {{label | inr}}
  .large-select-card.select-card-box.pt-3(
    :class="{active: activeState===cardValue}",
    v-if="cardType === 'large'",
    @click="updateState")
    .row
      .col-12.d-flex
        .text-style.text-common-style.flex-grow-1
          .d-flex.align-items-center.col-7.p-0.m-0(align="center")
            i.icon-style(v-if="icon", :class="[`icon-${icon}`,`icon-color-${icon}`]")
            img.mx-3(v-if="svg", :src="require(`@/assets/images/${svg}.svg`)")
            span {{label | inr}}
          .col-5.justify-content-end(align="center")
            label.text-style.justify-content-start(:for="label")
              input(
                type="radio",
                :disabled="readOnly || disabled",
                :name="name",
                :id="`${label}-${uid}`",
                @change="updateState",
                @keydown.space="updateState")
              span.color-teal(v-if="activeState===cardValue") Selected
              span.before-check(v-else) Select
      .col.m-3
        p.paragraph-style.text-common-style {{description}}
        div(v-if="cost")
          .line-style
          .price-style.text-common-style {{cost | inr}} / Employee
          span.price-ext-style.text-common-style Annually
  .large-select-card.select-card-box(
    :class="{active: activeState===cardValue}",
    v-if="cardType === 'full-width'",
    @click="updateState")
    .d-flex.align-items-center.py-3
      .p-0
        label.m-0.ml-3(:for="label")
          input(type="radio",
            :disabled="readOnly || disabled",
            :name="name",
            :id="`${label}-${uid}`",
            @change="updateState",
            @keydown.space="updateState")
          span.color-teal(v-if="activeState===cardValue")
          span.before-check(v-else)
      .d-flex.flex-column
        .d-flex.align-items-center.p-0.m-0
          i.mr-2(v-if="icon", :class="[`icon-${icon}`,`text-${iconColor}`]")
          img.mx-3(v-if="svg", :src="require(`@/assets/images/${svg}.svg`)")
          span.text-gray-900.font-weight-semibold.font-sm {{label | inr}}
        div
          p.m-0.text-gray-700.font-weight-meidum.font-xs {{description}}

</template>

<script>
export default {
  name: "SelectCard",
  props: {
    /* eslint-disable-next-line vue/require-prop-types, vue/require-default-prop */
    value: {},
    cardType: {
      type: String,
      default: "small",
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "blue-400",
    },
    svg: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    cost: {
      type: String,
      default: "",
    },
    cardValue: {
      type: [String, Boolean],
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const uid = this._uid;
    return {
      uid,
    };
  },
  computed: {
    checked() {
      return this.value === this.cardValue;
    },
    activeState: {
      get() {
        this.$nextTick(() => {
          if (document.getElementById(`${this.label}-${this.uid}`)) {
            document.getElementById(`${this.label}-${this.uid}`).checked = this.checked;
          }
        });
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    updateState() {
      this.activeState = this.cardValue;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.select-card-container {
  background: $white;
  height: 100%;
}
.select-card-box {
  border: 2px solid #e2e8f0;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  cursor: pointer;
}
label > input[type="radio"] {
  display: none;
}

label > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.6rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border: 1px solid #a0aec0;
  cursor: pointer;
}

label > input[type="radio"]:checked + *::before {
  border-width: 0.4rem;
  border-color: $teal-800;
  cursor: pointer;
}

label > input[type="radio"]:checked + * {
  color: $teal-800;
  cursor: pointer;
}

.before-check {
  color: #a0aec0;
}

.text-common-style {
  font-weight: 500;
  font-size: 14px;
  color: $gray-900;
}

.text-style {
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.small-select-card {
  // max-width: 112px;
  // height: auto;
  // flex-basis: 112px;
  // width: 25%;
  // flex-flow: row wrap;

  label {
    padding: 0.2rem 0.2rem;
  }
}

.medium-select-card {
  height: 100%;
  input {
    margin: 0 14px;
    height: 15px;
  }
  .icon-style {
    margin: 0 10px 0 14px;
  }
  .color-teal {
    color: $teal-800;
  }
}

.large-select-card {
  // max-width: 310px;
  height: auto;

  input {
    margin: 0 14px;
    height: 15px;
  }
  .icon-style {
    margin: 0 10px 0 14px;
  }
  .color-teal {
    color: $teal-800;
  }
  .line-style {
    border: 1px solid #e2e8f0;
    margin: 20px 0px;
  }
  .paragraph-style {
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $gray-800;
  }
  .price-style {
    margin-bottom: 7px;
  }
  .price-ext-style {
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $gray-600;
  }
}

.icon-color-heart {
  color: #ff6b6b;
}

.icon-color-teeth {
  color: $teal-700;
}
.icon-color-bandage {
  color: $mustard-500;
}
.icon-color-hospital {
  color: $blue-500;
}

.active {
  background: $teal-100;
  border: 2px solid $teal-800;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
}
</style>
