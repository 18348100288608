import gql from "graphql-tag";
import { orgBenefitFragment, orgFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertOrg(
      $id: ID
      $address: String!
      $name: String!
      $pincode: String!
      $meta: JSONObject!
      $status: String
    ) {
      upsertOrg(input: { id: $id, name: $name, address: $address, pincode: $pincode, meta: $meta, status: $status }) {
        org {
          ...Org
        }
      }
    }
    ${orgFragment}
  `,
  exportDataMutation: gql`
    mutation exportEmployeeData($orgId: ID, $meta: JSONObject) {
      exportEmployeeData(input: { orgId: $orgId, meta: $meta }) {
        message
      }
    }
  `,
  bulkEmailSyncMutation: gql`
    mutation bulkEmailSync($orgIds: [ID], $meta: JSONObject) {
      bulkEmailSync(input: { orgIds: $orgIds, meta: $meta }) {
        status
      }
    }
  `,
  addBenefitConnectionMutation: gql`
    mutation m1($benefitId: ID!, $orgId: ID!, $meta: JSONObject) {
      addOrgToBenefit(input: { benefitId: $benefitId, orgId: $orgId, meta: $meta }) {
        orgBenefitEdge {
          ...OrgBenefitEdge
        }
      }
    }
    ${orgBenefitFragment}
  `,
  deleteBenefitConnectionMutation: gql`
    mutation m2($benefitId: ID!, $orgId: ID!) {
      deleteOrgFromBenefit(input: { benefitId: $benefitId, orgId: $orgId }) {
        deletedCount
      }
    }
  `,
  unlinkBenefitConnectionMutation: gql`
    mutation unlinkBenefitConnection($benefitId: ID!, $orgId: ID!) {
      unlinkBenefitConnection(input: { benefitId: $benefitId, orgId: $orgId }) {
        success
        userBenefitRemoveCount
        dependentBenefitRemoveCount
      }
    }
  `,
  resyncOrgOnboardingStageTasks: gql`
    mutation resyncOrgOnboardingStageTasks($orgId: ID!) {
      resyncOrgOnboardingStageTasks(input: { orgId: $orgId }) {
        success
      }
    }
  `,
};
