<template lang="pug">
.add-dependents-modal
  b-card.px-3.dep-selection-card(:class="`shadow-${isInsideModal ? 'none' : 'lg'} ${!isProfileDependentsScreen && 'dep-selection-card_small-height'}}`", body-class="px-2")
    .my-4.d-lg-none.text-center.font-weight-medium.text-gray-800.font-sm(v-for="paragraph in coverageInfo") {{ paragraph }}
    .modal-heading.mt-1.mb-4.text-center(v-if="isProfileDependentsScreen")
      span.font-weight-semibold {{ isUpdateModal ? "Updating Dependents" : "Adding Dependents" }}
      hr
      span.depText.font-lg.pt-4.pb-4.font-weight-medium {{ getHeadingText }}
    .avatar-container(v-if="!isInsideModal")
      img.dep-profile-image(
        v-if="model.relation!=='None selected' && model.gender",
        :src="require(`@/assets/images/avatar-${model.relation}-${model.gender}.svg`)")
      img.dep-profile-image(
        v-else,
        :src="require('@/assets/images/user.svg')")
      hr.ml-1.mt-2
    b-form(@submit.prevent="")
      vue-form-generator(
        :schema="getSchema",
        :options="formOptions",
        ref="formData",
        :model="model")

      b-card.shadow-none(
        v-if="pOrIlFlag && (model.relation==='parent' || model.relation==='parent-in-law')",
        :body-class="stylingClasses")
        p.text-gray-800.font-sm {{ getText() }}
        b-form-group.mb-0(v-slot="{ ariaDescribedby }")
          n-inline-radio(
            v-model="coverPOrPil",
            name="cover-p-or-pil",
            :options="pOrPilOptions")
          
      b-card.shadow-none(v-if="model.relation==='child' && !isProfileDependentsScreen", :body-class="stylingClasses")
        p.text-gray-800.font-sm.mb-0 {{ getText() }}
        b-form-group.mt-3.mb-0(v-if="showOptionsForChild", v-slot="{ ariaDescribedby }")
          n-inline-radio(
            v-model="coverChild",
            name="include-child",
            :options="childInclusionOptions")

      n-button.w-100.my-3(
        v-if="!showDeleteorUpdateButton && !isInsideModal",
        variant="dark",
        buttonText="Save and Add",
        rightImageIcon="plus-circle",
        type="submit",
        @click="submitForm(saveDetails)",
        :disabled="isDisabled")

      .d-flex.justify-content-between.pb-4(v-if="isInsideModal && !isProfileDependentsScreen")
        n-button.w-50.mt-3(
          v-if="showDeleteorUpdateButton",
          variant="outline-primary",
          buttonText="Delete",
          @click.prevent="showDeletePopup()")
        n-button.w-50.mt-3.ml-2(
          v-if="showDeleteorUpdateButton",
          variant="dark",
          buttonText="Save",
          type="submit",
          @click="submitForm(updateDependent)",
          :disabled="isDisabled")

        n-button.w-50.mt-3.ml-2( 
          v-if="!showDeleteorUpdateButton",
          variant="dark",
          buttonText="Save",
          type="submit",
          @click="submitForm(saveDetails)",
          :disabled="isDisabled")
      .reminder-modal-footer.pb-2(v-else-if="isProfileDependentsScreen")
        hr
        .buttons.d-flex.align-right
          n-button.mx-1.px-2( 
            variant="light",
            buttonText="Cancel",
            @click="closeModal",)
          n-button.mx-1.px-2( 
            variant="dark",
            :buttonText="isUpdateModal ? 'Save Changes' : 'Request Addition'",
            type="submit",
            @click="submitForm(saveDetails)",
            :disabled="isDisabled")

      .d-flex(v-else)
        n-button.w-50.mr-1.mt-3.mb-4(
          v-if="showDeleteorUpdateButton",
          variant="outline-primary",
          buttonText="Delete Dependent",
          @click="showDeletePopup()")
        n-button.w-50.ml-1.mt-3.mb-4(
          v-if="showDeleteorUpdateButton",
          variant="dark",
          buttonText="Update",
          type="submit",
          @click="submitForm(updateDependent)",
          :disabled="isDisabled")

    special-modal(
      id="dependent-deletion-modal",
      headerIcon="icon",
      bgColor="red-300",
      iconColor="red-600",
      logoIcon="warning", 
      centered)
      template(v-slot:title)
        h3.hb3.d-inline.text-gray-900 Delete Dependent
      template(v-slot:modal-content)
        .text-center
          | Delete {{selectedDependent.name}}?
        .text-center
          | Once deleted, they will not be covered in your insurance policies.
      template(v-slot:buttons-area)
        .d-flex.align-items-center.w-100.mt-3.mb-2.px-5
          n-button.w-50.mr-2(
            variant="light",
            button-text="Skip", 
            @click="$bvModal.hide('dependent-deletion-modal')")
          n-button.w-50(
            variant="danger", 
            button-text="Delete", 
            @click="deleteDependent(user, selectedDependent)")
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { dependentDropdownOptions } from "../../constants";
import NAvatar from "@/components/Avatar.vue";
import utils, {
  validateDependentAge,
  isVfgErrorPresent,
  validateDependentGender,
  validateName,
  validateDependentCount,
  validateDateOfMarriage,
} from "@/utils";
import NButton from "@/components/NovaButton.vue";
import NInlineRadio from "@/components/NovaInlineRadio.vue";
import resDefs from "@/portal/pages/user/definitions.js";
import SpecialModal from "@/components/SpecialModal.vue";
import { AcceptedRelations } from "@/common/enums";

export default {
  name: "AddDependentsCard",
  components: {
    NButton,
    NInlineRadio,
    NAvatar,
    SpecialModal,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    dependentsModel: {
      type: Array,
      default: () => [],
    },
    selectedDependent: {
      type: Object,
      default: () => {},
    },
    isInsideModal: {
      type: Boolean,
      default: false,
    },
    coverageInfo: {
      type: Array,
      default: () => [],
    },
    policies: {
      type: Array,
      default: () => [],
    },
    isMidTerm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dependentDropdownOptions,
      coveredDependentsObject: utils.extractCoveredDependents(this.policies),
      showDeleteorUpdateButton: false,
      model: {},
      dependent: {},
      coverPOrPil: "",
      coverChild: "",
      addedParentData: [],
      addedPilData: [],
      coveredChildren: [],
      schema: {
        fields: [
          {
            model: "name",
            type: "inline-input",
            inputType: "text",
            label: `Dependent Name<span style="color: red">*</span>`,
            placeholder: "Enter dependent’s full name",
            required: true,
            noPadding: true,
            styleClasses: "mb-4",
            validator: [validateName],
          },
          {
            model: "dob",
            type: "datepicker",
            label: `Date of Birth (as per Govt ID)<span style="color: red">*</span>`,
            min: "1900-01-01",
            max: moment().format("YYYY-MM-DD"),
            styleClasses: "d-inline-block w-75 pr-2 mb-4",
            labelClasses: "mb-2 pb-1",
            validator: [validateDependentAge],
          },
          {
            model: "age",
            type: "inline-input",
            inputType: "text",
            label: "Age",
            disabled: true,
            styleClasses: "d-inline-block w-25 pl-2 mb-2 align-top",
            labelClasses: "mb-2",
          },
          {
            model: "gender",
            type: "select-cards",
            label: `Gender<span style="color: red">*</span>`,
            validator: [validateDependentGender],
            cardsData: [
              {
                name: "gender",
                icon: "female",
                label: "Female",
                card_value: "female",
              },
              {
                name: "gender",
                icon: "male",
                label: "Male",
                card_value: "male",
              },
            ],
            styleClasses: "mt-0 mb-2",
          },
          {
            model: "relation",
            type: "inline-select",
            label: `Relationship<span style="color: red">*</span>`,
            placeholder: "How is the dependent related to you?",
            validator: [validateDependentCount, utils.validateInlineSelect],
            reduce: (v) => v.value,
            options: dependentDropdownOptions,
            disabled: (model) => Boolean(model.id),
            hint: (model) => (model.id ? "Relationship is locked, please delete and create the dependent again." : ""),
          },
        ],
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true,
      },
      bgClass: "mustard-100",
      pOrPilOptions: [
        { label: "Parents", value: "parent" },
        { label: "Parents-in-law", value: "parent-in-law" },
      ],
      childInclusionOptions: [
        { label: "Include Dependent", value: "includeChild" },
        { label: "Don’t include", value: "dontIncludeChild" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    age() {
      return moment().diff(this.model.dob, "years", false);
    },
    isDisabled() {
      const index = this.dependentsModel.findIndex((dep) => this.isSamePerson(dep, this.model));
      // FIXME: clean up, remove nesting
      return !(
        this.model.name &&
        this.model.dob &&
        this.model.gender &&
        this.model.relation &&
        this.model.relation !== "None selected" &&
        (this.model.id ? this.dependentsModel[index]?.relation === this.model.relation : true) &&
        (utils.showDOMField(
          { ...this.user, benefits: this.$route.path.includes("user/dependents") ? this.policies : this.user.benefits },
          this.model,
          this.user?.org
        )
          ? this.model.meta?.dom
          : true)
      );
    },
    pOrIlFlag() {
      if (this.policies) {
        return (
          this.coveredDependentsObject?.pOrILFlag &&
          !(this.getFeatureFlags.SHOW_ELIGIBLE_DEP_ONLY && this.$route.path.includes("user/dependents"))
        );
      }
      return false;
    },
    showOptionsForChild() {
      if (this.coveredChildren.length < this.coveredDependentsObject?.childrenCount || this.model.meta?.isPreferred)
        return true;
      return false;
    },
    stylingClasses() {
      return [`bg-${this.bgClass}`, "border", "border-1", "border-gray-200", "rounded-lg"];
    },
    getHeadingText() {
      if (this.isUpdateModal) {
        return "Editing any detail requires approval from the insurer. Edit the details and save changes to create a request";
      }
      return "Adding a dependant requires approval from the insurer. Fill up this form to create a dependant addition request 👋";
    },
    getSchema() {
      if (this.getFeatureFlags.SHOW_ELIGIBLE_DEP_ONLY) this.updateRelationOptions();
      if (
        utils.showDOMField(
          { ...this.user, benefits: this.$route.path.includes("user/dependents") ? this.policies : this.user.benefits },
          this.model,
          this.user?.org
        )
      ) {
        return {
          fields: [
            ...this.schema.fields,
            {
              model: "meta.dom",
              type: "datepicker",
              label: `Date of Marriage (as per Govt ID)<span style="color: red">*</span>`,
              min: "1900-01-01",
              max: moment().format("YYYY-MM-DD"),
              required: true,
              validator: [validateDateOfMarriage],
            },
          ],
        };
      }

      return this.schema;
    },
    isUpdateModal() {
      if (this.selectedDependent.id) {
        return true;
      }
      return false;
    },
    isProfileDependentsScreen() {
      return this.$route.path.includes("user/dependents");
    },
  },
  watch: {
    selectedDependent() {
      if (!this.isInsideModal) {
        this.showDeleteorUpdateButton = !this.selectedDependent.isPlaceholder;
        this.model = utils.deepClone(this.selectedDependent);
        if (this.model.relation === "child") this.setNextChildCoverage();
        this.setModelAge();
      }
      this.setUserDataToModel();
      this.setParentDataToModel();
      this.setMidTermAdditionToModel();
      this.setDepCountToModel();
    },
    age() {
      this.setModelAge();
    },
    dependentsModel() {
      this.addedParentData = [];
      this.addedPilData = [];
      this.dependentsModel.forEach((dep) => {
        if (dep.relation === AcceptedRelations.PARENT) this.addedParentData.push({ id: dep.id, gender: dep.gender });
        if (dep.relation === AcceptedRelations.PARENT_IN_LAW)
          this.addedPilData.push({ id: dep.id, gender: dep.gender });
      });
    },
  },
  created() {
    this.addedParentData = [];
    this.addedPilData = [];
    this.setUserDataToModel();
    this.setParentDataToModel();
    this.dependentsModel.forEach((dep) => {
      if (dep.relation === AcceptedRelations.PARENT) this.addedParentData.push({ id: dep.id, gender: dep.gender });
      if (dep.relation === AcceptedRelations.PARENT_IN_LAW) this.addedPilData.push({ id: dep.id, gender: dep.gender });
      if (dep.meta?.isPreferred) {
        switch (dep.relation) {
          case AcceptedRelations.PARENT:
            this.coverPOrPil = "parent";
            break;
          case AcceptedRelations.PARENT_IN_LAW:
            this.coverPOrPil = "parent-in-law";
            break;
          case AcceptedRelations.CHILD:
            this.coveredChildren.push(dep);
            break;
        }
      }
    });
  },
  mounted() {
    if (this.isInsideModal) {
      this.model = this.selectedDependent;
      this.showDeleteorUpdateButton = Boolean(this.model?.name);
    }
    this.setNextChildCoverage();
    this.setModelAge();
  },
  methods: {
    async submitForm(action) {
      action();
    },
    updateRelationOptions() {
      const coveredDependentsArray = this.coveredDependentsObject.coveredDependents;
      if (this.coveredDependentsObject.pOrILFlag) {
        coveredDependentsArray.push(AcceptedRelations.PARENT, AcceptedRelations.PARENT_IN_LAW);
        if (
          this.$route.path.includes("user/dependents") &&
          this.dependentsModel.some((dep) => dep.relation === AcceptedRelations.PARENT)
        ) {
          const index = coveredDependentsArray.indexOf(AcceptedRelations.PARENT_IN_LAW);
          coveredDependentsArray.splice(index, 1);
        }
        if (
          this.$route.path.includes("user/dependents") &&
          this.dependentsModel.some((dep) => dep.relation === AcceptedRelations.PARENT_IN_LAW)
        ) {
          const index = coveredDependentsArray.indexOf(AcceptedRelations.PARENT);
          coveredDependentsArray.splice(index, 1);
        }
      }
      this.schema.fields.find((field) => field.model === "relation").options = this.dependentDropdownOptions.filter(
        (option) => coveredDependentsArray.includes(option.value)
      );
    },
    setModelAge() {
      if (!this.model?.dob || isNaN(this.age) || this.age === this.model?.age) return;
      this.$set(this.model, "age", this.age);
    },
    setUserDataToModel() {
      this.$set(this.model, "userGender", this.user.gender);
      this.$set(this.model, "userDob", this.user.dob);
    },
    setParentDataToModel() {
      this.$set(this.model, "addedParentData", this.addedParentData);
      this.$set(this.model, "addedPilData", this.addedPilData);
    },
    setDepCountToModel() {
      this.$set(this.model, "dependentsByRelationCount", this.getDependentsByRelationCount());
    },
    setMidTermAdditionToModel() {
      this.$set(this.model, "isMidTerm", this.isMidTerm);
    },
    getDependentsByRelationCount() {
      const dependentsByRelationCount = {};
      this.dependentsModel.forEach((dep) => {
        dependentsByRelationCount[dep.relation] = 1 + (dependentsByRelationCount[dep.relation] || 0);
      });
      return dependentsByRelationCount;
    },
    async saveDetails() {
      try {
        await this.$refs.formData.validate();
        if (isVfgErrorPresent(this.$refs.formData.errors)) {
          this.$store.commit("addAlert", {
            variant: "danger",
            message: "Please enter valid details before continuing to the next step",
          });
          return;
        }
        delete this.model.isPlaceholder;
        this.dependent = this.model;
        const updatedDependents = this.$route.path.includes("/user/dependents")
          ? [this.dependent]
          : this.updateCoverage([...this.dependentsModel, this.dependent]);
        this.$emit("modelUpdated", updatedDependents, this.model.relation);
        if (this.isProfileDependentsScreen) this.closeModal();
      } catch (err) {
        console.log(err);
      }
    },
    async updateDependent() {
      try {
        await this.$refs.formData.validate();
        if (isVfgErrorPresent(this.$refs.formData.errors)) {
          this.$store.commit("addAlert", {
            variant: "danger",
            message: "Please enter valid details before continuing to the next step",
          });
          return;
        }
        delete this.model.isPlaceholder;
        const index = this.dependentsModel.findIndex((dep) => this.isSamePerson(dep, this.model));
        let updatedDependents;
        if (this.$route.path.includes("/user/dependents")) {
          updatedDependents = [this.model];
        } else {
          updatedDependents = utils.deepClone(this.dependentsModel);
          updatedDependents[index] = this.model;
          this.updateCoverage(updatedDependents);
        }
        this.$emit("modelUpdated", updatedDependents, this.model.relation);
      } catch (err) {
        console.log(err);
      }
    },
    async deleteDependent(user, dependent) {
      try {
        if (dependent.relation === AcceptedRelations.CHILD) {
          const index = this.coveredChildren.findIndex((child) => this.isSamePerson(child, dependent));
          if (index >= 0) this.coveredChildren.splice(index, 1);
        }
        const index = this.dependentsModel.findIndex((dep) => this.isSamePerson(dep, dependent));
        if (index >= 0) this.dependentsModel.splice(index, 1);
        this.$bvModal.hide("dependent-deletion-modal");

        await this.$apollo.mutate({
          mutation: resDefs.users.deleteDependentMutation,
          variables: {
            orgId: user.org.id,
            userId: user.id,
            id: dependent.id,
          },
        });
        if (this.isInsideModal) this.$bvModal.hide("add-dependents-modal-mobile");
        this.resetForm();
        this.setNextChildCoverage();
      } catch (err) {
        console.log(err);
      }
    },
    showDeletePopup() {
      this.$bvModal.show("dependent-deletion-modal");
    },
    resetForm() {
      this.showDeleteorUpdateButton = false;
      this.model = {};
    },
    setNextChildCoverage() {
      if (this.model.meta?.hasOwnProperty("isPreferred"))
        this.coverChild = this.model.meta.isPreferred ? "includeChild" : "dontIncludeChild";
      else if (this.coveredChildren.length < this.coveredDependentsObject?.childrenCount)
        this.coverChild = "includeChild";
      else this.coverChild = "dontIncludeChild";
    },
    isSamePerson(p1, p2) {
      if (p1.id && p2.id) return p1.id === p2.id;
      return p1.name === p2.name && p1.dob === p2.dob && p1.gender === p2.gender && p1.relation === p2.relation;
    },
    updateCoverage(dependentsModel) {
      this.$emit("pPilCoverage", this.coverPOrPil);
      dependentsModel.forEach((dep, index = 0) => {
        if (dep.relation === AcceptedRelations.PARENT || dep.relation === AcceptedRelations.PARENT_IN_LAW) {
          dep = {
            ...dep,
            meta: {
              ...dep.meta,
              isPreferred:
                this.coverPOrPil === dep.relation ||
                this.coveredDependentsObject.coveredDependents.includes(dep.relation),
            },
          };
        }
        if (dep.relation === AcceptedRelations.CHILD && !dep?.meta?.hasOwnProperty("isPreferred")) {
          dep = {
            ...dep,
            meta: {
              ...dep.meta,
              isPreferred: false,
            },
          };
        }
        dependentsModel[index] = dep;
        index++;
      });
      if (this.model.relation === "child") {
        const index = this.coveredChildren.findIndex((child) => this.isSamePerson(child, this.model));
        if (this.coverChild === "includeChild") {
          this.model = { ...this.model, meta: { ...this.model.meta, isPreferred: true } };
          if (index >= 0) this.coveredChildren[index] = this.model;
          else this.coveredChildren.push(this.model);
        } else if (this.coverChild === "dontIncludeChild") {
          this.model = { ...this.model, meta: { ...this.model.meta, isPreferred: false } };
          if (index >= 0) this.coveredChildren.splice(index, 1);
        }
        const idx = dependentsModel.findIndex((dep) => dep.id === this.model.id);
        dependentsModel[idx] = this.model;
      }
      if (this.model.relation === "spouse") {
        this.model = { ...this.model, meta: { ...this.model.meta, isPreferred: true } };
        const idx = dependentsModel.findIndex((dep) => dep.id === this.model.id);
        dependentsModel[idx] = this.model;
      }
      return dependentsModel;
    },
    getText() {
      let text = "";
      if (this.model.relation === "parent" || this.model.relation === "parent-in-law") {
        if (this.coverPOrPil === "parent" && this.model.relation === "parent-in-law") {
          this.bgClass = "red-200";
          text =
            "You have chosen to cover Parents in the insurance policy. Click on 'Cover Parents-in-law' to change your selection.";
        } else if (this.coverPOrPil === "parent-in-law" && this.model.relation === "parent") {
          this.bgClass = "red-200";
          text =
            "You have chosen to cover Parents-in-law in the insurance policy. Click on 'Cover Parents' to change your selection.";
        } else {
          this.bgClass = "mustard-100";
          text = "Your Health Insurance can cover either your Parents, or Parents-in-law. Please make a selection.";
        }
      }
      if (this.model.relation === "child") {
        if (this.model.meta?.isPreferred || this.coveredChildren.length < this.coveredDependentsObject?.childrenCount) {
          this.bgClass = "mustard-100";
          text =
            "Your Health Insurance can cover " +
            this.coveredDependentsObject?.childrenCount +
            " children. Please decide if you would like to include this dependent.";
        } else if (this.coveredChildren.length >= this.coveredDependentsObject?.childrenCount) {
          this.bgClass = "red-200";
          text =
            "You have already selected " +
            this.coveredDependentsObject?.childrenCount +
            " children to be covered by your policy. Please exclude one of them, to include this one.";
        }
      }
      return text;
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.add-dependents-modal {
  .avatar-container {
    hr {
      width: 2rem;
      height: 0.2rem;
      background: $blue-300;
      border-radius: 1.5rem;
    }
  }
  .modal-heading,
  .reminder-modal-footer {
    hr {
      border-top-color: $gray-400;
      width: 150%;
      margin-left: -3.5rem;
    }
  }
  .dep-profile-image {
    height: 2.5rem;
    width: 2.5rem;
    border: 0.2rem solid $blue-300;
    border-radius: 50%;
  }
  .dep-selection-card {
    overflow: auto;
    overflow-x: hidden;
  }
  .dep-selection-card_small-height {
    height: 65vh;
  }
  .bg-yellow > .card-body {
    background: $mustard-100;
    border: 0.1rem solid $gray-200;
    border-radius: 0.5rem;
  }
  .bg-red {
    background: $red-200;
  }
  .radio {
    min-height: 0.5rem !important;
    vertical-align: middle;
  }
  .depText {
    color: $gray-900;
  }
  .buttons {
    place-content: flex-end;
  }
}
</style>
