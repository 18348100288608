export const IntegrationType = {
  GSUITE: "GSUITE",
  DARWINBOX: "DARWINBOX",
  KEKA: "KEKA",
  ZOHOPEOPLE: "ZOHOPEOPLE",
  GREYTHR: "GREYTHR",
  SLACK: "SLACK",
  BAMBOOHR: "BAMBOOHR",
  UKNOWVA: "UKNOWVA",
  RAZORPAYX: "RAZORPAYX",
  MWELL_SLACK: "MWELL_SLACK",
};
