import { render, staticRenderFns } from "./EndorsementTable.vue?vue&type=template&id=21f9d752&scoped=true&lang=pug&"
import script from "./EndorsementTable.vue?vue&type=script&lang=js&"
export * from "./EndorsementTable.vue?vue&type=script&lang=js&"
import style0 from "./EndorsementTable.vue?vue&type=style&index=0&id=21f9d752&prod&lang=scss&scoped=true&"
import style1 from "./EndorsementTable.vue?vue&type=style&index=1&id=21f9d752&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f9d752",
  null
  
)

export default component.exports