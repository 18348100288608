import Vue from "vue";
import VueRouter from "vue-router";

import { store } from "../store";
import routes from "./routes";

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes, // short for routes: routes
  linkActiveClass: "active",
  scrollBehavior(to, from, savedPosition) {
    return { y: store.state.scrollPositions[to.name] || 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (from) {
    store.commit({
      type: "changeScrollPosition",
      page: from.name,
      scrollPosition: window.scrollY,
    });
  }

  await store.dispatch("loadLocalStore");
  if (to.fullPath.startsWith("/#/")) next(to.fullPath.slice(2));
  next();
});

export default router;
