<template lang="pug">
.row.policy-card-wrapper
  template(v-if="allPolicies.length > 0")
    .col-lg-6.my-3(
      v-for="(policyEdge,index) in allPolicies",
      :key="policyEdge.node.id"
      v-if="!isPolicyExpired(policyEdge.node)")
      policy-card(
        :index="index",
        :policy="policyEdge.node",
        :user="user",
        :edgeMeta="policyEdge.meta",
        :to="getPolicyRouteData(policyEdge)",
        :previewMode="previewMode")
  template(v-if="showTopupCard")
    .col-lg-6.my-3
      policy-card(
        :policy="addOnTopupCard",
        :variant="'addOnCards'",
        :to="{name: 'topups', params: {policy: gmcPolicy.node}}",
        :user="user")
  template(v-if="showFlexiWalletCard")
    .col-lg-6.my-3
      policy-card(
        :policy="addOnFlexiWalletCard",
        :variant="'addOnCards'",
        :buttonUrl="flexiWalletUrl")
</template>

<script>
import { mapGetters } from "vuex";
import PolicyCard from "./PolicyCard.vue";
import utils from "@/utils";

export default {
  name: "PolicyCardWrapper",
  components: {
    PolicyCard,
  },
  props: {
    allPolicies: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      default: null,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addOnTopupCard: {
        id: "topup-card",
        type: "addOnCards",
      },
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    gmcPolicy() {
      return this.allPolicies.find((p) => p.node.type === "gmc");
    },
    showTopupCard() {
      return this.gmcPolicy && this.getFeatureFlags.SUPER_TOPUPS && !this.previewMode;
    },
    showFlexiWalletCard() {
      return this.getFeatureFlags.FLEXI_WALLET && !this.previewMode;
    },
    flexiWalletCoins() {
      return 5000;
    },
    flexiWalletUrl() {
      return `${process.env.VUE_APP_FLEXI_WALLET_RETOOL_APP}?coins=${this.flexiWalletCoins}&userid=${this.user?.email}`;
    },
    addOnFlexiWalletCard() {
      return {
        id: "flexi-wallet-card",
        type: "addOnCards",
        infoCard: {
          title: "Current Balance",
          icon: "wallet",
          value: this.flexiWalletCoins,
        },
      };
    },
  },
  methods: {
    isPolicyExpired(policy) {
      const expiryDate = policy.meta?.endDate ? policy.meta.endDate : "2050-01-01";
      return utils.isExpired(expiryDate);
    },
    getPolicyRouteData(policyEdge) {
      return { name: "policy", params: { policyId: policyEdge.node?.id, user: this.user } };
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-card-wrapper {
  & > div {
    max-width: 100%;
  }
}
</style>
