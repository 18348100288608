import gql from "graphql-tag";
import { ApolloClient } from "apollo-client";
import { ApolloLink, Observable } from "apollo-link";
import { refreshToken } from "./queriesAndMutations/auth";
import { cache } from "./apolloCache";
import { versioningMiddleware } from "./middlewares/versioning";
import { errorMiddleware } from "./middlewares/apolloError";
import { userFragment } from "@/portal/pages/admin/fragments";
import { store } from "@/portal/store";
import { httpLink } from "@/common/middlewares/httpLink";
import router from "@/portal/router";
import { apolloClient } from "@/portal/apollo";
import mobileApp from "@/portal/mobileApp";
import { sleep, blitzInitializeUser } from "@/utils";

/*
  - calls login mutation
  - commits the creds to the
  vuex store and local storage

  - it optionally does redirect as well
*/
export async function loginUser(mutation, redirect = false, router) {
  const authenticateUser = await mutation();
  if (!authenticateUser) {
    console.warn("Couldn't login user!");
    redirectToUnauthorized(router);
    return;
  }
  const { user, token, refreshToken } = authenticateUser;
  if (user?.id) {
    // initialize blitzllama user attributes after login
    blitzInitializeUser(user);
  }
  store.commit("loginUser", { user, token, refreshToken });

  if (redirect) {
    redirectAfterAuthn(user, router, store.getters.isProspect);
  }
}

export async function loginImpersonatedUser(authenticateUser) {
  const { token, refreshToken } = authenticateUser;

  localStorage.setItem("imposterToken", localStorage.getItem("token"));
  localStorage.setItem("imposterRefreshToken", localStorage.getItem("refreshToken"));
  localStorage.setItem("imposterUser", localStorage.getItem("user"));
  localStorage.setItem("imposterLastVisitedUrl", window.location.href);

  // need to do it here to get impersonateOtherUser details
  localStorage.setItem("token", token);

  await apolloClient.cache.data.clear();

  const newUser = await apolloClient.query({
    query: gql`
      query ImpersonatedUser {
        me {
          ...User
        }
      }
      ${userFragment}
    `,
  });

  store.commit("loginUser", { user: newUser.data.me, token, refreshToken });
}

export async function logoutImpersonatedUser() {
  const authenticateUser = {
    token: localStorage.getItem("imposterToken"),
    refreshToken: localStorage.getItem("imposterRefreshToken"),
    user: JSON.parse(localStorage.getItem("imposterUser")),
  };

  await loginUser(async () => authenticateUser);

  const redirectUrl = localStorage.getItem("imposterLastVisitedUrl");
  localStorage.removeItem("imposterToken");
  localStorage.removeItem("imposterUser");
  localStorage.removeItem("imposterLastVisitedUrl");

  window.location.href = redirectUrl;
}

export function redirectToUnauthorized(router) {
  router.push({ name: "error", params: { type: "unauthorized" } });
}

/*
  Logout user removes the creds from
  vuex store and local storage

  it shows the toast for logout
  redirects to unauthorized page
*/
export async function logoutActiveUser(forced = true, isMobileApp = false) {
  return new Promise(async (resolve, reject) => {
    try {
      // mobile app google signout
      if (isMobileApp) {
        await mobileApp.request("GOOGLE_SIGNOUT");
      }

      // update store and local storage
      try {
        store.commit("logoutUser");
      } catch (e) {
        // do nothing
      }

      // redirection
      if (forced) {
        // TODO - we need to have a state for force logout
        router.push({ path: "/error/unauthorized" });
      } else {
        router.push({ path: "/login/logout" }).catch((err) => {
          // Ignore navigation duplicated errors, as this function might be called form the logout screen itself
          if (err.name !== "NavigationDuplicated") throw err;
        });
      }

      // handle after logout
      resolve({
        showMessage: () => {
          store.commit("addAlert", {
            variant: "success",
            message: "You were successfully logged out",
          });
        },
      });
    } catch (err) {
      reject(err);
    }
  });
}

export function redirectAfterAuthn(user, router, isProspect) {
  if (!router || !user) {
    console.warn("Unable to find user/router");
    return;
  }

  window.posthog.capture("employee_login", {
    employee_email: user?.email,
    show_onboarding_screen: user?.meta?.showOnboardingScreens,
    org_name: user?.org.name,
  });

  if (isProspect) {
    router.push("/prospect/bulk-upload");
  } else {
    router.push("/dashboard");
  }
}

/*
  Checks if token is already expired,
  that is the diff b/w current and expiry
  time is smaller than 20s
*/
export function isTokenExpired(expiryTimestamp) {
  const currentTimestamp = new Date();
  // TOD DO - we should watch the response the request
  // to refresh the token
  if ((expiryTimestamp.getTime() - currentTimestamp.getTime()) / 1000 < 20) {
    return true;
  }

  return false;
}

const tokenBeingRefreshed = {
  isTrue: false,
};
/*
 TO DO: This middleware is not being used any more
 as TokenRefreshLink handles the queueing
*/
export const handleTokenRefreshApiQueue = new ApolloLink((operation, forward) => {
  if (!tokenBeingRefreshed.isTrue) {
    // this holds on all the incoming request while token is being refreshed
    // and fires it once token is there
    return promiseToObservable(sleep(0)).flatMap(() => forward(operation));
  } else {
    return promiseToObservable(watcher(tokenBeingRefreshed)).flatMap(() => {
      const token = localStorage.getItem("token");
      if (token) {
        operation.setContext({
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      }

      forward(operation);
    });
  }
});

export const renewTokens = async () => {
  tokenBeingRefreshed.isTrue = true;

  if (!store.state.refreshToken) {
    return;
  }

  try {
    const freshTokens = await refreshToken(store.state.refreshToken, apolloClientRefresh);
    const { user, token } = freshTokens?.data?.authenticateWithRefreshToken;

    store.commit("refreshAccessToken", { user, token });
    tokenBeingRefreshed.isTrue = false;
    return { user, token };
  } catch (e) {
    tokenBeingRefreshed.isTrue = false;
    if (e?.networkError?.result?.errors[0]?.errorMessage !== "VERSION_MISMATCH") {
      await logoutActiveUser(false);
    }
  }
};

export const promiseToObservable = (promise) =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
    return subscriber;
  });

export async function watcher(cond) {
  return new Promise(async (resolve, reject) => {
    while (cond.isTrue) {
      await sleep(500);
    }

    resolve(true);
  });
}

/*
  This client is used to refresh token
*/
const apolloClientRefresh = new ApolloClient({
  link: ApolloLink.from([errorMiddleware, versioningMiddleware, httpLink]),
  cache,
  connectToDevTools: true,
});
