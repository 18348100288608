<template lang="pug">
  b-modal(:id="id", body-class="overflow-hidden", centered, hide-header, hide-footer, scrollable)
    .modal-bg.w-100.h-100.confirmation-modal(v-if="planIndex !== -1")
      .hb5.confirm-modal-header.px-4.text-center.pt-4
        span You've picked the {{ data.pricing_table_state.policies[planIndex].cardTitle }} as your base plan
      .modal-inner-card.w-75.shadow.mx-auto.my-4.text-white.rounded
        .h-50.rounded.rounded-bottom-none.p-4(:class="`bg-${variant}`")
          i.mr-2.align-middle(:class="`icon-${data.pricing_table_state.policies[planIndex].imageIcon}`")
          span.font-weight-semibold {{ data.pricing_table_state.policies[planIndex].cardTitle }}
          .d-flex.pt-3
            div Cost-efficient plan to provide basic coverage for your team
            .font-weight-semibold(v-if="parent==='policy-compare'") {{ getItemPrice(data, planIndex) }}
              span.font-weight-normal  Annually
            .font-weight-semibold(v-else) {{ getTermPrice(data, planIndex) }}
              span.font-weight-normal  Annually
        .modal-inner-card-icon.position-relative.text-dark.d-flex.flex-row.justify-content-center.align-items-center
          span.font-weight-semibold For
          i.icon-arrow.text-dark.rotate-down
        .font-sm.px-4.pt-2
          .row.text-gray-600.font-weight-semibold
            .col-6
              span EMPLOYEES
            .col-6.p-0
              span COVERAGE TYPE
          .row
            .col-6
              .text-left.text-gray-900
                h1.pt-1 {{ data.meta.employee_count }}
            .col-6
              .row.my-2
                img.coverage-type-image.mt-1.mr-2(
                  :src="require(`@/assets/images/coverage${this.data.meta.coverage_type}.svg`)")
                .font-sm.font-weight-medium.break-spaces.text-dark(
                  :class="[this.data.meta.coverage_type==='E'?'mt-2':'']") {{ getCoverageType() }}
      .modal-info.px-4.mb-4
        .text-center.text-dark.p-4.font-weight-medium
          span.font-weight-medium
            | Don’t worry you can makes changes to it and customize it even at a later stage.
            | You can also revist the viewing plans page to change your base plan later
        .row
          .col-md-4.offset-md-2
            n-button.mt-2(variant="light", block, :buttonText="'Close'", @click="$bvModal.hide(id)")
          .col-md-4
            n-button.mt-2(
              variant="dark",
              block,
              :buttonText="'Confirm'",
              @click="confirmation",
              :rightImageIcon="'chevron-right'")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import resDefs from "@/web/pages/policyCalculator/definitions.js";
import utils from "@/utils";
export default {
  components: {
    NButton,
  },
  props: {
    id: {
      type: String,
      default: "confirm-modal",
    },
    planIndex: {
      type: Number,
      default: -1,
    },
    data: {
      type: Object,
      default: () => {},
    },
    variant: {
      type: String,
      default: "",
    },
    parent: {
      type: String,
      default: "policy-compare",
    },
  },
  data() {
    const resName = "prospects";
    const resDef = resDefs[resName];
    return {
      resDef,
    };
  },
  methods: {
    getCoverageType() {
      switch (this.data.meta.coverage_type) {
        case "E":
          return "Employee Only";
        case "ES":
          return "Employee & Spouse";
        case "ESC":
          return "Employee, Spouse\n& Children";
        case "ESCP":
          return "Employee, Spouse,\nChildren & Parents";
        default:
          return "Data not found";
      }
    },
    getItemPrice(data, planIndex) {
      return utils.toINR(
        data.pricing_table_state.items[
          data.pricing_table_state.items.length - 1
        ][data.pricing_table_state.policies[planIndex].termPrice]
      );
    },
    getTermPrice(data, planIndex) {
      return utils.toINR(
        data.pricing_table_state.items[
          data.pricing_table_state.items.length - 3
        ][data.pricing_table_state.policies[planIndex].termPrice] * 1.18
      );
    },
    async confirmation() {
      const { policies, items } = this.data.pricing_table_state;
      const selectedPlan = policies[this.planIndex].termPrice;
      await this.$apollo.mutate({
        mutation: this.resDef.eventMutation,
        variables: {
          id: null,
          prospectId: this.data.id ? this.data.id : null,
          eventName: "select_plan",
          eventParams: {
            planName: policies[this.planIndex].cardTitle,
            pricingTableState: utils.filterPlan(items, selectedPlan),
            meta: this.data.meta,
          },
        },
      });
      this.$bvModal.hide(this.id);
      this.$emit("showInfoModal");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.confirmation-modal {
  .modal-bg {
    background: linear-gradient(to bottom, $gray-200 14.6rem, $white 14.6rem);
  }
  .confirm-modal-header {
    color: $blue-900;
  }
  .modal-inner-card {
    min-height: 15rem;
  }

  .modal-inner-card-icon {
    width: 5rem;
    height: 2rem;
    left: 1rem;
    top: -1rem;
    background: $gray-200;
    border-radius: 2rem;
  }
  .rotate-down {
    transform: rotate(270deg);
  }
}
</style>
