<template lang="pug">
b-link.card.text-decoration-none(@mouseover="textUnderline = true" @mouseout="textUnderline = false" :class="{'card-hover': textUnderline}" :to="`${baseUrl}/${status}`")
  .stat-card.d-flex.align-items-between.gap-.p-4(:class="variantClass")
    .left.d-flex.gap-1
      div
        .hb4.pb-2.text-gray-900 {{ statusName }}
        .text-gray-800.font-sm.mt-1.font-weight-medium.stat-card-subtitle.mb-4 {{ statusDescription }}
        span.text-gray-900.font-weight-semibold.font-md.mt-4(:class="textUnderline && 'text-decoration-underline'") {{ ctaText }}
    .right
      .text-gray-900.h2.font-xxl {{ statusCount }}
</template>

<script>
export default {
  name: "EndorsementStatusCard",
  data() {
    return {
      textUnderline: false,
    };
  },
  props: {
    cardIndex: {
      type: Number,
      default: 1,
    },
    status: {
      type: String,
      default: "",
    },
    statusName: {
      type: String,
      default: "",
    },
    statusDescription: {
      type: String,
      default: "",
    },
    ctaText: {
      type: String,
      default: "",
    },
    statusCount: {
      type: Number,
      default: 0,
    },
    variant: {
      type: String,
      default: "red",
    },
    baseUrl: {
      type: String,
      default: "changes/draft",
    },
  },
  computed: {
    variantClass() {
      return `${this.variant}-card`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

.card-hover {
  box-shadow: $box-shadow-lg;
}

.stat-card {
  border-radius: 0.5rem;
  flex: 1;

  &.red-card {
    border-left: 5px solid $red-600;
    background-color: $red-100;
  }

  &.mustard-card {
    border-left: 5px solid $mustard-600;
    background-color: $mustard-100;
  }

  &.malibu-card {
    border-left: 5px solid $malibu-600;
    background-color: $malibu-100;
  }
}

.stat-card-subtitle {
  max-width: 80%;
}

.card-no {
  background-color: black;
  color: white;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
}
</style>
