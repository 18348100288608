import { validateDateRange } from "@/utils";

export default {
  copySchema: {
    fields: [
      {
        model: "dates",
        type: "date-range",
        label: "Dates to copy current schedule",
        min: "1900-01-01",
        max: "2100-12-31",
        value: null,
        required: true,
        validator: [validateDateRange],
      },
    ],
  },
}
