<template lang="pug">
  .fixed-top(:class="[headerClass, headerSeparator?'header-separator':'']")
    .container-header(style="height:75px;")
      b-navbar.nav-padding(variant="transparent")
        b-navbar-brand(to="/")
          img.d-inline-block(:src="require(`@/assets/images/${logoImage}.svg`)", style="height:26px;")

        b-navbar-nav.mr-auto.mt-1
          slot(name="left")

        b-navbar-nav.mx-auto.mt-1
          slot(name="middle")

        b-navbar-nav.ml-auto.mt-1
          slot(name="right")
</template>

<script>
export default {
  props: {
    logoImage: {
      type: String,
      default: "logo-main-white",
    },
    headerClass: {
      type: String,
      default: "",
    },
    headerSeparator: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
div.dark-navbar {
  background-color: $tiber;
  transition: background-color 0.3s;
  padding: 0 !important;
  & > div {
    border-bottom-width: 0px;
    border-bottom-style: solid;
    border-bottom-color: #309a92;
  }
}
div.light-navbar {
  background-color: #ffffff !important;
  transition: background-color 0.3s;
  padding: 0 !important;
  & > div {
    border-bottom-width: 0px;
    border-bottom-style: solid;
    border-bottom-color: #e5ecfb;
  }
}
.header-separator > div {
  border-bottom-width: 1px !important;
}
.nav-padding {
  padding: 0.75rem 0;
  height: 100%;
}
</style>
