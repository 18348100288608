import {
  getFilteredListQuery,
  getSingleQuery,
} from "../queries";
import gql from "graphql-tag";

export default {
  gqlType: "UserChangeBatch",
  singleQuery: getSingleQuery("UserChangeBatch"),
  listQuery: getFilteredListQuery("UserChangeBatch"),
  markNoEndorsementFromHR: gql`
    mutation markNoEndorsementFromHR($batchId: ID!) {
      markNoEndorsementFromHR(input: { batchId: $batchId }) {
        success
      }
    }
  `,
}
