/* eslint-disable no-console */

import { register } from "register-service-worker";
import { store } from "./portal/store";

window.addEventListener("load", async () => {
  if (process.env.NODE_ENV === "production") {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready() {
        console.log(
          "App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB"
        );
      },
      registered() {
        console.log("sw: Service worker has been registered.");
        let refreshing = false;
        navigator.serviceWorker.addEventListener("controllerchange", () => {
          console.log("sw: controller changed");
          if (!refreshing) {
            console.log("sw: reloading window");
            window.location.reload();
            refreshing = true;
          }
        });
      },
      cached() {
        console.log("sw: Content has been cached for offline use.");
      },
      updatefound(registration) {
        console.log("sw: New content is downloading.");
        store.commit("setSwLoadingState", true);
      },
      async updated() {
        console.log("sw: Found and downloaded new service worker version, showing versioning modal");
        store.commit("enableVersioningModal", true);
        store.commit("setSwLoadingState", false);

        // Ensure you donot have Update on Reload checked on our devtools, that would force update to run on every refresh.
        // const registrations = await navigator.serviceWorker.getRegistrations();
        // const unregisterPromises = registrations.map((registration) => registration.unregister());

        // await Promise.all(unregisterPromises);

        // const allCaches = await caches.keys();
        // const cacheDeletionPromises = allCaches.map((cache) => caches.delete(cache));
        // await Promise.all(cacheDeletionPromises);
      },
      offline() {
        console.log("No internet connection found. App is running in offline mode.");
      },
      error(error) {
        console.error("Error during service worker registration:", error);
      },
    });
  } else {
    console.log("Not registering service worker");
  }
});
