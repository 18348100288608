<template lang="pug">
.card-container(@click="$emit('cardClick')")
  .icon-style.px-3
    i.icon-bg(:class="`icon-${icon}`")
  .text-style
    span {{text}}
  .icon-style
    i.icon-chevron-right.chervon-bg
</template>

<script>
export default {
  name: "Card",
  props: {
    icon: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.card-container {
  height: 98px;
  background: $white;
  border: 2px solid $gray-300;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  margin: 20px 0;
  position: relative;
  transition: bottom 0.2s;
  bottom: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  .chervon-bg {
    color: $gray-400;
    position: relative;
    left: 0;
    transition: left 0.2s;
  }
}

.icon-style {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-style {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-family: "Hergon Grotesk";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 120%;
  color: $gray-900;
}
.icon-bg {
  height: 42px;
  width: 42px;
  padding-left: 10px;
  // display:flex;
  border-radius: 50%;
  line-height: 40px;
  background: $gray-200;
}

.card-container:hover {
  border: 2px solid $teal-700;
  bottom: 0.5rem;
  .chervon-bg {
    color: $gray-900;
    left: 0.5rem;
  }
}
</style>
