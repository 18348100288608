export const FileAction = {
  OnboardOrg: "onboardOrg",
  ImportECards: "importECards",
  FetchECards: "fetchECards",
  ClaimsBulkUpdate: "claimsBulkUpdate",
  BulkUpload: "bulkUpload",
  BulkDelete: "bulkDelete",
  PremiumDetails: "premiumDetails",
  EndorsementDoc: "endorsementDocuments",
  InsurerImage: "insurerImage",
  TpaImage: "tpaImage",
  PolicyDocUpload: "policyDocUpload",
  SetUpOrgAndPolicies: "setupOrgAndPolicies",
  FileUpload: "fileUpload",
  InsurerTpaHandbook: "insurerTpaHandbook",
  BenefitsBulkAddition: "benefitsBulkAddition",
};
