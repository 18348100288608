<template lang="pug">
.w-100
  .px-3(
    v-if="dependentsModel.length === 0")
    empty-state-card(
      type="Dependent",
      :coveredDependents="getCoveredDependents",
      :disabled="isDisabled",
      @add-dependent="openDependentsModal")
  .d-flex.flex-column.align-items-center.px-2.px-md-4.flex-grow.text-center.bg-mustard-100.w-100(
    v-else)
    .d-flex.w-100.justify-content-between.align-items-center.mt-3.mt-md-5
      h4 Dependents
      h4
        b-badge.px-3(pill, variant="dark") {{ dependentsModel.length }}
    n-button.mt-2.w-100(
      variant="success",
      buttonText="Add dependents",
      rightImageIcon="plus-circle",
      :disabled="isDisabled",
      @click="openDependentsModal")

    .row.mt-3.w-100.py-2(v-if="$route.path.includes('/user/dependents')")
      .col-sm-8.p-0
        div.text-gray-800.d-flex.ml-0.pl-0
          i.icon-question.ic-large.font-lg.ml-0.mr-2.mt-0.pt-0
          p.align-items-center.text-left.font-sm This is the list of all dependents added by you,&nbsp;
            | A few dependents
            | might not be covered under any of the policies.
            | Your can review policy details
            | to know more.
      .col-sm-4.p-0.text-sm-right
        n-button(buttonText="Review policies", variant="outline-primary", size="sm", @click="$router.push({ path: '/dashboard', hash: '#policies' })")

    .d-flex.flex-column.align-items-center.dependents-wrapper.mb-3
      b-card.mt-3.w-100.py-2.px-1.p-md-3(
        v-for="dependent, index of dependentsModel",
        v-bind:key="index",
        no-body)
        .d-flex.justify-content-between.align-items-center.w-100.p-1
          .d-flex.w-100.justify-content-between(:class="{'flex-wrap': isMobileView()}")
            .d-flex.align-items-center.mr-3
              n-avatar.mb-1(
                v-if="dependent.relation && dependent.gender",
                variant="info",
                :avatar="`${dependent.relation}-${dependent.gender}`",
                :size="2.5")
              n-avatar(v-else, :image="require('@/assets/images/user.svg')", :size="2.5")
              .d-flex.flex-column.align-items-baseline.ml-2.ml-md-3
                span.font-weight-semibold.text-gray-900.text-capitalize {{ dependent.name }}
                .d-flex.flex-row.flex-wrap.pt-1.text-gray-700.text-capitalize.font-weight-medium.font-sm
                  span.text-nowrap {{ dependent.relation }}
                  span.ml-1.text-nowrap • {{ dependent.gender }}
                  span.ml-1.text-nowrap(v-if="dependent.dob")
                    | • {{ calculateAge(dependent.dob) }} Years
            .d-flex.pt-1.text-gray-700.text-capitalize.font-weight-medium.font-sm
              n-popover-chip.mr-1.my-1(
                v-if="hasNoPolicyAssigned(dependent)",
                chipIcon="progress-0",
                :isMinimal="true",
                :chipId="`no-policy-${dependent.id}`",
                popoverTitle="No policy has been assigned to this dependent yet. Please contact your HR for more information.",
                popoverHeading="No Policy Added",
                :chipText="getStatusChipData(dependent)?.text",
                triggers="hover",
                variant="white")
                template(v-slot:icon)
                  n-icon.mr-1(
                    name="progress-0",
                    :pathCount="2",
                    :size="1.0")
              coverage-pills-group-wrapper.flex-wrap.my-auto(
                v-else,
                showDependentPopovers=true,
                :item="getPillsGroupItem(dependent)",
                triggers="hover",
                :statusText="getStatusChipData(dependent)?.text",
                :statusChipVariant="getStatusChipData(dependent)?.variant",
                :class="{'justify-content-end': !isMobileView()}"
              )
          .d-flex
            b-avatar.ml-1(button, @click="openDependentsModal($event, dependent)")
              i.icon-edit

  add-dependents-modal-container(
    v-if="show && !isDisabled",
    @close="closeModal",
    :user="user",
    :model="toBeUpdatedDependentsModel",
    :isMidTermAddition="isMidTermAddition",
    @modelUpdated="updateDependentsModel",
    :selectedDependent="selectedDependent",
    :policies="policies")
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import CoveragePillsGroupWrapper from "../../portal/pages/endorsements/components/CoveragePillsGroupWrapper.vue";
import NPopoverChip from "../NovaPopoverChip.vue";
import AddDependentsModalContainer from "./AddDependentsModalContainer.vue";
import NButton from "@/components/NovaButton.vue";
import NAvatar from "@/components/Avatar.vue";
import utils from "@/utils";
import EmptyStateCard from "@/components/Cards/EmptyStates/EmptyStateCard.vue";

export default {
  name: "AddDependentsWrapper",
  components: {
    NButton,
    NAvatar,
    AddDependentsModalContainer,
    EmptyStateCard,
    CoveragePillsGroupWrapper,
    NPopoverChip,
  },
  props: {
    // TODO: Refactor it to keep only user/dependent
    user: {
      type: Object,
      default: null,
    },
    dependentsModel: {
      type: Array,
      default: () => [],
    },
    policies: {
      type: Array,
      default: () => [],
    },
    isMidTermAddition: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      selectedDependent: {},
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    toBeUpdatedDependentsModel() {
      if (this.dependentsModel) return utils.deepClone(this.dependentsModel);
      else return [];
    },
    extractCoveredDependents() {
      const inReviewBenefits = [
        ...utils.bundlePoliciesWithEdgeMeta(utils.getInReviewBenefitsFromUserChanges(this.user.userChanges, true)),
        ...utils.bundlePoliciesWithEdgeMeta(utils.getInReviewBenefitsFromUserChanges(this.user.userChanges, false)),
      ];
      const allBenefits = [...this.policies, ...inReviewBenefits];
      if (!allBenefits.length) return "";
      return utils.extractCoveredDependents(allBenefits);
    },
    getCoveredDependents() {
      const depObj = this.extractCoveredDependents;
      return utils.getContent(depObj);
    },
    areDependentsCovered() {
      const depObj = this.extractCoveredDependents;
      return Boolean(depObj.coveredDependents?.length || depObj.pOrILFlag);
    },
    isDisabled() {
      if (!this.getFeatureFlags.ALLOW_MID_TERM_DEPENDENT_ADDITION) {
        return this.isMidTermAddition;
      }
      return this.getFeatureFlags.SHOW_ELIGIBLE_DEP_ONLY && !this.areDependentsCovered;
    },
  },
  methods: {
    isMobileView() {
      return utils.mobileCheck();
    },
    hasNoPolicyAssigned(dependent) {
      return !this.getPillsGroupItem(dependent).meta.length;
    },
    getStatusChipData(dependent) {
      if (this.getPillsGroupItem(dependent).meta?.some((change) => change.status === "rejected"))
        return {
          variant: "danger",
          text: "Rejected",
        };
      else if (
        this.getPillsGroupItem(dependent).meta?.some((change) => ["draft", "org-ok", "nova-ok"].includes(change.status))
      )
        return {
          variant: "warning",
          text: "Approval Pending",
        };
      else if (!this.getDependentChanges(dependent).length) {
        return {
          variant: "light",
          text: "No Policy Assigned",
        };
      }
    },
    getPillsGroupItem(dependent) {
      const changes = this.getDependentChanges(dependent);
      return {
        user_type: "dependent",
        id: dependent.id,
        meta: changes,
        type: "add",
      };
    },
    getDependentChanges(dependent) {
      const arr = this.getChangesOfLinkedBenefits(dependent);
      const dependentChanges = this.user.userChanges.reduce((arr, change) => {
        change.dependent?.id &&
          change.dependent?.id === dependent.id &&
          change.benefit.isPolicy &&
          change.type === "add" &&
          arr.push({
            ...change.benefit,
            benefitsType: change.benefit.type,
            status: change.status,
          });
        return arr;
      }, arr);
      return dependentChanges;
    },
    // Returns already linked benefits as done changes
    getChangesOfLinkedBenefits(dependent) {
      return dependent.benefits
        .map((benefit) => benefit.node)
        .reduce((arr, benefit) => {
          benefit.isPolicy &&
            arr.push({
              ...benefit,
              type: "add",
              status: "done",
              benefitsType: benefit.type,
            });
          return arr;
        }, []);
    },
    openDependentsModal(e, dep = {}) {
      e?.preventDefault();
      this.selectedDependent = dep;
      this.show = !this.show;
      window.posthog.capture("emp_signup_save_dependent_click", {
        employee_email: this.user?.email,
        org_name: this.user?.org.name,
      });
    },
    closeModal() {
      this.show = false;
    },
    // TODO(NV-894): Emit new dependent relation and a bool flag denoting whether it's a new addition or an updation
    updateDependentsModel(dependents, relation, isNewAddition) {
      this.$emit("dependentsUpdated", dependents, relation, isNewAddition);
    },
    calculateAge(dob) {
      dob = moment(dob, "YYYY-MM-DD");
      return moment().diff(dob, "years", false);
    },
    getAvatar(gender, relation) {
      return require(`@/assets/images/avatar-${relation}-${gender}.svg`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.dependents-wrapper {
  min-height: 400px;
  max-height: 500px;
  overflow: auto;
  width: 100%;
}

.bg-mustard-100 {
  background-color: $mustard-100;
}
</style>
