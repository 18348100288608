export const Base = require("./Base.vue").default;
export const routes = [
  {
    path: "workspace/:id",
    component: () => import("./Workspace.vue"),
    name: "workspace",
  },
  {
    path: "employees/:id",
    component: () => import("./Employees.vue"),
    name: "employees",
  },
  {
    path: "pricing-table/:id",
    component: () => import("./PricingTable.vue"),
    name: "pricingTable",
  },
  {
    path: "addons/:id",
    component: () => import("./Addons.vue"),
    name: "addons",
  },
];
