import gql from "graphql-tag";
import { getListQuery, getSingleQuery } from "../queries";
import ngageCalendarsSchema from "../schemas/ngageCalendars";
import ngageCalendarsMutations from "../mutations/ngageCalendars";

export default {
  gqlType: "calendarEvent",
  fields: ["name", "type", "updatedAt"],
  listQuery: getListQuery("CalendarEvent"),
  singleQuery: getSingleQuery("CalendarEvent"),
  singleQueryByName: gql`
    query calendarEventByName($name: String!) {
      calendarEvent(name: $name) {
        id
        segment {
          id
          name
        }
        eventDate
        name
        schedule
        meta
      }
    }
  `,
  ...ngageCalendarsSchema,
  ...ngageCalendarsMutations,
  validate: (n) => {
    // returns an error message if error exists, else returns false
    for (let item of n.schedule) {
      // embedded links in strapi are in html tag format
      if (item?.content?.meta?.body?.text?.includes("<a href=")) {
        return `Error: ${item?.content?.name} contains an embedded link`;
      }
    }
    return false;
  },
  transform: (n, write = false) => {
    if (write && n?.schedule?.length > 0) {
      const payload = JSON.parse(JSON.stringify(n));
      payload.schedule.forEach((item) => {
        if (item.content) {
          item.contentId = item.content.meta.id;
          delete item.content;
        }
      });
      return payload;
    }
    return n;
  },
}
