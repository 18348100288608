import { fileFragment, benefitFragment } from ".";
import gql from "graphql-tag";

export const orgGroupFileFragment = gql`
  fragment OrgGroupFileEdge on OrgGroupFileEdge {
    node {
      ...File
    }
    meta
  }
  ${fileFragment}
`;

export const orgBenefitFragment = gql`
  fragment OrgBenefitEdge on OrgBenefitEdge {
    node {
      ...Benefit
    }
    meta
  }
  ${benefitFragment}
`;

export const orgBenefitsFragment = gql`
  fragment OrgBenefits on Org {
    id
    benefits {
      ...OrgBenefitEdge
    }
  }
  ${orgBenefitFragment}
`;

export const orgFilesFragment = gql`
  fragment OrgFiles on Org {
    id
    files {
      ...OrgGroupFileEdge
    }
  }
  ${orgGroupFileFragment}
`;

export const orgFragment = gql`
  fragment Org on Org {
    id
    name
    address
    pincode
    meta
    status
    featureFlags
    benefits {
      ...OrgBenefitEdge
    }
    files {
      ...OrgGroupFileEdge
    }
  }
  ${orgBenefitFragment}
  ${orgGroupFileFragment}
`;

export const orgWithEndorsementStatsFragment = gql`
  fragment Org on Org {
    id
    name
    OrgEndorsementStats {
      additions
      deletions
      updates
    }
  }
`;
