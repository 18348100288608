<template lang="pug">
.sidenav
  .sidenav-backdrop(v-if="isSidebarExpanded" @click="$emit('closeSideNavbar')") 
  .side-navbar-mobile.justify-content-between(
    :class="bgColor")
    .mobile-nav(@click="$emit('openSideNavbar')")
      n-icon.mr-3.align-middle(name="hamburger", :size="1.5")
      span(v-if="currentNavName !== ''") {{ currentNavName }}
    .mobile-support.px-3(@click="showSalesIqChat")
      n-icon.mr-2.align-middle(name="support")
      span Support
  transition(name="fade", mode="out-in")
    .side-navbar-container.d-flex.flex-column.mb-0.font-sm.mb-0(
      :class="[bgColor, { open: isSidebarExpanded }]")
      transition(name="fade", mode="out-in")
        .close-sidebar.py-2.w-100(@click="$emit('closeSideNavbar')")
          n-icon.py-3.px-2(name="cross")
      b-link.side-navbar-logo.d-flex.cursor-pointer(to="/")
        img.main-logo-style.d-none.d-md-block.my-3.mr-2(
          src="@/assets/images/logo-white-main.svg",alt="nova main logo")
        img.main-logo-style.logo-text.d-none.d-md-block.my-3(
          src="@/assets/images/logo-text-white.svg",alt="nova logo text")
      ul.side-navbar-links.mt-4
        div(v-for="tab in sideNavLinks", :key="tab.name")
          li.d-flex.justify-content-center.align-items-center.side-navbar-link.mb-1.p-2(
            v-if="filterSideNavLinks(tab)",
            :class="{ 'active-tab': isActiveTab(tab) }")
            b-link.font-md(:to="tab.route", @click="tab.route ? null : showSalesIqChat()")
              n-icon.ml-2.align-sub.list-icon(
                :name="tab.icon",
                :pathCount="tab.iconPathCount || 2",
                v-b-tooltip.hover.right="{ customClass: 'tooltip-margin' }",
                :title="isSidebarExpanded ? '' : tab.name",
                :variant="tab.variant")
              transition(name="fade", mode="out-in")
                span.list-item-name.ml-2.align-middle.text-nowrap(v-if="isSidebarExpanded") {{ tab.name }}
          hr.divider(v-if="tab.divideAfter")
      ul.side-navbar-links.mt-3.d-flex.h-100.flex-column.justify-content-end(
        v-if="sideNavFunctions.length > 0")
        div(v-for="tab in sideNavFunctions", :key="tab.name")
          li.d-flex.justify-content-center.align-items-center.side-navbar-link.p-2(
            v-if="!(!getFeatureFlags['ENDORSEMENTS'] && tab.route === '/org-admin/changes')",
            :class="{'active-tab': isActiveTab(tab) }")
            b-link.font-md(:to="tab.route", @click="tab.route ? null : showSalesIqChat()")
              n-icon.ml-2.align-sub.list-icon(
                :name="tab.icon",
                :pathCount="tab.iconPathCount || 2",
                v-b-tooltip.hover.right="{ customClass: 'tooltip-margin' }",
                :title="isSidebarExpanded ? '' : tab.name",
                :variant="tab.variant")
              transition(name="fade", mode="out-in")
                span.list-item-name.ml-2.align-middle.text-nowrap(v-if="isSidebarExpanded") {{ tab.name }}
          hr.divider(v-if="tab.divideAfter")
      ul.collapse-navbar.px-2.cursor-pointer(@click="$emit('toggleSideNavbar')")
        li.d-flex.justify-content-center.align-items-center.side-navbar-link.p-2.w-100(
          :class="isSidebarExpanded ? '' : 'py-0'")
          span.font-md.d-flex.align-items-center.text-nowrap(v-if="isSidebarExpanded") Collapse Navbar
          n-icon.align-middle.ml-auto(
            name="expand-nav", 
            :class="isSidebarExpanded ? 'rotate-180' : 'mx-auto'", :size="1.75")

      .side-navbar-bottom.mt-4.mt-md-3
        b-link.side-navbar-bottom-link.font-xs.text-nowrap(to="/dashboard")
          span.font-sm(v-if="isSidebarExpanded") Switch to Personal Account
          .side-navbar-bottom-user.d-flex.align-items-center.cursor-pointer.mt-1
            n-avatar.cursor-pointer(
              :size="isSidebarExpanded ? 1.25 : 2",
              :name="user.name",
              :image="user.photoFile ? user.photoFile.url : null",
              :tooltipPlacement="isSidebarExpanded ? 'top' : 'topleft'")
            .swap-icon(v-if="!isSidebarExpanded")
              n-icon(name="swap-horizontal", :size="0.75")
            span.user-name.align-middle.mx-2.font-md(
              v-if="isSidebarExpanded", v-b-tooltip="user.name.length > maxNameLength ? user.name : null")
              | {{ truncatedName }}
            n-icon.align-middle(v-if="isSidebarExpanded", name="chevron-right")
      .mt-auto.mx-3
        n-button.w-100.mb-1.bg-malibu-900.border-malibu-900.ph-org-admin-open-in-app-button(
          v-if="isMobileBrowser",
          size="sm",
          variant="dark",
          buttonText="Open our App"
          imageIcon="download",
          @click="openAppOrContinue('app')")
        b-link.side-navbar-logo-mobile.cursor-pointer(to="/")
          img.main-logo-style.d-inline-block.d-md-none.my-3.mr-2(
            src="@/assets/images/logo-white-main.svg",alt="nova main logo")
          img.main-logo-style.d-inline-block.d-md-none.logo-text.my-3(
            src="@/assets/images/logo-text-white.svg",alt="nova logo text")
</template>

<script>
import { mapGetters, mapState } from "vuex";
import mobileApp from "../portal/mobileApp";
import { sideNavbarData, sideNavbarType } from "../portal/pages/admin/constants";
import utils, { getAdminActiveTab } from "@/utils";
import NAvatar from "@/components/Avatar.vue";
import NButton from "@/components/NovaButton.vue";

export default {
  name: "NovaSideNavbar",
  components: {
    NAvatar,
    NButton,
  },
  props: {
    sideNavbarState: {
      type: Boolean,
      default: false,
    },
    navType: {
      type: String,
      default: sideNavbarType.ADMIN,
    },
    currentNavName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      sideNavbarData: sideNavbarData,
      appLinkingModalId: "app-linking-modal",
      sideNavbarType: sideNavbarType,
      maxNameLength: 15,
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    ...mapState(["user"]),
    getNameInitials: function () {
      return utils.getNameInitials(this.user?.name || "");
    },
    truncatedName() {
      if (this.user?.name?.length > this.maxNameLength) {
        return this.user.name.slice(0, this.maxNameLength - 2) + "...";
      }
      return this.user.name;
    },
    isSidebarExpanded: function () {
      return this.sideNavbarState;
    },
    bgColor: function () {
      return this.navType === this.sideNavbarType.ORG_ADMIN ? "bg-tiber" : "bg-blackpearl";
    },
    isApp() {
      return mobileApp.isApp;
    },
    isMobileBrowser() {
      return (utils.mobileCheck() || utils.tabCheck()) && !this.isApp;
    },
    showSmallTopMargin() {
      return this.$route.path.includes("/integrations/");
    },
    sideNavLinks() {
      return this.sideNavbarData[this.navType].sideNavLinks;
    },
    sideNavFunctions() {
      return this.sideNavbarData[this.navType].sideNavFunctions;
    },
    activeTabRoute() {
      return getAdminActiveTab(this.$route.path).route;
    },
  },
  mounted() {
    this.$store.commit("toggleNovaSideNavState", this.isSidebarExpanded);
  },
  methods: {
    showSalesIqChat() {
      this.$store.commit("toggleSupportContainer");
    },
    filterSideNavLinks(tab) {
      if (
        (!this.getFeatureFlags.ENDORSEMENTS && tab.name === "Endorsements") ||
        (this.getFeatureFlags.ENDORSEMENTS && tab.name === "Review Changes") ||
        (!this.getFeatureFlags.HR_ONBOARDING && tab.name === "Onboarding") ||
        (!this.getFeatureFlags.HR_ANALYTICS && tab.name === "Analytics") ||
        (!this.getFeatureFlags.CLAIMS_PORTAL_EXPERIENCE && tab.name === "Claims")
      ) {
        return false;
      } else {
        return true;
      }
    },
    openAppOrContinue(target) {
      this.$store.commit("setDeepLinkTarget", { deepLinkTarget: target });
      this.$bvModal.hide(this.appLinkingModalId);
      if (target === "app") utils.redirectToApp(this.$route?.fullPath);
    },
    isActiveTab(tab) {
      if (tab.name === "Home") return this.$route.path === tab.route;
      return this.$route.path.includes(tab.route) || tab.route === this.activeTabRoute;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
$transition-duration: 0.3s;
.sidenav-backdrop {
  display: none;
}
.side-navbar-container {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  width: 4.5rem;
  padding: 0.6rem;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: 500;
  color: rgba($white, 0.6);
  z-index: 3001;
  transition: all $transition-duration ease;
  .list-icon {
    font-size: 1.25rem !important;
    transition: all $transition-duration ease;
  }
  .logo-text {
    opacity: 0;
    transition: all $transition-duration ease;
  }
  li {
    position: relative;
    a {
      color: inherit;
      font-weight: 400;
      text-decoration: none;
      transition: all $transition-duration ease;
      width: 100%;
      height: 1.25rem;
    }
  }
  .side-navbar-link {
    i {
      color: rgba($white, 0.6);
      &.text-red-300 {
        color: $red-300;
      }
    }
    transition: color 0.1s ease;
    :hover {
      color: $white;
      i {
        color: $white;
        &.text-red-300 {
          color: $red-200;
        }
      }
    }
  }
  .side-navbar-logo {
    padding-left: 0.9rem;
  }
  .main-logo-style {
    height: 1.75rem;
  }
  .side-navbar-bottom {
    padding: 1rem 0;
  }
  .side-navbar-bottom-user {
    justify-content: center;
    span.user-name {
      color: $white;
    }
    .swap-icon {
      position: absolute;
      background: $white;
      margin-left: 1.6rem;
      margin-top: 1.2rem;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swap-icon i {
      color: $gray-900;
    }
  }
  .collapse-navbar {
    cursor: pointer;
    transition: all $transition-duration ease;
    i {
      color: $white;
    }
  }
  .side-navbar-bottom-link {
    color: rgba($white, 0.6);
    &:hover {
      text-decoration: none;
      color: $white;
    }
  }
  .divider {
    height: 0;
    border-top: 1px solid $gray-400;
    opacity: 0.3;
  }
  .side-navbar-links {
    flex: 1;
    .active-tab {
      background: rgba($white, 0.15);
      border-radius: 6px;
      color: $white;
      i {
        color: $white !important;
      }
    }
  }
  &.open {
    width: 13.5rem;
    padding: 0.6rem 1rem !important;
    .list-icon {
      font-size: 1rem !important;
      height: 1.25rem;
    }
    .logo-text {
      opacity: 1;
    }
    .side-navbar-logo {
      padding: 0 0.8rem;
    }
    .side-navbar-bottom {
      padding: 0 1rem 1rem 1rem;
      transition: all $transition-duration ease;
    }
    .side-navbar-bottom-user {
      justify-content: flex-start;
    }
    .collapse-navbar {
      padding: 0.2rem 1rem;
      :hover {
        color: $white !important;
        opacity: 1;
      }
    }
  }
}
.side-navbar-mobile,
.close-sidebar,
.side-navbar-logo-mobile {
  display: none;
}
@media (max-width: 992px) {
  .sidenav-backdrop {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
    height: 100%;
    opacity: 0.5;
    z-index: 999;
  }
  .collapse-navbar {
    display: block;
  }
}
@include media-breakpoint-down(sm) {
  .side-navbar-container {
    opacity: 0;
    width: 0;
    .side-navbar-links {
      flex: 0;
    }
    &.open {
      opacity: 1;
      width: 220px;
      display: flex;
      padding: 2rem 1rem 0.5rem 1rem;
    }
  }
  .side-navbar-mobile {
    width: 100%;
    height: 70px;
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    z-index: 3001;
    color: $white;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    i {
      font-size: 1.5rem;
    }
  }
  .mobile-nav {
    padding-top: 0.8rem;
  }
  .mobile-support {
    padding-top: 0.5rem;
    border-radius: 5px;
    border: 1px solid $white;
  }
  .close-sidebar,
  .side-navbar-logo-mobile {
    display: block;
    color: $white;
  }
  .collapse-navbar {
    display: none;
  }
}
</style>
