import gql from "graphql-tag";

export const segmentFragment = gql`
  fragment Segment on Segment {
    id
    type
    category
    name
    meta
    org {
      id
      name
    }
    orgs {
      id
      name
    }
    users {
      id
      name
    }
    updatedAt
  }
`;
