import { getListQuery, getSingleQuery } from "../queries";
import { challengeStatusSchema } from "../constants";
import userChallengesSchema from "../schemas/userChallenges";
import userChallengesMutations from "../mutations/userChallenges";

export default {
  gqlType: "UserChallenge",
  fields: [
    "orgName",
    "segmentName",
    "userName",
    "templateName",
    "startDate",
    "currentTotal",
    "goalValue",
    "challengeStatus",
  ],
  listQuery: getListQuery("UserChallenge"),
  singleQuery: getSingleQuery("UserChallenge"),
  challengeStatusSchema,
  ...userChallengesSchema,
  ...userChallengesMutations,
  transform: (n, write = false) => {
    const segment = n?.org?.segments?.find(
      (segment) => segment.type === "FITNESS"
    );
    const payload = {
      ...n,
      segment,
      challengeTemplateId: n.challengeTemplate?.id,
      templateName: n.challengeTemplate?.name,
      orgName: n.org?.name,
      segmentName: segment?.name,
      userName: n.user?.name,
      segmentId: n.segment?.id,
      orgId: n.org?.id,
      userId: n.user?.id,
      duration: n.challengeTemplate?.duration,
    };
    if (write) {
      delete payload.user;
      delete payload.org;
      delete payload.challengeTemplate;
    }
    return payload;
  },
}
