<template lang="pug">
.row.no-gutters.pr-3
  template(v-if="sortedDependents.length > 0")
    .col-md-6.mb-2.p-0(v-for="(dependent, index) in sortedDependents",:key="index")
      dependent-details-card(:cardData="dependent", @clicked="showData(dependent)")
</template>

<script>
import utils from "@/utils";
import { AcceptedRelations } from "../../../../../common/enums";
import DependentDetailsCard from "./DependentDetailsCard.vue";
export default {
  name: "DependentDetailsWrapper",
  components: {
    DependentDetailsCard,
  },
  props: {
    dependentsModel: {
      type: Array,
      default: () => [],
    },
    policies: {
      type: Array,
      default: () => [],
    },
    coverPOrPil: {
      type: String,
      default: () => "",
    },
  },
  data() {
    const depPolicyDetail = {};
    this.policies.map((policy) => {
      const depObj = utils.extractCoveredDependents([policy]);
      depPolicyDetail[policy.type] = depObj;
    });
    return {
      depPolicyDetail,
      dependentsData: [],
      totalDepCount: {},
    };
  },
  computed: {
    sortedDependents() {
      const sortedDependents = this.dependentsData;
      const relations = [
        AcceptedRelations.SPOUSE,
        AcceptedRelations.CHILD,
        AcceptedRelations.PARENT,
        AcceptedRelations.PARENT_IN_LAW,
      ];
      sortedDependents.sort((dep1, dep2) => {
        if (dep1.isPlaceholder && !dep2.isPlaceholder) return 1;
        if (relations.indexOf(dep1.relation) < relations.indexOf(dep2.relation)) return -1;
        else if (relations.indexOf(dep1.relation) === relations.indexOf(dep2.relation)) {
          if (dep1.name < dep2.name) return -1;
          else return 1;
        } else return 1;
      });
      return sortedDependents;
    },
  },
  watch: {
    dependentsModel() {
      this.dependentsData = [];
      this.updateDependentsData();
    },
  },
  created() {
    this.updateDependentsData();
  },
  methods: {
    getCardData(dependent) {
      const depPolicies = [];
      for (const [key, value] of Object.entries(this.depPolicyDetail)) {
        const relation = dependent.relation;
        if (value.coveredDependents.includes(relation)) {
          if (
            (relation === AcceptedRelations.PARENT || relation === AcceptedRelations.PARENT_IN_LAW) &&
            !value.pOrILFlag
          ) {
            depPolicies.push(key);
          }
          if (
            (relation === AcceptedRelations.CHILD || relation === AcceptedRelations.SPOUSE) &&
            dependent.meta?.isPreferred
          ) {
            depPolicies.push(key);
          }
        } else if (relation === this.coverPOrPil && value.pOrILFlag && dependent.meta?.isPreferred) {
          depPolicies.push(key);
        }
      }
      return { ...dependent, depPolicies };
    },
    showData(dependent) {
      this.$emit("clicked", utils.deepClone(dependent));
    },
    pushDependentPlaceholders(relation) {
      let count = 0;
      switch (relation) {
        case AcceptedRelations.CHILD:
          count = this.totalDepCount.childrenCount;
          break;
        case AcceptedRelations.PARENT:
          count = this.totalDepCount.parentCount;
          break;
        case AcceptedRelations.PARENT_IN_LAW:
          count = this.totalDepCount.parentInLawCount;
          break;
        case AcceptedRelations.SPOUSE:
          count = this.totalDepCount.coveredDependents.includes("spouse") ? 1 : 0;
          break;
      }
      while (count > 0) {
        count--;
        this.dependentsData.push({ isPlaceholder: true, relation });
      }
    },
    updateDependentsData() {
      this.totalDepCount = utils.extractCoveredDependents(this.policies);
      if (this.totalDepCount.pOrILFlag || this.totalDepCount.coveredDependents.includes("parent")) {
        this.totalDepCount.parentCount = 2;
      }
      if (this.totalDepCount.pOrILFlag || this.totalDepCount.coveredDependents.includes("parent-in-law")) {
        this.totalDepCount.parentInLawCount = 2;
      }
      this.dependentsModel.forEach((dependent) => {
        switch (dependent.relation) {
          case AcceptedRelations.CHILD:
            this.totalDepCount.childrenCount--;
            break;
          case AcceptedRelations.PARENT_IN_LAW:
            this.totalDepCount.parentInLawCount--;
            break;
          case AcceptedRelations.PARENT:
            this.totalDepCount.parentCount--;
            break;
          case AcceptedRelations.SPOUSE:
            this.totalDepCount.coveredDependents.splice(this.totalDepCount.coveredDependents.indexOf("spouse"), 1);
        }
        this.dependentsData.push(this.getCardData(dependent));
      });
      // push placeholder data
      const relations = [
        AcceptedRelations.CHILD,
        AcceptedRelations.PARENT,
        AcceptedRelations.PARENT_IN_LAW,
        AcceptedRelations.SPOUSE,
      ];
      relations.forEach((relation) => {
        this.pushDependentPlaceholders(relation);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
