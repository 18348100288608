import gql from "graphql-tag";
import { orgOnboardingStageTaskFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertOrgOnboardingStageTask(
      $id: ID
      $status: String
      $task: String
      $stage: String
      $meta: JSONObject
      $orgId: ID
      $benefitId: ID
    ) {
      upsertOrgOnboardingStageTask(
        input: {
          id: $id
          status: $status
          task: $task
          stage: $stage
          meta: $meta
          orgId: $orgId
          benefitId: $benefitId
        }
      ) {
        orgOnboardingStageTask {
          ...OrgOnboardingStageTask
        }
      }
    }
    ${orgOnboardingStageTaskFragment}
  `,
};
