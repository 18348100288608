import { onError as onApolloError } from "apollo-link-error";
import { logoutActiveUser, promiseToObservable } from "./../auth";
import { store } from "./../../portal/store";
import utils, { sleep } from "@/utils";
import router from "@/portal/router";

const SUPPRESSED_ERROR_CALLS = ["authenticateWithRefreshToken"];

export const errorMiddleware = onApolloError((error) => {
  if (error.networkError?.message === "Failed to fetch") {
    // Retry api call after 500ms
    // this is helpful in cases where
    // multiple api calls were made and accesstoken were expired
    // it'll do retry after fail
    return promiseToObservable(sleep(100)).flatMap(() => error.forward(error.operation));
  } else if (error.networkError?.response?.status === 401) {
    handleInvalidToken();
  } else if (
    error.graphQLErrors?.[0]?.errorMessage === "VERSION_MISMATCH" &&
    !store.state.showVersioningModal
  ) {
    // handling version mismatch error specifically
    // TODO: custom error code is not sent from BE middleware, try to find more graceful soln
    store.commit("enableVersioningModal", true);
    store.commit("setSwLoadingState", true);
  } else {
    /*
      There're some network calls we don't want to throw
      error for, for example :- refreshToken, refreshToken either
      feels like nothing happened under the hood, if everything is good
      or it just redirects you to login page
    */
    const operation = error?.response?.data ? Object.keys(error.response.data)[0] : "";
    if (!SUPPRESSED_ERROR_CALLS.includes(operation)) {
      utils.handleApolloErrors(error, store);
    } else {
      return promiseToObservable(logoutActiveUser(false)).flatMap(() => error.forward(error.operation));
    }
  }
});

const handleInvalidToken = () => {
  router.push({ path: "/login/logout" });
};
