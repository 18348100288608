import gql from "graphql-tag";

export const jobFragment = gql`
  fragment Job on Job {
    id
    name
    queueName
    status
    result
    logs
    timestamp
    email
    fileURL
    progress
  }
`;
