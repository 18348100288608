<template lang="pug"> 
.transfer-batch-modal
  b-modal(:id="id", size="md", cancel-variant="light-secondary", :scrollable="true")
    template(v-slot:modal-title)
      .d-flex.align-items-center
        .text-gray-900.font-weight-semibold.font-md Select a batch
    
    .p-3
      n-table#change-batches-table(
        :items="batches",
        :fields="fields",
        :hover="true",
        :fixed="false",
        ref="change-batches-table",
        @row-clicked="(batch) => transferBatch(batch)",
        tbody-tr-class="cursor-pointer",
      )
        template(v-slot:monthAndYear="{ data }")
          span.font-sm.font-weight-medium.text-gray-800 {{ `${getMonthYear(data.item.startingAt)}` }}
        template(v-slot:status="{ data }")
          div(v-if="data.item.status === 'completed'")
            span(class="text-success") ACTIVATION COMPLETED
          div(v-else-if="data.item.status === 'overdue'")
            span(class="text-danger") BATCH OVERDUE
          div(v-else)  
            span(class="text-primary") ACTIVATING BENEFITS

    template(v-slot:modal-footer)
      .d-flex.justify-content-end
        n-button.font-sm(
          variant="light-secondary",
          buttonText="Cancel",
          @click="handleClose")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import NTable from "@/components/NovaTable.vue";
import utils from "@/utils";

export default {
  name: "TransferBatchModal",
  components: {
    NButton,
    NTable,
  },
  props: {
    batches: {
      type: Array,
      default: [],
    },
    id: {
      type: String,
      default: "transfer-batch-modal",
    },
  },
  data() {
    const rowStyling = ["align-middle"];
    return {
      orgId: this.$route.params.orgId,
      selectedBatch: null,
      getMonthYear: utils.getMonthYear,
      fields: [
        {
          key: "monthAndYear",
          label: "Batch Name",
          tdClass: rowStyling,
        },
        {
          key: "status",
          label: "Batch status",
          tdClass: rowStyling,
        },
        {
          key: "dateOfSubmission",
          label: "",
          tdClass: rowStyling,
        },
      ],
    };
  },
  methods: {
    handleClose() {
      this.$emit("modal-closed");
    },
    transferBatch(batch) {
      this.$emit("update-batch", batch.id);
      this.handleClose();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
</style>
