import { getListQuery, getSingleQuery } from "../queries";
import formsSchema from "../schemas/forms";
import formsMutations from "../mutations/forms";

export default {
  gqlType: "Form",
  fields: ["slug", "type", "meta"],
  singleQuery: getSingleQuery("Form"),
  listQuery: getListQuery("Form"),
  ...formsSchema,
  ...formsMutations,
  transform: (n) => {
    return { ...n };
  },
}
