<template lang="pug">
.col-lg-6.px-0
  .position-relative
    .d-flex.text-center
      .img-container
        .d-block.w-100.h-100
          img.blog-image(:src="thumbnailImage")
    br
    div
      h4.hb4.d-none.d-lg-block {{ blog.title }}
      h5.hb5.d-block.d-lg-none {{ blog.title }}
      .row.mx-0.text-gray-700
        .row.mx-0.align-items-center.pr-4.width-max-content
          span.icon.icon-user-circle.pr-2
          span.align-middle {{ blog.authors[0].name }}
        .row.mx-0.align-items-center.pr-4.width-max-content
          span.icon.icon-clock.pr-2
          span.align-middle {{ blog.reading_time }} min{{blog.reading_time!==1?'s':''}}
    b-link.stretched-link(:href="blog.url", target="_blank")
</template>

<script>
export default {
  name: "LatestBlog",
  props: {
    blog: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    thumbnailImage() {
      return this.blog.feature_image.replace("/images/", "/images/size/w600/");
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.icon {
  font-size: 1.5rem;
}
.blog-image {
  border-radius: 0.25rem;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.img-container {
  height: 200px;
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
}
@media (min-width: 576px) {
  .img-container {
    height: 300px;
    width: 100%;
    border-radius: 0.25rem;
  }
}
</style>
