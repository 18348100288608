import { camelCase } from "lodash-es";
import { ClaimCategory } from "../../../common/enums/claims/claimCategory";
import { ClaimStage } from "../../../common/enums/claims/claimStage";
import { ClaimStatus } from "../../../common/enums/claims/claimStatus";
import { ClaimType } from "../../../common/enums/claims/claimType";
import { claimTypeToClaimCategory } from "../../../common/enums/claims/maps/claimTypeToClaimCategory";
import { ProcessFlow } from "../../../common/enums/claims/processFlow";
import { Status as StatusEnum } from "../../../common/enums/claims/status";
import { ClaimDocument } from "../../../common/enums/claims/claimDocument";
import { processFlowToStages } from "../../../common/enums/claims/maps/processFlowToStages";

export const challengeStatusSchema = {
  CREATED: "CREATED",
  IN_PROGRESS: "IN_PROGRESS",
  ON_HOLD: "ON_HOLD",
  COMPLETED: "COMPLETED",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  DELETED: "DELETED",
};

export const orgStatusSchema = [
  { id: "ACTIVE", name: "Active" },
  { id: "DELETED", name: "Deleted" },
  { id: "PROSPECT", name: "Prospect" },
  { id: "INACTIVE", name: "Inactive" },
];

export const userRoles = ["guest", "user", "org_member", "org_admin", "account_admin", "admin", "prospect"];

export const policyTypes = {
  "no-policy-defined": {
    title: "Health Insurance",
    subtitle: "Just a little longer before your policy is activated.",
    image: "nopolicy",
    icon: "hospital",
    type: "no-policy-defined",
  },
  gmc: {
    title: "Health Insurance",
    subtitle: "Your group mediclaim insurance policy",
    tagline: "GMC - Group Mediclaim Policy",
    icon: "hospital",
    image: "stethoscope",
    color: "red-500",
    bgColor: "red-100",
    displayTitle: "Group Medical Cover",
  },
  gpa: {
    title: "Accident Coverage",
    subtitle: "Your group personal accident insurance policy details",
    tagline: "GPA - Group Personal Accident",
    icon: "wheelchair",
    image: "hospital-bed",
    color: "mustard-600",
    bgColor: "mustard-100",
    displayTitle: "Group Personal Accident Policy",
  },
  gtl: {
    title: "Life Insurance",
    subtitle: "Your group term life insurance policy",
    tagline: "TL - Term Life Insurance",
    icon: "bed",
    image: "umbrella",
    color: "malibu-600",
    bgColor: "malibu-100",
    displayTitle: "Group Term Life Policy",
  },
  covid: {
    title: "COVID Protection",
    subtitle: "Wearing a mask and washing your hands is still the best policy",
    tagline: "COV19 - Covid Coverage Policy",
    icon: "covid",
    image: "hospital-bed",
    color: "purple-400",
    bgColor: "purple-100",
    displayTitle: "Covid",
  },
  topup: {
    title: "Top-up",
    subtitle: "Better coverage for you and your family",
    tagline: "Top-up",
    icon: "add",
    color: "teal-700",
    bgColor: "teal-100",
    displayTitle: "Topup Policy",
  },
  "super-topup": {
    title: "Super Top-up",
    subtitle: "Better coverage for you and your family",
    tagline: "Super Top-up",
    icon: "add",
    color: "teal-700",
    bgColor: "teal-100",
    displayTitle: "Super Topup Policy",
  },
  dental: {
    title: "Dental Care",
    subtitle: "Make sure the smile stays on",
    tagline: "Dental Care Policy",
    icon: "teeth",
    image: "dental-tools",
    color: "blue-400",
    bgColor: "blue-100",
    displayTitle: "Dental Policy",
  },
  workmen: {
    title: "Workmen Compensation",
    subtitle: "Your workmen compensation insurance policy details",
    tagline: "Workmen Compensation Insurance Policy",
    icon: "wheelchair",
    image: "hospital-bed",
    color: "gray-900",
    bgColor: "gray-100",
    displayTitle: "Workmen Compensation Insurance Policy",
  },
  dcl: {
    title: "Life Insurance",
    subtitle: "Your direct contractual liability policy details",
    tagline: "CL - Contractual Liability Insurance",
    icon: "bed",
    image: "umbrella",
    color: "malibu-600",
    bgColor: "malibu-100",
    displayTitle: "Direct Contractual Liability Policy",
  },
  ppc: {
    title: "Parental Policy",
    subtitle: "Health insurance policy for your parents",
    tagline: "Health insurance policy for your parents",
    icon: "hospital",
    image: "stethoscope",
    color: "red-500",
    bgColor: "red-100",
    displayTitle: "Parental Policy",
  },
  addOnCards: {
    "topup-card": {
      title: "Looking for extra\ncoverage options?",
      subtitle: "You can purchase a super top-up over your work policy and expand your coverage by 20 lacs",
      btnText: "Learn more about super top-up ->",
      image: "new-product-bg-1.svg",
    },
    "flexi-wallet-card": {
      title: "Want to customize your benefits?",
      subtitle: "You can customize your benefits with the\nadditional wallet amount from your company.",
      btnText: "Customize Your Benefits ->",
      image: "flexi-wallet-card.svg",
    },
  },
};

export const benefitTypes = {
  "partner-signup": {
    subtitle: "Employee Benefits",
  },
  other: {
    subtitle: "Other",
  },
  "free-coupon": {
    subtitle: "Free Coupon",
  },
  "embeded-partners": {
    subtitle: "Embeded Partner",
  },
  "sso-partners": {
    subtitle: "SSO Partner",
  },
};

export const claimsStatus = StatusEnum;

export const claimStatusList = Object.values(claimsStatus);

export const claimStatusListAsCrm = Object.values(ClaimStatus);

export const claimDocumentsList = Object.keys(ClaimDocument).map((key) => ClaimDocument[key]);

export const claimDocumentSegments = {
  "Insurance Claim Forms": [ClaimDocument.CLAIM_FORM_PART_A, ClaimDocument.CLAIM_FORM_PART_B],
  "Identification Documents": [
    ClaimDocument.ADHAAR_PRIMARY,
    ClaimDocument.ADHAAR_PATIENT,
    ClaimDocument.CANCELLED_CHEQUE,
    ClaimDocument.PAN_PRIMARY,
    ClaimDocument.E_CARD,
  ],
  "Medical Reports & Prescriptions": [ClaimDocument.DISCHARGE_SUMMARY, ClaimDocument.INVESTIGATION_REPORT],
  "Bills & Receipts": [
    ClaimDocument.HOSPITAL_BILL,
    ClaimDocument.PAID_RECEIPT,
    ClaimDocument.MED_INV_PRESCRIPTION,
    ClaimDocument.PHARMACY_BILL,
    ClaimDocument.INVESTIGATION_BILL,
  ],
};

export const acceptedFamilyDefinitionsList = [
  "E",
  "ES2C",
  "ES2CP",
  "ES2CP/IL",
  "ES2CP+IL",
  "ES3C",
  "ES3CP",
  "ES3CP/IL",
  "ES3CP+IL",
  "ES4C",
  "ES4CP",
  "ES4CP/IL",
  "ES4CP+IL",
  "ES5C",
  "ES5CP",
  "ES5CP/IL",
  "ES5CP+IL",
  "ES6C",
  "ES6CP",
  "ES6CP/IL",
  "ES6CP+IL",
  "P",
  "P/IL",
];

export const cashlessClaimCategoryList = claimTypeToClaimCategory.get(ClaimType.CASHLESS);
export const reimbursementClaimCategoryList = claimTypeToClaimCategory.get(ClaimType.REIMBURSEMENT);
export const claimCategoryList = Object.values(ClaimCategory);
export const allClaimStageOptions = Object.values(ClaimStage);
export const processFlowList = Object.values(ProcessFlow);

export const claimStageListForAfterApproval = processFlowToStages.get(ProcessFlow.HARD_COPY_AFTER_APPROVAL);
export const claimStageListForBeforeApproval = processFlowToStages.get(ProcessFlow.HARD_COPY_BEFORE_APPROVAL);
export const claimStageListForNoHardCopy = processFlowToStages.get(ProcessFlow.NO_HARD_COPY);
export const claimStageListForNoFlow = processFlowToStages.get(ProcessFlow.NO_FLOW);

export const claimStageListForAfterApprovalKeyMap = {};
claimStageListForAfterApproval.map((stage) => {
  claimStageListForAfterApprovalKeyMap[stage] = camelCase(stage);
});

export const claimStageListForBeforeApprovalKeyMap = {};
claimStageListForBeforeApproval.map((stage) => {
  claimStageListForBeforeApprovalKeyMap[stage] = camelCase(stage);
});

export const claimStageListForNoHardCopyKeyMap = {};
claimStageListForNoHardCopy.map((stage) => {
  claimStageListForNoHardCopyKeyMap[stage] = camelCase(stage);
});

export const claimStageListForNoFlowKeyMap = {};
claimStageListForNoFlow.map((stage) => {
  claimStageListForNoFlowKeyMap[stage] = camelCase(stage);
});

export const processFlowStageMap = {};
processFlowStageMap[ProcessFlow.HARD_COPY_AFTER_APPROVAL] = claimStageListForAfterApprovalKeyMap;
processFlowStageMap[ProcessFlow.HARD_COPY_BEFORE_APPROVAL] = claimStageListForBeforeApprovalKeyMap;
processFlowStageMap[ProcessFlow.NO_HARD_COPY] = claimStageListForNoHardCopyKeyMap;
processFlowStageMap[ProcessFlow.NO_FLOW] = claimStageListForNoFlowKeyMap;

export const claimActionModal = {
  saveAndExit: {
    actionName: "Information Saved ✨",
    actionHeroImage: require("@/assets/images/Save_and_Exit_Header_Image_Container.svg"),
    actionDescription: "Your Claim is saved and sound. \nRedirecting you to the Claims list...",
  },
  saveAndNew: {
    actionName: "Opening New 💫",
    actionHeroImage: require("@/assets/images/Save_and_CreateNew_Header_Image_Container.svg"),
    actionDescription: "Your Claim is saved and sound. \nOpening a new empty form for you!",
  },
  duplicate: {
    actionName: "Duplicating 💫",
    actionHeroImage: require("@/assets/images/Duplicate_Claim_Header_Image_Container.svg"),
    actionDescription: "Your Claim is saved and sound. \nDuplicating the claim details...",
  },
};

export const allClaimStageOptionKeys = [
  ...new Set([
    ...Object.values(claimStageListForAfterApprovalKeyMap),
    ...Object.values(claimStageListForBeforeApprovalKeyMap),
    ...Object.values(claimStageListForNoHardCopyKeyMap),
    ...Object.values(claimStageListForNoFlowKeyMap),
  ]),
];

export const orgOnboardingStageLabels = {
  TEAM_SET_UP: "Team Set up",
  POLICY_ADDITIONAL_DATA: "Additional data required",
  INSURER_REVIEW: "Under Insurer Review",
  ISSUE_POLICY_COPY: "Policy Copy Issued",
  ISSUE_ECARDS: "E-cards Issued",
  BENEFIT_ACTIVATION: "Benefits Activation",
};

export const orgOnboardingStageTaskLabels = {
  TEAM_EMPLOYEE_DATA_VERIFICATION: "Verifying Employee Data",
  TEAM_DOCUMENT_VERIFICATION: "Verifying Org Documents",
  POLICY_EMPLOYEE_DATA_VERIFICATION: "Verifying additional Employee Data",
  POLICY_DOCUMENT_VERIFICATION: "Verifying Policy Documents",
  INSURER_REVIEW: "Under Insurer Review",
  ISSUE_POLICY_COPY: "Policy Copy Issued",
  ISSUE_ECARDS: "E-cards Issued",
  BENEFIT_ACTIVATION: "Benefits Activation",
  WELCOME_EMAIL: "Welcome Email",
  PORTAL_ACTIVATION_EMAIL: "Portal Activation Email",
  ECARD_EMAIL: "E-card Linked Email",
};

export const orgOnboardingStageTaskStatusSchema = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  ON_HOLD: "ON_HOLD",
  COMPLETED: "COMPLETED",
  DELETED: "DELETED",
};

export const orgOnboardingStageTaskStatusText = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "Ongoing",
  ON_HOLD: "On Hold",
  COMPLETED: "Completed",
};

export const orgOnboardingStageTaskStatusColorClass = {
  NOT_STARTED: "red-600",
  IN_PROGRESS: "blue-600",
  ON_HOLD: "mustard-600",
  COMPLETED: "teal-700",
};

export const orgOnboardingStageTaskStatusWeight = {
  NOT_STARTED: 0,
  IN_PROGRESS: 2,
  ON_HOLD: 2,
  COMPLETED: 4,
};

export const orgOnboardingStageFilters = {
  org: [{ id: "TEAM_SET_UP", name: "Team Set up" }],
  policy: [
    { id: "POLICY_ADDITIONAL_DATA", name: "Additional data required" },
    { id: "INSURER_REVIEW", name: "Under Insurer Review" },
    { id: "ISSUE_POLICY_COPY", name: "Policy Copy Issued" },
    { id: "ISSUE_ECARDS", name: "E-cards Issued" },
  ],
  benefit: [{ id: "BENEFIT_ACTIVATION", name: "Benefits Activation" }],
};

export const orgOnboardingTaskFilters = {
  TEAM_SET_UP: [
    { id: "TEAM_EMPLOYEE_DATA_VERIFICATION", name: "Verifying Employee Data" },
    { id: "TEAM_DOCUMENT_VERIFICATION", name: "Verifying Org Documents" },
  ],
  POLICY_ADDITIONAL_DATA: [
    { id: "POLICY_EMPLOYEE_DATA_VERIFICATION", name: "Verifying additional Employee Data" },
    { id: "POLICY_DOCUMENT_VERIFICATION", name: "Verifying Policy Documents" },
  ],
};

export const sideNavbarType = {
  ORG_ADMIN: "orgAdmin",
  ADMIN: "admin",
};

export const sideNavbarData = {
  orgAdmin: {
    sideNavLinks: [
      {
        icon: "home-2",
        name: "Home",
        route: "/org-admin",
      },
      {
        icon: "endorsements",
        name: "Onboarding",
        route: "/org-admin/onboarding",
      },
      {
        icon: "employee-id",
        name: "Team",
        route: "/org-admin/members",
      },
      {
        icon: "endorsements",
        name: "Review Changes",
        route: "/org-admin/review-user-changes",
      },
      {
        icon: "endorsements",
        name: "Endorsements",
        route: "/org-admin/changes",
      },
      {
        icon: "claims-2",
        name: "Claims",
        route: "/org-admin/claims",
      },
      {
        icon: "benefits",
        name: "Benefits",
        route: "/org-admin/benefits",
      },
      {
        icon: "organization",
        name: "Organization",
        route: "/org-admin/organization",
      },
      {
        icon: "document",
        name: "Documents",
        route: "/org-admin/files",
      },
      {
        icon: "analytics",
        iconPathCount: 2,
        name: "Analytics",
        route: "/org-admin/analytics",
        divideAfter: true,
      },
    ],
    sideNavFunctions: [
      {
        icon: "support-2",
        name: "Support",
      },
      {
        icon: "sign-out-2",
        variant: "red-300",
        name: "Sign out",
        route: "/login/logout",
      },
    ],
  },
  admin: {
    sideNavLinks: [
      {
        icon: "home-2",
        name: "Home",
        route: "/admin",
      },
      {
        icon: "users-2",
        name: "Users",
        iconPathCount: 3,
        route: "/admin/users",
      },
      {
        icon: "organization",
        name: "Orgs",
        route: "/admin/orgs",
      },
      {
        icon: "benefits",
        name: "Policies & Benefits",
        route: "/admin/policies",
      },
      {
        icon: "policies",
        name: "Insurers & TPAs",
        route: "/admin/insurers",
      },
      {
        icon: "cli-tools",
        name: "CLI Tools",
        route: "/admin/clitools",
      },
      {
        icon: "claims-2",
        name: "Claims",
        route: "/admin/claims",
      },
      {
        icon: "endorsements",
        name: "Endorsements",
        route: "/admin/review",
      },
      {
        icon: "cx-entities",
        name: "CX Entities",
        iconPathCount: 4,
        route: "/admin/CXPods",
      },
      {
        icon: "engineering-2",
        name: "Engineering",
        route: "/admin/jobs",
      },
      {
        icon: "dumbell",
        name: "Fitness",
        route: "/admin/challengeTemplates",
      },
      {
        icon: "segmentation",
        name: "Segmentation",
        route: "/admin/segments",
      },
      {
        icon: "miscellaneous",
        name: "Miscellaneous",
        route: "/admin/content",
        divideAfter: true,
      },
    ],
    sideNavFunctions: [],
  },
};

export const adminActiveTabTopNavData = {
  home: null,
  users: null,
  orgs: [
    {
      route: "/admin/orgs",
      title: "Orgs",
    },
    {
      route: "/admin/orgProperties",
      title: "Org Properties",
    },
    {
      route: "/admin/hr-onboarding",
      title: "HR Onboarding",
    },
    {
      route: "/admin/integrations",
      title: "Integrations",
    },
  ],
  policies: [
    {
      route: "/admin/policies",
      title: "Policies",
    },
    {
      route: "/admin/benefits",
      title: "Benefits",
    },
  ],
  insurers: [
    {
      route: "/admin/insurers",
      title: "Insurers",
    },
    {
      route: "/admin/tpas",
      title: "TPAs",
    },
    {
      route: "/admin/hospitals",
      title: "Hospitals",
    },
  ],
  cliTools: null,
  claims: [
    {
      route: "/admin/claims",
      title: "Claims",
    },
  ],
  endorsements: null,
  cxEntities: [
    {
      route: "/admin/CXPods",
      title: "CX Pods",
    },
    {
      route: "/admin/CXPodMembers",
      title: "CX Pod Members",
    },
    {
      route: "/admin/CXAccountOwners",
      title: "CX Account Owners",
    },
    {
      route: "/admin/CXPodOnboarding",
      title: "CX Pod Onboarding Form",
    },
  ],
  engineering: [
    {
      route: "/admin/jobs",
      title: "Jobs",
    },
    {
      route: "/admin/files",
      title: "Files",
    },
    {
      route: "/admin/samlVendorConfigs",
      title: "Saml Vendor configs",
    },
    {
      route: "/admin/forms",
      title: "Forms",
    },
    {
      route: "/admin/fileGroups",
      title: "File Groups",
    },
  ],
  fitness: [
    {
      route: "/admin/challengeTemplates",
      title: "Challenge Templates",
    },
    {
      route: "/admin/orgChallenges",
      title: "Org Challenges",
    },
    {
      route: "/admin/userChallenges",
      title: "User Challenges",
    },
    {
      route: "/admin/ngageCalendar",
      title: "Ngage Calendar",
    },
  ],
  segmentation: [
    {
      route: "/admin/segments",
      title: "Segments",
    },
    {
      route: "/admin/userSegments",
      title: "User Segments",
    },
  ],
  miscellaneous: [
    {
      route: "/admin/content",
      title: "Content",
    },
    {
      route: "/admin/prospects",
      title: "Prospects",
    },
    {
      route: "/admin/compare-policy",
      title: "Compare Policy",
    },
  ],
};

export const industriesList = [
  "Staffing and Recruiting",
  "Education Administration Programs",
  "Human Resources",
  "Hospitals and Health Care",
  "Retail",
  "Chemical",
  "IT Services and IT Consulting",
  "Consumer Services",
  "Travel Arrangements",
  "Textile Manufacturing",
  "Market Research",
  "Education",
  "Environmental Services",
  "Service Provider",
  "Technology",
  "Retail, E -Commerce",
  "Insurance",
  "Biotechnology Research",
  "Manufacturing",
  "Machinery",
  "Financial Services",
  "Construction, Infrastructure",
  "Restaurants",
  "Business Consulting and Services",
  "Data Infrastructure and Analytics",
  "Human Resources Services",
  "E-Learning Providers",
  "Wholesale",
  "E-learning",
  "Food and Beverage Retail",
  "Internet",
  "Philanthropy",
  "Software Development",
  "Automation Machinery Manufacturing",
  "Hospitality",
  "Wellness and Fitness Services",
  "Engineering",
  "Computer Games",
  "Medical Equipment Manufacturing",
  "Technology, Information and Media",
  "Motor Vehicle Manufacturing",
  "BFSI",
  "Courier , Logistics , Supply Chain ,Packaging , Transport",
  "Civil Engineering",
  "Information Technology & Services",
  "Information Technology (IT)",
  "Computer Software",
  "Technology, Information and Internet",
  "Industrial Machinery Manufacturing",
  "Other",
];
