import { getListQuery, getSingleQuery } from "../queries";
import { CXPodMemberSchema } from "../schemas/CXEntities";
import CXPodMemberMutations from "../mutations/CXPodMembers";

export default {
  gqlType: "CXPodMember",
  fields: ["userEmail", "podName", "role", "status"],
  searchPlaceholder: "Search by User Email",
  singleQuery: getSingleQuery("CXPodMember"),
  listQuery: getListQuery("CXPodMember"),
  ...CXPodMemberSchema,
  ...CXPodMemberMutations,
  transform: (n, write = false) => {
    const payload = { ...n, podName: n.pod?.name, podId: n.pod?.id, userEmail: n.user?.email, userId: n.user?.id };
    if (write) {
      delete payload.user;
      delete payload.pod;
    }
    return payload;
  },
};
