import gql from "graphql-tag";
import {
  orgChallengeFragment,
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertOrgChallenge(
      $id: ID
      $challengeTemplateId: ID!
      $segmentId: ID
      $orgId: ID
      $userId: ID
      $parentChallengeId: ID
      $startDate: String
      $startTime: String
      $expiryDate: String
      $expiryTime: String
      $currentTotal: Float
      $challengeStatus: String
      $meta: JSONObject
      $duration: Float
    ) {
      upsertOrgChallenge(
        input: {
          id: $id
          challengeTemplateId: $challengeTemplateId
          segmentId: $segmentId
          orgId: $orgId
          userId: $userId
          parentChallengeId: $parentChallengeId
          startDate: $startDate
          startTime: $startTime
          expiryDate: $expiryDate
          expiryTime: $expiryTime
          currentTotal: $currentTotal
          challengeStatus: $challengeStatus
          meta: $meta
          duration: $duration
        }
      ) {
        orgChallenge {
          ...OrgChallenge
        }
      }
    }
    ${orgChallengeFragment}
  `,
  bulkDeleteMutation: gql`
    mutation bulkDeleteOrgChallenges(
      $challengeTemplateId: ID!
      $segmentId: ID
      $orgId: ID
    ) {
      bulkDeleteChallenges(
        input: {
          challengeTemplateId: $challengeTemplateId
          segmentId: $segmentId
          orgId: $orgId
        }
      ) {
        success
      }
    }
  `,
}
