<template lang="pug">
.container
  .row
    .col-12
      h4 Customize your Group Health Insurance Plan
      p
        | You can choose a health insurance plan specifically for you and your family.
        | Any changes made will be effective only after approval by the company.
      .table-responsive
        table.table.table-hover.table-striped
          thead.text-center
            tr
              th(scope="col") PARAMETERS
              th(
                scope="col",
                v-for="opt, optName in addons.options",
                :key="optName",
                :class="{'table-active': isSelected(optName)}") {{optName}}
          tbody
            tr(v-for="param, idx in addons.fields", :key="param")
              th.align-middle(scope="row", v-html="param")
              td(
                v-for="opt, optName in addons.options",
                :key="optName",
                :class="{'table-active': isSelected(optName)}", v-html="opt[idx]")
            tr
              th.align-middle Select Plan
              td(
                v-for="opt, optName in addons.options",
                :key="optName",
                :class="{'table-active': isSelected(optName)}")
                b-button(
                  block,
                  :variant="isActive(optName)?'outline-dark-primary':'outline-primary'",
                  :pressed="isSelected(optName)",
                  @click="selectOption(optName, $event)")
                  span(v-if="isActive(optName)") Active Plan
                  span(v-else-if="isSelected(optName)") Selected
                  span(v-else) Select
      p.small
        | #[sup *]E = Employee, S = Spouse, C = Children, P = parents, PIL = Parents in Law
        br
        | #[sup **]Per Annum to be paid by the employee
      b-button(variant="primary", @click="requestPlanChange") Update selection
</template>

<script>
export default {
  data() {
    return {
      activeOption: "BASE PLAN",
      model: {
        option: "BASE PLAN",
      },
      addons: {
        fields: [
          "Sum Insured",
          "Family Structure",
          "Parents Sublimit",
          "Room rent",
          "Maternity Limits (Normal & C-Section)",
          "Pre & Post Natal Expenses within Maternity",
          "Normonal & Immune Therapy",
          "Premium (Excluding GST)",
        ],
        options: {
          "BASE PLAN": [
            "INR 300,000",
            "E+S+2C<sup>*</sup>",
            "N/A",
            "INR 5,000 for normal room and INR 10,000 for ICU",
            "INR 50,000 each",
            "INR 5,000",
            "Not Covered",
            "N/A",
          ],
          "BASE+": [
            "INR 500,000",
            "E+S+2C<sup>*</sup>",
            "N/A",
            "INR 5,000 for normal room and INR 10,000 for ICU",
            "INR 50,000 each",
            "INR 5,000",
            "Not Covered",
            "INR 950<sup>**</sup>",
          ],
          SILVER: [
            "INR 300,000",
            "E+S+2C+2P/PIL<sup>*</sup>",
            "INR 150,000",
            "INR 5,000 for normal room and INR 10,000 for ICU",
            "INR 50,000 each",
            "INR 5,000",
            "Not Covered",
            "INR 2804<sup>**</sup>",
          ],
          GOLD: [
            "INR 400,000",
            "E+S+2C+2P/PIL<sup>*</sup>",
            "INR 200,000",
            "INR 6,000 for normal room and no limit for ICU",
            "INR 60,000 each",
            "INR 5,000",
            "Covered upto INR 75,000",
            "INR 4660<sup>**</sup>",
          ],
          PLATINUM: [
            "INR 500,000",
            "E+S+2C+2P/PIL<sup>*</sup>",
            "INR 300,000",
            "INR 7,000 for normal room and no limit for ICU",
            "INR 65,000 & INR 70,000",
            "INR 7,500",
            "Covered upto INR 100,000",
            "INR 6773<sup>**</sup>",
          ],
        },
      },
    };
  },
  created() {},
  methods: {
    isActive(opt) {
      return opt === this.activeOption;
    },
    isSelected(opt) {
      return this.model.option === opt;
    },
    selectOption(opt, $e) {
      $e.preventDefault();
      this.model.option = opt;
    },
    async requestPlanChange($e) {
      $e.preventDefault();
      this.$store.commit("clearAlerts");
      if (this.model.option !== this.activeOption)
        this.$store.commit("addAlert", {
          variant: "success",
          message:
            "Your plan customization request has been recorded. You will be notified after approval from the company",
        });
      else
        this.$store.commit("addAlert", {
          variant: "warning",
          message: "You have not made any change to your plan selection",
        });
    },
    async createResource(e) {
      e.preventDefault();
    },
  },
};
</script>

<style></style>
