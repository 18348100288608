<template lang="pug">
.user-changes
  .tabs-container.justify-content-start.align-items-center.px-4.py-3(v-if="tabs.length")
    .tab-heading.d-inline-block.cursor-pointer.font-md.font-weight-semibold.text-center.text-gray-800.px-3(
      v-for="tab in tabs.filter((tab) => !tab.hide)",
      @click="activeTab = tab.value",
      :key="tab.value",
      :class="{'active-tab': activeTab === tab.value}"
    )
      span.d-inline-flex.flex-row.align-items-center.align-middle.mr-2 {{tab.title}}&nbsp;
      n-chip(:variant="activeTab === tab.value ? 'primary' : 'secondary'", pill) {{tab.count}}
  .banner-container(:class="tabs.length ? 'mt-n3' : ''")
    banner(
      v-if="totalCount",
      :variant="activeTab",
      :title="bannerData.bannerTitle",
      :subtext="bannerData.bannerSubtext",
      :icon="bannerData.bannerIcon",
      :buttonText="bannerData.buttonText",
      rightImageIcon="chevron-right",
      buttonTextSecondary="Transfer Batches",
      rightImageIconSecondary="swap-horizontal",
      :iconColor="bannerData.iconColor",
      :bannerBackground="bannerData.bannerBackground",
      :exportFlag="bannerData.exportFlag",
      :employeeCount="getMissingEmployeeCount",
      :orgId="orgId",
      :currentTab="activeTab",
      :selectedBatchId="getSelectedBatchId",
      :items="items",
      :total-changes="totalCount",
      :unapproved-data="unapprovedData",
      :modalName="bannerData.modalName",
      :modelNameSecondary="'transfer-batch-modal-for-batch'",
      :exportButtonVariant="bannerData.exportButtonVariant",
      :is-selected-batch-overdue="isSelectedBatchOverdue",
      @refreshTable="$emit('row-updated')",
      :batches="batches",
      @update-batch="$emit('update-batch', $event)",
      :showSecondaryButton="!$route.path.includes(`org-admin/`)",
      :buttonDisabledSecondary="buttonDisabledSecondary",
    )
</template>

<script>
import Banner from "../../endorsements/components/Banner.vue";
import NChip from "@/components/NovaChip.vue";

export default {
  components: {
    NChip,
    Banner,
  },
  props: {
    tabs: {
      type: Array,
      default: [],
    },
    defaultActiveTab: {
      // TODO: Default to 0th index of first tab.
      type: String,
    },
    orgId: {
      type: String,
    },
    selectedBatchId: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    bannerData: {
      type: Object,
    },
    isSelectedBatchOverdue: {
      type: Boolean,
      default: false,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    unapprovedData: {
      type: Object,
      default: () => {},
    },
    batches: {
      type: Array,
      default: () => [],
    },
    buttonDisabledSecondary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: this.defaultActiveTab,
    };
  },
  computed: {
    getMissingEmployeeCount() {
      return this.tabs[0]?.count || 0;
    },
    getSelectedBatchId() {
      return this.$route.params.batchId || this.selectedBatchId;
    },
  },
  watch: {
    activeTab() {
      this.$emit("tab-changed", this.activeTab);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

.tabs-container {
  background-color: $white;
  border: $border-width-custom-1 solid $gray-300;
  align-items: flex-end !important;
  .tab-heading {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
}
.active-tab {
  background: $blue-100;
  color: $blue-600;
  border-radius: 0.5rem;
}
</style>
