<template lang="pug">
  b-modal(v-bind="{...$props, ...$attrs}",
    hide-footer,
    hide-header,
    body-class="special-modal hide-scrollbar",
    content-class="special-content",
    @hide="$emit('hide')"
  )
    .bs-hero(v-if="heroImage")
      img.hero(:src="heroImage")
    .bs-hero(v-if="bgColor", :class="[bgColor ? `bg-${bgColor}` : '']")
    .spl-logo(v-if="logoIcon")
      i(:class="[iconColor ? `icon-${logoIcon} text-${iconColor}` : `icon-${logoIcon}`]")
    .container.content(:class="logoIcon? 'pt-6' : 'pt-5'")
      span.mb-3
        slot(name="title")
          h3.hb3.d-inline.font-weight-semibold.text-gray-900 The special message goes here
        i.ml-2.h5.m-0.align-text-bottom(v-if="headerIcon" :class="`icon-${headerIcon}`")
      p.modal-text-content
        slot(name="modal-content")
          | Enable conditional workflows to ensure your new joinees are covered from Day One.
      slot(name="buttons-area")
        .d-flex.justify-content-center.mt-3
          n-button( button-text="Close")
          n-button.ml-2(variant="tiber" button-text="Try Now!")

</template>

<script>
import NButton from "@/components/NovaButton.vue";

export default {
  name: "SpecialModal",
  components: {
    NButton,
  },
  props: {
    heroImage: {
      type: String,
      default: null,
    },
    headerIcon: {
      type: String,
      default: null,
    },
    logoIcon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: null,
    },
    bgColor: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_variables.scss";


.spl-logo {
  position: absolute;
  left: 50%;
  i {
    left: -50%;
    top: -12px;
    position: relative;
    padding: 1.375rem;
    background: white;
    border: 2px solid $gray-300;
    border-radius: 50%;
    font-size: 2rem;
  }
}

.special-modal {
  max-height: 80vh;
  overflow-y: auto;
  padding: 0 !important;
  position: unset;
  border-radius: 0.4rem;
  @include media-breakpoint-down(sm) {
    border-radius: 0.95rem !important;
  }
  .bs-hero {
    height: 10vh;
    .hero {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
}

.special-content {
  border-radius: 0.5rem;
  @include media-breakpoint-down(sm) {
    border-radius: 1rem !important;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3rem;
  }

  .modal-text-content {
    color: $gray-900;
    font-weight: 500;
  }
}
</style>
