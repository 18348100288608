<template lang="pug">
i(:class="[`icon-${name}`, `text-${variant}`]", :style="`font-size: ${size}rem`", v-on="$listeners")
  template(v-if="pathCount > 1")
    span(v-for="index in pathCount", :class="`path${index}`")
</template>

<script>
export default {
  name: "NovaIcon",
  props: {
    name: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
    },
    pathCount: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
      default: 1.3,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    emitCurrentTab(tab) {
      this.$emit("input", tab);
    },
  },
};
</script>

<style lang="scss"></style>
