import gql from "graphql-tag";
import { dependentFragment } from "./dependent";
import { userBenefitFragmentWithoutContent } from "./userBenefit";
export const claimFragment = gql`
  fragment Claim on Claim {
    id
    meta
    type
    user {
      id
      name
      displayName
      email
      gender
      dob
      dependents {
        ...Dependent
      }
      benefits {
        ...UserBenefitEdgeWithoutContent
      }
      org {
        id
        name
      }
      meta
    }
    policy {
      id
      type
      name
      meta
      insurer {
        id
        name
      }
      tpa {
        id
        name
      }
    }
    dependent {
      ...Dependent
    }
    status
    updatedAt
    createdAt
    displayContent
    timeline
    adminTimeline
    notesTimeline
    internalNotesTimeline
    csat
    claimOwner {
      id
      name
      email
    }
    nbNumber
    createdBy {
      id
      name
    }
    updatedBy {
      id
      name
    }
    createSource
    updateSource
  }
  ${dependentFragment}
  ${userBenefitFragmentWithoutContent}
  ${dependentFragment}
`;

export const claimResourceListFragment = gql`
  fragment Claim on Claim {
    id
    meta
    type
    user {
      id
      name
      displayName
      email
      gender
      dob
      meta
      org {
        id
        name
      }
    }
    policy {
      id
    }
    dependent {
      id
      name
      dob
      relation
      gender
      status
      meta
    }
    createdAt
    updatedAt
    status
    displayContent
  }
`;
