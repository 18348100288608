import { claimStatusListAsCrm, claimCategoryList } from "../../constants";
import utils from "@/utils";
export default {
  schema: {
    fields: [
      {
        model: "meta.claimIntimationNumber",
        type: "inline-input",
        placeholder: "-",
        inputType: "text",
        styleClasses: "d-inline-flex flex-column col-md-6",
        label: "Claim Intimation Number",
        required: false,
      },
      {
        model: "type",
        type: "inline-select",
        reduce: (v) => v.value,
        options: [
          { label: "Cashless", value: "cashless" },
          { label: "Reimbursement", value: "reimbursement" },
        ],
        styleClasses: "d-inline-flex flex-column col-md-6",
        label: `Claim Type<span style="color: red">*</span>`,
        validator: [utils.validateInlineSelect, "required"],
        searchable: false,
        placeholder: "Select Cashless/Reimbursement",
        required: true,
      },
      {
        model: "meta.claimNumber",
        type: "inline-input",
        placeholder: "-",
        inputType: "text",
        styleClasses: "d-inline-flex flex-column col-md-6",
        label: "Claim Number",
      },
      {
        model: "meta.crm.claimCategory",
        type: "inline-select",
        options: [],
        styleClasses: "d-inline-flex flex-column col-md-6",
        label: `Claim Category<span style="color: red">*</span>`,
        validator: [utils.validateInlineSelect, "required"],
        placeholder: "-None-",
        required: true,
      },
      {
        model: "meta.crm.claimStatus",
        type: "inline-select",
        options: claimStatusListAsCrm,
        styleClasses: "d-inline-block col-md-6",
        label: `Claim Status<span style="color: red">*</span>`,
        validator: [utils.validateInlineSelect, "required"],
        placeholder: "-None-",
        required: true,
        disabled: true,
        permanentlyDisabled: true,
      },
      {
        model: "policy.meta.startDate",
        type: "datepicker",
        styleClasses: "d-inline-block col-md-6",
        label: "Policy Start Date",
        placeholder: "DD/MM/YYYY",
        required: false,
        readonly: true,
      },
      {
        model: "meta.crm.processFlowHardCopy",
        type: "inline-select",
        styleClasses: "d-inline-block col-md-6",
        label: `Process Flow<span style="color: red">*</span>`,
        placeholder: "eg. Hard Copy details",
        validator: [utils.validateInlineSelect, "required"],
        required: true,
      },
      {
        model: "policy.meta.endDate",
        type: "datepicker",
        styleClasses: "d-inline-block col-md-6",
        label: "Policy End Date",
        placeholder: "DD/MM/YYYY",
        readonly: true,
      },
      {
        model: "meta.crm.claimStage",
        type: "inline-select",
        styleClasses: "d-inline-block col-md-6",
        label: `Claim Stage<span style="color: red">*</span>`,
        validator: [utils.validateInlineSelect, "required"],
        required: true,
        placeholder: "-None-",
      },
    ],
  },
};
