import gql from "graphql-tag";

export const strapiContentFragment = gql`
  fragment StrapiContent on StrapiContent {
    id
    slug
    dynamicContent
    meta
  }
`;

export const contentFragment = gql`
  fragment Content on Content {
    id
    name
    slug
    type
    content
    meta
  }
`;
