import gql from "graphql-tag";

export const formFragment = gql`
  fragment Form on Form {
    id
    slug
    type
    meta
  }
`;
