<template>
  <BaseLayout>
    <div class="content">
      <div class="container-fluid">
        <div class="row error-page-content">
          <div
            class="col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center my-auto"
          >
            <h2 class="title text-danger">{{ error.title }}</h2>
            <h2 class="title">{{ error.message }}</h2>
            <b-button :to="error.linkTo" size="lg" variant="outline">{{
              error.linkTitle
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from "./Base.vue";
export default {
  components: {
    BaseLayout,
  },
  computed: {
    error() {
      let title = "404 Not Found";
      let message = "Oops! Looks like this page does not exist.";
      let linkTo = "/";
      let linkTitle = "Go to Homepage";
      if (this.$route.params.type === "unauthorized") {
        title = "Unauthorized";
        message =
          "Seems like you are not logged in or do not have permissions to access this page.";
        linkTo = "/login";
        linkTitle = "Go to Login";
      }
      return {
        title,
        message,
        linkTo,
        linkTitle,
      };
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.error-page-content {
  min-height: 50vh;
}
</style>
