<template lang="pug">
.container
  .bg-purple-100.rounded-3.row.py-5
    .col.d-flex.justify-content-center.px-md-2.family-img-container
      img.family-img(:src="require('@/assets/images/family-picture 1.svg')")
    .col-6.d-flex.flex-column.justify-content-center.px-md-w.main-container
      .font-xl.font-weight-semibold.text-purple-900.text-center {{ getCoverageContent.heading }}
      .font-sm.text-purple-700.font-weight-medium.mt-3.text-center {{ getCoverageContent.subtext }}
      n-button.mt-3.dependent-button(
        variant="secondary2",
        buttonText="Add dependents",
        imageIcon="plus-circle",
        :disabled="disabled",
        @click="$emit('add-dependent', $event)")
    .col.d-flex.justify-content-center.px-md-2.family-img-container
      img.family-img(:src="require('@/assets/images/grandparents 1.svg')")
</template>

<script>
import NButton from "@/components/NovaButton.vue";

export default {
  name: "EmptyStateDependents",
  components: {
    NButton,
  },
  props: {
    coveredDependents: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getCoverageContent() {
      const coverageContent = {
        heading: "Introduce your dependents!",
        subtext:
          "Add dependent details here. They will be automatically covered, when your company extends insurance and benefits availability",
      };
      if (this.coveredDependents) {
        coverageContent.heading = `Your Insurance policy covers your ${this.coveredDependents}`;
        coverageContent.subtext = "Add dependents to your policy to ensure they are taken care of during emergencies";
      }
      return coverageContent;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_misc.scss";

@media (max-width: 1010px) {
  .family-img-container {
    display: none !important;
  }
  .main-container {
    max-width: 100%;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100%;
  }
  .container {
    height: auto !important;
  }
}
.family-img-container {
  .family-img {
    width: 6.875rem;
  }
}
.container {
  height: 60vh;
}
.dependent-button {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}
</style>
