import gql from "graphql-tag";

export default {
  starterForm: {
    schema: {
      fields: [
        {
          model: "name",
          type: "input",
          inputType: "text",
          label: "Your Name",
          placeholder: "Enter your name here",
          required: true,
        },
        {
          model: "companyName",
          type: "input",
          inputType: "text",
          label: "Company Name",
          placeholder: "Enter your company's name here",
          required: true,
        },
        {
          model: "employeeCount",
          type: "input",
          inputType: "text",
          label: "Number of Employees",
          placeholder: "Enter the number of employees",
          required: true,
        },
      ],
      groups: [
        {
          fields: [
            {
              model: "email",
              type: "input",
              inputType: "email",
              label: "Your Email",
              placeholder: "Enter your email here",
              required: true,
            },
            {
              type: "label",
              label: "A professional email is preferred",
              model: "emailSubText",
            },
          ],
        },
        {
          fields: [
            {
              model: "mobile",
              type: "input",
              inputType: "text",
              label: "Your Mobile Number",
              placeholder: "Enter your mobile number here",
              required: true,
            },
            {
              type: "label",
              label:
                "Don't worry, we don't spam or share your information with anyone",
              model: "mobileSubText",
            },
          ],
        },
        {
          fields: [
            {
              model: "companyRole",
              label: "Your Role",
              type: "select",
              values: ["Founder", "HR", "Finance", "Other"],
              selectOptions: {
                noneSelectedText: "Select your role",
              },
              required: true,
            },
            {
              type: "label",
              label:
                "Please tell us about your primary role at the organization",
              model: "companyRoleSubText",
            },
          ],
        },
      ],
    },
    createFormResponse: gql`
      mutation CreateFormResp(
        $meta: JSONObject!
        $formId: ID
        $formSlug: String
      ) {
        upsertFormResponse(
          input: { meta: $meta, formId: $formId, formSlug: $formSlug }
        ) {
          formResponse {
            id
            meta
            __typename
          }
          __typename
        }
      }
    `,
  },
};
