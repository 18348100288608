import { getListQuery, getSingleQuery } from "../queries";
import tpasSchema from "../schemas/tpas";
import tpasMutations from "../mutations/tpas";

export default {
  gqlType: "Tpa",
  singleQuery: getSingleQuery("Tpa"),
  listQuery: getListQuery("Tpa"),
  fields: ["name"],
  ...tpasSchema,
  ...tpasMutations,
  transform: (n) => n,
}
