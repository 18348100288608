<template lang="pug">
.wrapper
  .main-panel.full-page
    .container.mb-4
      .row
        .col-md-8.offset-md-2.col-lg-6.offset-lg-3
          .py-4
            //- temporary link to unicorn/webflow site
            //- change to "/" once we're back on the inhouse website
            b-link.text-decoration-none(href="https://novabenefits.com")
              i.icon-nova-basic.nova-icon
          .pb-3.get-started-text.font-hc let's get started
          h5.text-title Is your company buying insurance for the first time?
          .small-text-option-title.font-weight-medium Select any of the options to continue
          Card(icon="description", text="Yes, we’re buying insurance for the first time", @cardClick="YesClicked")
          Card(icon="renew", text="No, we’re renewing our insurance policy", @cardClick="NoClicked")
          div(v-if="showContinueOption")
            hr
            Card(icon="clock", text="Continue where we left off last time", @cardClick="ContinueClicked")
    Logos.logo-class(:logos="logosArray")
  renewal-form-modal()
</template>

<script>
import utils from "@/utils";
import Card from "./components/Card.vue";
import Logos from "./components/Logos.vue";
import RenewalFormModal from "./components/RenewalFormModal.vue";
import resDefs from "./definitions";

export default {
  name: "GetStarted",
  components: {
    Card,
    Logos,
    RenewalFormModal,
  },
  data() {
    return {
      resDef: resDefs.prospects,
      logosArray: ["snapdeal", "dream11", "coindcx", "kubric"],
    };
  },
  computed: {
    showContinueOption() {
      return !!this.$store.state.prospectId;
    },
  },
  methods: {
    async YesClicked() {
      this.$store.commit("clearAlerts");
      try {
        const result = await this.$apollo.mutate({
          mutation: this.resDef.upsertMutation,
          variables: {},
        });
        const resId = result.data.upsertProspect.prospect.id;
        const prospectId = await utils.toNanoId(this, resId);
        this.$store.commit("saveProspectId", prospectId);
        this.$router.push(`/policy-calculator/workspace/${prospectId}`);
      } catch (err) {
        console.log(err);
      }
    },
    async ContinueClicked() {
      this.$store.commit("clearAlerts");
      try {
        const prospectId = this.$store.state.prospectId;
        this.$router.push(`/policy-calculator/workspace/${prospectId}`);
      } catch (err) {
        console.log(err);
      }
    },
    NoClicked() {
      this.$bvModal.show("renewal-form-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.full-page {
  background-color: $tiber;
}

.nova-icon {
  color: $white;
  font-size: 45px;
  opacity: 25%;
}

.get-started-text {
  color: $mustard-500;
}

.text-title {
  font-size: 28px;
  color: $white;
}

.logo-class {
  padding-bottom: 1rem;
}

.small-text-option-title {
  margin-top: 2.5rem;
  color: $gray-400;
}
</style>
