<template lang="pug">
  .claims
    n-spinner(:active="$apollo.loading")
    .row.claims(v-if="noClaims")
      .col-md-12
        .bg-mustard-200.mb-4.position-relative.py-4.rounded-3
          .hanging-pot.d-none.d-md-block
            img(:src="require(`@/assets/images/hanging-pot.svg`)")
          .col-md-5.text-center.mx-auto.py-5
            h1.hb1.text-blackpearl You don't have any claims with us so far
            p.text-mustard-900.font-weight-medium.font-lg
              | Once you submit a claim, it can be tracked from this page
            n-button.w-50(variant="primary", buttonText="Start a claim", :to="{name: 'start-claim'}")
          .plant-nu.d-none.d-md-block
            img(:src="require(`@/assets/images/plant-nu-2.svg`)")
      .col-md-7(v-if="relationshipManager && getFeatureFlags['RM_CARD']")
        r-m-card.rm-card-container(
          :name="relationshipManager.name",
          variant = "horizontal",
          :photoFile="relationshipManager.photoFile")
      div(:class="relationshipManager && getFeatureFlags['RM_CARD'] ? 'col-md-5': 'col-md-12'")
        b-card.rounded-2.mb-4.learn-more-card.shadow-sm
          b-link.stretched-link(:to="{name:'start-claim'}")
          .d-flex.justify-content-between.align-items-center
            .d-flex.align-items-center
              b-avatar.bg-mustard-100(varinat="none")
                i.icon-document.font-lg.text-mustard-600
              .ml-3.p-0
                h5.my-0.font-weight-semibold.text-gray-900 Learn more about claims
                p.m-0 Click here to learn more about the claim process
            .px-0.pr-1
              i.fa.fa-chevron-right

      .col-md-4.p-2
        .border-top.p-2
          b-card.my-3.px-2.py-3(no-body)
            .d-flex.justify-content-start.align-items-center
              span.icon-widgets.mr-2.text-blue-400(style="margin-top:-5px;")
              span.font-hergon.text-gray-900.font-weight-semibold.font-lg Types of Claims
          p.text-gray-800.mb-4
            | Every health insurance plan has two types of claim settlement process – cashless and reimbursement.
          span.text-gray-900.font-weight-semibold Cashless Claim Settlement
          p.mt-2.mb-4
            | Under a cashless claim settlement process, when you are admitted in a network hospital,
            | the insurance company directly settles your medical bills with the hospital, hence the name.
          span.text-gray-900.font-weight-semibold Reimbursement Claim Settlement
          p.mt-2
            | Here you have to first shoulder your medical bills.
            | After you get treated and are discharged from the hospital,
            | you have to submit the relevant bills to the insurance company.
            | The company then reimburses the medical costs you incurred.
      .col-md-4.p-2
        .border-top.p-2
          b-card.my-3.px-2.py-3(no-body)
            .d-flex.justify-content-start.align-items-center
              span.icon-claims-1.mr-2.text-blue-400(style="margin-top:-4px;")
              span.font-hergon.text-gray-900.font-weight-semibold.font-lg How to file a claim?
          p.text-gray-800.mb-4
            | The first way—and the most convenient—is when your Hospital or treatment provider can
            | submit the claim directly to the insurance company. They do this through the network, electronically.
          p.text-gray-800
            | The other way is by completing the claim form and sending the paperwork to the insurance company yourself.
            | This situation can arise if your hospital is not in the network for your insurer or can't file it on
            | your behalf. Then you will have to file the claim to request payment for the medical services you obtained.
      .col-md-4.p-2.mb-5
        .border-top.p-2
          b-card.my-3.px-2.py-3(no-body)
            .d-flex.justify-content-start.align-items-center
              span.icon-check-circle.mr-2.text-blue-400(style="margin-top:-4px;")
              span.font-hergon.text-gray-900.font-weight-semibold.font-lg Document checklist for filing claims
          p.text-gray-800
            | Here’s a general list of documents needed to file a claim.
            | Certain other documents may also be requested based on treatment.
          ul.claim-process-timeline
            li.text-gray-800
              i.icon-check-circle.text-blue-400
              p Claim Form
              p
                b Part A -
                | Duly completed by the insured on the prescribed format
              p
                b Part B -
                | Filled by the Hospital
            li.text-gray-800(v-for="doc, index) in docChecklist", :key="index")
              i.icon-check-circle.text-blue-400
              p {{ doc }}
    div(v-else)
      span.font-hc Ongoing Claims
      .container
        .row.mt-4(v-if="activeClaims.length === 0")
          .col-md-12.bg-mustard-200.mb-4.position-relative.py-4.rounded-3
            .hanging-pot.d-none.d-md-block
              img(:src="require(`@/assets/images/hanging-pot.svg`)")
            .col-md-5.text-center.mx-auto.py-5
              h1.hb1.text-blackpearl You do not have any ongoing claims
              p.text-mustard-900.font-weight-medium.font-lg
                | Once you submit a claim, it can be tracked from this page
              n-button.w-50(variant="primary", buttonText="Start a claim", :to="{name: 'start-claim'}")
            .plant-nu.d-none.d-md-block
              img(:src="require(`@/assets/images/plant-nu-2.svg`)")
        .row.ongoing-claim-card.px-0.mt-4.overflow-hidden.rounded-3.shadow-sm(
          v-else,
          v-for="claim in activeClaims",
          v-bind:key="claim.id")
          .col-md-5.p-0.bg-white
            .d-flex.align-items-center.p-3(:class="claim.displayContent.header_bg")
              b-avatar(:class="claim.displayContent.icon_bg", variant="none", size="3rem")
                i.text-white(:class="`icon-${claim.displayContent.icon}`")
              .d-flex.flex-column.ml-3
                span.font-hc(:class="claim.displayContent.headingColor", style="opacity:0.5;")
                  | step {{getCurrStatusLineNo(claim.status, claim.type) + 1}}/
                  | {{claimStatusLine(claim.type).length}} |
                  | {{ claimStatusLine(claim.type)[getCurrStatusLineNo(claim.status,claim.type)] }}
                span.font-weight-medium.font-lg(:class="claim.displayContent.headingColor") {{ claim.displayContent.heading }}
            .row.px-4.pb-4
              .col-md-6.pt-4
                span.font-weight-medium.text-gray-700 Patient Name
                .d-flex.align-items-center.mt-2
                  n-avatar(
                    :size="2",
                    :name="claim.dependent ? claim.dependent.name : claim.user.name",
                  )
                  .d-flex.flex-column.ml-3
                    span.text-gray-900.font-weight-medium
                      | {{claim.dependent?claim.dependent.name:claim.user.name}}
                    span.text-gray-700.font-sm.text-capitalize.font-weight-medium
                      | {{ claim.dependent?claim.dependent.relation:'self'}}

              .col-md-6.pt-4
                span.font-weight-medium.text-gray-700 Claim Type
                .d-block
                  span.d-inline-flex.align-items-center.mt-2.badge-pill.py-1.font-sm(v-if="claim.type", :class="{\
                    'bg-purple-100': claim.type==='reimbursement',\
                    'text-purple-600': claim.type==='reimbursement',\
                    'bg-malibu-100': claim.type==='cashless',\
                    'text-malibu-800': claim.type==='cashless',\
                  }")
                    i.mr-1(:class="{\
                      'icon-money': claim.type==='reimbursement',\
                      'icon-institution': claim.type==='cashless'\
                    }")
                    span.font-weight-semibold.text-capitalize {{ claim.type }}
                  span.py-1.font-sm(v-else) -
              .col-md-6.pt-4
                span.font-weight-medium.text-gray-700 Hospital
                .d-flex.align-items-center.mt-2
                  .d-flex.flex-column
                    span.text-gray-900.font-weight-medium {{ claim.meta.hospital }}
                    span.text-gray-700.font-sm {{ claim.meta.city }}

              .col-md-6.pt-4
                span.font-weight-medium.text-gray-700 Policy
                .d-flex.flex-column
                  .d-flex.align-items-center.mt-2
                    i.icon-hospital.text-red-500.mr-1
                    span.text-gray-900.font-weight-medium.text-truncate(
                      v-b-tooltip.hover,
                      :title="getPolicyType(claim.policy.type)")
                      | {{ getPolicyType(claim.policy.type) }}
                  span.text-gray-700.font-sm {{ claim.policy.insurer.name }}

          .col-md-7.px-md-5.py-4.d-flex.flex-column.justify-content-between
            div
              span.font-hc.text-gray-500 About
              p.text-gray-900.font-weight-semibold {{ claim.displayContent.subheading }}
              p.text-gray-900.font-weight-medium {{ claim.displayContent.content }}
            div
              .position-relative
                hr.mt-5(style="border-top: 2px solid rgba(0,0,0,0.1) !important;")
              .d-flex.justify-content-between.align-items-center
                n-button.px-5(variant="dark", buttonText="Need help?", @click="showSalesIqChat")
                n-button(
                  variant="outline-secondary",
                  buttonText="View Claim Details",
                  rightImageIcon="chevron-right",
                  :to="{ name: 'claim-details', params: { id: claim.id }}")

        .row
          hr.my-4.w-100(style="border-top: 2px solid rgba(0,0,0,0.1) !important;")
          span.col-12.font-hc.px-0.mb-4(v-if="previousClaims.length > 0") Previous Claims
          .col-12.px-0(v-for="claim in previousClaims", v-bind:key="claim.id")
            div
              b-card.rounded-2
                b-link.stretched-link(:to="{ name: 'claim-details', params: { id: claim.id }}")
                .row
                  .col-12.col-md.mt-1.mt-md-0
                    span.text-gray-700.font-weight-semibold Patient Name
                    .d-flex.align-items-center.mt-2
                      n-avatar(
                        :size="2",
                        :name="claim.dependent ? claim.dependent.name : claim.user.name",
                      )
                      .d-flex.flex-column.ml-3.font-weight-medium
                        span.text-gray-900.font-md
                          | {{claim.dependent?claim.dependent.name:claim.user.name}}
                        span.text-gray-700.font-sm.text-capitalize
                          | {{ claim.dependent?claim.dependent.relation:'self'}}
                  .col-6.col-md.mt-3.mt-md-0
                    span.text-gray-700.font-weight-semibold Claim Type
                    .d-block.font-weight-medium
                      span.d-inline-flex.align-items-center.mt-2.badge-pill.py-1.font-sm(v-if="claim.type", :class="{\
                        'bg-purple-100': claim.type==='reimbursement',\
                        'text-purple-600': claim.type==='reimbursement',\
                        'bg-malibu-100': claim.type==='cashless',\
                        'text-malibu-800': claim.type==='cashless',\
                      }")
                        i.mr-1(:class="{\
                          'icon-money': claim.type==='reimbursement',\
                          'icon-institution': claim.type==='cashless'\
                        }")
                        span.font-weight-semibold.text-capitalize {{ claim.type }}
                      span.py-1.font-sm(v-else) -
                  .col-6.col-md.mt-3.mt-md-0
                    span.text-gray-700.font-weight-semibold Hospital
                    .d-flex.align-items-center.mt-2
                      .d-flex.flex-column.font-weight-medium
                        span.text-gray-900.font-md {{ claim.meta.hospital }}
                        span.text-gray-700.font-sm {{ claim.meta.city }}
                  .col-12.col-md.mt-3.mt-md-0
                    span.text-gray-700.font-weight-semibold Policy
                    .d-flex.flex-column.font-weight-medium
                      .d-flex.align-items-center.mt-2
                        i.icon-hospital.text-red-500.mr-1
                        span.text-gray-900.font-md.text-truncate(
                          v-b-tooltip.hover,
                          :title="getPolicyType(claim.policy.type)")
                            | {{ getPolicyType(claim.policy.type) }}
                      span.text-gray-700.font-sm.text-truncate
                        | {{ claim.policy.insurer.name }}
                  .col-12.col-md.mt-3.mt-md-0
                    span.text-gray-700.font-weight-semibold Claim Status
                    .d-flex.align-items-center.mt-2
                      .d-flex.flex-column.font-weight-medium
                        span.text-gray-900.font-md
                          | {{ claim.status ==="REJECTED"?'Rejected':'Claim Settled' }}
                        span.text-gray-700.font-sm On {{getFormattedDate(claim.updatedAt)}}

        .row.mt-4
          .col-md-6.pl-0.pr-0.pr-md-2.mb-4
            b-card.rounded-2.gray-cards.shadow-sm
              b-link.stretched-link(:to="{name: 'start-claim'}")
              .d-flex.justify-content-between.align-items-center
                .d-flex.align-items-center
                  b-avatar.bg-gray-200(varinat="none")
                    i.icon-claims-1.font-lg.text-gray-800
                  .ml-3.p-0
                    h5.hb5.my-0.text-gray-900 Start a claim
                    p.m-0.font-weight-medium.text-gray-700 Click here to begin the claim process
                .px-0.pr-1
                  i.fa.fa-chevron-right
          .col-md-6.pl-0.pl-md-2.pr-0.mb-4
            b-card.rounded-2.mb-4.gray-cards.shadow-sm
              b-link.stretched-link(
                :to="this.claimProcessContentId?`/claim-process/${this.claimProcessContentId}`:'/claim-process'")
              .d-flex.justify-content-between.align-items-center
                .d-flex.align-items-center
                  b-avatar.bg-gray-200(varinat="none")
                    i.icon-document.font-lg.text-gray-800
                  .ml-3.p-0
                    h5.hb5.my-0.text-gray-900 Learn more about claims
                    p.m-0.font-weight-medium.text-gray-700 Click here to learn more about the claim process
                .px-0.pr-1
                  i.fa.fa-chevron-right
        .row.mt-2
          .col-md-5.px-0
            h4.hb4.text-gray-900 Learn more about filing claims
            p.font-weight-medium.text-gray-700
              | You can find more relevant material related to claims in the FAQs section on the&nbsp;
              b-link(@click="showSalesIqChat") support page
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import moment from "moment";
import userDefs, { claimStatusLine, getCurrStatusLineNo } from "../definitions";
import { userBenefitFragment, relationshipManagerFragment } from "../../admin/fragments";
import utils from "@/utils";
import NSubmitButton from "@/components/SubmitButton";
import NButton from "@/components/NovaButton";
import NAvatar from "@/components/Avatar";
import NUploadComponent from "@/components/FileUpload";
import RMCard from "@/components/RMCard.vue";

export default {
  components: {
    NButton,
    NAvatar,
    NSubmitButton,
    NUploadComponent,
    RMCard,
  },
  data() {
    return {
      show: false,
      userId: null,
      claims: null,
      totalCount: null,
      activeClaims: [],
      previousClaims: [],
      claimStatusLine: claimStatusLine,
      docChecklist: [
        "Aadhar Card copy of the primary insurer and the patient",
        "Pan Card copy of the primary insurer",
        "TPA ID Card of the primary insurer and the patient",
        "Detailed Discharge Summary",
        "Hospital Main bill",
        "Break-up bill for the Hospital Main bill",
        "Receipt for the amount collected from the patient",
      ],
      relationshipManager: {},
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    noClaims() {
      if (this.claims) {
        return this.claims.length < 1;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.$store.commit("updateSectionHeader", "Your Claims");
    this.$apollo.queries.claims.refetch();
  },
  methods: {
    getFormattedDate(date) {
      return utils.getFormattedDate(date);
    },
    showSalesIqChat() {
      this.$store.commit("toggleSupportContainer");
    },
    getPolicyType(policyType) {
      return utils.getPolicyType(policyType);
    },
    claimContent(status) {
      return utils.claimContentByStatus(status);
    },
    getCurrStatusLineNo(status, type) {
      return getCurrStatusLineNo(status, type);
    },
  },
  apollo: {
    userId: {
      query: gql`
        query LoggedInUser {
          me {
            id
          }
        }
      `,
      update(data) {
        return data.me.id;
      },
    },
    relationshipManager: {
      query: gql`
        query LoggedInUser {
          me {
            id
            org {
              id
              relationshipManager {
                ...RelationshipManager
              }
            }
          }
        }
        ${relationshipManagerFragment}
      `,
      update(data) {
        return data?.me?.org?.relationshipManager;
      },
    },
    claimProcessContentId: {
      query: gql`
        query GmcClaimProcessContent {
          me {
            id
            benefits {
              ...UserBenefitEdge
            }
          }
        }
        ${userBenefitFragment}
      `,
      update(data) {
        const gmcPolicy = data.me.benefits.find((p) => p.node.type === "gmc") || data.me.benefits[0];
        return gmcPolicy?.node?.id;
      },
    },
    claims: {
      query: userDefs.claims.listQuery,
      variables() {
        return { userId: this.userId };
      },
      skip() {
        return !this.userId;
      },
      update(data) {
        this.totalCount = data.claims.totalCount;
        const items = data.claims.edges.map(({ node }) => userDefs.claims.transform(node));
        this.activeClaims = [];
        this.previousClaims = [];
        items.forEach((claim) => {
          const lastUpdate = moment(claim.updatedAt);
          const dateDiff = Math.abs(lastUpdate.diff(moment(), "days"));
          if (claim.status === "DELETED") {
          } else if ((dateDiff > 30 && claim.status === "REJECTED") || claim.status === "CLAIM_SETTLED") {
            this.previousClaims.push(claim);
          } else {
            this.activeClaims.push(claim);
          }
        });
        return items;
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.claims .claim-process-timeline {
  margin: 0;
  padding: 0;
  margin-top: 2rem;
  border-radius: 12px;
  position: relative;
  list-style: none;
}
.claim-process-timeline li {
  padding-bottom: 1.5rem;
  border-left: 1px solid $gray-400;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
  &:last-child {
    border: 0px;
    padding-bottom: 0;
  }
  i {
    position: absolute;
    left: -0.67rem;
    line-height: 0.85;
    background-color: $portal-bg;
  }
}

.start-claim-card {
  border-left: 6px solid $malibu-500 !important;
}

.learn-more-card {
  border-left: 6px solid $mustard-500 !important;
}

.gray-cards {
  border-left: 6px solid $gray-300 !important;
}

.hanging-pot {
  position: absolute;
  right: 0;
  top: 0;
}

.plant-nu {
  position: absolute;
  left: 0;
  bottom: 0;
}

.ongoing-claim-card {
  border: 1px solid #e5ecfb;
}
</style>
