<template lang="pug">
.container.mobile-nav.d-md-none
  .row
    .col-sm-12.p-0
      b-card.mt-3.w-100(no-body)
        b-card-body.w-100.p-0
          .d-flex.justify-content-between.align-items-center.p-3.cursor-pointer.hover(
            @click="navigateTo('/user/profile/details')")
            .d-flex.align-items-center
              n-icon.mx-1(name="human-head", :size="1.5", variant="gray-900")
              .d-flex.flex-column.align-items-baseline.ml-3
                span.font-weight-semibold.text-gray-900.text-capitalize Basic Details
                span.font-weight-medium.text-gray-700 Name, DoB, Gender
            i.icon-chevron-right
          .d-flex.justify-content-between.align-items-center.p-3.cursor-pointer.hover(
            @click="navigateTo('/user/profile/about')")
            .d-flex.align-items-center
              n-icon.mx-1(name="men", :size="1.5", variant="gray-900")
              .d-flex.flex-column.align-items-baseline.ml-3
                span.font-weight-semibold.text-gray-900.text-capitalize About you
                span.font-weight-medium.text-gray-700 City, PIN, Marital Status
            i.icon-chevron-right
          .d-flex.justify-content-between.align-items-center.p-3.cursor-pointer.hover(
            @click="navigateTo('/user/profile/contact-details')")
            .d-flex.align-items-center
              n-icon.mx-1(name="at-the-rate", :size="1.5", variant="gray-900")
              .d-flex.flex-column.align-items-baseline.ml-3
                span.font-weight-semibold.text-gray-900.text-capitalize Contact Details
                span.font-weight-medium.text-gray-700 Email, Phone Number
            i.icon-chevron-right
          .d-flex.justify-content-between.align-items-center.p-3.cursor-pointer.hover(
            @click="navigateTo('/user/profile/security')")
            .d-flex.align-items-center
              n-icon.mx-1(name="key", :size="1.5", variant="gray-900")
              .d-flex.flex-column.align-items-baseline.ml-3
                span.font-weight-semibold.text-gray-900.text-capitalize Security
                span.font-weight-medium.text-gray-700 Password Reset
            i.icon-chevron-right

  .row
    .col-sm-12.p-0
      b-card.mt-3.w-100.p-0(no-body)
        b-card-body.w-100.p-0.p-0
          .d-flex.justify-content-between.align-items-center.p-3.cursor-pointer.hover(
            @click="navigateTo('/user/dependents')")
            .d-flex.align-items-center
              n-avatar(variant="none", icon="family", bgColor="purple-100", iconColor="purple-600")
              .d-flex.flex-column.align-items-baseline.ml-3
                span.font-weight-semibold.text-gray-900.text-capitalize Dependents
                span.font-weight-medium.text-gray-700.text-capitalize
                  | {{ user.dependents ? user.dependents.length : '0' }}&nbsp;
                  | Dependent{{user.dependents && user.dependents.length > 1?'s':''}}
            i.icon-chevron-right

  .row.mt-3(v-if="isAdmin")
    .col-sm-12.p-0
      n-button.w-100(
        variant="outline-primary",
        imageIcon="star",
        buttonText="Super Admin",
        to="/admin")

  .row.mt-3
    .col-sm-12.p-0
      n-button.w-100(
        variant="outline-danger",
        imageIcon="sign-out",
        buttonText="Sign Out",
        to="/login/logout")
</template>

<script>
import { mapGetters } from "vuex";
import NButton from "@/components/NovaButton.vue";
import NAvatar from "@/components/Avatar.vue";

export default {
  components: {
    NButton,
    NAvatar,
  },
  data() {
    return {
      user: {},
      route: null,
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
  mounted() {
    if(!this.$route.path==='/reset-password') {
      this.$store.commit("updateNavHeader", {
        name: "Your Account",
        icon: null,
      });
    }

    this.route = this.$route.path;
    this.user = this.$store.state.user;
    const detailsUrl = "/user/profile/details";
    if (window.innerWidth > 767 && this.$route.path !== detailsUrl) {
      this.$router.push(detailsUrl);
    }
  },
  methods: {
    navigateTo(url) {
      if (this.$route.path !== url) {
        this.$router.push(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.mobile-nav .bg-purple-100 {
  background-color: $purple-100;
}
.mobile-nav .c-purple-600 {
  color: $purple-600;
}
.mobile-nav .hover:hover {
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
