import { getListQuery } from "../queries";
import utils from "@/utils";

export default {
  schema: {
    fields: [
      {
        model: "type",
        type: "select",
        label: "Benefit Type",
        values: ["other", "free-coupon", "partner-signup", "embeded-partners", "sso-partners"],
        fieldClasses: ["form-select"],
        required: true,
      },
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Benefit Name",
        required: true,
      },
      {
        model: "tag",
        type: "select",
        label: "Tag",
        values: ["Free", "Paid"],
        fieldClasses: ["form-select"],
      },
      {
        model: "isPolicy",
        label: "Is Policy",
        type: "checkbox",
        default: false,
        visible: false,
      },
      {
        type: "checklist",
        model: "restrictedFields",
        label: "Restricted Fields",
        values: ["name", "dob", "gender"],
        listBox: true,
      },
      {
        model: "content",
        type: "id-selector",
        gqlQuery: getListQuery("StrapiContent"),
        queryVariables: () => ({
          filter: {
            collectionType: "Benefit",
          },
        }),
        label: "Benefit Details Content (from strapi)",
        nodesFromData: utils.getOptionsMapperForStrapi("strapiContents"),
        getLink: utils.getLinkMapperForStrapi("Benefit"),
        required: true,
        validator: "required",
      },
      {
        model: "meta",
        type: "object",
        label: "Benefit Meta",
        schema: {
          fields: [
            {
              type: "switch",
              label: "Is Flexi?",
              model: "isFlexi",
              default: false,
            },
            {
              model: "provider",
              label: "Provider Name",
              type: "input",
              inputType: "text",
              required: true,
            },
            {
              model: "couponCode",
              label: "Coupon Code (if applicable)",
              type: "input",
              inputType: "text",
            },
            {
              model: "providerApp",
              label: "Provider Website",
              type: "input",
              inputType: "text",
              hint: "Please keep https:// in the url!",
            },
          ],
        },
      },
    ],
  },
};
