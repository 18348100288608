<template lang="pug">
b-card.p-3(no-body)
  .row.p-0
    .col-md-3.col-sm-12.border-right.p-0.pl-3.d-none.d-md-block.pt-3
      h5.font-hc.text-gray-600 Edit Details
      profile-nav
    .reset-password-banner.col-md-8.ml-4.d-flex.justify-content-between.p-4(v-if="showResetPasswordButton")
      .text-container
        h4.heading.font-weight-medium.hb4 Password
        h6.sub-text You have not yet created a password for your account!
      .cta-container
        n-button.ml-md-4(
          size="large",
          variant="dark",
          type="button",
          @click="sendResetPasswordMail",
          buttonText="Create New Password")
    template(v-else)
      .col
        .row
          .col-md-12.nova-info-banner.p-0
            profile-info-banner(
              v-if="currentPage === 'details' && showProfileInfoBanner"
              title="Request to change the profile details is in process",
              subtext="For more information, please contact your HR.",
              :user="user",
            )
        .row
          .col-md-9.offset-md-1.col-sm-12.pt-3
            b-form(@submit.preventDefault="currentPage === 'security' ? changePassword($event) : updateProfile($event)")
              .mb-4.d-flex.flex-column.flex-md-row.justify-conent-center.align-items-center(v-if="currentPage === 'details'")
                n-avatar.d-md-none.mb-4.mb-md-0(
                  :size="8",
                  :name="profileModel.name",
                  :image="profileModel.photoFile?profileModel.photoFile.url:null",
                  :loading="loadingProfile")
                n-avatar.d-none.d-md-block(
                  :size="4",
                  :name="profileModel.name",
                  :image="profileModel.photoFile ? profileModel.photoFile.url : null",
                  :loading="loadingProfile")
                .d-flex.justify-content-between.align-items-center
                  file-upload.btn.btn-outline-primary.ml-md-4(
                    extensions="gif,jpg,jpeg,png,webp",
                    accept="image/png,image/gif,image/jpeg,image/webp",
                    :directory="false",
                    :size="1024 * 1024 * 10",
                    :thread="3",
                    :drop="true",
                    :drop-directory="true",
                    @input-file="handleProfileUpload")
                    n-icon.align-sub.mr-2(name="renew")
                    span.font-weight-medium Upload new
                  n-button.btn.text-danger.ml-2(imageIcon="delete",size="large", variant="outline-secondary",buttonText="Remove", @click.prevent="removeProfilePicture()")
              .text-gray-800.font-weight-medium.mb-4(v-if="currentPage === 'security'") Reset Password
              vue-form-generator(
                :schema="schemaMap[currentPage]",
                :model="currentPage === 'security' ? pwdModel : profileModel",
                :options="formOptions",
                :ref="currentPage")
              n-password-policy(v-if="currentPage === 'security'", :password="pwdModel.newPassword", :passwordChangeSubmitted="passwordChangeSubmitted")
              n-button.mt-5(
                variant="secondary",
                :buttonText="currentPage === 'details' ? 'Request Update' : 'Save Changes'",
                type="submit",
              )
          special-modal(
            id="update-profile-confirmation-modal",
            headerIcon="icon",
            :iconColor="'grey-900'",
            logoIcon="error",
            centered,
            :heroImage="require('@/assets/images/confirm-profile-update-hero-image.svg')"
          )
            template(v-slot:title)
              h3.font-weight-bold Before you request changes...
            template(v-slot:modal-content)
              .text-center.text-gray-700.text-md.font-weight-medium.w-75.mx-auto Requested changes are subject to your HR’s approval or rejection. Any changes here might impact your policy coverage based on insurer’s policy guideline.
            template(v-slot:buttons-area)
              .d-flex.w-100.justify-content-around
                n-button.ml-3(
                  buttonText="Cancel",
                  variant="outline-dark",
                  size="lg",
                  color="gray-200",
                  @click="closeModal",
                  style="width:40%;",
                )
                n-button.mr-3(
                  buttonText="Confirm ->",
                  variant="dark",
                  size="lg",
                  @click="updateUserWithPolicies($event)",
                  style="width:40%;",
                )
</template>

<script>
import gql from "graphql-tag";
import { mapState } from "vuex";
import FileUpload from "vue-upload-component";
import resDefs from "../definitions";
import adminResDefs from "../../admin/definitions";
import ProfileNav from "./components/ProfileNav.vue";
import { changeUserPassword } from "@/common/queriesAndMutations/auth";
import NIcon from "@/components/NovaIcon.vue";
import NButton from "@/components/NovaButton.vue";
import NAvatar from "@/components/Avatar.vue";
import NPasswordPolicy from "@/components/PasswordPolicy.vue";
import SpecialModal from "@/components/SpecialModal.vue";
import ProfileInfoBanner from "./components/ProfileInfoBanner.vue";
import utils, { isVfgErrorPresent } from "@/utils";
import { userFragment } from "../../admin/fragments";
import { isEqual } from "lodash-es";

export default {
  components: {
    NButton,
    NAvatar,
    ProfileNav,
    FileUpload,
    NIcon,
    NPasswordPolicy,
    SpecialModal,
    ProfileInfoBanner,
  },
  data() {
    const resDef = resDefs.profile;
    return {
      resDefs,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: true,
      },
      loadingProfile: false,
      schemaMap: {
        details: resDef.profileSchema,
        about: resDef.aboutSchema,
        "contact-details": resDef.contactSchema,
        security: resDef.pwdSchema,
      },
      profileModel: {},
      pwdModel: {},
      showResetPasswordButtonFlag: false,
      passwordChangeSubmitted: false,
      showProfileInfoBanner: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    currentPage() {
      const currentPage = this.$route.path;
      return currentPage.split("/user/profile/")?.[1];
    },
    showResetPasswordButton() {
      return this.showResetPasswordButtonFlag && this.currentPage === "security";
    },
  },
  watch: {
    "$route.path"() {
      if (this.$route.path.includes("/security")) {
        this.$apollo.queries.profileQuery.refetch();
      }
    },
  },
  created() {
    this.$apollo.addSmartQuery("profileModel", {
      query: resDefs.users.singleQuery,
      update(data) {
        const user = { ...data.node };
        if (data.node.org) {
          user.orgId = data.node.org.id;
        }
        this.showProfileInfoBanner = user.userChanges?.some((change) => change.type === "update");
        return user;
      },
    });
    // This query has been added for reflecting current state of
    // user password status in security tab as our state isn't reactive
    this.$apollo.addSmartQuery("profileQuery", {
      query: gql`
        query MyProfile {
          me {
            ...User
          }
        }
        ${userFragment}
      `,
      update(data) {
        this.showResetPasswordButtonFlag = data.me?.meta?.isPasswordSetupRequired;
        if (data.me) {
          this.$store.commit("updateUser", data.me);
        }
        return null;
      },
      fetchPolicy: "no-cache",
    });
  },
  mounted() {
    this.$store.commit("updateSectionHeader", "Your Profile");
    this.$store.commit("updateNavHeader", {
      name: "Basic Details",
      back: true,
    });
  },
  methods: {
    uploadImage() {
      this.$refs.profileUpload.click();
    },
    async handleProfileUpload(newFile, oldFile) {
      if (newFile && oldFile === undefined) {
        this.loadingProfile = true;
        const MAX_WIDTH = 320;
        const MAX_HEIGHT = 180;
        const MIME_TYPE = "image/jpeg";
        const QUALITY = 0.7;
        try {
          const file = await utils.getCompressedImage(newFile.file, MAX_HEIGHT, MAX_WIDTH, MIME_TYPE, QUALITY);
          let id = null;
          if (this.profileModel.photoFile) {
            id = this.profileModel.photoFile.id;
          }
          const result = await this.$apollo.mutate({
            mutation: adminResDefs.files.upsertMutation,
            variables: {
              file,
              id,
              action: "profilePhotoUpload",
              uploadingUserId: this.$store.state.user.id,
            },
          });
          this.profileModel.photoFile = result.data.upsertFile.file;
          const userData = {
            id: this.profileModel.id,
            orgId: this.profileModel.orgId,
            photoFileId: this.profileModel.photoFile.id,
          };
          await this.updateUser(userData);
        } catch (err) {
          console.log(err);
          this.$store.commit("addAlert", {
            variant: "danger",
            message: "Sorry, Something went wrong while uploading profile picture.",
          });
        }

        this.loadingProfile = false;
      }
    },
    transformProfileModel(e) {
      if (e) {
        e.preventDefault();
      }
      const { photoFile, userChanges, ...restUser } = this.profileModel;
      return restUser;
    },
    hasPolicies() {
      return this.user.benefits.some((benefit) => benefit.node.isPolicy);
    },
    async updateUserWithPolicies(e) {
      await this.updateUser(this.transformProfileModel(e));
      this.closeModal();
    },
    async updateProfile(e) {
      e.preventDefault();
      await this.$refs[this.currentPage].validate();
      if (isVfgErrorPresent(this.$refs[this.currentPage].errors)) {
        this.$store.commit("clearAlerts");
        this.$store.commit("addAlert", {
          variant: "danger",
          message: "Please fill valid details.",
        });
        return;
      }
      if (this.hasPolicies() || this.showProfileInfoBanner) {
        this.$bvModal.show("update-profile-confirmation-modal");
      } else {
        await this.updateUser(this.transformProfileModel());
      }
    },
    closeModal() {
      this.$bvModal.hide("update-profile-confirmation-modal");
    },
    async removeProfilePicture() {
      const userData = {
        id: this.profileModel.id,
        photoFileId: null,
      };
      await this.updateUser(userData);
      this.profileModel.photoFile = null;
    },
    async updateUser(userInput) {
      this.$store.commit("clearAlerts");
      const changedUserInfo = {};
      for (const key in userInput) {
        if (!isEqual(userInput[key], this.user[key])) {
          changedUserInfo[key] = userInput[key];
        }
      }
      delete changedUserInfo.orgId;
      try {
        const res = await this.$apollo.mutate({
          mutation: resDefs.userChanges.updateUserOrDependentInfo,
          variables: {
            userId: userInput.id,
            orgId: userInput.orgId,
            changedUserInfo,
          },
        });
        await this.$apollo.queries.profileQuery.refetch();
        await this.$apollo.queries.profileModel.refetch();
        this.$store.commit("addAlert", {
          variant: "success",
          message: "Successfully updated the details",
        });
      } catch (err) {
        console.log(err);
      }
    },
    async sendResetPasswordMail() {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation SendResetPasswordMail($email: String!) {
              sendResetPasswordEmail(input: { email: $email }) {
                emailSent
              }
            }
          `,
          variables: {
            email: this.user.email,
          },
        });
        const emailSent = result.data.sendResetPasswordEmail.emailSent;
        if (emailSent) {
          this.$store.commit("addAlert", {
            variant: "success",
            message: "Password reset link has been sent to your registered Mail ID",
          });
        } else {
          this.$store.commit("addAlert", {
            variant: "danger",
            message: "Sorry, Something went wrong, please try again",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    validatePasswords() {
      if (!this.pwdModel.currentPassword) {
        return [false, "Old password is required when updating the password"];
      }
      if (!this.pwdModel.newPassword || !this.pwdModel.confirmNewPassword) {
        return [false, "Please enter new password and confirmation"];
      }
      if (this.pwdModel.newPassword !== this.pwdModel.confirmNewPassword) {
        return [false, "New password and confirmation don't match"];
      }
      return [true];
    },
    async changePassword(e) {
      e.preventDefault();
      await this.$refs[this.currentPage].validate();
      if (isVfgErrorPresent(this.$refs[this.currentPage].errors)) {
        this.passwordChangeSubmitted = true;
        this.$store.commit("addAlert", {
          variant: "danger",
          message: "Password does not satisfy given conditions.",
        });
        return;
      }
      const [valid, errMessage] = this.validatePasswords();
      if (!valid) {
        this.$store.commit("clearAlerts");
        this.$store.commit("addAlert", {
          variant: "danger",
          message: errMessage,
        });
        return;
      }
      const passwordData = {
        id: this.profileModel.id,
        ...this.pwdModel,
      };
      delete passwordData.confirmNewPassword;
      this.$store.commit("clearAlerts");
      try {
        const res = await changeUserPassword({
          ...passwordData,
        });
        if (res?.data?.changePassword?.passwordUpdated) {
          this.$store.commit("addAlert", {
            variant: "success",
            message: "Password successfully updated",
          });
          return;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.reset-password-banner {
  height: fit-content;
  background-color: $gray-100;
  align-items: center;
  .sub-text {
    color: $gray-700;
  }
}
</style>
