import gql from "graphql-tag";
import { relationshipManagerFragment } from "./relationshipManager";
import { segmentFragment } from "./segment";
import { userFragment } from "./user";

export const orgPropertyFragment = gql`
  fragment OrgProperty on OrgProperty {
    id
    org {
      id
      name
    }
    name
    isGlobal
    meta
    node {
      ... on User {
        ...User
      }
      ... on RelationshipManager {
        ...RelationshipManager
      }
      ... on Segment {
        ...Segment
      }
    }
    nodes {
      node {
        ... on Segment {
          ...Segment
        }
        ... on User {
          ...User
        }
      }
    }
    updatedAt
  }
  ${userFragment}
  ${relationshipManagerFragment}
  ${segmentFragment}
`;
