<template lang="pug">
div.bg-white(:class="$store.state.navBottomBorder ? 'mb-3 mb-md-4' : ''")
  b-navbar.p-0(toggleable="md")
    div.container.px-3(:class="$store.state.navBottomBorder ? 'border-bottom-gray-300': 'nav-border-bottom'")
      b-navbar-brand
        img.main-logo-style.d-none.d-md-block.my-3(src="@/assets/images/logo-main.svg",alt="nova main logo",to="/")
        i.icon-nova-basic.main-icon-style.my-2.d-block.d-md-none(v-if="$route.name==='dashboard' || !isLoggedIn")
        i.icon-chevron-left.back-button.d-block.d-md-none(@click="goBack", role="button", v-else)
          
      b-navbar-nav.d-md-inline-flex
        slot(name="left")

      b-navbar-nav.d-md-inline-flex.ml-auto
        slot(name="middle")
        
      b-navbar-nav.ml-auto.my-2
        slot(name="right")
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TopNavbar",
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.main-logo-style {
  display: inline-block;
  height: 1.5rem;
}
.border-bottom-gray-300 {
  border-bottom: 1px solid $gray-300;
}
.nav-border-bottom {
  border-bottom: 1px solid $gray-300;
}
@media (max-width: 768px) {
  .main-logo-style {
    height: 23px;
  }
  .main-icon-style {
    font-size: 2rem;
    color: $gray-500;
  }
  .nav-border-bottom {
    border-bottom: none;
  }
}

.back-button {
  font-size: 2rem;
}
</style>
