<template lang="pug">
.wrapper(v-if="prospect_state.meta")
  n-navbar(
    v-if="!isFeatureListModal",
    :logoImage="'logo-main-white'",
    :headerClass="'dark-navbar'",
    :headerSeparator="false")

    template(v-slot:middle)
      transition(name="fade", mode="out-in")
        h5.d-none.d-sm-inline-block.page-title.pt-2(
          key="1", v-if="altNav===0 || currentView==='mobile'") Insurance Premium Pricing

        div(key="2", v-else)
          div(key="3")
            .container-nav-policies.pt-2(style="height: 2.5rem;")
              .d-inline-block.col-md-4.text-center(
                v-for="policy in prospect_state.pricing_table_state.policies",
                :key="policy.id")
                i.text-white.align-sub(:class="`icon-${policy.imageIcon}`")
                span.h5.text-white  {{ policy.cardTitle }}

    template(v-slot:right)
      transition(name="fade", mode="out-in")
        div(key="1", v-if="altNav===0 || currentView==='mobile'")
          n-button(
            :disabled="false",
            :buttonText="'Schedule a call'",
            :variant="'warning'",
            :imageIcon="'calendar'",
            @click="openCalendlyPopup")
        div(key="2", v-else)

  .header-tiber-bg
  .main-panel(style="margin-top:75px; overflow-x: auto;")
    .container-pec.mb-1
      .m-auto
        .row.m-0.pt-0.px-2.col-md-12
          b-card.container-table
            .row.px-4
              .d-none.d-lg-inline-block.pb-4.d-xl-none.w-100.text-center
                div
                  .d-lg-inline-block.d-xl-none
                    br
                    span.hb4 Recommended Plans
                    br
                    span.font-weight-medium.text-gray-800(v-if="prospect_state.meta.org_name")
                      | For {{ prospect_state.meta.org_name }}

              .col-12.col-lg-3.d-inline-block.px-2.pb-2.mx-auto
                div
                  .d-lg-none.d-xl-inline-block
                    br
                    span.hb4 Recommended Plans
                    br
                    span.font-weight-medium.text-gray-800(v-if="prospect_state.meta.org_name")
                      | For {{ prospect_state.meta.org_name }}
                  hr.d-lg-none.d-xl-block
                  .row.w-100.pb-2
                    .col-6.col-lg-12.col-xl-6(v-if="prospect_state.meta.employee_count")
                      .d-none.d-lg-block.d-xl-none
                        br
                        br
                        br
                      .font-hc.text-gray-700 EMPLOYEES
                      h2.pt-2 {{ prospect_state.meta.employee_count }}
                      .d-none.d-lg-block.d-xl-none
                        br

                  .font-sm.d-lg-inline-block.text-gray-700.pr-lg-2.pr-xl-0
                    | Here are plans based on our understanding of your requirements.
                    | Don’t worry all of our plans are fully customizable
                    img.d-inline-block.mx-auto.mx-lg-0(
                      :src="require(`@/assets/emojis/wave.svg`)",
                      style="width: 1rem;")
              .d-none.d-lg-inline-block.px-0.col-12(
                v-for="policy in prospect_state.pricing_table_state.policies",
                :key="policy.id",
                :class="`col-lg-${12/prospect_state.pricing_table_state.fields.length}`",
                style="z-index: 2;")
                n-card.mx-auto.custom-width(
                  :cardTitle="policy.cardTitle",
                  :variant="'solid-'+policy.variant",
                  :imageIcon="policy.imageIcon",
                  :buttonText="policy.buttonText",
                  :termPrice="parseInt(prospect_state.pricing_table_state.items\
                    [prospect_state.pricing_table_state.items.length-3]\
                    [policy.termPrice]*1.18) / (prospect_state.meta.employee_count || 1)",
                  :cardText="policy.cardText",
                  @buttonClick="showModal(policy.id)",
                  :termName="policy.termPrice")

            .d-inline-block.d-lg-none.px-2.col-12(
              v-for="policy in prospect_state.pricing_table_state.policies",
              :key="policy.id+10")
              .rounded.my-4(:class="'border-'+policy.variant")
                n-card.card-mobile.rounded-bottom-none(
                  :cardTitle="policy.cardTitle",
                  :variant="'solid-'+policy.variant",
                  :imageIcon="policy.imageIcon",
                  :buttonText="policy.buttonText",
                  :termPrice="parseInt(prospect_state.pricing_table_state.items\
                    [prospect_state.pricing_table_state.items.length-3][policy.termPrice]*1.18)\
                    / (prospect_state.meta.employee_count || 1)",
                  :cardText="policy.cardText",
                  @buttonClick="pickPlan(policy.cardTitle)",
                  :disableButton="true")
                table.table-mobile
                  tr.table-row-mobile(v-for="item in itemsMobile")
                    td.text-gray-600 {{ item.feature }}
                    td(v-if="item[policy.termPrice].toString().charAt(0)==='!'\
                      || item[policy.termPrice].toString().charAt(0)==='?'") {{ item[policy.termPrice].substring(1) }}
                    td(v-else) {{ item[policy.termPrice] }}
                n-button.d-block.mt-2.mx-auto.font-sm(
                  variant="link",
                  :disabled="false",
                  :buttonText="'View Details ->'",
                  @click="openFeatureListModal(policy)")
                n-button.d-block.col-10.mt-2.mb-4.mx-auto(
                  :variant="policy.variant",
                  :disabled="false",
                  @click="showModal(policy.id)",
                  :buttonText="selectedPolicyVariant && selectedPolicyVariant===policy.termPrice\
                    ?'Continue with this plan':'Pick this plan'",
                  :rightImageIcon="selectedPolicyVariant && selectedPolicyVariant===policy.termPrice\
                    ?'chevron-right':null")

            .table-container.d-none.d-lg-flex
              b-table.table-quote-sheet(
                fixed,
                foot-clone,
                primary-key="id",
                :items="items",
                :tbody-transition-props="{name: 'row-list'}",
                :fields="prospect_state.pricing_table_state.fields",
                :no-border-collapse="true",
                thead-class="d-none")

                template(#cell(feature)="scope")
                  .row.mx-0.d-block
                    i.col-md-1.pl-0.mr-2.d-inline-block.align-middle(
                      v-if="scope.item.icon",
                      :class="[\
                        `icon-${scope.item.icon}`, \
                        `text-${scope.item.iconColor}`, \
                        scope.item.iconOpacity?`ic-${scope.item.iconOpacity}`:'']")
                    span.hb5.pt-2.col-md-10.pl-0.d-inline-block.align-middle(
                      v-if="scope.item.feature",
                      :id="`pricing-table-row-header-${scope.index}`",
                      tabindex="-1",
                      :class="[\
                        scope.item.icon?'regular-feature':'', \
                        scope.item.popoverText?'underlined':''\
                      ]") {{ scope.item.feature }}

                    b-popover(
                      v-if="scope.item.popoverText",
                      :target="`pricing-table-row-header-${scope.index}`",
                      triggers="focus hover",
                      placement="bottom",
                      :fallback-placement="['bottom','bottom','bottom','bottom']")
                      b-card.overflow-hidden(
                        :class="`popover-custom-card`",
                        :ref="`pricing-table-row-header-${scope.index}`")
                        span.popover-text.break-spaces {{ scope.item.popoverText }}
                        u.d-block
                          b-link.font-lg.text-success(href="//www.google.com", target="_blank")
                            span Learn more
                            span.no-letter-spacing ->

                template(#cell()="scope")
                  div
                    span.icon-check-circle(v-if="scope.value.toString().trim()=='yes'")
                    span.icon-cancel.text-gray-500(v-else-if="scope.value.toString().trim()=='no'")
                    .highlight-danger(v-else-if="scope.value.toString().charAt(0)==='!'")
                      span {{ scope.value.substring(1) | inr }}
                    .highlight-success(v-else-if="scope.value.toString().charAt(0)==='?'")
                      span {{ scope.value.substring(1) | inr }}
                    span(v-else) {{ scope.value | inr }}

                template(#bottom-row="scope")
                  b-td.align-middle.cursor-pointer(
                    :colspan="prospect_state.pricing_table_state.fields.length",
                    @click="toggleExpansion()")
                    .col-md-12.text-left.max-width-100
                      span.hb5 View detailed policy parameters
                      i.align-middle.float-right(
                        :class="[isTableExpanded?'icon-chevron-up':'icon-chevron-down']")

                template(v-for="field in prospect_state.pricing_table_state.fields", #foot()="scope")
                  .footer.font-weight-medium.py-2
                    | {{ getPriceTableState(prospect_state, scope) }}

            .pt-2.d-none.d-lg-block
              .col-3.d-inline-block.font-sm
                span.mt-2 You can also customize these plans as per your needs
              .col-lg-3.d-inline-block.px-0.px-xl-4
                n-button.ml-2.col-lg-11(
                  :variant="'primary'",
                  :disabled="false",
                  @click="showModal(1)",
                  :buttonText="selectedPolicyVariant && selectedPolicyVariant==='basic'\
                    ?'Continue with this plan':'Pick this plan'",
                  :rightImageIcon="selectedPolicyVariant && selectedPolicyVariant==='basic'\
                    ?'chevron-right':null")
              .col-lg-3.d-inline-block.px-0.px-xl-4
                n-button.ml-2.col-lg-11(
                  :variant="'dark'",
                  :disabled="false",
                  @click="showModal(2)",
                  :buttonText="selectedPolicyVariant && selectedPolicyVariant==='standard'\
                    ?'Continue with this plan':'Pick this plan'",
                  :rightImageIcon="selectedPolicyVariant && selectedPolicyVariant==='standard'\
                    ?'chevron-right':null")
              .col-lg-3.d-inline-block.px-0.px-xl-4
                n-button.ml-2.col-lg-11(
                  :variant="'success'",
                  :disabled="false",
                  @click="showModal(3)",
                  :buttonText="selectedPolicyVariant && selectedPolicyVariant==='prime'\
                    ?'Continue with this plan':'Pick this plan'",
                  :rightImageIcon="selectedPolicyVariant && selectedPolicyVariant==='prime'\
                    ?'chevron-right':null")
        .row.m-0
          n-card.col-md-6.pt-4.px-2(
            :cardType="'testimonial'",
            :variant="'testimonial'",
            :testimonialSource="'Chanchal Singh'",
            :testimonialOrg="'Drip Capital'",
            :cardText="'\" The Nova team has made buying Insurance effortlessly easy with tailored recommendations\
              on Insurers and policy features with an employee first approach  \"'")
          n-card.col-md-6.pt-4.px-2(
            :cardTitle="'Not sure which plan is right for you and your team?'",
            :cardType="'schedule'",
            @buttonClick="openCalendlyPopup()",
            :typeSchedule="true",
            :imageIcon="'schedule-woman.svg'",
            :cardText="'We are happy to help.'")

    n-confirm-modal(
      :id="'confirm-modal'",
      :planIndex="planIndex",
      :data="prospect_state",
      :variant="variant",
      @showInfoModal="showInfoModal")
    n-info-modal()

    .expanded-feature-list-modal-mobile(v-if="isFeatureListModal")
      div(:class="'bg-'+mobileModalPolicy.variant", style="height: 8rem")
        .text-white.font-lg.modal-close-button-mobile(
          :class="mobileModalPolicy.variant",
          @click="closeFeatureListModal(mobileModalPolicy.termPrice)")
          .icon-cross
        .modal-header-mobile
          i.h3.mr-2.align-middle(
            v-if="mobileModalPolicy.imageIcon",
            :class="[`icon-${mobileModalPolicy.imageIcon}`, `text-white`]")
          .d-inline-block.pb-2.h5 {{ mobileModalPolicy.cardTitle }}
          .font-lg.pl-2
            | {{ getMobileModalPolicy(prospect_state, mobileModalPolicy) }} / Employee
          .font-sm.term-text.pt-1.pl-2
            span Annually
      .expanded-list-table-wrapper-mobile
        div(style="height: 100%; overflow-y: auto;")
          table.table-mobile
            tr.table-row-mobile(v-for="item in itemsMobile", :key="item.feature")
              td.px-0
                i.align-middle(
                  :class="[\
                    `icon-${item.icon}`, \
                    `text-${item.iconColor}`, \
                    item.iconOpacity?`ic-${item.iconOpacity}`:'']")
              td.w-50.table-feature-mobile {{ item.feature }}
              td {{ item[mobileModalPolicy.termPrice] | inr }}

      .mx-0.bottom-section-modal-mobile
        .w-75.mx-auto.d-table.h-100
          .d-table-cell.align-middle.py-2
            n-button.my-1(
              :variant="mobileModalPolicy.variant",
              block,
              :disabled="false",
              @click="showModal(mobileModalPolicy.id)",
              :buttonText="'Pick this plan'")
  n-footer
</template>

<script>
import NNavbar from "@/layout/NovaNavbar.vue";
import NButton from "@/components/NovaButton.vue";
import NFooter from "@/layout/Footer.vue";
import NInfoModal from "@/components/NovaInfoModal.vue";
import utils from "@/utils";
import NConfirmModal from "./components/NovaConfirmModal.vue";
import NCard from "./components/NovaCard.vue";
import resDefs from "./definitions";
export default {
  name: "PricingTableStatic",
  components: {
    NNavbar,
    NCard,
    NButton,
    NInfoModal,
    NFooter,
    NConfirmModal,
  },
  data() {
    const resName = "prospects";
    const resDef = resDefs[resName];
    return {
      resName,
      resDef,
      prospect_state: {},
      altNav: 0, // 3 states, 0 from top to when names get covered, 1 till end of tiber bg, 2 till end of page scroll
      windowTop: 0,
      items: [],
      isTableExpanded: true,
      planIndex: -1,
      variant: "",
      selectedPolicyVariant: "",
      isFeatureListModal: false,
      mobileModalPolicy: {},
      scrollTo: 0,
      windowWidth: window.innerWidth,
      currentView: window.innerWidth > 992 ? "web" : "mobile",
      minimizedTableItems: [],
      expandedTableItems: [],
    };
  },
  watch: {
    windowTop: function () {
      const scrollPos = this.windowTop;
      if (scrollPos > 295) {
        this.altNav = 2;
      } else if (scrollPos > 100) {
        this.altNav = 1;
      } else {
        this.altNav = 0;
      }
    },
    windowWidth() {
      if (this.windowWidth > 992) {
        if (this.currentView === "mobile") {
          this.currentView = "web";
        }
      } else {
        if (this.currentView === "web") {
          this.currentView = "mobile";
        }
      }
    },
    isTableExpanded: function () {
      if (this.isTableExpanded) {
        this.items = JSON.parse(JSON.stringify(this.expandedTableItems));
        this.itemsMobile = this.expandedTableItems.slice(
          0,
          this.expandedTableItems.length
        );
      } else {
        this.items = JSON.parse(JSON.stringify(this.minimizedTableItems));
        this.itemsMobile = JSON.parse(JSON.stringify(this.items));
      }
      this.items.unshift({});
    },
  },
  async created() {
    this.$Progress.start();
    const prospectId = await utils.fromNanoId(this, this.$route.params.id);
    this.$apollo.addSmartQuery("prospect_state", {
      query: this.resDef.singleQuery,
      variables: () => ({
        id: prospectId,
      }),
      update(data) {
        if (!data.node) {
          this.$router.push("/error/not-found");
          this.$Progress.fail();
        }
        this.$Progress.finish();
        this.isTableExpanded = false;

        this.expandedTableItems = data.node.pricing_table_state.items.slice(
          0,
          data.node.pricing_table_state.items.length - 1
        );

        this.minimizedTableItems = data.node.pricing_table_state.items.slice(
          0,
          8
        );
        this.minimizedTableItems = this.minimizedTableItems.concat(
          data.node.pricing_table_state.items.slice(
            data.node.pricing_table_state.items.length -
              data.node.meta.bottom_rows,
            data.node.pricing_table_state.items.length - 1
          )
        );
        return data.node;
      },
    });
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.windowResize);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.windowResize);
  },
  methods: {
    onScroll(e) {
      this.windowTop = window.top.scrollY;
    },
    windowResize() {
      this.windowWidth = window.innerWidth;
    },
    showModal(planId) {
      this.planIndex = this.prospect_state.pricing_table_state.policies.findIndex(
        (pol) => pol.id === planId
      );
      this.variant = this.prospect_state.pricing_table_state.policies[
        this.planIndex
      ].variant;
      this.$bvModal.show("confirm-modal");
    },
    getCoverageType() {
      switch (this.prospect_state.meta.coverage_type) {
        case "E":
          return "Employee Only";
        case "ES":
          return "Employee & Spouse";
        case "ESC":
          return "Employee, Spouse\n& Children";
        case "ESCP":
          return "Employee, Spouse,\nChildren & Parents";
        default:
          return "Data not found";
      }
    },
    showInfoModal() {
      this.$bvModal.show("info-modal");
    },
    toggleExpansion() {
      this.isTableExpanded = !this.isTableExpanded;
    },
    openCalendlyPopup() {
      utils.openCalendlyPopup();
    },
    openFeatureListModal(policy) {
      this.scrollTo = window.top.scrollY;
      this.mobileModalPolicy = policy;
      const wrapper = document.getElementsByClassName("wrapper")[0];
      wrapper.style.overflow = "hidden";
      this.toggleExpansion();
      this.isFeatureListModal = true;
    },
    closeFeatureListModal() {
      const wrapper = document.getElementsByClassName("wrapper")[0];
      wrapper.style.overflow = "visible";
      this.toggleExpansion();
      this.isFeatureListModal = false;
      window.scrollTo({
        top: this.scrollTo,
        behavior: "smooth",
      });
    },
    getMobileModalPolicy(prospectState, mobileModalPolicy) {
      return utils.toINR(
        parseInt(
          prospectState.pricing_table_state.items[
            prospectState.pricing_table_state.items.length - 1
          ][mobileModalPolicy.termPrice]
        ) /
          (prospectState.meta.employee_count || 1)
      );
    },
    getPriceTableState(prospectState, scope) {
      return utils.toINR(
        prospectState.pricing_table_state.items[
          prospectState.pricing_table_state.items.length - 1
        ][scope.field.key]
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.main-panel {
  background: none;
  // background-color: $website-bg;
  // & > .footer {
  //   background-color: $website-bg !important;
  // }
}
.modal-open {
  overflow-y: scroll;
  padding: 0 !important;
}

.page-title {
  color: #87eae3;
}
.container-table {
  width: 100%;
  padding: 0;
  z-index: 0;
}
.explore-data {
  color: white;
  & > * > div {
    padding-bottom: 0.5rem;
  }
}
hr {
  border: 1px solid #e2e8f0;
}
.table-container {
  width: 100%;
  padding: 0 0.5rem;
  position: relative;
  top: -0.5rem;
  z-index: 0;
}
.highlight-success {
  background-color: $teal-300;
  padding: 0 0.75rem;
  width: fit-content;
  border-radius: 0.25rem;
  transform: rotate(-0.75deg);
  & > span {
    display: inline-block;
    transform: rotate(0.75deg);
  }
}
.highlight-danger {
  background-color: $red-300;
  padding: 0 0.75rem;
  width: fit-content;
  border-radius: 0.25rem;
  transform: rotate(0.75deg);
  & > span {
    display: inline-block;
    transform: rotate(-0.75deg);
  }
}
.coverage-type-image {
  height: 24px;
  width: 24px;
}
.max-width-fit {
  max-width: fit-content;
}
@media (min-width: 992px) {
  .header-tiber-bg {
    background: linear-gradient(180deg, $tiber 50vh, transparent 50vh);
    max-width: 100vw !important;
    min-height: 100vh !important;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
  }
}

tr {
  height: 68px;
}
td {
  text-align: center;
  vertical-align: middle !important;
}
th {
  vertical-align: middle !important;
  font-size: 1rem;
  line-height: 19px;
}
tr:first-child > th,
tr:first-child > td {
  border-top: 0;
}
.border-primary {
  border: 2px solid;
  border-color: $blue-500 !important;
}
.border-dark {
  border: 2px solid;
  border-color: $gray-900 !important;
}
.border-success {
  border: 2px solid;
  border-color: $teal-800 !important;
}
.card-mobile {
  position: relative;
  left: -2px;
  top: -2px;
  width: calc(100% + 4px);
}

.table-mobile {
  margin: 0 auto;
  width: 90%;
}
.table-row-mobile {
  height: 3.5rem;

  border-bottom: 1px solid $gray-300;
  > td {
    text-align: left;
    font-size: 12px;
    padding-right: 1rem !important;
  }
}
.expanded-feature-list-modal-mobile {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 10000;
  & > .bg-success {
    background-color: $teal-800 !important;
  }
}
.modal-close-button-mobile {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 5rem;
  text-align: center;
  padding-top: 0.55rem;
  position: absolute;
  right: 2rem;
  top: 1.5rem;
  cursor: pointer;
  &.primary {
    background-color: $blue-400;
  }
  &.dark {
    background-color: $gray-800;
  }
  &.success {
    background-color: $teal-700;
  }
  & > .icon-cross {
    font-size: 1.5rem;
  }
}
.modal-header-mobile {
  color: white;
  padding: 1.5rem 0 0 2rem;
}
.bottom-section-modal-mobile {
  // height: 4rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $gray-100;
  box-shadow: 0px -15px 20px rgba(0, 0, 0, 0.04),
    0px -2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 0.25rem 0.25rem 0 0;
}
.expanded-list-table-wrapper-mobile {
  height: calc(100vh - 12rem);
}
</style>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

.tiber-bg {
  background: $tiber;
  max-width: 100vw !important;
}

$table-border-color: #edf2f7;
//Bootstrap table classes override
th.table-feature > div.footer {
  color: $gray-600;
  font-weight: 500;
  margin-bottom: 0;
  @extend .hb5;
}
.table-quote-sheet > * > tr {
  transition: transform 0.3s, opacity 0.3s;
  & > td {
    @extend .font-weight-medium;
    border-color: $table-border-color !important;
    padding: 0 !important;

    & div {
      height: 100%;
      text-align: center;
      max-width: 240px;
      margin: auto !important;
    }
  }

  & > th {
    border-color: $table-border-color !important;
    // background: white !important;
    vertical-align: middle !important;
    & > div > span {
      color: $gray-600;
      &.regular-feature {
        color: black;
        &.underlined {
          cursor: pointer !important;
          text-decoration: underline dashed $gray-400;
          text-underline-offset: 3px;
          text-decoration-thickness: 2px !important;
        }
      }
    }
  }

  &:first-child > th,
  &:first-child > td {
    border-top: 0;
  }
  &:last-child > th,
  &:last-child > td {
    border-bottom: 1px solid #edf2f7;
  }
  &:last-child {
    background-color: #fbfdff;
    height: 4rem;
  }
}

.table-quote-sheet > * > tr .container-table > .card-body {
  padding: 0 1rem 1rem 1rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

//Bootstrap popover classes override
.popover-custom-card {
  border-top: 5px solid $tiber !important;
}
.popover .arrow {
  visibility: hidden;
}
.popover-body {
  padding: 0;
  width: 20vw;
  @media (min-width: 1px) and (max-width: 576px) {
    width: 70vw !important;
    left: -50px;
  }
}
.popover {
  position: relative;
  border-radius: 6px !important;
  border: none !important;
  z-index: 1020 !important;
  width: 20vw;
  @media (min-width: 1px) and (max-width: 576px) {
    width: 70vw !important;
    left: -50px;
  }
}

//Animations
//Navbar
.fade-enter-active,
.fade-leave-active {
  transition: transform 0.3s, opacity 0.3s;
}
.fade-enter {
  opacity: 0;
  transform: translateY(1.5rem);
}
.fade-leave-to {
  opacity: 0;
  transform: translateY(-1.5rem);
}

//Table rows
table .row-list-enter-active {
  transition: transform 0.5s, opacity 0.5s;
}
table .row-list-leave-active {
  transition: opacity 0.5s;
}
.row-list-enter {
  opacity: 0;
  transform: translateY(-2rem);
}
.row-list-leave-to {
  opacity: 0;
}

.custom-width {
  width: calc(100% - 3rem);
}
@media (min-width: 992px) {
  .custom-width {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .custom-width {
    width: 16rem;
  }
}
.max-width-100 {
  max-width: 100% !important;
}

//Column highlights
@mixin accent-background(
  $table-column-accent-left,
  $table-column-accent-right,
  $table-column-accent-opacity: 0.33
) {
  .table-basic,
  .table-basic-selected {
    background: linear-gradient(
      to right,
      white $table-column-accent-left,
      rgba(233, 242, 255, $table-column-accent-opacity)
        $table-column-accent-left,
      rgba(233, 242, 255, $table-column-accent-opacity)
        $table-column-accent-right + 0.3%,
      white $table-column-accent-right + 0.3%
    );
    vertical-align: middle !important;
    & > div {
      &.footer {
        font-weight: 500;
        text-align: center;
        color: $blue-900;
        top: 35%;
      }
    }
  }
  .table-basic-selected {
    background: linear-gradient(
      to right,
      white $table-column-accent-left,
      $mustard-100 $table-column-accent-left,
      $mustard-100 $table-column-accent-right + 0.3%,
      white $table-column-accent-right + 0.3%
    );
    vertical-align: middle !important;
  }
  .table-prime,
  .table-prime-selected {
    padding: 1rem 0 !important;
    background: linear-gradient(
      to right,
      white $table-column-accent-left - 1%,
      rgba(240, 255, 254, $table-column-accent-opacity)
        $table-column-accent-left - 1%,
      rgba(240, 255, 254, $table-column-accent-opacity)
        $table-column-accent-right - 0.5%,
      white $table-column-accent-right - 0.5%
    );
    vertical-align: middle !important;
    & > div {
      &.footer {
        font-weight: 500;
        text-align: center;
        color: $teal-900;
        top: 35%;
      }
    }
  }
  .table-prime-selected {
    background: linear-gradient(
      to right,
      white $table-column-accent-left - 1%,
      $mustard-100 $table-column-accent-left - 1%,
      $mustard-100 $table-column-accent-right - 0.5%,
      white $table-column-accent-right - 0.5%
    );
    vertical-align: middle !important;
  }
  .table-standard,
  .table-standard-selected {
    padding: 1rem 0 !important;
    background: linear-gradient(
      to right,
      white $table-column-accent-left - 0.5%,
      rgba(242, 246, 255, $table-column-accent-opacity)
        $table-column-accent-left - 0.5%,
      rgba(242, 246, 255, $table-column-accent-opacity)
        $table-column-accent-right - 0.5%,
      white $table-column-accent-right - 0.5%
    );
    vertical-align: middle !important;
    & > div {
      &.footer {
        font-weight: 500;
        text-align: center;
        color: $gray-900;
        top: 35%;
      }
    }
  }
  .table-standard-selected {
    padding: 1rem 0 !important;
    background: linear-gradient(
      to right,
      white $table-column-accent-left - 0.5%,
      $mustard-100 $table-column-accent-left - 0.5%,
      $mustard-100 $table-column-accent-right - 0.5%,
      white $table-column-accent-right - 0.5%
    );
    vertical-align: middle !important;
  }
}
@include accent-background(0%, 100%);
@media (min-width: 1200px) {
  @include accent-background(2.25%, 98.25%);
}
@media (min-width: 1440px) {
  @include accent-background(7%, 93.75%);
}
</style>
