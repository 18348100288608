import gql from "graphql-tag";
import { dependentFragment, userBenefitFragment, userFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation m0(
      $id: ID
      $email: String
      $orgId: ID
      $name: String
      $displayName: String
      $gender: String
      $dob: String
      $roles: [String]
      $meta: JSONObject
      $dependents: [JSONObject]
      $status: String
      $photoFileId: ID
    ) {
      upsertUser(
        input: {
          id: $id
          orgId: $orgId
          name: $name
          displayName: $displayName
          email: $email
          gender: $gender
          dob: $dob
          roles: $roles
          meta: $meta
          dependents: $dependents
          status: $status
          photoFileId: $photoFileId
        }
      ) {
        user {
          ...User
        }
      }
    }
    ${userFragment}
  `,
  upsertUserDirectly: gql`
    mutation upsertUserDirectly(
      $id: ID
      $email: String
      $orgId: ID
      $name: String
      $displayName: String
      $gender: String
      $dob: String
      $roles: [String]
      $meta: JSONObject
      $dependents: [JSONObject]
      $status: String
      $photoFileId: ID
    ) {
      upsertUserDirectly(
        input: {
          id: $id
          orgId: $orgId
          name: $name
          displayName: $displayName
          email: $email
          gender: $gender
          dob: $dob
          roles: $roles
          meta: $meta
          dependents: $dependents
          status: $status
          photoFileId: $photoFileId
        }
      ) {
        user {
          ...User
        }
      }
    }
    ${userFragment}
  `,
  deleteUser: gql`
    mutation deleteUser($id: ID!, $orgId: ID!, $dateOfExit: String!) {
      deleteUser(input: { id: $id, orgId: $orgId, dateOfExit: $dateOfExit }) {
        deletedUser {
          ...User
        }
      }
    }
    ${userFragment}
  `,
  deleteUsersByIds: gql`
    mutation deleteUsersByIds($userIds: [ID]!, $orgId: ID!, $dateOfExit: String!) {
      deleteUsersByIds(input: { userIds: $userIds, orgId: $orgId, dateOfExit: $dateOfExit }) {
        users {
          ...User
        }
      }
    }
    ${userFragment}
  `,
  addBenefitConnectionMutation: gql`
    mutation m1($benefitId: ID!, $userId: ID!, $meta: JSONObject) {
      addUserToBenefit(input: { benefitId: $benefitId, userId: $userId, meta: $meta }) {
        userBenefitEdge {
          ...UserBenefitEdge
        }
      }
    }
    ${userBenefitFragment}
  `,
  addDependentMutation: gql`
    mutation m1(
      $id: ID
      $userId: ID!
      $orgId: ID
      $relation: String!
      $gender: String!
      $photoUrl: String
      $meta: JSONObject
      $dob: String!
      $name: String!
      $benefits: [JSONObject]
    ) {
      upsertDependent(
        input: {
          id: $id
          userId: $userId
          orgId: $orgId
          relation: $relation
          gender: $gender
          photoUrl: $photoUrl
          meta: $meta
          dob: $dob
          name: $name
          benefits: $benefits
        }
      ) {
        dependent {
          ...Dependent
        }
      }
    }
    ${dependentFragment}
  `,
  deleteDependentMutation: gql`
    mutation deleteDependent($userId: ID, $orgId: ID, $id: ID!, $dateOfExit: String) {
      deleteDependent(input: { userId: $userId, orgId: $orgId, id: $id, dateOfExit: $dateOfExit }) {
        deletedCount
      }
    }
  `,
  deleteBenefitConnectionMutation: gql`
    mutation deleteUserFromBenefit($benefitId: ID!, $userId: ID!) {
      deleteUserFromBenefit(input: { benefitId: $benefitId, userId: $userId }) {
        deletedCount
      }
    }
  `,
  updateUsersStatus: gql`
    mutation updateUsersStatus($orgId: ID!, $status: UserStatusEnum!) {
      updateUsersStatus(input: { orgId: $orgId, status: $status }) {
        success
      }
    }
  `,
  addBulkBenefit: gql`
    mutation addBulkBenefit($orgId: ID!, $benefitId: ID!) {
      addBulkBenefit(input: { orgId: $orgId, benefitId: $benefitId }) {
        status
      }
    }
  `,
  resyncUserBenefits: gql`
    mutation resyncUserBenefits($id: ID!) {
      resyncUserBenefits(input: { id: $id }) {
        user {
          ...User
        }
      }
    }
    ${userFragment}
  `,
  sendOnboardingInvites: gql`
    mutation sendOnboardingInvites(
      $inviteBy: String!
      $orgId: ID
      $userIds: [ID]
      $invitationType: String
      $isSelectedBatchOverdue: Boolean
    ) {
      sendOnboardingInvites(
        input: {
          inviteBy: $inviteBy
          orgId: $orgId
          userIds: $userIds
          invitationType: $invitationType
          isSelectedBatchOverdue: $isSelectedBatchOverdue
        }
      ) {
        success
      }
    }
  `,
  sendOnboardingInviteToHr: gql`
    mutation sendOnboardingInviteToHr($userId: ID!) {
      sendOnboardingInviteToHr(input: { userId: $userId }) {
        success
      }
    }
  `,
  zylaPwaMutation: gql`
    mutation getZylaPwaLink($userId: ID!, $dependent: JSONObject!) {
      getZylaPwaLink(input: { userId: $userId, dependent: $dependent }) {
        zylaPwaLink
        success
      }
    }
  `,
  ekincarePwaMutation: gql`
    mutation getEkincarePwaParameters($userId: ID!, $benefitId: ID!) {
      getEkincarePwaParameters(input: { userId: $userId, benefitId: $benefitId }) {
        message
        authTag
      }
    }
  `,
};
