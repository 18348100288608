<template lang="pug">
  .main-testimonial-container(v-if="testimonials.length > 0")
    .nova-advantage-text.mb-5.font-hc The Nova Advantage
    .testimonial-card-container
      .row.justify-content-center

        ValuePropCard.col-lg-auto.py-2(
          v-for="(valueProp, index) in valueProps",
          :key="index",
          :cardIcon="valueProp.cardIcon",
          :cardTitle="valueProp.cardTitle",
          :text="valueProp.text",
          :bgColor="valueProp.bgColor")
          template(v-slot:mobileCard)
            .d-lg-none.border-class
              hr
              p.text-gray-800.font-weight-500.my-4(v-html="testimonials[index].text")
              AvatarCard(
                :imgSrc="`${testimonials[index].personImage.url}`",
                :name="testimonials[index].name",
                :role="testimonials[index].designation",
                :company="testimonials[index].org",
                :quoteVariant="valueProp.bgColor")

      .row.justify-content-center.para-container.d-none.d-lg-flex
        .col-lg-auto.mt-4(v-for="testimonial in testimonials", :key="testimonial.id")
          .border-class.h-100.justify-content-between.d-flex.flex-column
            p.text-gray-800.font-weight-500.mt-4(v-html="testimonial.text")
            AvatarCard(
              :imgSrc="`${testimonial.personImage.url}`",
              :name="testimonial.name",
              :role="testimonial.designation",
              :company="testimonial.org")

</template>

<script>
import resDefs from "../../policyCalculator/definitions";
import ValuePropCard from "./ValuePropCard";
import AvatarCard from "./AvatarCard";

export default {
  name: "Testimonial",
  components: {
    ValuePropCard,
    AvatarCard,
  },
  data() {
    return {
      testimonials: [],
      valueProps: [
        {
          cardIcon: "shield",
          cardTitle: "We help you find the best price",
          text:
            "Explore our customisable policy planner to find out what works best for your company.",
          bgColor: "success",
        },
        {
          cardIcon: "thumb-up",
          cardTitle: "Trustworthy Recommendations",
          text:
            "We make sure you avoid hidden restrictions, loopholes or gotchas.",
          bgColor: "secondary",
        },
        {
          cardIcon: "ambulance",
          cardTitle: "5 Star Claims Experience",
          text: "Fully Managed Claims with live tracking and 24/7 support.",
          bgColor: "warning",
        },
      ],
    };
  },
  async mounted() {
    const result = await this.$apollo.query({
      query: resDefs.strapiContents.listQuery,
      variables: {
        filter: {
          collectionType: "Testimonial",
          tags: ["Homepage"],
        },
      },
    });
    this.testimonials = result?.data?.strapiContents?.edges.map(
      (t) => t.node?.meta
    );
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.nova-advantage-text {
  text-align: center;
  font-weight: 600;
  letter-spacing: 0.08em;
  color: $gray-500;
}

.testimonial-card-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  @include media-breakpoint-down(md) {
    margin: 0 0.1rem;
  }
}

.value-prop-cards-container {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
}

.para-container {
  & > div > div {
    margin: 0 auto;
    max-width: 342px;
    @include media-breakpoint-down(lg) {
      width: 100%;
      max-width: 250px;
    }
    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: 500px;
    }
  }
}
.border-class {
  border-top: 1px solid $gray-300;

  @include media-breakpoint-down(md) {
    max-width: 100%;
    padding: 0 1rem 0rem 1rem;
    margin: 1rem 0;
    border: none;
  }
}
</style>
