import ErrorPage from "../pages/ErrorPage.vue";
import GetStartedForm from "../pages/forms/GetStartedForm.vue";
import GetStarted from "../pages/policyCalculator/GetStarted.vue";
import ReviewPlan from "../pages/policyCalculator/ReviewPlan.vue";

const routes = [
  {
    path: "/",
    component: require("../pages/homepage").Base,
    children: [

      // temporary changes to disable our site and use unicorn/webflow
      {
        path: "/",
        redirect: { name: "getStartedWithCalculator" },
      },
      // ...require("../pages/homepage/").routes,

      {
        path: "/tools/group-quote-calculator",
        redirect: { name: "getStartedWithCalculator" },
      },
      {
        path: "/group-health-premium-calculator",
        redirect: { name: "getStartedWithCalculator" },
      },
      {
        path: "/policy-calculator/",
        redirect: { name: "getStartedWithCalculator" },
      },
      {
        path: "/old-calculator/",
        name: "oldCalculator",
        meta: { allowedRoles: ["*"] },
        component: require("../pages/Calculator.vue").default,
      },
      {
        path: "/get-started",
        name: "getStartedForm",
        component: GetStartedForm,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "/policy-calculator",
        name: "policyCalculator",
        component: require("../pages/policyCalculator").Base,
        children: require("../pages/policyCalculator").routes,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "/policy-calculator/review-plan/:id",
        name: "reviewPlan",
        component: ReviewPlan,
        meta: { allowedRoles: ["*"] },
      },
    ],
  },
  {
    path: "/policy-calculator/get-started",
    name: "getStartedWithCalculator",
    component: GetStarted,
    meta: { allowedRoles: ["*"] },
  },
  {
    path: "/policy-compare/:id/:organization",
    name: "policyCompare",
    component: require("../pages/policyCalculator/PricingTableStatic.vue")
      .default,
    meta: { allowedRoles: ["*"] },
  },
  { path: "/error/:type", component: ErrorPage },
  { path: "*", component: ErrorPage },
];

export default routes;