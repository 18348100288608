import gql from "graphql-tag";
import { getListQuery, getSingleQuery } from "../queries";
import segmentsSchema from "../schemas/segments";
import segmentsMutations from "../mutations/segments";

export default {
  gqlType: "Segment",
  fields: ["name", "type", "updatedAt"],
  listQuery: getListQuery("Segment"),
  singleQuery: getSingleQuery("Segment"),
  singleQueryByName: gql`
    query segmentByName($name: String!) {
      segment(name: $name) {
        id
        type
        name
        meta
      }
    }
  `,
  ...segmentsSchema,
  ...segmentsMutations,
  transform: (n) => {
    return {
      ...n,
      org: {
        id: n?.org?.id,
        name: n?.org?.name,
      },        
      orgId: n?.category === "USER" ? n.org?.id : null,
    };
  },
}
