<template lang="pug">
.main-progress-bar-container.d-none.d-lg-block(v-if="steps.length > 0")
  ul.all-items
    li.single-item(v-for="(step,index) in steps", :key="step.name")
      span.icon-name-style(:class="getColorClass(step)")
        i.mx-2.icon-style(:class="`icon-${step.icon}`")
        span.text-style.w-100(v-if="indexSmallerThanSteps(steps, index)") {{step.name}}
      hr.hr-dash(v-if="indexSmallerThanSteps(steps, index)")
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentSelectedStepName: "",
    };
  },
  mounted() {
    this.currentSelectedStepName = this.$route.name;
  },
  methods: {
    getColorClass(step) {
      return step.routeName === this.currentSelectedStepName
        ? this.currentSelectedStepName === "reviewPlan"
          ? "final-step"
          : "active-step"
        : "";
    },
    indexSmallerThanSteps(steps, index) {
      return index < steps.length - 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.main-progress-bar-container {
  margin: 0 50px 0 50px;
}
.all-items {
  display: flex;
  margin: 0;
  padding: 0;
}
.single-item {
  display: flex;
  align-items: center;
  flex-grow: 1;
}
.icon-style {
  color: inherit;
}
.text-style {
  font-size: 15px;
  color: inherit;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  text-align: left;
}
.hr-dash {
  border-color: $gray-800;
  flex: 1 1 15%;
  margin: 0 10px;
}
.active-step {
  color: $white !important;
}
.final-step {
  color: goldenrod !important;
}
.icon-name-style {
  color: $gray-800;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  text-decoration: none;
}
</style>
