<template lang="pug">
  b-modal(
    v-bind="{...$props, ...$attrs}",
    v-model="showModal",
    dialog-class="bs-modal-dialog",
    :body-class="`bs-modal ${height}`",
    content-class="bs-content",
    @hidden="$emit('hidden')")
    span.icon-cross.cross(:class="`cross-${crossVariant}`", @click="showModal = false;")
    .bs-hero(v-if="heroImage", :style="{ backgroundImage: `url(${heroImage})` }")
      img(:src="heroImage")
    slot(name="content")
    template(v-slot:modal-footer)
      slot(name="footer")
</template>

<script>
import { mapGetters } from "vuex";
import { BModal } from "bootstrap-vue";
import utils from "@/utils";

export default {
  name: "BottomSheetModal",
  components: {
    BModal,
  },
  props: {
    benefitName: {
      type: String,
      default: null,
    },
    heroImage: {
      type: String,
      default: null,
    },
    crossVariant: {
      type: String,
      default: "dark",
    },
    height: {
      type: String,
      default: "medium",
    },
  },
  data() {
    return {
      showModal: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    const isMobile = utils.mobileCheck();
    // Bottom Sheet is shown only on mobile(sm). Scroll'll first increase the height of sheet and after a threshold just scroll down.
    if (isMobile) {
      // This event gets triggered JUST before a modal opens hence the setTimeout
      this.$root.$on("bv::modal::show", (bvEvent, modalId) => {
        window.setTimeout(() => {
          const modalElement = document.getElementsByClassName("bs-modal")[0];
          if (modalElement) modalElement.addEventListener("scroll", this.handleBottomSheetScroll);
        }, 500);
      });
    }
  },
  destroyed() {
    const modalElement = document.getElementsByClassName("bs-modal")[0];
    if (modalElement) {
      modalElement.removeEventListener("scroll", this.handleBottomSheetScroll);
    }
  },
  methods: {
    handleBottomSheetScroll: function (e) {
      const modalElement = document.getElementsByClassName("bs-modal")[0];
      const oh = modalElement.offsetHeight + 5;
      if (screen.height / oh > 1.45) {
        e.preventDefault();
        modalElement.style.height = oh + "px";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_typography.scss";

div[id$="bs-modal"] {
  animation-name: bs-slide-in-bottom;
  animation-duration: 0.0001s; // Required to avoid weird artifacts at the start of the animation
  animation-timing-function: ease;
  &.show {
    animation-duration: 0.3s;
  }
}
@keyframes bs-slide-in-bottom {
  from {
    top: 10%;
    opacity: 0.75;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.bs-modal-dialog {
  overflow-y: initial !important;
  .cross {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border-radius: 50%;
    font-size: 1.2rem;
    cursor: pointer;
    box-shadow: $box-shadow-lg;
    padding: 0.4rem;
    @include media-breakpoint-down(sm) {
      right: 1rem;
      top: 1rem;
    }
  }
  .cross-dark {
    color: white;
    background-color: $gray-900;
  }
  .cross-light {
    color: $gray-900;
    background-color: white;
  }
  @include media-breakpoint-down(md) {
    max-width: 650px;
  }
  @include media-breakpoint-down(sm) {
    position: absolute !important;
    bottom: 0 !important;
    margin: 0 !important;
    min-width: 100vw;
    border-radius: 1rem;
    align-items: end;
    max-width: 100vw;
    .modal-content {
      border-radius: 1rem 1rem 0px 0px;
    }
  }
  .modal-footer {
    @include media-breakpoint-down(sm) {
      border-top: 0px;
      position: relative;
      background: transparent;
      bottom: 1.875rem;
      width: 100%;
      button {
        border-radius: 5.25rem;
        padding: 12px;
      }
    }
  }

  .bs-content {
    border-radius: 0.5rem;
    @include media-breakpoint-down(sm) {
      border-radius: 1rem !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}
.bs-modal {
  overflow-y: auto;
  padding: 0 !important;
  position: unset !important;
  border-radius: 0.4rem;
  @include media-breakpoint-down(sm) {
    border-radius: 0.95rem !important;
  }
  &.small {
    height: 20vh;
  }
  &.medium {
    height: 40vh;
  }
  &.large {
    height: 75vh;
  }
}
.bs-hero {
  height: 12rem;
  @include media-breakpoint-down(md) {
    height: 9rem;
  }
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
</style>
