<template lang="pug">
.mini-policy-card
  .plan-card-container(:class="`${policy.type}`")
      .col.col-sm-8.mx-2.my-3.py-1
        div(:class="policy.type === 'no-policy-defined' ? 'mt-4':''")
          i.mr-2.policy-card-icon.align-sub(v-if="cardData.icon", :class="[`icon-${cardData.icon}`, `icn-style`]")
          span.font-weight-semibold.text-gray-900 {{cardData.title}}
        .font-xs.text-gray-800.mx-4
          span Coverage Includes -
          span.font-weight-bold {{familyDef}}
</template>

<script>
import { mapState } from "vuex";
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";
import PlanInfo from "../../../components/PlanInfo.vue";
import { policyTypes } from "../../../admin/constants";

export default {
  name: "MiniPolicyCard",
  components: {
    PlanInfo,
    NButton,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    policy: {
      type: Object,
      default: () => {
        return { type: "no-policy-defined" };
      },
    },
    edgeMeta: {
      type: Object,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    variant: {
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState(["user"]),
    policyMeta() {
      const meta = [];
      if (
        (!this.edgeMeta?.isVariableCtc && this.edgeMeta?.si) ||
        (this.edgeMeta?.isVariableCtc && this.edgeMeta?.variableSiFactor && this.edgeMeta?.variableSiText)
      ) {
        meta.push({
          name: "Sum Insured",
          inrValue: this.policy.meta.hideSi
            ? "-"
            : this.edgeMeta.isVariableCtc
            ? this.edgeMeta.variableSiFactor + this.edgeMeta.variableSiText
            : this.edgeMeta.si,
        });
      }
      if (this.policy?.insurer?.id) {
        const url = utils.getS3Link(this.policy?.insurer?.photoUrl ?? "");
        meta.push({
          isImage: true,
          name: "Insurer",
          logo: url,
          value: this.policy.insurer.name,
        });
      }
      meta.push({
        familyDefinition: this.edgeMeta?.familyDefinition,
        value: utils.getCoverageValue(this.user, this.policy),
      });
      return meta;
    },
    cardData() {
      return policyTypes[this.policy.type];
    },
    familyDef() {
      return utils.getFamilyDefinition(this.edgeMeta.familyDefinition);
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_misc.scss";
.mini-policy-card {
  .plan-card-container {
    box-shadow: $box-shadow-xs;
  }
  .policy-card-icon {
    color: $gray-900;
    transition: color 0.3s ease;
    font-size: 1.5rem;
    @include media-breakpoint-down(md) {
      font-size: 1rem;
    }
  }
  .gmc,
  .ppc {
    @include media-breakpoint-down(md) {
      background-color: $red-100;
      border-color: $red-400;
    }
  }
  .gpa {
    @include media-breakpoint-down(md) {
      background-color: $mustard-100;
      border-color: $mustard-400;
    }
  }
  .gtl,
  .dcl {
    @include media-breakpoint-down(md) {
      background-color: $malibu-100;
      border-color: $malibu-400;
    }
  }
  .covid {
    @include media-breakpoint-down(md) {
      background-color: $purple-100;
      border-color: $purple-400;
    }
  }
  .workmen {
    @include media-breakpoint-down(md) {
      background-color: $gray-100;
      border-color: $gray-400;
    }
  }
  .topup,
  .super-topup {
    @include media-breakpoint-down(md) {
      background-color: $teal-100;
      border-color: $teal-400;
    }
  }
  .dental {
    @include media-breakpoint-down(md) {
      background-color: $blue-100;
      border-color: $blue-400;
    }
  }
}
</style>
