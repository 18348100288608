export default {
  schema: {
    fields: [
      {
        model: "type",
        type: "select",
        label: "Segment Type",
        values: ["FITNESS", "MENTAL_WELLNESS", "ORG_GROUP"],
        required: true,
      },
      {
        model: "category",
        type: "select",
        label: "Segment Category",
        values: ["ORG", "USER"],
        required: true,
      },
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Name",
        required: true,
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        model: "segmentType",
        type: "select",
        values: ["FITNESS", "MENTAL_WELLNESS", "ORG_GROUP"],
        selectOptions: {
          noneSelectedText: "Segment Type filter (none)",
        },
      },
    ],
  },
};
