<template lang="pug">
  .h-100(v-if="variant === 'addOnCards'")
    .plan-card-container(:class="previewMode?'':'cursor-pointer'")
      .new-prod-img-style(
        :style="{'background-image': 'url(' + require(`@/assets/images/${cardData.image}`) + ')'}",
        :class="policy.id")
        .mx-5.w-75
          span.text-gray-900.font-xl.font-weight-bold.break-spaces {{cardData.title}}
          .text-gray-900.font-lg.font-weight-medium.break-spaces.mt-2.mb-4 {{cardData.subtitle}}
          template(v-if="policy.infoCard")
            .text-gray-700.font-weight-medium.font-sm {{ policy.infoCard.title }}
            plan-info.mt-1.mb-4.w-25(
              :icon="policy.infoCard.icon",
              :inrValue="policy.infoCard.value")
          n-button.rounded-lg(:buttonText="cardData.btnText", variant="dark", @click="viewPolicyDetails")
  .h-100(v-else)
    .plan-card-container(
      :class="[`${policy.type}`, (to && !previewMode)?'cursor-pointer':'']",
      @click="viewPolicyDetails")
      .row.no-gutters.plan-card-top-row
        .col.col-sm-8.ml-4.my-4
          div(:class="policy.type === 'no-policy-defined' ? 'mt-4':''")
            i.mr-2.policy-card-icon.ic-large.align-sub(
              v-if="cardData.icon",
              :class="[`icon-${cardData.icon}`, `icn-style`]")
            span.hb4 {{cardData.title}}
          .font-md.subtitle.font-weight-medium {{cardData.subtitle}}
          .font-sm.text-gray-600.mb-6(v-if="policy.type === 'no-policy-defined'")
            | But don't worry, we've got you covered from day one
        .col.d-flex
          img.d-block.ml-auto.img-style(v-if="cardData.image", :src="require(`@/assets/images/${cardData.image}.png`)")
      .plan-details-container.d-flex.flex-wrap.px-4(v-if="policyMeta && policy.type !== 'no-policy-defined'")
        .mx-1(v-for="plan in policyMeta")
          .text-gray-700.font-inter.font-sm.font-weight-medium.my-2 {{plan.name}}
          plan-info(
            :image="plan.logo",
            :value="plan.value",
            :familyDefinition="plan.familyDefinition",
            :inrValue="plan.inrValue")
      .row.no-gutters.mt-auto(v-if="!previewMode")
        .col
          n-button.btn-style.w-100.rounded-top-none.mt-1.py-3.border-0.shadow-none(
            v-if="to",
            buttonText="View Details ->",
            variant="light")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";
import PlanInfo from "../components/PlanInfo.vue";
import { policyTypes } from "../admin/constants";

export default {
  name: "PolicyCard",
  components: {
    PlanInfo,
    NButton,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    policy: {
      type: Object,
      default: () => {
        return { type: "no-policy-defined" };
      },
    },
    edgeMeta: {
      type: Object,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    buttonUrl: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: "",
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    policyMeta() {
      const meta = [];
      if (
        (!this.edgeMeta?.isVariableCtc && this.edgeMeta?.si) ||
        (this.edgeMeta?.isVariableCtc && this.edgeMeta?.variableSiFactor && this.edgeMeta?.variableSiText)
      ) {
        meta.push({
          name: "Sum Insured",
          inrValue: this.policy.meta.hideSi
            ? "-"
            : this.edgeMeta.isVariableCtc
            ? this.edgeMeta.variableSiFactor + this.edgeMeta.variableSiText
            : this.edgeMeta.si,
        });
      }
      if (this.policy?.insurer?.id) {
        const url = utils.getS3Link(this.policy?.insurer?.photoUrl ?? "");
        meta.push({
          isImage: true,
          name: "Insurer",
          logo: url,
          value: this.policy.insurer.name,
        });
      }

      meta.push({
        name: !this.previewMode ? "Coverage Type" : "Eligible to Cover",
        familyDefinition: this.edgeMeta?.familyDefinition,
        value: !this.previewMode ? utils.getCoverageValue(this.user, this.policy, true) : null,
      });
      return meta;
    },
    cardData() {
      if (this.variant === "addOnCards") {
        return policyTypes[this.policy.type][this.policy.id];
      }
      return policyTypes[this.policy.type];
    },
  },
  methods: {
    viewPolicyDetails() {
      if (this.previewMode) return;
      if (this.policy?.type === "addOnCards") {
        if (this.policy?.id === "topup-card") {
          const gmcPolicy = utils.getActivePolicyByType(this.user.benefits, "gmc");
          const coverage = gmcPolicy?.meta?.familyDefinition || "ESC";

          window.posthog.capture("topup_card_click", {
            base_policy_coverage: coverage,
            base_policy_amount: parseInt(gmcPolicy.meta.si),
          });
        }
        if (this.policy?.id === "flexi-wallet-card") {
          window.posthog.capture("flexi_wallet_card_click", {});
        }
      }
      if (this.to) this.$router.push(this.to);
      else if (this.buttonUrl) window.open(this.buttonUrl, "_blank");
    },
    isInsurerOrSP(givenText) {
      const cases = ["service provider", "insurer"];
      return cases.indexOf(givenText.toLowerCase()) !== -1;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_misc.scss";
.plan-card-container {
  box-shadow: $box-shadow-xs;

  .plan-card-top-row {
    border-top: 0.25rem solid transparent;
    transition: border-color 0.3s ease;
  }

  .policy-card-icon {
    color: $gray-900;
    transition: color 0.3s ease;
  }

  &.no-policy-defined {
    .icn-style {
      color: red;
    }
    .img-style {
      margin-top: 4rem;
      margin-right: 1rem;
      margin-bottom: 3rem;
    }
    .subtitle {
      margin-top: 1.6rem;
      color: #5d6a83;
    }
  }

  &.gmc,
  &.ppc {
    .plan-card-top-row {
      border-top-color: $red-400;
    }
  }

  &.gpa {
    .plan-card-top-row {
      border-top-color: $goldenrod;
    }
  }

  &.gtl,
  &.dcl {
    .plan-card-top-row {
      border-top-color: $malibu-500;
    }
  }

  &.covid {
    .plan-card-top-row {
      border-top-color: $purple-400;
    }
  }

  &.workmen {
    .plan-card-top-row {
      border-top-color: $gray-900;
    }
  }

  &.topup,
  &.super-topup {
    .plan-card-top-row {
      border-top-color: $teal-700;
    }
  }

  &.dental {
    .plan-card-top-row {
      border-top-color: $blue-400;
    }
  }

  .img-style {
    height: 6rem;
    width: 6rem;
  }
  .plan-details-container {
    gap: 1rem;
    margin-bottom: 2.5rem;
  }

  .btn-style {
    color: $blue-700 !important;
    background: $gray-100;
  }

  transition: all 0.3s ease;
  will-change: top; // Removes stutter of plan-info blocks on hover transition

  &:hover {
    box-shadow: $box-shadow;
    top: -0.3rem;
    .btn-style {
      background: $gray-200;
    }
  }
}
.new-prod-img-style {
  background-repeat: no-repeat;
  &.topup-card {
    background-position: right bottom;
    padding: 3rem 0;
  }
  &.flexi-wallet-card {
    background-position: 20rem 0;
    padding: 1.5rem 0;
  }
  @include media-breakpoint-down(md) {
    &.flexi-wallet-card {
      background-position: 28rem 0;
    }
  }
  @include media-breakpoint-down(sm) {
    &.topup-card {
      background-size: cover;
    }
    &.flexi-wallet-card {
      background-position: 18rem 0;
    }
  }
  background-size: contain;
}
</style>
