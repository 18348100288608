const app = {
  isApp: !!window.ReactNativeWebView,
  request(reqType, data) {
    const request = {
      action: reqType,
      payload: data || {},
    };
    if (window.ReactNativeWebView) {
      return new Promise((resolve, reject) => {
        window.addEventListener("message", function (response) {
          response = JSON.parse(response.data);
          resolve(response);
          /* eslint-disable */
          window.removeEventListener("message", arguments.callee, false);
        });
        window.ReactNativeWebView.postMessage(JSON.stringify(request));
      });
    } else {
      return null;
    }
  },
};

export default app;
