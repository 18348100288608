<template lang="pug">
div
  .right-upper-card
    .estimate-container
      .mt-2.d-flex.justify-content-between
        div
          .estimate-text.d-none.d-md-block.font-hc.font-weight-semibold Premium Estimate
          .py-2.mb-0.h3.text-white {{ planPricing[0]/(prospectState.employeeCount|| 1) | inr }} / Employee
          .h5.text-gray-600.d-none.d-md-block Annually
          .h5.text-gray-600.d-block.d-md-none Annual Premium Estimate
        .d-block.d-lg-none.px-sm-1.mr-4
          n-button.final-continue-btn.mt-2.mt-md-0(
            :disabled="false",
            @click="closeMobileModal",
            buttonText="View Plan",
            rightImageIcon="chevron-down")
      .esitmate-general-info-container.pt-4
        .row.no-gutters.esitmate-general-info
          .mr-3(v-if="prospectState.employeeCount")
            .font-hc.font-weight-semibold.text-gray-600.ls-008 Employees
            .h3.text-white.pt-1 {{ prospectState.employeeCount }}
          div(v-if="prospectState.coverageType")
            .font-hc.font-weight-semibold.text-gray-600.ls-008 Coverage Type
            .cov-value.d-flex
              img.pr-2(:src="require(`@/assets/images/coverage${prospectState.coverageType}.svg`)")
              span.font-sm.font-weight-semibold.text-white.pt-1.break-spaces {{coverageType}}
      i.icon-bg(:class="`icon-nova-${prospectState.meta.selectedPolicy}`")
  .right-lower-card.d-flex
    .lower-card-text-container
      BillLine(
        :icon="`nova-${prospectState.meta.selectedPolicy}`",
        :name="`Nova ${capitalizePlanName} Coverage`",
        :cost="`${planPricing[0]}`")
      .font-hc.font-weight-semibold.text-gray-600.ls-008.pl-2(v-if="addOns.length > 0") Policy Addons
      BillLine(
        v-for="addon in addOns",
        :key="addon.id",
        :icon="addon.icon",
        :name="addon.title",
        :cost="addon.cost * prospectState.employeeCount")
      hr.hr-line
      BillLine(icon="", name="Employees to be covered", :cost="`x ${prospectState.employeeCount}`")
      BillLine(icon="", name="Total Annual Premium", :cost="`${getTotalPrice}`")
    .gst-text-btn
      span.font-sm.text-center.my-1.mx-4.text-gray-700
        | These are GST excluded estimates based on the information you’ve provided.
        | The actual price may be a bit lower or higher.
      n-button.mt-4(:buttonText="'Submit policy request ->'", :variant="'dark'", @click="confirmation", size="lg")
      span {{ this.prospectState.meta.submitted?'Your response has already been recorded':'' }}
  n-info-modal()
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import NInfoModal from "@/components/NovaInfoModal.vue";
import utils from "@/utils";
import BillLine from "./BillLine.vue";
export default {
  name: "ReviewPlanCard",
  components: {
    BillLine,
    NButton,
    NInfoModal,
  },
  props: {
    prospectState: {
      type: Object,
      default: () => {},
    },
    planPricing: {
      type: Array,
      default: () => [],
    },
    addOns: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    coverageType: function () {
      return utils.getCoverageType(this.prospectState.coverageType);
    },
    capitalizePlanName: function () {
      return (
        this.prospectState.meta.selectedPolicy.charAt(0).toUpperCase() + this.prospectState.meta.selectedPolicy.slice(1)
      );
    },
    getTotalPrice: function () {
      return this.addOns.reduce(
        (acc, addon) => acc + parseInt(addon.cost) * this.prospectState.employeeCount,
        this.planPricing[0]
      );
    },
  },
  methods: {
    showInfoModal() {
      this.$bvModal.show("info-modal");
    },
    confirmation() {
      this.$emit("confirmPlan");
      this.showInfoModal();
    },
    closeMobileModal() {
      this.$bvModal.hide("review-plan-card");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.right-upper-card {
  background: $tiber;
  position: relative;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.estimate-container {
  height: 100%;
  padding: 1rem 0 2rem 1.5rem;
  position: relative;
  overflow: hidden;
}
.icon-bg {
  font-size: 10rem;
  position: absolute;
  top: 1rem;
  opacity: 65%;
  right: -2.9rem;
  color: $teal-900;
}
.estimate-text {
  letter-spacing: 0.08em;
  color: $gray-600;
}

.ls-008 {
  letter-spacing: 0.08em;
}
.right-lower-card {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.lower-card-text-container {
  width: 100%;
  padding: 1rem 1.2rem;
  max-height: calc(100vh - 32.3rem);
  overflow-y: auto;
}
@media (max-width: 1440px) {
  .lower-card-text-container {
    max-height: calc(100vh - 33.25rem);
  }
}
@media (max-width: 992px) {
  .lower-card-text-container {
    max-height: calc(100vh - 28rem);
  }
}
.hr-line {
  border-bottom: 2px solid $gray-900;
  background-color: $gray-900;
}

.gst-text-btn {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: 1rem;
}

.final-continue-btn {
  color: $white;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 6px;
  &:active {
    background: rgba(255, 255, 255, 0.25) !important;
  }
}

@media (max-width: 992px) {
  .gst-text-btn {
    width: 100%;
    background: $gray-100;
    box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.04), 0px -2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 6px 6px 0px 0px;
    & > span {
      order: 2;
    }
  }
  .icon-bg {
    font-size: 10rem;
    position: absolute;
    top: 4.5rem;
    z-index: 0;
    opacity: 65%;
    right: -3.6rem;
    color: $teal-900;
  }
}

@media (max-width: 768px) {
  .icon-bg {
    font-size: 10rem;
    position: absolute;
    top: 4rem;
    z-index: 0;
    opacity: 65%;
    right: -3.6rem;
    color: $teal-900;
  }
}

@media (max-width: 576px) {
  .icon-bg {
    font-size: 10rem;
    position: absolute;
    top: 4rem;
    z-index: 0;
    opacity: 65%;
    right: -3.6rem;
    color: $teal-900;
  }
}
@media (max-width: 500px) {
  .continue-btn {
    margin: 0;
  }
  .gst-text-btn {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    span {
      order: 2;
    }
  }
}
</style>
