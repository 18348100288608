import { getListQuery, getSingleQuery } from "../queries";
import userSegmentsSchema from "../schemas/userSegments";
import userSegmentsMutations from "../mutations/userSegments";

export default {
  gqlType: "UserSegment",
  fields: ["user", "org", "segment", "updatedAt"],
  listQuery: getListQuery("UserSegment"),
  singleQuery: getSingleQuery("UserSegment"),
  ...userSegmentsSchema,
  ...userSegmentsMutations,
  validate: (n) => {
    // returns an error message if error exists, else returns false
    if (!n?.segment) return `Please select a segment`;
    if (!n?.users && !n?.emails) return `Please select atleast one user`;
    else if (n.users?.length) {
      for(const user of n.users) {
        if (Object.keys(user).length === 0) return "User field cannot be empty";
      }
      const uniqueNodes = new Set(
        n?.users?.map((user) => user?.node?.id)
      );
      if (uniqueNodes && uniqueNodes.size < n?.users?.length)
        return `Please remove duplicate users`;
      for (let user of n?.users) {
        if (user.node && user?.node?.org?.id !== n?.segment?.org?.id)
          return `${user?.node?.name} does not belong to the org that segment belongs to`;
      }
    }
    return false;
  },
  transform: (n, write = false) => {
    return {
      orgId: n?.segment?.org?.id,
      userIds: n?.users?.map((u) => u?.node?.id),
      emails: n?.emails || [],
      segmentId: n?.segment?.id,
      user: n?.user?.name,
      org: n?.user?.org?.name,
      userId: n?.user?.id,
      segment: n?.segment?.name,
      meta: n?.meta,
    };
  },
}
