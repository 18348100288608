import { getListQuery, getSingleQuery } from "../queries";
import samlVendorConfigsSchemas from "../schemas/samlVendorConfigs";
import samlVendorConfigsMutations from "../mutations/samlVendorConfigs";

export default {
  gqlType: "SamlVendorConfig",
  fields: ["name", "entityId", "acsUrl", "ssoUrl", "acsBinding", "updatedAt"],
  listQuery: getListQuery("SamlVendorConfig"),
  singleQuery: getSingleQuery("SamlVendorConfig"),
  ...samlVendorConfigsSchemas,
  ...samlVendorConfigsMutations,
  transform: (n) => {
    return {
      ...n,
    };
  },
}


