<template lang="pug">
  n-inline-input.pt-1(
    v-model="value",
    v-bind="schema",
    :class="{'pb-3': !schema.noPadding}",
    :state="state",
    :validationMessage="validationMessage")
</template>

<script>
import NInlineInput from "@/components/NovaInlineInput.vue";
import { abstractField } from "vue-form-generator";

export default {
  components: {
    NInlineInput,
  },
  mixins: [abstractField],
  computed: {
    validationMessage() {
      if (this.errors.length > 0) {
        for (const err of this.errors) {
          if (typeof err === "string")
            return err;
          else if (typeof err === "object" && !err.isWarning)
            return err.message;
        }
        return this.errors?.[0].message || "";
      }
      return "";
    },
    state() {
      let hasWarning = false;
      for (const err of this.errors) {
        if (typeof err === "string" || (typeof err === "object" && !err.isWarning))
          return "error";
        else if(typeof err === "object" && err.isWarning) hasWarning = true;
      }
      return hasWarning ? "warning" : "";
    },
  },
};
</script>
