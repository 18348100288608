import gql from "graphql-tag";
import {
  contentFragment,
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation m0(
      $id: ID
      $name: String
      $slug: String
      $type: String
      $content: String
      $meta: JSONObject
    ) {
      upsertContent(
        input: {
          id: $id
          name: $name
          type: $type
          slug: $slug
          content: $content
          meta: $meta
        }
      ) {
        content {
          ...Content
        }
      }
    }
    ${contentFragment}
  `,
}
