import { getListQuery, getSingleQuery } from "../queries";
import { challengeStatusSchema } from "../constants";
import orgChallengesSchema from "../schemas/orgChallenges";
import orgChallengesMutations from "../mutations/orgChallenges";

export default {
  gqlType: "OrgChallenge",
  fields: [
    "orgName",
    "segmentName",
    "templateName",
    "startDate",
    "currentTotal",
    "goalValue",
    "challengeStatus",
  ],
  listQuery: getListQuery("OrgChallenge"),
  singleQuery: getSingleQuery("OrgChallenge"),
  challengeStatusSchema,
  ...orgChallengesSchema,
  ...orgChallengesMutations,
  transform: (n) => {
    const segment = n?.org?.segments?.find(
      (segment) => segment.type === "FITNESS"
    );
    return {
      ...n,
      segment,
      challengeTemplateId: n.challengeTemplate?.id,
      templateName: n.challengeTemplate?.name,
      orgName: n.org?.name,
      segmentName: segment?.name,
      userName: n.user?.name,
      segmentId: n.segment?.id,
      orgId: n.org?.id,
      userId: n.user?.id,
      parentChallengeId: n.parentChallenge?.id,
      duration: n.challengeTemplate?.duration,
    };
  },
}
