export const Base = require("./Base.vue").default;

export const routes = [
  {
    path: "",
    name: "batches",
    component: Base,
    children: [
      { path: "draft/:tabName", name: "draft", component: Base },
      { path: "ongoing/:batchId", name: "ongoingBatch", component: Base },
      { path: "ongoing/:batchId/:tabName", name: "ongoingBatch", component: Base },
      { path: "overdue/:batchId", name: "overdueBatch", component: Base },
      { path: "overdue/:batchId/:tabName", name: "overdueBatch", component: Base },
      { path: "completed/:batchId", name: "completedBatch", component: Base },
    ],
  },
];
