import gql from "graphql-tag";
import Handlebars from "handlebars";
import { kebabCase, pickBy } from "lodash-es";
import blitz from "blitzllama-js";
import { isObject } from "lodash";

const FILE_EXTENSION_REGEX = /\.[0-9a-z]+$/i;

const createNumberFormatter = (
  style = "currency",
  currency = "INR",
  maximumFractionDigits = 0,
  minimumFractionDigits = 0
) => {
  return new Intl.NumberFormat("en-IN", {
    style: style,
    currency: currency,
    maximumFractionDigits: maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits,
  });
};

const newImageFromBlob = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
    img.onerror = reject;
  });
};

const getCanvasBlob = (canvas, mime, quality) => {
  return new Promise(function (resolve, reject) {
    canvas.toBlob(
      function (blob) {
        resolve(blob);
      },
      mime,
      quality
    );
  });
};

const calculateSize = (img, maxWidth, maxHeight) => {
  let width = img.width;
  let height = img.height;

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
};

export default {
  copyToClipboard(value) {
    navigator.clipboard.writeText(value);
  },
  getClientIntroScreenVersion() {
    const clientCookieValue = ("; " + document.cookie).split(`; INTRO_SCREEN_VERSION=`).pop().split(";")[0];
    return clientCookieValue === "" ? 0 : clientCookieValue;
  },
  handleApolloErrors(err, store) {
    let msgs = [];
    console.log("apollo err", err);
    window.apolloErr = err;
    if (err.networkError) {
      msgs.push("Network Error: " + err.networkError.toString());
    }
    if (err.graphQLErrors) {
      err.graphQLErrors.forEach((x) => {
        /** If there is a definitive message in the error stack, push only the definitive message. */
        if (x.state) {
          Object.values(x.state).forEach((m) => (msgs = msgs.concat(m)));
        } else {
          msgs.push(x.message);
        }
      });
    }
    if (msgs.length === 0) msgs.push("Unknown error in GQL: " + err.toString());

    /**
     * Pass messages in the following format if you want to override the existing alert title.
     * {
     *  title,
     *  description
     * }
     */
    if (store)
      msgs.forEach((message) => {
        /** if the message is an object, it should have the title and description keys. */
        if (isObject(message)) {
          store.commit("addAlert", { variant: "danger", message: message.description, title: message.title });
        } else {
          store.commit("addAlert", { variant: "danger", message });
        }
      });
    return msgs;
  },
  quickHash(str) {
    var hash = 0;
    var i = 0;
    var len = str.length;
    while (i < len) {
      hash = ((hash << 5) - hash + str.charCodeAt(i++)) << 0;
    }
    return hash + 2147483647 + 1;
  },
  statusToIconDetails(status) {
    const map = {
      draft: "progress-1",
      "org-ok": "progress-2",
      "nova-ok": "progress-3",
      "provider-ok": "progress-4",
      done: "progress-4",
      archive: "progress-5",
      rejected: "minus-circle",
    };
    return map[status];
  },
  createObjectWithNkeysOfSameValue(keys, value) {
    const newObject = {};
    keys.forEach((key) => (newObject[key] = value));
    return newObject;
  },
  createEmptyBatchSummary() {
    const initialStatsSummary = { count: 0, dependents: 0, users: 0 };
    const types = ["changes", "add", "delete", "update", "approved", "rejected"];
    return this.createObjectWithNkeysOfSameValue(types, initialStatsSummary);
  },
  updateBatchSummaryFromUserChange(userChange, batchSummary) {
    const type = userChange.type;
    batchSummary[type].count += 1;
    if (userChange.dependentId) {
      batchSummary[type].dependents += 1;
    } else {
      batchSummary[type].users += 1;
    }
    return batchSummary;
  },
  toCamelCase(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (ch, idx) => (idx === 0 ? ch.toLowerCase() : ch.toUpperCase()))
      .replace(/\s+/g, "");
  },
  toPascalCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (ch, idx) => ch.toUpperCase()).replace(/\s+/g, "");
  },
  deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  },
  toINR(x, withRupeeSym = true) {
    const inrFormatter = createNumberFormatter();
    if (x < 0) return (withRupeeSym ? "- ₹ " : "") + inrFormatter.format(x).slice(2);
    return (withRupeeSym ? "₹ " : "") + inrFormatter.format(x).slice(1);
  },
  toINRWithFloatingPoint(x, withRupeeSym = true) {
    const inrFormatter = createNumberFormatter("currency", "INR", 2, 2);
    if (x < 0) return (withRupeeSym ? "- ₹ " : "") + inrFormatter.format(x).slice(2);
    return (withRupeeSym ? "₹ " : "") + inrFormatter.format(x).slice(1);
  },
  toGlobalId(type, id) {
    return btoa([type, id].join(":"));
  },
  toPossesiveForm(str) {
    if (!str || str === "") {
      return null;
    }
    return `${str}'s`;
  },
  fromGlobalId(gid) {
    const [type, id] = atob(gid).split(":", 2);
    return { type, id };
  },
  async toNanoId(vueInstance, globalId) {
    const result = await vueInstance.$apollo.mutate({
      mutation: gql`
        mutation toNanoId($globalId: String!) {
          toNanoId(input: { globalId: $globalId }) {
            nanoId
          }
        }
      `,
      variables: {
        globalId: globalId,
      },
    });
    return result.data.toNanoId.nanoId;
  },
  async fromNanoId(vueInstance, nanoId) {
    const result = await vueInstance.$apollo.mutate({
      mutation: gql`
        mutation fromNanoId($nanoId: String!) {
          fromNanoId(input: { nanoId: $nanoId }) {
            globalId
          }
        }
      `,
      variables: {
        nanoId: nanoId,
      },
    });
    return result.data.fromNanoId.globalId;
  },
  openCalendlyPopup() {
    /* eslint-disable-next-line  no-undef */
    Calendly.showPopupWidget(`https://calendly.com/${process.env.VUE_APP_CALENDLY_SCHEDULING_LINK}`);
  },
  filterPlan(items, selectedPlan) {
    const allItems = [];
    items.forEach((item) => {
      item[item.feature] = item[selectedPlan];
      const result = pickBy(item, (value, key) => key === item.feature);
      allItems.push(result);
    });
    return allItems;
  },
  getNameInitials(name) {
    return (
      name
        .match(/(\b\S)?/g)
        ?.join("")
        .match(/(^\S|\S$)?/g)
        ?.join("")
        .toUpperCase() || ".."
    );
  },
  getFormattedNumber(number) {
    if (isNaN(number)) return null;
    const nfObject = new Intl.NumberFormat("hi-IN");
    return nfObject.format(number);
  },
  getFormattedINRValue(value) {
    return isNaN(value) ? value : this.toINR(value, false);
  },
  getFormattedINRWithFloatingPoint(value, withRupeeSym = false) {
    return isNaN(value) ? value : this.toINRWithFloatingPoint(value, withRupeeSym);
  },
  saveVisitorInfoZoho(user) {
    window.$zoho.salesiq.visitor.name(user?.name);
    window.$zoho.salesiq.visitor.info({
      org: user?.org?.name,
    });
    window.$zoho.salesiq.visitor.email(user?.email);
  },
  checkElemExistsById(id) {
    return new Promise((resolve, reject) => {
      let counter = 0;
      const checkExist = setInterval(function () {
        counter++;
        if (document.getElementById(id)) {
          clearInterval(checkExist);
          resolve(true);
        }
        if (counter > 10) {
          resolve(false);
          clearInterval(checkExist);
        }
      }, 250);
    });
  },
  async hideZohoSI() {
    if (await this.checkElemExistsById("contact-support-container")) {
      document.getElementById("contact-support-container").style.display = "none";
    }
  },
  async showZohoSI() {
    if (await this.checkElemExistsById("contact-support-container")) {
      document.getElementById("contact-support-container").style.display = "block";
    }
  },
  getOptionsMapperForStrapi(queryName, strapiType = null) {
    return (data) => {
      const options = [];
      data[queryName]?.edges.forEach((item) => {
        if (!strapiType || strapiType === item.node.meta.type) {
          const { id, slug, meta } = item.node;
          options.push({
            id,
            slug,
            meta,
            name: `${slug} (${meta.id})`,
          });
        }
      });
      return options;
    };
  },
  getOptionsMapperForSibTemplates(queryName) {
    return (data) =>
      data[queryName].map((template) => ({
        id: template.id,
        name: `${template.name} (${template.id})`,
      }));
  },
  getLinkMapperForStrapi(collectionType) {
    // convert pascalCase to kebab-case for building url
    const collectionSlug = kebabCase(collectionType);
    return (item) =>
      `${
        process.env.VUE_APP_STRAPI_CMS_URL
      }/admin/plugins/content-manager/collectionType/application::${collectionSlug}.${collectionSlug}/${
        item?.meta?.id || item?.id
      }`;
  },
  async getCompressedImage(file, MAX_HEIGHT, MAX_WIDTH, MIME_TYPE, QUALITY) {
    const blobURL = URL.createObjectURL(file);
    const img = await newImageFromBlob(blobURL);
    URL.revokeObjectURL(this.src);
    const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
    const canvas = document.createElement("canvas");
    canvas.width = newWidth;
    canvas.height = newHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, newWidth, newHeight);
    const compressedBlob = await getCanvasBlob(canvas, MIME_TYPE, QUALITY);
    const compressedImage = new File([compressedBlob], `${file.filename}.jpg`, {
      type: "image/jpeg",
    });
    return compressedImage;
  },
  flushPaginationCachedData(store, resName) {
    const rootQuery = { ...store.data.data.ROOT_QUERY };
    Object.keys(rootQuery).forEach((key) => {
      if (key.startsWith(resName)) {
        delete rootQuery[key];
      }
    });
    // mutating the store with new rootQuery
    store.data.data.ROOT_QUERY = { ...rootQuery };
  },

  getSingularOrPlural(word, count) {
    return count + " " + (count === 1 ? word : word + "s");
  },

  getS3Link(photoUrlKey) {
    if (!photoUrlKey) {
      return "";
    }
    return process.env.VUE_APP_AWS_S3_URL_PREFIX + "/" + photoUrlKey;
  },
};

export function getFitnessValueMultiplierByUnit(rates, unit) {
  return 1 / rates[unit] || 1;
}

export function roundToPrecision(value, digitsAfterDecimal) {
  return Math.round(value * Math.pow(10, digitsAfterDecimal)) / Math.pow(10, digitsAfterDecimal);
}

export function onWheelNumInputBlur(event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
}

export function useHandlebars(text, valuesObject) {
  const template = Handlebars.compile(text);
  return template(valuesObject);
}

export function mapBenefitTypeToIcon(benefitType) {
  const map = {
    gmc: { icon: "hospital", variant: "danger" },
    gpa: { icon: "wheelchair", variant: "warning" },
    gtl: { icon: "bed", variant: "success" },
    covid: { icon: "covid", variant: "primary" },
    dental: { icon: "teeth", variant: "primary" },
    telemedicine: { icon: "mobile", variant: "light-secondary" },
    ppc: { icon: "hospital", variant: "danger" },
  };
  return map[benefitType] ? map[benefitType] : { icon: "hospital", variant: "danger" };
}

export async function nukeStrapiCache(cacheKeyToBeNuked, vueInstance, mutation) {
  const variables = {
    file: null,
    cmdName: "nukeCache",
    args: vueInstance.cmdDef.transformArgs({
      cacheKeyToBeNuked,
    }),
  };
  await vueInstance.$apollo.mutate({
    context: {
      hasUpload: true, // Important!
    },
    mutation,
    variables,
  });
}

export function oldClaimContentStrapiMapper(type) {
  const mapping = {
    cashlessContent: "Cashless",
    emergencyContent: "Emergency",
    reimbursementContent: "Reimbursement",
  };
  return mapping[type];
}

export function getContentFromPolicyClaimContent(content, type) {
  return content?.dynamicContent[0]?.PolicyClaimsProcessContent.find((item) => {
    return item.claimType === type;
  });
}

export function getContentFromOldClaimContent(content, type) {
  return content?.[type]?.dynamicContent?.[0].claimFormsLink;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isVfgErrorPresent(errors) {
  return (
    errors?.filter((err) => {
      return typeof err.error === "string" || (typeof err.error === "object" && !err.error.isWarning);
    }).length > 0
  );
}

export function getFileExtension(filename) {
  return filename.match(FILE_EXTENSION_REGEX)?.[0];
}

export function captureError(errObj, vueInfo = null) {
  if (!errObj) errObj = {}; // for some reason, sometimes errObj is null
  const errorData = {
    error: errObj.message,
    file: errObj.fileName,
    line: errObj.lineNumber,
    stacktrace: errObj.stack,
    location: vueInfo,
    url: window.location.href,
  };
  console.log("capturing error:", errorData);
  window.posthog.capture("client-error", errorData);
}

export function blitzInitializeUser(user) {
  blitz.createUser(user.id);
  blitz.setUserProperties({
    name: user.name,
    orgName: user.org.name,
  });
}

// returns true if any of the values in vfg model object is truthy i.e set
export function isVfgModelSet(obj) {
  if (!obj) return false;
  return Object.keys(obj).some((key) => {
    if (typeof obj[key] === "string") {
      return obj[key] !== "None selected";
    }
    if (typeof obj[key] === "object") {
      return isVfgModelSet(obj[key]);
    }
    return obj[key];
  });
}

export function removeEmpty(obj) {
  Object.keys(obj).forEach(function (key) {
    if (obj[key] && typeof obj[key] === "object") {
      removeEmpty(obj[key]);
    } else if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
}

// Implement error handling when using this function
export function mutateValuesWith(inObject, pattern, value) {
  if (typeof inObject !== "object") {
    throw new Error("first argument of mutateValuesWith should be an object");
  }
  Object.keys(inObject).forEach((key) => {
    if (pattern.test(inObject[key])) {
      inObject[key] = value;
    }
  });
}
export function setBackgroundScroll(value) {
  const body = document.getElementsByTagName("body")[0];
  body.style.overflow = value ? "hidden" : "visible";
}
export function getAdminActiveTab(path) {
  switch (path) {
    case "/admin":
      return { name: "home", route: "/admin" };
    case "/admin/users":
      return { name: "users", route: "/admin/users" };
    case "/admin/orgs":
    case "/admin/orgProperties":
    case "/admin/hr-onboarding":
    case "/admin/integrations":
    case "/admin/orgs/analytics":
      return { name: "orgs", route: "/admin/orgs" };
    case "/admin/policies":
    case "/admin/benefits":
    case "/admin/benefits/analytics":
      return { name: "policies", route: "/admin/policies" };
    case "/admin/insurers":
    case "/admin/tpas":
    case "/admin/hospitals":
      return { name: "insurers", route: "/admin/insurers" };
    case "/admin/clitools":
      return { name: "clitools", route: "/admin/clitools" };
    case "/admin/claims":
    case "/admin/claims/analytics":
      return { name: "claims", route: "/admin/claims" };
    case "/admin/review":
      return { name: "endorsements", route: "/admin/review" };
    case "/admin/CXPods":
    case "/admin/CXPodMembers":
    case "/admin/CXAccountOwners":
    case "/admin/CXPodOnboarding":
      return { name: "cxEntities", route: "/admin/CXPods" };
    case "/admin/jobs":
    case "/admin/forms":
    case "/admin/samlVendorConfigs":
    case "/admin/files":
    case "/admin/fileGroups":
      return { name: "engineering", route: "/admin/jobs" };
    case "/admin/challengeTemplates":
    case "/admin/orgChallenges":
    case "/admin/userChallenges":
    case "/admin/ngageCalendar":
      return { name: "fitness", route: "/admin/challengeTemplates" };
    case "/admin/segments":
    case "/admin/userSegments":
      return { name: "segmentation", route: "/admin/segments" };
    case "/admin/content":
    case "/admin/prospects":
    case "/admin/compare-policy":
      return { name: "miscellaneous", route: "/admin/content" };
    default:
      return { name: "home", route: "/admin" };
  }
}
