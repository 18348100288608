<template lang="pug">
div
  div(v-if="!isMobileView")
    template(v-if="activeScreenName === 'batchesSummary'")
      b-card.shadow-none.mb-3(
        header-bg-variant="transparent",
        bg-variant="transparent",
        body-bg-variant="white",
        body-class="shadow-sm",
      )
        .d-flex.justify-content-between.dashboard-header.pb-3
          .d-flex.align-items-center
            .position-relative
              .text-white
                span.position-absolute.endorsbatch-caltext-1.font-weight-semibold.font-lg {{ dateOnCalendar }}
                span.position-absolute.endorsbatch-caltext-2.font-weight-semibold {{ monthOnCalendar }}
              img.p-3(:src="require('@/assets/images/calendar-3.svg')")
            .my-2
              .hb3.mt-3.mb-0.text-gray-900 {{ `${monthOnCalendar} ${yearOfBatch} Batch` }}
              .text-gray-800.font-sm.mt-1.font-weight-medium.dash-subtitle {{ dashboardSubtitle }}

          .d-flex.align-items-center.text-right
            .p-2.pr-3.vertical-separator
              .text-gray-700.font-weight-semibold.font-sm DAYS REMAINING
              .h3.font-xl.text-gray-900.mt-2 {{ daysLeft }}
            .p-2.pl-3
              .text-gray-700.font-weight-semibold.font-sm SUBMISSION DATE
              .h3.font-xl.text-gray-900.mt-2 {{ normalizedEndingDate }}

        .p-4.mt-4.no-emdorsements-banner(v-if="!currentBatchTotalCount")
          .h4.text-gray-900.font-xl No Endorsements yet!
          .text-gray-800.font-sm.mt-1.font-weight-medium.dash-subtitle {{ `Let’s start by adding new employees to the ${monthOnCalendar} ${yearOfBatch} batch` }}
          b-button.mt-4(
            variant="outline-dark",
            size="sm",
            v-b-modal.add-employee-modal,
          ) Add Employee
          div.endorsement-popover(v-b-tooltip.hover.bottom="noEndorsementTooltipText()")
            b-button.mt-4.ml-2(
              variant="dark",
              size="sm",
              :disabled="isNoEndorsementBatch",
              @click="$root.$emit('bv::show::modal', 'basic-modal')",
              v-b-modal.basic-modal,
             ) {{ isNoEndorsementBatch ? "No Endorsements marked" : "Mark No Endorsements" }}

            basic-modal(
              title="Hold on!",
              :description="`Are you sure you don't have any new employee or dependent related additions, deletions or changes for ${monthOnCalendar} ${yearOfBatch} batch?`",
              proceedBtnText="I am sure ->",
              @cancelBtnClicked="$root.$emit('bv::hide::modal', 'basic-modal')",
              @proceedBtnClicked="markNoEndorsementFromHR")

        .d-flex.px-2.pb-4.pt-5.gap-2(v-else)
          StatusCard(v-for="statusCard of statusCards", v-bind="statusCard", :statusCount="batchesStructure.current.tabs[statusCard.status].count")

      .d-flex.justify-content-between.mt-5.shadow-sm
        div(v-if="org").endorsement-table-container
          .hb4.font-weight-semibold.pt-3.pl-3 Previous Batches

          .mt-3.n-tab-n-table-container
          n-table#endorsment-batches-table(
            :items="ongoingOrCompletedBatches",
            :fields="fields",
            :hover="true",
            :fixed="false",
            ref="endorsment-batches-table",
            @row-clicked="(item) => changeActiveBatch(item)",
            tbody-tr-class="cursor-pointer",
          )
            template(v-slot:monthAndYear="{ data }")
              span.font-sm.font-weight-medium.text-gray-800 {{ `${getMonthYear(data.item.startingAt)}` }}
            template(v-slot:status="{ data }")
              div(v-if="data.item.meta && data.item.meta.markedNoEndorsementByHR")
                span(class="text-primary") NO ENDORSEMENTS
              div(v-else-if="data.item.status === 'ongoing'")
                span(class="text-primary") ACTIVATING BENEFITS
              div(v-else-if="data.item.status === 'overdue'")
                span(class="text-red-600") BATCH OVERDUE
              div(v-else)
                span(class="text-success") ACTIVATION COMPLETED
            template(v-slot:summary="{ data }")
              .d-flex.align-items-center
                span.font-sm.font-weight-semibold.text-gray-900
                  | -> {{ getSingularOrPlural('Change', data.item.meta.changes.count) }}
                  span(v-if="['ongoing', 'overdue'].includes(data.item.status)")  Submitted
                .text-gray-400.mx-2.font-lg.font-weight-extralight
                  .d-flex.align-items-center(
                    v-if="data.item.status === 'completed'"
                  ) |
                    n-chip.border.ml-1(
                      v-if="data.item.meta.approved.count > 0",
                      variant="light",
                      :isMinimal="true",
                    )
                      span.text-gray-900.font-xs.font-weight-medium {{ data.item.meta.approved.count }} Approved
                      template(v-slot:icon)
                        i.icon-check-circle.mr-1.text-success
                    n-chip.border.ml-2(
                      v-if="data.item.meta.rejected.count > 0",
                      variant="light",
                      :isMinimal="true",
                    )
                      span.text-gray-900.font-xs.font-weight-medium {{ data.item.meta.rejected.count }} Rejected
                      template(v-slot:icon)
                        .rotated.mr-1
                          i.icon-plus-circle.text-danger
            template(v-slot:batchChangesTypeSummary="{ data }")
              .d-flex.column.justify-content-between.chip-container-endorsement
                .light-success.mr-1
                  n-chip(chipIcon="add-user", pill, :iconWhite="false")
                    | {{ getSingularOrPlural("Addition", data.item.meta.add.count) }}
                .light-warning.mr-1
                  n-chip(chipIcon="remove-user", pill, :iconWhite="false")
                    | {{ getSingularOrPlural("Deletion", data.item.meta.delete.count) }}
                .light-secondary.mr-1
                  n-chip(chipIcon="rule", pill, :iconWhite="false")
                    | {{ getSingularOrPlural("Data Change", data.item.meta.update.count) }}
            template(v-slot:premiumEstimate="{ data }")
              div.text-gray-900
                i.icon-rupee.ic-small.align-sub
                | {{ getTotalEstimatedPremiumPerBatch(data.item) }}
            template(v-slot:isPaid="{ data }")
              div(v-if="data.item.meta")
                span(:class="getBatchPremiumPaymentStatus(data.item).textColor") {{ getBatchPremiumPaymentStatus(data.item).text }}
            template(v-slot:linkToEndorsements="{ data }")
              i.icon-chevron-right.text-gray-600(
                @click="changeActiveBatch(data.item)"
              )
            template(
              v-if="ongoingOrCompletedBatches && !ongoingOrCompletedBatches.length",
              v-slot:custom-foot="data"
            )
              tr
                td.text-center(:colspan="fields.length")
                  empty-states(type="Endorsements" tableType="previous-endorsements")

    template(v-else)
      n-breadcrumb(
        v-bind="breadcrumbData",
        :currentName="`${getSelectedBatchDate.monthYear} Batch`"
      )
      nova-progress-timeline(
        v-if="!(this.activeScreenName === 'overdue' || isSelectedBatchNoEndorsement)",
        :states="getTimelineStates()",
      )
      template(v-if="this.activeScreenName === 'overdue'")
        .info-container.px-4.my-3.bg-red-100.rounded-8.d-flex.align-items-center.justify-content-start.position-relative
          .icon-container.p-2
            i.text-red-500.icon-error
          .info-details.d-flex.align-items-start.justify-content-start.flex-column
            .info-title.font-md.font-inter.font-weight-semibold.text-gray-900.pl-2.pr-5.pb-1
              | Batch Overdue
            .info-text.font-sm.font-inter.font-weight-medium.text-gray-900.pl-2.pr-4
              | There were changes in this batch that were either missing critical data or were unapproved, hence the batch isn't submitted yet.
              | Please fill in the missing data (if any) as soon as possible and approve the batch.
              | If you need to move changes to the next batch, please contact the Nova team.
      template(v-else-if="isSelectedBatchNoEndorsement")
        .warn-container.px-4.my-3.bg-mustard-100.rounded-8.d-flex.align-items-center.justify-content-start.position-relative
          .icon-container.p-2
            i.text-mustard-600.icon-warning
          .info-details.d-flex.align-items-start.justify-content-start.flex-column
            .info-title.font-md.font-inter.font-weight-semibold.text-gray-900.pl-2.pr-5.pb-1
              | No endorsement batch
            .info-text.font-sm.font-inter.font-weight-medium.text-gray-900.pl-2.pr-4
              | You have marked this batch as "No endorsement".

      summary-card(
        v-if="summaryCardVisible",
        :isCurrentBatch="!selectedBatch || selectedBatch?.status === 'current'",
        :title="`${getSelectedBatchDate.monthYear} Batch`",
        :subtext="summaryCardText",
        :orgId="orgId",
        :batch="selectedBatch || currentBatch",
        @refetchBatch="onRefetchBatch",
        :isDynamicPremiumEnabled="isDynamicPremiumEnabled()",
        :isPremiumBreakupEnabled="org?.featureFlags?.PREMIUM_BREAKUP",
      )
      changes-header(
        v-if="displayChangesHeader()",
        :tabs="getTabs",
        :banner-data="getBannerData",
        :defaultActiveTab="getDefaultActiveTab",
        :orgId="this.orgId",
        :selectedBatchId="getSelectedBatchId",
        :items="activeTable.changes",
        :total-count="activeTable.count",
        :unapproved-data="activeTable.segregatedCount",
        :baseUrl="getBasePath()",
        :is-selected-batch-overdue="isSelectedBatchOverdue",
        @tab-changed="changeActiveTab",
        @row-updated="refreshAffectedTabs",
        :batches="batches",
        @update-batch="updateBatch",
        :buttonDisabledSecondary="selectedBatch?.status==='completed'",
      )
      //- NOTE(ygupta): refer to route definition in admin/index.js for orgId as a param for admin review changes path
      endorsement-table(
        v-if="activeTable",
        :table-type="activeTable.value",
        :items="activeTable.changes",
        :total-count="activeTable.count",
        :batches="batches",
        :selectedBatch="currentBatch",
        :is-selected-batch-overdue="isSelectedBatchOverdue",
        :unapproved-data="activeTable.segregatedCount",
        :orgId="this.$route.params.orgId ? this.$route.params.orgId : this.$store.state.user.org.id",
        @row-updated="refreshAffectedTabs",
        @searchQuery="onSearchQueryInput")
        template(v-slot:pagination)
          .d-flex.justify-content-between
            .d-flex.align-items-center.ml-2.text-gray-800.font-sm.font-weight-medium
              | Showing {{ getOffset(activeTable.currentPage) + 1 }}-{{ getOffset(activeTable.currentPage) + getRelevantRowCount(activeTable) }}
              span(v-if="!activeTableIsMissingData") &nbsp;out of {{ activeTable.count }}
              | &nbsp;records
            n-pagination(
              v-model="activeTable.currentPage",
              :total-rows="activeTable.count",
              :per-page="pageSize",
              align="right"
            )
  div(v-else)
    b-card.px-5.py-10(no-body)
      .row.justify-content-center.my-2
        n-avatar.shadow-none(:image="require('@/assets/images/endorsement-mobile.svg')", :size="8", variant="")
      .row.justify-content-center
        h4.font-weight-semibold Experience it on a desktop!
        p.text-gray-700.font-sm.text-center.px-3 You can utilize this feature more effectively on a desktop.
      .row.justify-content-center
        n-button(buttonText="Go Back", size="lg", @click="$router.go(-1)")
  add-employee-modal
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { groupBy } from "lodash";
import resDefs from "../admin/definitions";
import orgAdminResDefs from "../orgAdmin/definitions";
import orgDef from "../admin/definitions/orgs";
import StatusCard from "./components/EndosementCard.vue";
import AddEmployeeModal from "./components/AddEmployeeModal.vue";
import EndorsementTable from "./components/EndorsementTable.vue";
import ChangesHeader from "./components/ChangesHeader.vue";
import FiltersPanel from "./components/FiltersPanel.vue";
import ReviewChangesModal from "./components/ReviewChangesModal.vue";
import SummaryCard from "./components/SummaryCard.vue";
import NButton from "@/components/NovaButton.vue";
import NChip from "@/components/NovaChip.vue";
import NTable from "@/components/NovaTable.vue";
import NIcon from "@/components/NovaIcon.vue";
import StatsCard from "@/components/Cards/StatsCard.vue";
import NInlineInput from "@/components/NovaInlineInput.vue";
import NBreadcrumb from "@/components/NovaBreadcrumb.vue";
import NModal from "@/components/NovaModal.vue";
import utils from "@/utils";
import NovaProgressTimeline from "@/components/NovaProgressTimeline.vue";
import EmptyStates from "@/components/Cards/EmptyStates/EmptyStateCard.vue";
import NAvatar from "@/components/Avatar.vue";
import BasicModal from "@/components/BasicModal.vue";
import NPopover from "@/components/NovaPopover.vue";

// TODO: Bring back FiltersPanel

export default {
  name: "EndorsementBatches",
  components: {
    NButton,
    NTable,
    NChip,
    NIcon,
    StatsCard,
    NInlineInput,
    StatusCard,
    NModal,
    AddEmployeeModal,
    ChangesHeader,
    ReviewChangesModal,
    EndorsementTable,
    FiltersPanel,
    NovaProgressTimeline,
    SummaryCard,
    NBreadcrumb,
    EmptyStates,
    NAvatar,
    BasicModal,
    NPopover,
  },
  data() {
    const resName = "userChanges";
    const orgAdminResDef = orgAdminResDefs[resName];
    return {
      "enrollment-pending": null,
      approved: null,
      unapproved: null,
      orgAdminResDef,
      orgId: this.$route.params.orgId ? this.$route.params.orgId : this.$store.state.user.org.id,
      searchQuery: "",
      batches: [],
      ongoingOrCompletedBatches: null,
      currentOrUpcomingBatches: null,
      allChanges: [],
      getMonthYear: utils.getMonthYear,
      getDateWithSuffix: utils.getDateWithSuffix,
      getSingularOrPlural: utils.getSingularOrPlural,
      unapprovedChangesCount: 0,
      currentBatch: null,
      selectedBatch: null,
      showListForBatches: null,
      isNoEndorsementBatch: false,
      statusCards: [
        {
          cardIndex: 1,
          statusName: "Missing Data",
          statusDescription: "Changes that are missing important data.",
          ctaText: "View Missing Data ->",
          variant: "red",
          status: "enrollment-pending",
        },
        {
          cardIndex: 2,
          statusName: "Approval Pending",
          statusDescription: "Changes that need HR approval to be processed.",
          ctaText: "Approve pending changes ->",
          variant: "mustard",
          status: "unapproved",
        },
        {
          cardIndex: 3,
          statusName: "Ready for Nova",
          statusDescription: "Changes that are ready to be sent for processing.",
          ctaText: "View Batch ->",
          variant: "malibu",
          status: "approved",
        },
      ],
      activeTab: null,
      activeScreenTab: null,
      batchesStructure: {
        current: {
          tabs: {
            "enrollment-pending": {
              value: "enrollment-pending",
              title: "Missing Data",
              count: 0,
              currentPage: 1,
              bannerData: {
                bannerTitle: "These changes are missing critical data!",
                bannerSubtext: `Please collect the missing data before ${
                  this.getDueDate().dateString
                } for these changes to be processed.`,
                bannerIcon: "question",
                buttonText: "Send reminders to all",
                rightImageIcon: "chevron-right",
                modalName: "send-reminder-modal",
                bannerBackground: "red-100",
                iconColor: "red-600",
              },
              changes: [],
              ...this.getSegregatedCount(),
            },
            unapproved: {
              value: "unapproved",
              title: "Approval Pending",
              count: 0,
              currentPage: 1,
              iconColor: "mustard-600",
              bannerData: {
                bannerTitle: "These changes need your approval to be processed!",
                bannerSubtext: `Approving these will forward them as requests to your insurer on ${
                  this.getDueDate().dateString
                } to be processed.`,
                bannerIcon: "refresh",
                buttonText: "Approve all changes",
                modalName: "review-changes-modal",
                bannerBackground: "mustard-100",
                iconColor: "mustard-600",
                exportFlag: true,
              },
              changes: [],
              ...this.getSegregatedCount(),
            },
            approved: {
              value: "approved",
              title: "Ready for Nova",
              count: 0,
              currentPage: 1,
              bannerData: {
                bannerTitle: "These changes are ready for Nova to be processed!",
                bannerSubtext: `This batch will be sent out on ${this.getDueDate().dateString}.`,
                bannerIcon: "nova-basic",
                bannerBackground: "malibu-100",
                iconColor: "malibu-600",
              },
              hide: false,
              changes: [],
              ...this.getSegregatedCount(),
            },
          },
          defaultActiveTab: this.$route.params.tabName || "unapproved",
        },
        ongoing: {
          tabs: this.$route.path.includes("org-admin/")
            ? null
            : {
                "org-ok": {
                  value: "org-ok",
                  title: "Ready for Nova",
                  count: 0,
                  currentPage: 1,
                  bannerData: {
                    bannerTitle: "These changes need your approval to be processed!",
                    bannerSubtext: `These changes are approved by the HR and are ready to be processed. You can verify all the changes and approve it to send it to our Partners.`,
                    bannerIcon: "refresh",
                    buttonText: "Approve all changes",
                    bannerBackground: "mustard-100",
                    modalName: "review-changes-modal",
                    iconColor: "mustard-600",
                    exportFlag: true,
                  },
                  changes: [],
                  ...this.getSegregatedCount(),
                },
                "nova-ok": {
                  value: "nova-ok",
                  title: "Ready for Insurer",
                  count: 0,
                  currentPage: 1,
                  bannerData: {
                    bannerTitle: "These changes need your approval to be processed!",
                    bannerSubtext: `These changes are verified by Nova and are ready to be shared with the Insurer. You can approve all the changes after getting a final confirmation from the Insurer and mapping the ecards to the employees.`,
                    bannerIcon: "refresh",
                    buttonText: "Approve all changes",
                    modalName: "review-changes-modal",
                    bannerBackground: "mustard-100",
                    iconColor: "mustard-600",
                    exportFlag: true,
                  },
                  changes: [],
                  ...this.getSegregatedCount(),
                },
                done: {
                  value: "done",
                  title: "Completed",
                  count: 0,
                  currentPage: 1,
                  bannerData: {
                    bannerTitle: "These changes are completed",
                    bannerSubtext: "",
                    bannerIcon: "refresh",
                    bannerBackground: "mustard-100",
                    iconColor: "mustard-600",
                    exportFlag: true,
                  },
                  changes: [],
                  ...this.getSegregatedCount(),
                },
                rejected: {
                  value: "rejected",
                  title: "Rejected",
                  count: 0,
                  currentPage: 1,
                  bannerData: {
                    bannerTitle: "These changes were rejected",
                    bannerSubtext: "",
                    bannerIcon: "refresh",
                    bannerBackground: "mustard-100",
                    iconColor: "mustard-600",
                    exportFlag: true,
                  },
                  changes: [],
                  ...this.getSegregatedCount(),
                },
              },
          count: 0,
          currentPage: 1,
          changes: [],
          value: "ongoing",
          defaultActiveTab: this.$route.params.tabName || "org-ok",
        },
        overdue: {
          tabs: {},
          defaultActiveTab: this.$route.params.tabName || "unapproved",
        },
        completed: {
          tabs: null,
          count: 0,
          currentPage: 1,
          bannerData: {
            bannerTitle: "These changes have already been processed",
            bannerSubtext: "",
            bannerIcon: "nova-basic",
            bannerBackground: "teal-100",
            iconColor: "teal-600",
            exportFlag: true,
            exportButtonVariant: "dark",
          },
          changes: [],
          ...this.getSegregatedCount(),
          value: "completed",
        },
      },
      pageSize: 10,
      batchIds: [],
      breadcrumbData: {
        rootName: "Endorsements",
        rootUrl: this.getBasePath(),
        rootIcon: "stroke-endorsements-3",
      },
      tabAlreadyRefreshed: false,
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    summaryCardText() {
      return this.selectedBatch
        ? `Submitted on ${this.getSelectedBatchDate.submitDate}`
        : `Submission on ${this.monthOnCalendar} ${this.dateOnCalendar} ${this.yearOfBatch}`;
    },
    fields() {
      const rowStyling = ["align-middle"];
      const fields = [
        {
          key: "monthAndYear",
          label: "Batch Name",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "status",
          label: "Batch status",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "summary",
          label: "Changes status",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "numberOfAdditions",
          label: "",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "numberOfDeletions",
          label: "",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "numberOfUpdates",
          label: "",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "batchChangesTypeSummary",
          label: "Endorsement type",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "dateOfSubmission",
          label: "",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "premiumEstimate",
          label: "Premium Estimation",
          tdClass: rowStyling,
          visible: this.org?.featureFlags?.PREMIUM_BREAKUP,
        },
        {
          key: "isPaid",
          label: "Payment Status",
          tdClass: rowStyling,
          visible: this.org?.featureFlags?.PREMIUM_BREAKUP,
        },
        {
          key: "linkToEndorsements",
          label: "",
          tdClass: rowStyling,
          visible: true,
        },
      ];
      return fields.filter((field) => field.visible);
    },
    checkIfCompletedBatch() {
      return this.activeScreenName === "completed";
    },
    getDefaultActiveTab() {
      return !this.checkIfCompletedBatch ? this.batchesStructure[this.activeScreenName].defaultActiveTab : "";
    },
    getTabs() {
      return !this.checkIfCompletedBatch ? Object.values(this.batchesStructure[this.activeScreenName].tabs) : [];
    },
    summaryCardVisible() {
      return (
        ["ongoing", "overdue", "completed"].includes(this.activeScreenName) ||
        (this.isDynamicPremiumEnabled && this.activeScreenName === "current")
      );
    },
    getBannerData() {
      return (
        this.batchesStructure[this.activeScreenName].bannerData ??
        this.batchesStructure[this.activeScreenName].tabs[this.activeTable.value].bannerData
      );
    },
    activeTableIsMissingData() {
      return this.activeTable.title === "Missing Data";
    },
    currentBatchTotalCount() {
      return Object.values(this.batchesStructure.current.tabs).reduce((acc, status) => acc + status.count, 0);
    },
    endingDate() {
      return this.currentBatch?.endingAt || utils.getLastDateOfMonth(new Date());
    },
    normalizedEndingDate() {
      return moment(this.endingDate).format("MMMM DD YYYY");
    },
    monthOnCalendar() {
      return utils.getMonthShortForm(utils.getMonth(this.endingDate).toLowerCase());
    },
    dateOnCalendar() {
      return utils.getDate(this.endingDate);
    },
    yearOfBatch() {
      return moment(this.endingDate).year();
    },
    daysLeft() {
      return utils.getDateDifference(this.endingDate);
    },
    dashboardSubtitle() {
      return `These are the endorsements for the month of ${this.monthOnCalendar}. Any new employee additions done before ${this.monthOnCalendar} ${this.dateOnCalendar} will be added to this batch.`;
    },
    isBatchesDisplayed() {
      return this.currentlyDisplaying === "batchesSummary";
    },
    getDateOfSubmission() {
      const batch = this.filterBatchById(this.batchType, this.batchId);
      return batch?.endingAt ? this.getDateWithSuffix(batch.endingAt) : "";
    },
    isMobileView() {
      return utils.mobileCheck();
    },
    activeScreenName() {
      if (this.$route.name === "draft") {
        return "current";
      } else if (this.selectedBatch?.status) {
        return this.selectedBatch.status;
      }
      return "batchesSummary";
    },
    activeTable() {
      const currentScreen = this.activeScreenName;
      if (currentScreen === "current") {
        return this.batchesStructure[currentScreen]?.tabs[this.$route.params.tabName];
      } else if (["ongoing", "overdue"].includes(currentScreen)) {
        if (!this.$route.path.includes("org-admin/") || currentScreen === "overdue") {
          return this.batchesStructure[currentScreen]?.tabs[this.$route.params.tabName];
        } else {
          return this.batchesStructure[currentScreen];
        }
      } else if (currentScreen === "completed") {
        return this.batchesStructure[currentScreen];
      }
      return "";
    },
    getSelectedBatchDate() {
      return {
        submitDate: this.getDateWithSuffix(this.selectedBatch?.endingAt) || "",
        monthYear: this.getMonthYear(this.selectedBatch?.startingAt) || "",
      };
    },
    viaSuperAdminScreen() {
      return !this.$route.path.includes("org-admin/");
    },
    getSelectedBatchId() {
      return this.selectedBatch?.id || this.currentBatch?.id;
    },
    isSelectedBatchOverdue() {
      return this.selectedBatch?.status === "overdue";
    },
    isSelectedBatchNoEndorsement() {
      return this.selectedBatch?.meta?.markedNoEndorsementByHR;
    },
  },
  watch: {
    "currentBatch.meta.markedNoEndorsementByHR": function () {
      this.isNoEndorsementBatch = !!this.currentBatch?.meta?.markedNoEndorsementByHR;
    },
    "activeTable.currentPage": function () {
      if (this.activeTable && this.$apollo.queries[this.activeTable.value] && !this.tabAlreadyRefreshed) {
        this.$apollo.queries[this.activeTable.value].fetchMore({
          variables: {
            offset: this.getOffset(this.activeTable.currentPage),
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            // TODO: The previous result can be further utilized to reduce number of
            // queries run when traversing back and forth in pagination
            const transformedClubbedData = utils.deepClone(
              this.orgAdminResDef.transformGetClubbedUserChanges(fetchMoreResult)
            );
            this.activeTable.changes = transformedClubbedData.changes;
          },
        });
      }
      this.tabAlreadyRefreshed = false;
    },
    activeScreenName(newScreen) {
      if (this.activeScreenName !== "batchesSummary") {
        if (this.$route.params.orgId) {
          window.posthog.capture("endorsements_previous_batch_click", {
            batch_submitted_on: this.endingDate,
            org_name: this.user?.org?.name,
            email: this.user?.email,
          });
        } else {
          window.posthog.capture("endorsement_current_batch_action", {
            section_clicked: this.activeScreenName,
            day_of_month: new Date().getDate(),
            org_name: this.user?.org?.name,
            email: this.user?.email,
          });
        }
      }
    },
  },
  mounted() {
    if (this.activeScreenName !== "batchesSummary") {
      if (this.$route.params.orgId) {
        window.posthog.capture("endorsements_previous_batch_click", {
          batch_submitted_on: this.endingDate,
          org_name: this.user?.org?.name,
          email: this.user?.email,
        });
      } else {
        window.posthog.capture("endorsement_current_batch_action", {
          section_clicked: this.activeScreenName,
          day_of_month: new Date().getDate(),
          org_name: this.user?.org?.name,
          email: this.user?.email,
        });
      }
    }
    if (this.$route.query.markNoEndorsements) {
      this.$bvModal.show("basic-modal");
      this.$router.replace({ query: null });
    }
  },
  async created() {
    if (!this.$route.path.includes("org-admin/")) {
      this.batchesStructure.current.tabs = {
        ...this.batchesStructure.current.tabs,
        ...this.batchesStructure.ongoing.tabs,
      };
      this.batchesStructure.current.tabs.approved.hide = true;
    }
    this.batchesStructure.overdue.tabs = {
      ...this.batchesStructure.current.tabs,
    };
    const { data } = await this.$apollo.query({
      query: resDefs.userChangeBatches.listQuery,
      variables: {
        filter: {
          orgId: this.orgId,
        },
      },
    });
    this.batches = data.userChangeBatches.edges.map((n) => n.node);
    // TODO: Change ongoingOrCompletedBatches name to more appropriate, skipping to prevent other incoming changes from breaking
    this.ongoingOrCompletedBatches = this.batches.filter((batch) =>
      ["ongoing", "overdue", "completed"].includes(batch.status)
    );
    this.currentBatch = this.batches.filter((batch) => batch.status === "current")[0];
    this.selectedBatch = this.batches.filter((batch) => batch.id === this.$route.params?.batchId || "")[0];
    this.currentOrUpcomingBatches = this.batches.filter((batch) => ["current", "upcoming"].includes(batch.status));
    this.fetchDataForActiveScreen();
  },
  methods: {
    async updateBatch(newBatchId) {
      const batchId = this.getSelectedBatchId;

      await this.$apollo.mutate({
        mutation: resDefs.userChanges.transferUserChangeBetweenBatches,
        variables: {
          for: "batchId",
          newBatchId,
          batchId,
        },
      });
      this.refreshAffectedTabs();
    },
    async markNoEndorsementFromHR() {
      let result;
      try {
        result = await this.$apollo.mutate({
          mutation: resDefs.userChangeBatches.markNoEndorsementFromHR,
          variables: {
            batchId: this.currentBatch?.id,
          },
        });
      } catch (e) {
        console.error("API returned with an error");
      }
      if (result?.data?.markNoEndorsementFromHR?.success) {
        this.$store.commit("addAlert", {
          variant: "success",
          message: `"No endorsement" marked for the ${this.monthOnCalendar} ${this.yearOfBatch} batch`,
        });
      } else {
        this.$store.commit("addAlert", {
          variant: "danger",
          message: `Unable to mark "No Endorsement" for the ${this.monthOnCalendar} ${this.yearOfBatch} batch`,
        });
      }
      this.$bvModal.hide("basic-modal");
      this.$router.go();
    },
    isDynamicPremiumEnabled() {
      return (
        !!this.org?.featureFlags?.RATER_BASED_PREMIUM_ESTIMATION &&
        !(
          !process.env.VUE_APP_DYNAMIC_PREMIUM_ESTIMATION ||
          process.env.VUE_APP_DYNAMIC_PREMIUM_ESTIMATION?.toLowerCase() === "false"
        )
      );
    },
    noEndorsementTooltipText() {
      return this.isNoEndorsementBatch
        ? "This status will automatically change whenever there is any new endorsement request."
        : "";
    },
    async onSearchQueryInput(value) {
      if (this.activeScreenTab) {
        const variables = await this.$apollo.queries[`${this.activeScreenTab}`].options.variables();
        variables.filter.query = value;
        this.$apollo.queries[`${this.activeScreenTab}`].refetch();
        return;
      }
      const variables = await this.$apollo.queries[this.activeScreenName].options.variables();
      variables.filter.query = value;
      this.$apollo.queries[this.activeScreenName].refetch();
    },
    getRelevantRowCount(activeTable) {
      return this.activeTableIsMissingData
        ? activeTable.changes.length
        : Object.keys(
            groupBy(activeTable.changes, (change) => {
              return change.user_id + "," + change.type;
            })
          ).length;
    },
    getBasePath() {
      return this.$route.path.includes("org-admin/")
        ? "/org-admin/changes"
        : `/admin/review/${this.$route.params.orgId}/changes`;
    },
    getOffset(currentPage, pageSize = 10) {
      return (currentPage - 1) * pageSize;
    },
    displayChangesHeader() {
      return (
        !(this.$route.path.includes("org-admin/") && ["ongoing", "completed"].includes(this.activeScreenName)) &&
        (this.batchesStructure[this.activeScreenName].tabs || this.checkIfCompletedBatch)
      );
    },
    getDueDate() {
      const dueDate = utils.getLastDateOfMonth(new Date());
      return {
        dateString: utils.getDateWithSuffix(dueDate),
        remainingDays: `${utils.getSingularOrPlural("Day", utils.getDateDifference(dueDate))}`,
      };
    },
    changeActiveBatch(activeBatch) {
      let path;
      if (activeBatch.status === "current") {
        path = "changes/draft";
        this.showListForBatches = this.currentOrUpcomingBatches;
      } else if (["ongoing", "overdue"].includes(activeBatch.status)) {
        path = `changes/${activeBatch.status}/${activeBatch.id}`;
        if (!this.$route.path.includes("org-admin/") || activeBatch.status === "overdue") {
          path += `/${this.batchesStructure[activeBatch.status].defaultActiveTab}`;
        }
      } else if (activeBatch.status === "completed") {
        path = `changes/${activeBatch.status}/${activeBatch.id}`;
      }
      this.$router.push({ path });
    },
    changeActiveTab(activeTab) {
      this.activeScreenTab = activeTab;
      if (this.$route.params.tabName !== activeTab && this.activeScreenName !== "batchesSummary") {
        this.activeTab = this.batchesStructure[this.activeScreenName].tabs[activeTab];
        if (this.activeScreenName === "current") {
          this.$router.push({
            path: `${this.getBasePath()}/draft/${activeTab}`,
          });
        } else {
          this.$router.push({
            path: `${this.getBasePath()}/${this.activeScreenName}/${this.$route.params.batchId}/${activeTab}`,
          });
        }
      }
    },
    refetchTableData() {
      Object.keys(this.batchesStructure[this.batchType].tabs).map((tabName) => {
        this.$apollo.queries[`${this.batchType}-${tabName}`].refetch();
      });
    },
    openReviewChangesModal() {
      this.$bvModal.show("review-changes-modal");
    },
    getTimelineStates() {
      const states = {
        "Collecting employee information": {
          heading: "Collecting Employee Information",
          info: `Collect and approve all endorsements data for this batch before it goes out to Nova on ${this.getSelectedBatchDate.submitDate}.`,
        },
        "Activating Benefits": {
          heading: "Activating Benefits",
          info: "Your batch is being processed by Nova to activate benefits.",
        },
        "Activation completed": {
          heading: "Activation Completed",
          info: "Your batch is processed and benefits have been activated by Nova.",
        },
      };

      const stateTypes = {
        completed: {
          headingColor: "gray-900",
          color: "teal-700",
          lineColor: "teal-700",
          status: "Completed",
          icon: "check-circle",
          chipVariant: "light-success",
        },
        ongoing: {
          headingColor: "gray-900",
          color: "mustard-600",
          lineColor: "mustard-600",
          status: "Ongoing",
          icon: "progress-1-wider",
          chipVariant: "light-warning",
        },
        pending: {
          headingColor: "gray-700",
          color: "gray-700",
          lineColor: "gray-500",
          status: "Pending",
          icon: "progress-1-thinner",
          chipVariant: "extra-light-secondary",
        },
      };

      switch (this.activeScreenName) {
        case "current":
          return [
            {
              ...states["Collecting employee information"],
              ...stateTypes.ongoing,
            },
            { ...states["Activating Benefits"], ...stateTypes.pending },
            { ...states["Activation completed"], ...stateTypes.pending },
          ];
        case "ongoing":
          return [
            {
              ...states["Collecting employee information"],
              ...stateTypes.completed,
            },
            { ...states["Activating Benefits"], ...stateTypes.ongoing },
            { ...states["Activation completed"], ...stateTypes.pending },
          ];
        case "completed":
          return [
            {
              ...states["Collecting employee information"],
              ...stateTypes.completed,
            },
            { ...states["Activating Benefits"], ...stateTypes.completed },
            { ...states["Activation completed"], ...stateTypes.completed },
          ];
      }
    },
    addSmartQueryForTable(queryName, clubbingData, cb) {
      clubbingData.variables.offset = this.getOffset(clubbingData.currentPage);
      this.$apollo.addSmartQuery(queryName, {
        query: this.orgAdminResDef.getClubbedUserChanges,
        variables: () => clubbingData.variables,
        skip: () => {
          return !clubbingData.variables.filter.batchIds.length;
        },
        update: (data) => {
          const transformedClubbedData = utils.deepClone(this.orgAdminResDef.transformGetClubbedUserChanges(data));
          clubbingData.changes = transformedClubbedData.changes;
          clubbingData.count = transformedClubbedData.totalCount;
          clubbingData.segregatedCount = transformedClubbedData.segregatedCount;
          if (cb) cb();
        },
      });
    },
    fetchDataForActiveScreen() {
      const variables = {
        limit: this.pageSize,
        filter: {
          orgId: this.orgId,
          types: [],
          query: this.searchQuery,
        },
      };
      const activeScreen = this.activeScreenName;
      if (["batchesSummary", "current"].includes(activeScreen)) {
        // in both these cases we need the current type clubbing data.
        // batch data by default is fetched below
        Object.keys(this.batchesStructure.current.tabs).map((tabName) => {
          const clubbingData = this.batchesStructure.current.tabs[tabName];
          clubbingData.variables = utils.deepClone(variables);
          clubbingData.variables.filter.clubbingType = tabName;
          let types = [];
          if (tabName === "enrollment-pending" || tabName === "unapproved") {
            types = ["draft"];
            clubbingData.variables.filter.batchIds = this.currentOrUpcomingBatches.map((batch) => batch.id);
          } else if (tabName === "approved") {
            types = ["org-ok", "nova-ok", "done"];
          } else if (this.viaSuperAdminScreen) {
            types = [tabName];
            clubbingData.variables.filter.batchIds = this.currentOrUpcomingBatches.map((batch) => batch.id);
          }
          clubbingData.variables.filter.types.push(...types);
          if (tabName === "approved") {
            clubbingData.variables.filter.batchIds = this.currentOrUpcomingBatches.map((batch) => batch.id);
          }
          this.addSmartQueryForTable(tabName, clubbingData, () => {
            if (this.batchesStructure.current.defaultActiveTab === tabName) {
              this.changeActiveTab(this.batchesStructure.current.defaultActiveTab);
            }
          });
          clubbingData.value = tabName;
        });
      } else if (activeScreen === "overdue") {
        Object.keys(this.batchesStructure[this.activeScreenName].tabs).map((tabName) => {
          const clubbingData = this.batchesStructure[this.activeScreenName].tabs[tabName];
          clubbingData.variables = utils.deepClone(variables);
          clubbingData.variables.filter.clubbingType = tabName;
          clubbingData.variables.filter.batchIds = [this.$route.params.batchId];
          let types = [];
          if (tabName === "enrollment-pending" || tabName === "unapproved") {
            types = ["draft"];
          } else if (tabName === "approved") {
            types = ["org-ok", "nova-ok", "done"];
          } else if (this.viaSuperAdminScreen) {
            types = [tabName];
          }
          clubbingData.variables.filter.types.push(...types);
          this.addSmartQueryForTable(tabName, clubbingData, () => {
            if (this.batchesStructure[this.activeScreenName].defaultActiveTab === tabName) {
              this.changeActiveTab(this.batchesStructure[this.activeScreenName].defaultActiveTab);
            }
          });
          clubbingData.value = tabName;
        });
      } else {
        if ((!this.$route.path.includes("org-admin/") && activeScreen === "ongoing") || activeScreen === "overdue") {
          Object.keys(this.batchesStructure[this.activeScreenName].tabs).forEach((tabName) => {
            const clubbingData = this.batchesStructure[this.activeScreenName].tabs[tabName];
            clubbingData.variables = utils.deepClone(variables);
            clubbingData.variables.filter.types.push(tabName);
            clubbingData.variables.filter.batchIds = [this.$route.params.batchId];
            this.addSmartQueryForTable(tabName, clubbingData, () => {
              if (this.batchesStructure[this.activeScreenName].defaultActiveTab === tabName) {
                this.changeActiveTab(this.batchesStructure[this.activeScreenName].defaultActiveTab);
              }
            });
            clubbingData.value = tabName;
          });
        } else {
          const clubbingData = this.batchesStructure[activeScreen];
          clubbingData.variables = utils.deepClone(variables);
          clubbingData.variables.filter.clubbingType = activeScreen;
          clubbingData.variables.filter.types = ["draft", "org-ok", "nova-ok", "done", "rejected"];
          clubbingData.variables.filter.batchIds = [this.$route.params.batchId];
          this.addSmartQueryForTable(activeScreen, clubbingData, () => {});
          clubbingData.value = activeScreen;
        }
      }
    },
    async refreshAffectedTabs(affectedTabs) {
      // TODO: (NV-693) Affected tabs will be passed once we implement overdue batches
      if (!affectedTabs) {
        affectedTabs = Object.keys(this.batchesStructure[this.activeScreenName].tabs);
      }
      affectedTabs.forEach((affectedTab) => {
        this.$apollo.queries[affectedTab].refetch();
      });
      // Refetch batch
      await this.onRefetchBatch(this.getSelectedBatchId);
      this.activeTable.currentPage = 1;
      this.tabAlreadyRefreshed = true;
    },
    getSegregatedCount() {
      return {
        segregatedCount: {
          add: 0,
          delete: 0,
          update: 0,
        },
      };
    },
    getBatchPremiumPaymentStatus(batch) {
      return utils.getBatchPremiumPaymentStatus(batch, this.org);
    },
    getTotalEstimatedPremiumPerBatch(item) {
      return utils.getTotalEstimatedPremiumAcrossInsurers(item);
    },
    async onRefetchBatch(id) {
      const { data } = await this.$apollo.query({
        query: resDefs.userChangeBatches.singleQuery,
        variables: { id },
        fetchPolicy: "no-cache",
      });

      this.updateBatchData(this.batches, data.node);
      this.updateBatchData(this.ongoingOrCompletedBatches, data.node);
      this.updateBatchData(this.currentOrUpcomingBatches, data.node);

      if (data.node.status === "current") {
        this.currentBatch = data.node;
      }
      this.selectedBatch = data.node;
    },
    updateBatchData(batches, updatedBatch) {
      const index = batches.findIndex((batch) => batch.id === updatedBatch.id);
      if (index !== -1) {
        batches[index] = updatedBatch;
      }
    },
  },
  apollo: {
    org: {
      query: orgDef.singleQuery,
      skip() {
        return !this.orgId;
      },
      variables() {
        return {
          id: this.orgId,
        };
      },
      update(data) {
        return data.node;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.n-tab-n-table-container {
  .nav-tabs > .nav-item > a,
  .nav-tabs > .nav-item > a:focus,
  .nav-tabs > .nav-item > a:hover {
    border: 0;
  }
}
.rotated {
  transform: rotate(135deg);
}

.endorsbatch-caltext-1 {
  top: 32%;
  left: 37%;
}
.endorsbatch-caltext-2 {
  font-size: 6px;
  top: 55%;
  left: 43%;
}

.dash-subtitle {
  max-width: 30vw;
}

.vertical-separator {
  border-right: 1px solid $gray-400;
}

.dashboard-header {
  border-bottom: 1px solid $gray-300;
}

.no-emdorsements-banner {
  border-radius: $border-radius;
  background: $blue-100 url("~@/assets/images/emp-card.svg") right no-repeat;
}

.checkbox-width1 {
  padding-left: 7px !important;
  padding-right: 0px !important;
}

.endorsement-table-container {
  width: -webkit-fill-available;
}

.endorsement-popover {
  display: inline-block;
}

.chip-container-endorsement {
  width: 90%;
}

.light-success {
  .n-chip {
    background-color: $teal-100 !important;
    color: $teal-800 !important;
  }
}
.light-warning {
  .n-chip {
    background-color: $mustard-100 !important;
    color: $mustard-800 !important;
  }
}
.light-secondary {
  .n-chip {
    background-color: $gray-100 !important;
    color: $gray-900 !important;
    border: $border-width-custom-1 solid $gray-300 !important;
  }
}

.info-container {
  height: 6rem;
  border: 1px solid $red-500;
}
.warn-container {
  height: 4rem;
  border: 1px solid $mustard-400;
}
.icon-container {
  width: 2rem;
  border-radius: 50%;
  height: 4rem;
}
</style>
