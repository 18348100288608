import users from "./users";
import userChanges from "./userChanges";
import orgs from "./orgs";
import claims from "./claims";
import forms from "./forms";
import insurers from "./insurers";
import tpas from "./tpas";
import content from "./content";
import hospitals from "./hospitals";
import policies from "./policies";
import benefits from "./benefits";
import prospects from "./prospects";
import cliTools from "./cliTools";
import strapiContents from "./strapiContents";
import integrations from "./integrations";
import orgProperties from "./orgProperties";
import jobs from "./jobs";
import userChangeBatches from "./userChangeBatches";
import challengeTemplates from "./challengeTemplates";
import orgChallenges from "./orgChallenges";
import userChallenges from "./userChallenges";
import segments from "./segments";
import userSegments from "./userSegments";
import ngageCalendars from "./ngageCalendars";
import samlVendorConfigs from "./samlVendorConfigs";
import files from "./files";
import CXPods from "./CXPods";
import CXPodMembers from "./CXPodMembers";
import CXAccountOwners from "./CXAccountOwners";
import CXPodOnboarding from "./CXPodOnboarding";
import orgOnboardingStageTasks from "./orgOnboardingStageTasks";
import orgOnboardingStageTaskUpdates from "./orgOnboardingStageTaskUpdates";
import fileGroups from "./fileGroups";

export default {
  users,
  userChanges,
  orgs,
  claims,
  forms,
  insurers,
  tpas,
  content,
  hospitals,
  policies,
  benefits,
  prospects,
  cliTools,
  strapiContents,
  integrations,
  orgProperties,
  jobs,
  userChangeBatches,
  challengeTemplates,
  orgChallenges,
  userChallenges,
  segments,
  userSegments,
  ngageCalendars,
  samlVendorConfigs,
  files,
  CXPods,
  CXPodMembers,
  CXAccountOwners,
  CXPodOnboarding,
  orgOnboardingStageTasks,
  orgOnboardingStageTaskUpdates,
  fileGroups,
};
