<template lang="pug">
  b-tab(:class="active ? 'active-tab': ''", title-link-class="nova-tab", v-bind="{...$props, ...$attrs}",
    v-on="$listeners")
    template(#title)
      span(v-if="toastIcon")
        span(:class="`nav-icon icon-${toastIcon}`")
      span {{ navContent }}
      n-chip(v-if="pillContent", variant="secondary", :pill="true") {{ pillContent }}
    template(#default)
      slot(name="default")
</template>

<script>
import NChip from "@/components/NovaChip.vue";

export default {
  components: {
    NChip,
  },
  props: {
    toastIcon: {
      type: String,
      default: null,
    },
    navContent: {
      type: String,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    pillContent: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.nova-tab {
  &.nav-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: $gray-500;
    font-weight: 600;
    font-size: 0.875rem;
    border: none;
    padding-bottom: 1rem;
    .nav-icon {
      vertical-align: text-bottom;
    }
    .n-chip {
      background-color: $gray-500 !important;
    }

    &:hover {
      color: $gray-900;
      .n-chip {
        background-color: $gray-900 !important;
      }
    }

    &.active {
      border-bottom: 2px solid $tiber;
      color: $tiber;
      .n-chip {
        background-color: $blackpearl !important;
      }
    }
  }
}
</style>
