<template lang="pug">
span.d-flex
  template(v-for="metaData in normalItems")
    n-popover-chip.mr-1.my-1(
      variant="white",
      :set="(popoverContent = getPopOverData(metaData.status, metaData.benefitsType.toUpperCase(), changesType))",
      :chipIcon="statusToIcon(metaData.status)",
      :isMinimal="true",
      :chipId="`${metaData.id}-${metaData.status}-${itemId}`",
      :popoverTitle="popoverContent.title",
      :popoverHeading="popoverContent.heading",
      :status="metaData.status",
      :chipText="getChipTextForNItem(metaData)",
      :popoverDate="getDueDate().year",
      :popoverDescription="popoverContent.description",
      :popoverSubTitle="popoverContent.subTitle",
      :triggers="triggers")
      template(v-slot:icon)
        n-icon.mr-1(
          :name="statusToIcon(metaData.status)",
          :pathCount="2",
          :size="iconSize")
  template(v-if="plusItems?.length > 0")
    plus-n-chip.my-1(
      :id="plusItems[0].id",
      :chipText="plusItemChipText",
      :remainingItemsCount="plusItems.length")
    n-popover.custom-popup(
      :target="plusItems[0].id",
      :triggers="triggers",
      :set="(popoverContent = plusItems)",
      custom-class="custom-popup")
      template(v-slot:title)
        .d-flex.align-items-center.px-6
          n-icon.mr-1.m-1(
            name="benefits",
            :pathCount="2",
            variant="purple-600")
          div All {{ plusItemChipText }}
      template(v-slot:default)
        div
          hr.my-0
          template(v-for="benefit in popoverContent")
            .text-gray-900.font-weight-medium.font-sm.my-2 {{ benefit.benefitName }}
            hr.my-0
  template(v-if="statusText")
    b-badge.status-badge.align-self-center.mr-1.my-1.p-2(pill, :variant="statusChipVariant") {{ statusText }}
</template>

<script>
import PlusNChip from "./PlusNChip";
import NPopoverChip from "@/components/NovaPopoverChip.vue";
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";
import NChip from "@/components/NovaChip.vue";
import NModal from "@/components/NovaModal.vue";
import NPopover from "@/components/NovaPopover.vue";

export default {
  name: "PillsGroup",
  components: {
    NButton,
    PlusNChip,
    NChip,
    NModal,
    NPopoverChip,
    NPopover,
  },
  props: {
    itemId: {
      type: String,
    },
    statusChipVariant: {
      type: String,
    },
    statusText: {
      type: String,
    },
    popoversData: {
      type: Object,
      default: () => {},
    },
    normalItems: {
      type: Array,
      default: () => [],
    },
    plusItems: {
      type: Array,
      default: () => [],
    },
    plusItemChipText: {
      type: String,
      default: "Benefits",
    },
    triggers: {
      type: String,
      default: "",
      validator: (propValue) => {
        const isValidTrigger = ["hover", "click"].includes(propValue);
        return isValidTrigger || !propValue;
      },
    },
    iconSize: {
      type: Number,
      default: 1.0,
    },
    changesType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {
    this.$options.getDateWithSuffix = utils.getDateWithSuffix;
  },
  methods: {
    isMobileView() {
      return utils.mobileCheck();
    },
    statusToIcon(status) {
      return utils.statusToIconDetails(status);
    },
    getDueDate() {
      const dueDate = utils.getLastDateOfMonth(new Date());
      return {
        dateString: this.$options.getDateWithSuffix(dueDate),
        month: utils.getMonth(dueDate),
        year: utils.getMonthYear(dueDate),
      };
    },
    getChipTextForNItem(data) {
      return data.benefitsType.toUpperCase();
    },
    getPopOverData(status, benefit, type) {
      type = type === "update" ? "add" : type;
      const popover = this.popoversData[type][status];
      let title = popover?.title;
      let subTitle = popover?.subTitle;
      const description = popover?.description;
      if (status !== "done" && status !== "archive") {
        title = benefit + popover?.title;
        subTitle = subTitle + this.getDueDate().dateString;
      }
      return { heading: popover?.heading, title, description, subTitle };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.custom-popup {
  text-align: center;
  hr {
    width: 100%;
    border-color: $gray-300;
  }
  .text-purple-600 {
    .path1:before {
      color: $purple-600 !important;
    }
    .path2:before {
      color: $purple-600 !important;
    }
  }
}

@media (max-width: 600px) {
  .status-badge {
    order: -1;
  }
}
</style>
