import moment from "moment";
import { AcceptedRelations } from "../common/enums";

const IND_MOBILE_NUMBER_REGEXP = /^[6-9]\d{9}$/;
const PASSWORD_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@$!%*#&^]).{8,32}$/g;
const EMPLOYEE_ID_REGEX = /^[a-zA-Z0-9_@\- ]+$/;

export default {
  validateIndianMobileNumbers(number) {
    return number.toString().match(IND_MOBILE_NUMBER_REGEXP);
  },
  validateIndianMobileNumbersForVfg(num) {
    if (!num || num.toString().match(IND_MOBILE_NUMBER_REGEXP)) return [];
    return ["Invalid phone number!"];
  },
  // This function is a validator for VFG. That fixes the validation issue with vfg nested fields
  // To use this make sure validateAsync is true in form options.
  async validateChildFields(value) {
    const errors = [];
    const nestedVFG = this.$children[0];
    await nestedVFG.validate();
    if (nestedVFG.errors.length > 0) {
      // This error message wont be visible anywhere
      errors.push("Subfields are invalid!");
    }
    return errors;
  },
  validateInlineSelect(value) {
    if (value === "None selected") return ["None Selected"];
    return [];
  },
  validateDateOfAdmissionDischargeForVfg(dod, _self, formValues) {
    if (!dod) return [];
    if (!formValues.doa) return ["Add Date of Admission first"];
    if (moment(dod).isBefore(moment(formValues.doa))) return ["Date of Discharge can't be before Date of Admission"];
    return [];
  },
};

export function validateEmployeeAge(dob) {
  if (!dob) return [];
  if (moment(dob).isAfter(moment().subtract(18, "years"))) return ["Employee must be at least 18 years of age."];
  if (moment(dob).isBefore(moment().subtract(60, "years")))
    return [{ isWarning: true, message: "Are you sure you’ve selected your date of birth correctly?" }];
  return [];
}

export function validateName(name) {
  if (typeof name === "undefined") return [];
  if (!name) return ["Name field can't be empty!"];
  if (name.length < 2) return ["The length of name is too small! Minimum: 2"];
  if (name.length > 50) return ["The length of name is too long! Maximum: 50"];
  const specialCharacterSet = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
  if (specialCharacterSet.test(name))
    return [
      {
        isWarning: true,
        message:
          "We noticed you used special characters in your name, are you sure you’ve entered the full name correctly?",
      },
    ];
  return [];
}

export function validateDateOfJoining(doj) {
  if (!doj) {
    return ["Date of joining can't be empty!"];
  }
  if (moment(doj).isBefore(moment().subtract(45, "days"))) {
    return ["Date of joining cant be before 45 days."];
  }
}

// this function can be used to validate thr form field.
export function validateUser(formData, isOrgAdmin = false) {
  const errorMessage = [];
  const nameFieldValidation = validateName(formData.name);
  if (nameFieldValidation.length) {
    const msg =
      typeof nameFieldValidation[0] === "string"
        ? nameFieldValidation[0]
        : "We noticed you used special character in name";
    errorMessage.push(msg);
  }
  const displayNameFieldValidation = validateName(formData.displayName);
  if (displayNameFieldValidation.length) {
    const msg =
      typeof displayNameFieldValidation[0] === "string"
        ? displayNameFieldValidation[0]
        : "We noticed you used special character in Display name";
    errorMessage.push(msg);
  }
  if (formData.dob && !validateDate(formData.dob)) {
    errorMessage.push("Date of birth cannot be a future date");
  }
  if (formData.dependents && formData.dependents.length) {
    if (!formData.dependents.every((dependentDetail) => !validateName(dependentDetail.name).length)) {
      errorMessage.push("We noticed you used special character in dependent name");
    }
    if (!formData.dependents.every((dependentDetail) => validateDate(dependentDetail.dob))) {
      errorMessage.push("Dependent Date of birth cannot be a future date");
    }
  }
  if (formData.meta.dateOfJoining && !validateDate(formData.meta.dateOfJoining)) {
    errorMessage.push("Date of Joining cannot be a future date");
  }
  if (validateEmployeeId(formData.meta.employeeId).length) {
    errorMessage.push("We noticed you used special characters in your employee id.");
  }
  const dojValidationError = validateDateOfJoining(formData.meta.dateOfJoining);
  if (isOrgAdmin && dojValidationError.length) {
    errorMessage.push(...dojValidationError);
  }

  return errorMessage;
}

export function validateDate(dob) {
  try {
    if (new Date(dob) > new Date()) {
      return false;
    }
  } catch (error) {
    return false;
  }
  return true;
}

export function validateEmployeeId(employee_id) {
  if (!employee_id) return [];
  if (!EMPLOYEE_ID_REGEX.test(employee_id))
    return [
      {
        isWarning: true,
        message: "We noticed you used special characters in your employee id.",
      },
    ];
  return [];
}

export function validateDateOfMarriage(dom, _self, formValues) {
  if (typeof dom === "undefined") return [];
  if (!dom) {
    return ["Date of marriage can't be empty!"];
  }
  if (formValues.isMidTerm && !formValues.id && moment(dom).isBefore(moment().subtract(30, "days"))) {
    return ["Date of marriage cant be before 30 days, Please contact you HR for more info."];
  }
}

export function validateDependentAge(dob, _self, formValues) {
  if (!dob || !formValues.userDob) return [];
  if (["parent", "parent-in-law"].includes(formValues.relation)) {
    if (moment(formValues.userDob).diff(moment(dob), "years") < 15) {
      return ["Parent/Parent-in-law should be at least 15 years older than you."];
    }
    if (moment(dob).isBefore(moment().subtract(99, "years"))) {
      return [`${formValues.relation}s over 99 year of age are not covered under policy.`];
    }
    if (moment(formValues.userDob).diff(moment(dob), "years") > 40) {
      return [{ isWarning: true, message: "Are you sure you’ve selected the dependent’s date of birth correctly?" }];
    }
  }
  if (formValues.relation === "spouse") {
    if (moment(dob).isAfter(moment().subtract(18, "years"))) {
      return ["Spouse must be at least 18 years of age."];
    }
    if (moment(dob).isBefore(moment().subtract(60, "years"))) {
      return [
        {
          isWarning: true,
          message: "You've selected your spouse's age more than 60 years, please check before proceeding",
        },
      ];
    }
    if (Math.abs(moment(formValues.userDob).diff(moment(dob), "years")) > 40) {
      return [
        {
          isWarning: true,
          message: "Age gap between you and your spouse is greater than 40 years, please check before proceeding",
        },
      ];
    }
  }
  if (formValues.relation === "child") {
    // This first condition will work only when it's a midterm addition and it's a new dependent addition
    if (formValues.isMidTerm && !formValues.id && moment(dob).isBefore(moment().subtract(30, "days"))) {
      return ["Children over 30 days of age are not allowed under mid term additions."];
    } else if (moment(dob).isBefore(moment().subtract(24, "years"))) {
      return ["Child over 24 years of age is not covered under policy"];
    }
    if (moment(dob).diff(moment(formValues.userDob), "years") < 15) {
      return ["Child has to be at least 15 years younger than you."];
    }
    if (moment(dob).diff(moment(formValues.userDob), "years") > 40) {
      return [
        {
          isWarning: true,
          message: "Age gap between you and your child is more than 40 years, please check before proceeding",
        },
      ];
    }
  }
  return [];
}
export function validateDependentGender(gender, _self, formValues) {
  if (!gender || !formValues.userGender) return [];
  if (formValues.relation === "spouse" && gender === formValues.userGender) {
    return [
      {
        isWarning: true,
        message: "We found same gender for you of your spouse, please check before proceeding",
      },
    ];
  }
  if (formValues.relation === "parent" && formValues.addedParentData) {
    const addedParentData = formValues.addedParentData?.find((parentData) => parentData.id !== formValues.id);
    if (addedParentData && addedParentData.gender === formValues.gender) {
      return [
        {
          isWarning: true,
          message: "We found same gender for both of your parents, please check before proceeding",
        },
      ];
    }
  }
  if (formValues.relation === "parent-in-law" && formValues.addedPilData) {
    const addedPilData = formValues.addedPilData?.find((pilData) => pilData.id !== formValues.id);
    if (addedPilData && addedPilData.gender === formValues.gender) {
      return [
        {
          isWarning: true,
          message: "We found same gender for both of your parent-in-laws, please check before proceeding",
        },
      ];
    }
  }
}

export function validateDependentCount(relation, _self, formValues) {
  // Checks for the current model relation and the count of existing set of dep for the same relation for the user
  if (!relation || !formValues.dependentsByRelationCount) return [];
  let errMsg = "";
  if (
    formValues.relation === AcceptedRelations.SPOUSE &&
    formValues.dependentsByRelationCount[AcceptedRelations.SPOUSE] >= 1
  ) {
    errMsg = "1 spouse";
  } else if (
    formValues.relation === AcceptedRelations.PARENT &&
    formValues.dependentsByRelationCount[AcceptedRelations.PARENT] >= 2
  ) {
    errMsg = "2 parents";
  } else if (
    formValues.relation === AcceptedRelations.PARENT_IN_LAW &&
    formValues.dependentsByRelationCount[AcceptedRelations.PARENT_IN_LAW] >= 2
  ) {
    errMsg = "2 parents-in-law";
  } else if (
    formValues.relation === AcceptedRelations.CHILD &&
    formValues.dependentsByRelationCount[AcceptedRelations.CHILD] >= 20
  ) {
    errMsg = "20 children";
  } else if (
    formValues.relation === AcceptedRelations.SIBLING &&
    formValues.dependentsByRelationCount[AcceptedRelations.SIBLING] >= 20
  ) {
    errMsg = "20 siblings";
  }
  if (errMsg) return [`Employee cannot have more than ${errMsg}`];
  return [];
}

export function validateNovaPasswordForVfg(password) {
  if (!password || password.toString().match(PASSWORD_REGEXP)) return [];
  return ["Invalid password!"];
}

export function validateJobGrade(value, _self, formValues) {
  if (!value || value === "None selected") return [`Job Grade is required!`];
}

export function validateFileGroups(fileGroups) {
  if (fileGroups.length > 0) return [];
  return ["Please select an file group "];
}
