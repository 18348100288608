import { getListQuery, getSingleQuery } from "../queries";
import contentSchema from "../schemas/content";
import contentMutations from "../mutations/content";

export default {
  gqlType: "Content",
  fields: ["name", "type"],
  singleQuery: getSingleQuery("Content"),
  listQuery: getListQuery("Content"),
  ...contentSchema,
  ...contentMutations,
  transform: (n) => {
    return { ...n };
  },
}
