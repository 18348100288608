export default {
  badgeBackground(tag) {
    const tagStyles = {
      Free: "bg-teal-200 text-teal-900",
      Paid: "bg-gray-300 text-blue-700",
    };
    if (!Object.keys(tagStyles).includes(tag)) {
      return "";
    }
    return tagStyles[tag];
  },
};
