<template lang="pug">
.select-card-container.m-1.h-100
  .small-select-card.select-card-box.h-100(
    :class="{active: activeState.includes(label)}",
    v-if="cardType === 'small'",
    @click="updateState($event,'card')")
    .row
      .col-12
        label.m-2.text-style.text-common-style(:for="label")
          input(
            type="checkbox",
            :name="name",
            :id="label",
            @change="updateState",
            @keydown.space="updateState")
          span {{label}}
  .medium-select-card.select-card-box.h-100(
    :class="{active: activeState.includes(label)}",
    v-if="cardType === 'medium'",
    @click="updateState($event,'card')")
    .row
      .col-12
        label.m-3.text-style.text-common-style(:for="label")
          input(
            type="checkbox",
            :name="name",
            :id="label",
            @change="updateState",
            @keydown.space="updateState")
          span.color-teal(v-if="activeState.includes(label)") Selected
          span.before-check(v-else) Select
        .pb-3.text-style.text-common-style
          i.icon-style(:class="[`icon-${icon}`,`icon-color-${icon}`]")
          span {{label}}
  .large-select-card.select-card-box.pt-3.h-100(
    :class="{active: activeState.includes(label)}",
    v-if="cardType === 'large'",
    @click="updateState($event,'card')")
    .row
      .col-12.d-flex
        .text-style.text-common-style.flex-grow-1
          .d-flex.align-items-center.p-0.m-0(align="center")
            i.icon-style(:class="[`icon-${icon}`,`icon-color-${icon}`]")
            span {{label}}
          .ml-auto.mr-3.justify-content-end(align="center")
            label.text-style.justify-content-start(:for="label")
              input(
                type="checkbox",
                :name="name",
                :id="label",
                @change="updateState",
                @keydown.space="updateState")
              span.color-teal(v-if="activeState.includes(label)") Selected
              span.before-check(v-else) Select
      .col.m-3
        p.paragraph-style.text-common-style {{description}}
        .line-style
        .price-style.text-common-style {{cost | inr}} / Employee
        span.price-ext-style.text-common-style Annually
</template>

<script>
export default {
  name: "MultiSelectCard",
  model: {
    prop: "activeState",
    event: "onUpdateState",
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
    cardType: {
      type: String,
      default: "small",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    cost: {
      type: String,
      default: "",
    },
    activeState: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    activeState() {
      if (this.activeState.includes(this.label)) {
        document.getElementById(this.label).checked = true;
      }
    },
  },
  methods: {
    updateState(e, eventFrom) {
      let currentValue = [...this.activeState];
      if (eventFrom === "card") {
        if (this.activeState.includes(this.label)) {
          currentValue = currentValue.filter((item) => item !== this.label);
        } else {
          currentValue.push(this.label);
        }
        document.getElementById(this.label).checked = !document.getElementById(
          this.label
        ).checked;
      } else {
        if (e.target.checked) {
          currentValue.push(this.label);
        } else {
          currentValue = currentValue.filter((item) => item !== this.label);
          document.getElementById(this.label).checked = false;
        }
      }
      this.$emit("onUpdateState", currentValue);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.select-card-container {
  background: $white;
}
.select-card-box {
  border: 2px solid #e2e8f0;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  cursor: pointer;
}
label {
  cursor: pointer;
}
label > input[type="checkbox"] {
  display: none;
}
label > input[type="checkbox"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.6rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border: 1px solid #a0aec0;
  cursor: pointer;
}

label > input[type="checkbox"]:checked + *::before {
  content: "\2713";
  background: $teal-800;
  line-height: 15px;
  font-weight: 900;
  color: $white;
  border-color: $teal-800;
  cursor: pointer;
}
label > input[type="checkbox"]:checked + * {
  color: $teal-800;
  cursor: pointer;
}

.before-check {
  color: #a0aec0;
}

.text-common-style {
  font-weight: 500;
  font-size: 14px;
  color: $gray-900;
}

.text-style {
  margin: 0px;
  display: flex;
  align-items: center;
}

.small-select-card {
  height: auto;
}

.medium-select-card {
  height: auto;

  .icon-style {
    margin: 0 10px 0 14px;
  }
  .color-teal {
    color: $teal-800;
  }
}

.large-select-card {
  height: auto;

  input {
    margin: 0 14px;
    height: 15px;
  }
  .icon-style {
    margin: 0 10px 0 14px;
  }
  .color-teal {
    color: $teal-800;
  }
  .line-style {
    border: 1px solid #e2e8f0;
    background-color: #e2e8f0;
    margin: 20px 0px;
  }
  .paragraph-style {
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $gray-800;
  }
  .price-style {
    margin-bottom: 7px;
  }
  .price-ext-style {
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.01em;
    color: $gray-600;
  }
}
.icon-color-heart {
  color: #ff6b6b;
}

.icon-color-teeth {
  color: $teal-700;
}
.icon-color-bandage {
  color: $mustard-500;
}
.icon-color-hospital {
  color: $blue-500;
}

.active {
  background: $teal-100;
  border: 2px solid $teal-800;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
}
</style>
