import { getListQuery } from "../../queries";

export default {
  schema: {
    fields: [
      {
        model: "policy",
        type: "id-selector",
        queryName: "benefits",
        gqlQuery: getListQuery("Benefit"),
        styleClasses: ["d-inline-block col-md-6"],
        queryVariables: () => ({
          filter: {
            userId: "",
            isPolicy: true,
          },
        }),
        label: `Policy Name<span style="color:red">*</span>`,
        placeholder: "-None-",
        required: true,
        showSelected: false,
        validator: "required",
        disabled: true,
      },
      {
        model: "nbNumber",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: `Claim Identifier<span style="color:red">*</span>`,
        placeholder: "-",
        showSelected: false,
        readonly: true,
        required: true,
      },
      {
        model: "policy.meta.policyNumber",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: "Policy Number",
        placeholder: "-",
        readonly: true,
      },
      {
        model: "policy.insurer.name",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: "Insurer",
        placeholder: "-",
        showSelected: false,
        readonly: true,
      },
      {
        model: "uhid",
        type: "inline-input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: "UHID",
        placeholder: "-",
        readonly: true,
      },
      {
        model: "meta.crm.tpaClassification",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: "TPA Classification",
        placeholder: "Medi Assist / Non Medi Assist",
        readonly: true,
      },
      {
        model: "meta.crm.tpa",
        type: "id-selector",
        styleClasses: "d-inline-block col-md-6 offset-md-6",
        queryName: "tpas",
        gqlQuery: getListQuery("Tpa"),
        label: "TPA Name",
        placeholder: "For Non Medi Assist",
        showSelected: false,
        disabled: true,
      },
    ],
  },
};
