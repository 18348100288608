import gql from "graphql-tag";

export const UPLOAD_FILE = gql`
  mutation upsertFile($file: Upload!, $useAwsS3: Boolean, $meta: JSONObject, $uploadingUserId: ID!, $action: String!) {
    upsertFile(
      input: { upload: $file, useAwsS3: $useAwsS3, meta: $meta, uploadingUserId: $uploadingUserId, action: $action }
    ) {
      file {
        id
        name
        url
        meta
        action
        uploadingUser {
          org {
            name
          }
        }
      }
    }
  }
`;
export const GET_FILE_SIGNED_URL = gql`
  query getFileSignedUrl($action: String, $filePath: String) {
    getFileSignedUrl(action: $action, filePath: $filePath)
  }
`;

export const GET_HANDBOOK_FILE_URL = gql`
  query getHandbookFileUrl($insurerId: String, $tpaId: String) {
    getHandbookFileUrl(insurerId: $insurerId, tpaId: $tpaId)
  }
`;
