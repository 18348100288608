<template lang="pug">
div
  .search-field
    n-inline-input(
      v-model="searchQuery",
      :placeholder="searchFieldPlaceholder",
      :customClasses="['font-sm', 'col-md-6']",
      imageIcon="search",
      @input="$emit('searchQuery', $event)"
    )
  n-table.endorsement-table-container(:items="getItems", :fields="getFields", hover)
    template(v-slot:selected="{ data }")
      div(
        v-if="[$options.tableTypes.ENROLLMENT_PENDING,\
        $options.tableTypes.UNAPPROVED,\
        $options.tableTypes.ORG_OK,\
        $options.tableTypes.NOVA_OK].includes(tableType)"
      )
        b-checkbox(
          v-model="data.item.selected",
          :class="data.item.selected && !isDepRow(data) ? 'endors-dep-checkbox' : ''",
          button-variant="dark",
          :indeterminate="getIndeterminateState(data)",
          @change="addToSelected($event, data.item, getOriginalIndex(data.item))"
        )
    template(v-slot:avatar="{ data }")
      n-avatar.text-blue-800.font-weight-semibold(
        :name="isDepRow(data) ? data.item.dep_name : data.item.name",
        :variant="isDepRow(data) ? 'warning' : 'light'",
        :size="2"
      )
    template(v-slot:email="{ data }")
      span.font-sm.font-weight-medium.text-gray-800
        | {{ data.item.email }}
    template(v-slot:name="{ data }")
      span.font-sm(v-if="showDependentContext(data)") {{ getDependentContext(data) }}
      span.font-sm.font-weight-semibold.text-gray-900(v-if="isDepRow(data) ? data.item.dep_name : data.item.name") {{ getSubjectName(data) || "Missing" }}
    template(v-if="tableType!==$options.tableTypes.ENROLLMENT_PENDING", v-slot:dependents="{ data }")
      template(
        v-if="getDependentCount(data.item)",
      )
        n-chip.border.additional-benefits(
          :isMinimal="true"
          :id="`dependent-${data.item.user_id}-${data.item.type}`") {{ getDependentCount(data.item) }}
          template(v-slot:icon)
            n-icon.mr-1(name="family", :size="0.8") 
        n-popover.dependent-popup.w-100.p-0.border-gray-200(
          :id="`dependent-popup-${data.item.user_id}-${data.item.type}`",
          :target="`dependent-${data.item.user_id}-${data.item.type}`",
          triggers="hover",
          :placement="getDepPopoverPlacement",
          :custom-class="`dependent-popup dependent-popup-${getDepPopoverPlacement}`")
          template(v-slot:title)
            .px-3.pt-2
              .font-weight-medium Dependents Details
          template(v-for="change in getDependentChanges(data.item)")
            .w-100.p-3
              .d-flex.justify-content-between.align-items-center.w-100
              .d-flex.align-items-top
                n-avatar.text-blue-800.font-weight-semibold(
                  :name="change.dep_name",
                  variant="warning",
                  :size="2"
                )
                .d-flex.flex-column.align-items-baseline.ml-2.ml-md-3
                  span.font-weight-semibold.text-gray-900.text-capitalize {{ change.dep_name }}
                  .d-flex.flex-row.flex-wrap.pt-1.text-gray-700.text-capitalize.font-weight-medium.font-sm
                    span.text-nowrap {{ change.relation }}
                  .d-flex.flex-row.flex-wrap.pt-1.text-gray-700.text-capitalize.font-weight-medium.font-sm(v-if="change.type")
                    coverage-pills-group-wrapper(
                      :item="change",
                    )
            hr
          n-button.btn-block.border-0.border-radius-inherit(
            type="button",
            buttonText="View Details",
            variant="outline-primary",
            size="xl",
            v-b-toggle="[`employee-details-sidesheet`, `dependent-popup`]",
            @click="updateSidesheetData(data.item)",
          )
            
    template(v-slot:type="{ data }")
      .d-flex.align-items-center
        template(v-if="tableType !== $options.tableTypes.ENROLLMENT_PENDING")
          span.mr-1.text-gray-900.font-weight-semibold(v-if="data.item.meta.length")
            span(
              v-if="data.item.type === 'add' || data.item.type === 'delete'"
            )
              | {{ data.item.type === 'add' ? 'Addition to' : 'Deletion from' }}
            span.mr-1.text-gray-900.font-weight-semibold(
              v-else-if="data.item.type === 'update'"
            )
              | {{ getDataChangeString(data.item.meta[0]) }} change with
          template(v-if="data.item?.type")
            coverage-pills-group-wrapper(
              :item = "data.item",
              triggers="hover",
            )
        template(v-else)
          span(
            v-if="data.item.meta",
            v-for="missingField in getMissingDataForMember(data.item, ['self', 'placeholder'].includes(data.item.user_type), checkIfHasNoCoverageStart(data.item?.meta))")
            n-chip.mr-1.border(
              :id="data.item.id + missingField",
              :isMinimal="true") {{ missingField }}
              template(v-slot:icon)
                i.mr-1(:class="['icon-question', 'text-gray-500']")
    template(v-slot:changeType="{ data }")
      template(v-if="tableType !== $options.tableTypes.ENROLLMENT_PENDING")
        div(v-if="data.item.type === 'add'")
          n-chip(chipIcon="add-user", :variant="isDepRow(data)?'secondary2':'success'", pill) {{ getPillContent(data) }}
        div(v-else-if="data.item.type === 'delete'")
          n-chip(chipIcon="remove-user", :variant="isDepRow(data)?'secondary2':'danger'", pill) {{ getPillContent(data) }}
        div(v-else-if="data.item.type === 'update'")
          n-chip(chipIcon="rule", :variant="isDepRow(data)?'secondary2':'dark'", pill) {{ getPillContent(data) }}
      template(v-else)
        template(v-if="data.item.meta")
          div(v-if="data.item.user_type !== 'dependent'")
            n-chip(chipIcon="add-user", variant="success", pill) Employee Addition
          div(v-else)
            n-chip(chipIcon="add-user", variant="primary", pill) Dependent Addition
    template(v-slot:chevron="{ data }")
      template(
        v-if="tableType === 'enrollment-pending' && data.item.meta && getMissingDataForMember(data.item, ['self', 'placeholder'].includes(data.item.user_type), checkIfHasNoCoverageStart(data.item?.meta)).length"
      )
        i.icon-edit.text-gray-500.p-2.cursor-pointer(
          @click="openMissingDataModal(data.item)"
        )
      template(v-else)
        i.icon-chevron-right.text-gray-500.p-2.cursor-pointer(
          v-b-toggle.employee-details-sidesheet,
          @click="updateSidesheetData(data.item)"
        )
    template(v-slot:custom-foot="data")
      tr
        td.text-center(:colspan="getFields.length")
          div(v-if="items && items.length > 0")
            slot(name="pagination")
          div(v-else)
            empty-states(type="Endorsements" :tableType="tableType")

  employee-missing-data-modal(
    :employee-data="missingModalData.employeeData",
    :missing-fields="missingModalData.missingFields",
    :org-id="orgId",
    @employee-updated="refreshActiveTable()",
    :initial-active-tab="missingDataModalActiveTab",
    @modal-closed="handleMissingDataModalClose()"
  )
  transfer-batch-modal(
    :id="'transfer-batch-modal-for-changes'",
    :v-if="!this.$route.path.includes(`org-admin/`)",
    @update-batch="updateBatch",
    @modal-closed="handleTransferBatchModal('hide')",
    :batches="batches.filter(batch => batch.id !== getCurrentBatchId)"
  )
  employee-details-sidesheet(
    id="sidesheet",
    :employeeData="sidesheetData",
    :tableType="tableType",
    :org-id="orgId",
    :resetSelected="resetSelected",
    @approve="approveChanges",
    @reject="rejectChanges",
  )

  div(v-if="tableType === $options.tableTypes.UNAPPROVED && items.length > 0")
    review-changes-modal(
      :changes-data="items",
      :date-of-interest="getDueDate.dateString",
      :total-changes="totalCount",
      :unapproved-data="unapprovedData",
      :current-batch-id="getCurrentBatchId",
      @refreshTable="refreshActiveTable()",
      @resetFloatingBar="resetFloatingToolbar()",
    )
  .toolbar-position-endorsements.d-flex.justify-content-center(
    v-if="selectedUserChangesCount > 0 && !isSidesheetOpen()"
  )
    floating-toolbar.z-7(
      :tableType="tableType",
      :number-of-changes-selected="selectedUserChangesCount",
      @approve="approveChanges",
      @reject="rejectChanges"
      @transferBatch="handleTransferBatchModal('show')"
    )
</template>

<script>
import { groupBy, capitalize, flatten } from "lodash";
import resDefs from "../../orgAdmin/definitions";
import adminResDefs from "../../admin/definitions";
import { AcceptedRelations, AcceptedGenders } from "../../../../common/enums";
import ReviewChangesModal from "./ReviewChangesModal.vue";
import FloatingToolbar from "./FloatingToolbar.vue";
import EmployeeMissingDataModal from "./EmployeeMissingDataModal.vue";
import TransferBatchModal from "./TransferBatchModal.vue";
import EmployeeDetailsSidesheet from "./EmployeeDetailsSidesheet.vue";
import PlusNChip from "./PlusNChip";
import CoveragePillsGroupWrapper from "./CoveragePillsGroupWrapper.vue";
import EmptyStates from "@/components/Cards/EmptyStates/EmptyStateCard.vue";
import utils from "@/utils";
import NInlineInput from "@/components/NovaInlineInput.vue";
import NAvatar from "@/components/Avatar.vue";
import NTable from "@/components/NovaTable.vue";
import NChip from "@/components/NovaChip.vue";
import NButton from "@/components/NovaButton.vue";
import NPopover from "@/components/NovaPopover.vue";

export default {
  name: "EndorsementTable",
  components: {
    NButton,
    NChip,
    NTable,
    NAvatar,
    ReviewChangesModal,
    FloatingToolbar,
    EmployeeMissingDataModal,
    TransferBatchModal,
    EmployeeDetailsSidesheet,
    PlusNChip,
    EmptyStates,
    NInlineInput,
    CoveragePillsGroupWrapper,
    NPopover,
  },
  props: {
    tableType: {
      type: String,
      default: "",
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    totalCount: {
      type: Number,
      default: 0,
      required: true,
    },
    batches: {
      type: Array,
      default: () => [],
    },
    selectedBatch: {
      type: Object,
      default: () => {},
    },
    isSelectedBatchOverdue: {
      type: Boolean,
      default: false,
    },
    unapprovedData: {
      type: Object,
      default: () => {},
    },
    orgId: {
      type: String,
      default: null,
    },
  },
  data() {
    const resName = "userChanges";
    const resDef = resDefs[resName];
    const popoversData = resDef.popovers;
    return {
      searchQuery: "",
      searchFieldPlaceholder: "Search for an employee or their dependent by name or email ID",
      resName,
      resDef,
      popoversData,
      pageSize: 10,
      perPage: 10,
      currentPage: 1,
      selectedUserChangesMap: {},
      selectedUserChangesCount: 0,
      batchesMap: {},
      batchId: null,
      currentBatch: null,
      getDateWithSuffix: utils.getDateWithSuffix,
      getSingularOrPlural: utils.getSingularOrPlural,
      learnMoreLink: process.env.VUE_APP_UNDERSTANDING_ENDORSEMENT_WITH_NOVA_LINK,
      indeterminateMap: {},
      missingDataModalActiveTab: "user",
      missingModalData: {
        employeeData: {
          user: {},
          dependents: [],
        },
        missingFields: {
          user: [],
          dependents: [],
        },
      },
      sidesheetData: {
        user: {},
        dependents: [],
      },
      fields: [
        {
          key: "avatar",
          label: "",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "email",
          label: "Employee Email",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "name",
          label: "Subject",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "dependents",
          label: "Dependents",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "employeeId",
          label: "",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "type",
          label: "Endorsement Type",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "changeType",
          label: "Endorsement",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "date",
          label: "",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "chevron",
          label: "",
          tdClass: this.dependentRowStyling(),
        },
      ],
      getMissingDataForMember: utils.getMissingDataForMember,
      checkIfHasNoCoverageStart: utils.checkIfHasNoCoverageStart,
      changesMap: {},
      subChangesMap: {},
    };
  },
  computed: {
    getDepPopoverPlacement() {
      return this.isSidesheetOpen() ? "left" : "right";
    },
    getFields() {
      if (this.tableType === this.$options.tableTypes.ENROLLMENT_PENDING) {
        return this.fields.filter((field) => field.key !== "dependents");
      }
      return this.fields;
    },
    getGroupedChanges() {
      return groupBy(this.items, (change) => {
        return change.user_id + "," + change.type;
      });
    },
    getTransformedItems() {
      return flatten(Object.values(this.changesMap));
    },
    getItems() {
      return this.tableType === this.$options.tableTypes.ENROLLMENT_PENDING ? this.items : this.getTransformedItems;
    },
    nextMonthDetails() {
      return utils.getNextMonthDetails();
    },
    getDueDate() {
      const dueDate = utils.getLastDateOfMonth(new Date());
      return {
        dateString: this.getDateWithSuffix(dueDate),
        month: utils.getMonth(dueDate),
        year: utils.getMonthYear(dueDate),
      };
    },
    getCurrentBatchId() {
      return this.$route.params.batchId || this.selectedBatch?.id;
    },
  },
  watch: {
    items() {
      if (this.tableType !== this.$options.tableTypes.ENROLLMENT_PENDING) {
        this.changesMap = {};
        this.subChangesMap = {};
        this.organiseChangesToLevels();
      }
    },
    tableType() {
      this.toggleSelectedColumn();
      this.updateTableFields();
    },
    batches() {
      if (this.batches.length)
        this.batches.forEach((batch) => {
          this.batchesMap[batch.id] = batch;
        });
    },
  },
  mounted() {
    this.$root.$on("rejectDependent", (args) => {
      if (args.reject) {
        setTimeout(() => {
          this.rejectChanges(args.reject);
        }, 100);
      }
    });
  },
  async created() {
    this.$options.tableTypes = {
      ONGOING: "ongoing",
      OVERDUE: "overdue",
      COMPLETED: "completed",
      ENROLLMENT_PENDING: "enrollment-pending",
      UNAPPROVED: "unapproved",
      APPROVED: "approved",
      ORG_OK: "org-ok",
      NOVA_OK: "nova-ok",
      PROVIDER_OK: "provider-ok",
      DONE: "done",
      REJECTED: "rejected",
    };
    this.updateTableFields();
    this.toggleSelectedColumn();
    if (
      [this.$options.tableTypes.ONGOING, this.$options.tableTypes.OVERDUE, this.$options.tableTypes.COMPLETED].includes(
        this.tableType
      )
    ) {
      this.batchId = this.$route.params.batchId;
      const { data } = await this.$apollo.query({
        query: adminResDefs.userChangeBatches.listQuery,
      });
      const allBatches = data.userChangeBatches.edges.map((n) => n.node);
      this.currentBatch = allBatches.filter((batch) => batch.id === this.batchId && batch.status === this.tableType)[0];
    }
  },
  methods: {
    getCommonBenefits(arr) {
      // Initialize an array to store the common meta objects
      const commonMeta = [];
      // Get all keys that are common to all change objects
      const commonChanges = Object.keys(arr[0].meta[0]).filter((key) => {
        return arr.every((dep) => Object.keys(dep.meta[0]).includes(key));
      });

      // Loop through all change objects for the first benefit
      arr[0].meta.forEach((meta) => {
        // Create a new change for the common meta for this benefit
        const commonChange = { benefit_id: meta.benefit_id };

        // Check if all changes in the array (excluding the first) have meta for this benefit
        const hasMetaForAll = arr.slice(1).every((dep) => {
          // Find the change object for this benefit in the current object
          const change = dep.meta.find((uc) => uc.benefit_id === meta.benefit_id);
          // If no change object is found, exit the loop
          if (!change) return false;

          // For each common key, add the key-value pair to the common change object
          commonChanges.forEach((key) => {
            if (change[key] !== undefined) {
              commonChange[key] = change[key];
            }
          });

          // Continue looping through changes in the array
          return true;
        });

        // If all changes in the array have meta for this benefit, add it to the common meta array
        if (hasMetaForAll) {
          commonMeta.push(commonChange);
        }
      });

      // Return the array of common meta objects
      return commonMeta;
    },
    isDepRow(data) {
      return utils.isDependentRow(data);
    },
    getOriginalIndex(data) {
      return this.items.findIndex((item) => data === item);
    },
    showDependentContext(data) {
      return (
        this.tableType !== this.$options.tableTypes.ENROLLMENT_PENDING &&
        ["dependent", "placeholder"].includes(data.item.user_type)
      );
    },
    getDependentContext(data) {
      return capitalize(this.getDependentsSubject(data) + " of ");
    },
    getDependentsSubject(data) {
      if (data.item.user_type === "placeholder") return "Dependents";
      switch (data.item.relation) {
        case AcceptedRelations.CHILD: {
          return data.item.dep_gender === AcceptedGenders.MALE ? "Son" : "Daughter";
        }
        case AcceptedRelations.PARENT: {
          return data.item.dep_gender === AcceptedGenders.MALE ? "Father" : "Mother";
        }
        case AcceptedRelations.PARENT_IN_LAW: {
          return data.item.dep_gender === AcceptedGenders.MALE ? "Father In Law" : "Mother In Law";
        }
      }
      return data.item.relation;
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key].includes(value));
    },
    getDependentCount(item) {
      return this.subChangesMap[this.getKeyByValue(this.changesMap, item)]?.length;
    },
    getDependentChanges(item) {
      return this.subChangesMap[this.getKeyByValue(this.changesMap, item)];
    },
    getSubjectName(data) {
      if (this.tableType !== this.$options.tableTypes.ENROLLMENT_PENDING) return data.item.name;
      return this.isDepRow(data) ? data.item.dep_name : data.item.name;
    },
    getPillContent(data) {
      switch (data.item.type) {
        case "add":
          return this.isDepRow(data) ? "Dependent Addition" : "Addition";
        case "delete":
          return this.isDepRow(data) ? "Dependent Deletion" : "Deletion";
        case "update":
          return this.isDepRow(data) ? "Dependent Update" : "Data Changes";
      }
    },
    getDataChangeString(data) {
      return utils.getUserChangeStringForUpdate(data);
    },
    organiseChangesToLevels() {
      Object.entries(this.getGroupedChanges).forEach((group) => {
        const [key, value] = group;
        if (value.some((val) => val.user_type === "self")) {
          this.changesMap[key] = value.filter((val) => val.user_type === "self");
          this.subChangesMap[key] = value.filter((val) => val.user_type === "dependent");
        } else if (value.length > 1) {
          const userData = this.getUserData(value[0]);
          userData.meta = this.getCommonBenefits(value);
          this.changesMap[key] = [userData];
          this.subChangesMap[key] = value;
        } else {
          this.changesMap[key] = value;
        }
      });
    },
    openReviewChangesModal() {
      this.$bvModal.show("review-changes-modal");
    },
    refreshActiveTable() {
      this.$emit("row-updated");
    },
    openMissingDataModal(data) {
      this.calculateMissingModalData(data);
      this.$bvModal.show("employee-missing-data-modal");
    },
    resetSidesheetData() {
      this.sidesheetData.user = {};
      this.sidesheetData.dependents = [];
    },
    resetFloatingToolbar() {
      this.selectedUserChangesMap = {};
      this.selectedUserChangesCount = 0;
    },
    isSidesheetOpen() {
      return Object.keys(this.sidesheetData.user).length;
    },
    getUserData(data) {
      return {
        user_id: data?.user_id,
        user_type: "placeholder",
        name: data?.name,
        email: data?.email,
        gender: data?.gender,
        dob: data?.dob,
        userMeta: data?.userMeta,
        meta: data?.meta,
        type: data?.type,
      };
    },
    calculateMissingModalData(data) {
      const userId = data.user_id;

      // reset the data
      this.missingModalData.employeeData = { user: {}, dependents: [] };
      this.missingModalData.missingFields = { user: [], dependents: [] };

      if (["self", "placeholder"].includes(data.user_type) && data.meta) {
        this.missingModalData.employeeData.user = data;
        this.missingModalData.missingFields.user = this.getMissingData(data);
        this.missingDataModalActiveTab = "user";
      }

      this.items
        .filter((item) => item.user_id === userId)
        .forEach((item, index) => {
          if (
            !["self", "placeholder"].includes(data.user_type) &&
            item.meta &&
            item.meta[0]?.dependentId === data.meta[0].dependentId
          ) {
            this.missingDataModalActiveTab = `dependent${index}`;
          }
          if (["self", "placeholder"].includes(item.user_type)) {
            this.missingModalData.employeeData.user = item;
            if (item.meta) {
              this.missingModalData.missingFields.user = this.getMissingData(item);
            }
          } else if (item.user_type === "dependent") {
            this.missingModalData.employeeData.dependents.push(item);
            this.missingModalData.missingFields.dependents.push(this.getMissingData(item));
          }
        });
    },
    updateSidesheetData(data) {
      window.posthog.capture("employee_detail_click", {
        org_name: this.user?.org?.name,
        email: this.user?.email,
      });
      const userId = data.user_id;
      // reset the data
      this.resetSidesheetData();
      if (["self", "placeholder"].includes(data.user_type) && data.meta) {
        this.sidesheetData.user = data;
      } else {
        this.sidesheetData.user = this.getUserData(data);
      }
      this.items.forEach((item, index) => {
        if (item.user_id === userId && item.type === data.type) {
          if (this.tableType === this.$options.tableTypes.UNAPPROVED) this.addToSelected(true, item, index);
          if (["self", "placeholder"].includes(item.user_type)) {
            if (!this.sidesheetData.user?.meta) this.sidesheetData.user = item;
            else {
              // compile all details of each benefit for the user for each row
              this.sidesheetData.user.meta = this.sidesheetData.user.meta.map((existingMeta) => {
                return {
                  ...existingMeta,
                  benefit_obj: {
                    ...existingMeta.benefit_obj,
                    ...(item.meta.find((itemMeta) => itemMeta.benefit_id === existingMeta.benefit_id)?.benefit_obj ||
                      {}),
                  },
                };
              });
            }
          } else if (item.user_type === "dependent") this.sidesheetData.dependents.push(item);
        }
      });
      setTimeout(() => {
        this.$root.$emit("bv::hide::popover", `dependent-popup-${data.user_id}-${data.type}`);
      }, 100);
    },
    getMissingData(data) {
      return this.getMissingDataForMember(
        data,
        ["self", "placeholder"].includes(data.user_type),
        this.checkIfHasNoCoverageStart(data.item?.meta)
      );
    },
    handleMissingDataModalClose() {
      this.missingDataModalActiveTab = "user";
      this.missingModalData = {
        employeeData: {
          user: {},
          dependents: [],
        },
        missingFields: {
          user: [],
          dependents: [],
        },
      };
      this.$bvModal.hide("employee-missing-data-modal");
    },
    getDateOfJoining(user) {
      if (user?.userMeta?.dateOfJoining) {
        const dateOfJoining = user.userMeta.dateOfJoining;
        return `${utils.getDate(dateOfJoining)} ${utils.getMonth(dateOfJoining)}, ${utils.getYear(dateOfJoining)}`;
      }
      return "";
    },
    getFilteredUserTypeByUserId(userChangeId, userChangeType) {
      if (userChangeType === "self") {
        return this.items.findIndex(
          (item) => item.user_id === userChangeId && (item.user_type === "self" || item.user_type === "placeholder")
        );
      } else if (userChangeType === "dependent") {
        return this.items.filter((item) => item.user_id === userChangeId && item.user_type === userChangeType);
      }
    },
    // FIXME: Simplify method
    addToSelected(checked, userChange, index) {
      this.addOrRemoveCheckedRow(checked, index, userChange);
      if (userChange.user_type === "self" || userChange.user_type === "placeholder") {
        this.markAllRows(checked, userChange);
      } else {
        const filteredDepByUserId = this.getFilteredUserTypeByUserId(userChange.user_id, "dependent");
        if (filteredDepByUserId.every((dep) => dep.selected === checked)) {
          this.indeterminateMap[userChange.user_id] = false;
          this.addOrRemoveCheckedRow(checked, index, userChange);
        } else {
          this.indeterminateMap[userChange.user_id] = true;
          this.markAllRows(checked, userChange);
        }
      }
      this.selectedUserChangesCount = Object.values(this.selectedUserChangesMap).length;
    },
    resetSelected(userId) {
      this.selectedUserChangesMap = {};
      this.selectedUserChangesCount = 0;
      this.resetSidesheetData();
      this.items
        .filter((item) => item.user_id === userId && ["self", "placeholder", "dependent"].includes(item.user_type))
        .forEach((item, index) => {
          this.addToSelected(false, item, index);
        });
    },
    addOrRemoveCheckedRow(isChecked, index, userChange) {
      if (isChecked) {
        this.selectedUserChangesMap[index] = userChange;
      } else {
        delete this.selectedUserChangesMap[index];
      }
    },
    markAllRows(isChecked, userChange) {
      this.items.forEach((item, index) => {
        if (
          item.user_id === userChange.user_id &&
          item.type === userChange.type &&
          ["self", "dependent"].includes(item.user_type)
        ) {
          if (isChecked) {
            this.selectedUserChangesMap[index] = item;
            item.selected = true;
          } else {
            delete this.selectedUserChangesMap[index];
            item.selected = false;
          }
        }
      });
    },
    getIndeterminateState(data) {
      return !this.isDepRow(data) ? this.indeterminateMap[data.item.user_id] : false;
    },
    async approveChanges() {
      try {
        this.resetSidesheetData();
        if (this.tableType === this.$options.tableTypes.UNAPPROVED) {
          // Approval Pending
          await this.bulkUpdateUserChangesStatusByIdMutation("draft", "org-ok");
        } else if (this.tableType === this.$options.tableTypes.ENROLLMENT_PENDING) {
          // Missing Data
          await this.sendRemindersToSelectedUsers();
        } else if (this.tableType === this.$options.tableTypes.ORG_OK) {
          // Ready for Nova
          await this.bulkUpdateUserChangesStatusByIdMutation("org-ok", "nova-ok");
        } else if (this.tableType === this.$options.tableTypes.NOVA_OK) {
          // Ready for Insurer
          await this.bulkUpdateUserChangesStatusByIdMutation("nova-ok", "provider-ok");
        }
        this.resetFloatingToolbar();
        this.refreshActiveTable();
      } catch (err) {
        throw err;
      }
    },
    updateSelectedForCheckedRow() {
      this.selectedUserChangesMap = Object.assign(
        {},
        Object.values(this.selectedUserChangesMap).map((item) => {
          return {
            ...item,
            selected: true,
          };
        })
      );
    },
    async rejectChanges(dependent) {
      this.resetSidesheetData();
      let filterType;
      if ([this.$options.tableTypes.ENROLLMENT_PENDING, this.$options.tableTypes.UNAPPROVED].includes(this.tableType)) {
        filterType = "draft";
      } else {
        filterType = this.tableType;
      }
      if (dependent) {
        this.resetSelected();
        this.addOrRemoveCheckedRow(true, this.items.indexOf(dependent), dependent);
        this.updateSelectedForCheckedRow();
      }
      await this.bulkUpdateUserChangesStatusByIdMutation(filterType, "rejected");
      this.resetFloatingToolbar();
      window.posthog.capture("endorsements_delete", {
        number_of_employees: this.selectedUserChangesCount,
        org_name: this.user?.org?.name,
        email: this.user?.email,
      });
      this.refreshActiveTable();
    },
    async bulkUpdateUserChangesStatusByIdMutation(statusFilter, status) {
      this.$store.commit("clearAlerts");
      const userChangeIds = utils.getUserChangeIds(Object.values(this.selectedUserChangesMap), true);
      try {
        await this.$apollo.mutate({
          mutation: this.resDef.setUserChangesStatus,
          variables: {
            for: "userChangeIds",
            statusFilter,
            status,
            userChangeIds: [...new Set(userChangeIds)],
          },
        });
        this.$store.commit("addAlert", {
          variant: "success",
          message: `Successfully ${status === "rejected" ? "deleted" : "approved"} the selected changes`,
        });
        this.refreshActiveTable();
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async sendRemindersToSelectedUsers() {
      const userIds = new Set();
      Object.values(this.selectedUserChangesMap).forEach((row) => {
        userIds.add(row.user_id);
      });
      try {
        await this.$apollo.mutate({
          mutation: adminResDefs.users.sendOnboardingInvites,
          variables: {
            inviteBy: "userIds",
            userIds: [...userIds],
            invitationType: "MISSING_DATA",
            isSelectedBatchOverdue: this.isSelectedBatchOverdue,
          },
        });
        this.$store.commit("addAlert", {
          variant: "success",
          message: "Successfully sent reminders to the selected users",
        });
      } catch (err) {
        throw err;
      }
    },
    async search(e) {
      if (e.preventDefault) e.preventDefault();
      this.$apollo.queries.groupedUserChanges.refetch();
    },
    pageChanged(pageNumber, tableType) {
      this.$emit("pageChange", tableType, pageNumber, this.pageSize);
    },
    dependentRowStyling() {
      return (value, key, item) => {
        const data = { item };
        const styles = ["align-middle"];
        const depStyles = ["bg-alabaster"];
        if (key === "selected" || key === "avatar") styles.push("checkbox-width1");
        if (utils.isDependentRow(data)) styles.push(...depStyles);
        return styles;
      };
    },
    toggleSelectedColumn() {
      // TODO: Send from prop, or make global
      if (
        [
          this.$options.tableTypes.ENROLLMENT_PENDING,
          this.$options.tableTypes.UNAPPROVED,
          this.$options.tableTypes.ORG_OK,
          this.$options.tableTypes.NOVA_OK,
        ].includes(this.tableType)
      ) {
        this.fields.unshift({
          key: "selected",
          label: "",
          tdClass: this.dependentRowStyling(),
        });
      }
    },
    updateTableFields() {
      const field = this.fields.find((field) => field.key === "type");
      field.label =
        this.tableType === this.$options.tableTypes.ENROLLMENT_PENDING ? "Missing Data" : "Endorsement Type";
    },
    filterBenefitsByType(benefitsArray = []) {
      const benefitsAndPolicies = {
        policies: [],
        benefits: [],
      };
      benefitsArray.forEach((benefit) => {
        const type = benefit.isPolicy ? "policies" : "benefits";
        benefitsAndPolicies[type].push(benefit);
      });
      return benefitsAndPolicies;
    },
    handleTransferBatchModal(changeType = "hide") {
      if (changeType === "show") {
        this.$bvModal.show("transfer-batch-modal-for-changes");
        return;
      }

      this.$bvModal.hide("transfer-batch-modal-for-changes");
    },
    async updateBatch(newBatchId) {
      const userChangeIds = utils.getUserChangeIds(Object.values(this.selectedUserChangesMap), true);

      await this.$apollo.mutate({
        mutation: adminResDefs.userChanges.transferUserChangeBetweenBatches,
        variables: {
          for: "userChangeIds",
          newBatchId,
          userChangeIds,
        },
      });
      // reset floating toolbar
      this.resetFloatingToolbar();
      this.refreshActiveTable();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

.endors-dep-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:indeterminate ~ .custom-control-label::before {
    color: #fff;
    border-color: $blackpearl;
    background-color: $blackpearl;
  }
  & > input.custom-control-input {
    min-height: 0 !important;
  }
}

.search-field {
  @include py(3);
  @include px(4);
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
}
</style>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.prog-banner-icon {
  position: absolute;
  top: 28%;
  left: 2.3%;
}
.left-block-w {
  width: 4.5%;
}
.toolbar-position-endorsements {
  position: fixed;
  max-width: 612px;
  z-index: 3;
  left: 0;
  right: 0;
  bottom: 10vh;
  margin-left: auto;
  margin-right: auto;
}
.rotated {
  transform: rotate(135deg);
}

.endorsement-table-container {
  .nova-table-wrapper {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.dependent-popup {
  width: 100% !important;
  min-width: fit-content !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: $border-radius;
  hr {
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-color: $gray-300;
  }
  .popover-header,
  .popover-body {
    padding: 0 !important;
  }
}
/* For popover notch border */
.dependent-popup-right > .arrow::after {
  left: 0.3px;
}
.dependent-popup-left > .arrow::after {
  right: 0.3px;
}
</style>
