import { getListQuery } from "../queries";

export default {
  schema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getListQuery("Org"),
        label: "Organization",
        required: true,
      },
      {
        model: "integrationType",
        type: "select",
        values: ["GSUITE", "DARWINBOX", "ZOHOPEOPLE", "BAMBOOHR", "SLACK"],
        label: "Integration Type",
        required: true,
      },
      {
        model: "meta",
        type: "object",
        schema: {
          fields: [
            {
              model: "domain",
              type: "input",
              inputType: "text",
              label: "Domain",
              hint: "yourcompany.com if your emails are like you@yourcompany.com",
              required: true,
            },
          ],
        },
      },
      {
        model: "secrets",
        type: "object",
        schema: {
          fields: [],
        },
      },
    ],
  },
}
