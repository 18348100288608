import { claimDocumentsList } from "../../constants";
export default {
  upload: {
    schema: {
      fields: [
        {
          model: "meta.files",
          type: "files-uploader",
          modal: true,
          styleClasses: "float-right mr-4 mb-1",
          options: claimDocumentsList,
          name: "Upload Documents",
          icon: "cloud-upload",
          variant: "outline-dark",
        },
      ],
    },
  },
  uploaded: {
    schema: {
      fields: [
        {
          model: "meta.files",
          type: "files-uploader",
          modal: true,
          styleClasses: "float-right mr-4 mb-1",
          options: claimDocumentsList,
          name: "View Uploaded Documents",
          icon: "eye",
        },
      ],
    },
  },
};
