import { getListQuery, getSingleQuery } from "../queries";
import insurersSchema from "../schemas/insurers";
import insurersMutations from "../mutations/insurers";

export default {
  gqlType: "Insurer",
  fields: ["name", "type"],
  singleQuery: getSingleQuery("Insurer"),
  listQuery: getListQuery("Insurer"),
  ...insurersSchema,
  ...insurersMutations,
  transform: (n) => {
    return {
      ...n,
      hospitalContentId: n.hospitalContent?.id || null,
    };
  },
}
