<template lang="pug">
  .select-cards-main-container(v-if="cardsData.length > 0")
    div(
      :class="`${cardType}-style`",
      v-for="card in cardsData",
      :style="cardType==='small'?('flex-basis: '+ 100/cardsData.length +'%') : ''")
      MultiSelectCard(
        :cardType="cardType",
        :icon="card.icon",
        :label="card.label",
        :name="card.name",
        :description="card.description",
        :cost="card.cost",
        v-model="activeState")
</template>

<script>
import MultiSelectCard from "./MultiSelectCard";
export default {
  name: "MultiSelectCards",
  components: {
    MultiSelectCard,
  },
  props: {
    /* eslint-disable-next-line vue/require-prop-types, vue/require-default-prop */
    value: {},
    cardType: {
      type: String,
      default: "small",
    },
    cardsData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {
    activeState: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.select-cards-main-container {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.small-style {
  min-height: 100%;
}
.medium-style,
.large-style {
  flex-basis: 50%;
  margin-bottom: 0.8rem;
  min-height: 100%;
}

@media (max-width: 768px) {
  .medium-style,
  .large-style {
    flex-basis: 100%;
  }
  .small-style {
    flex-basis: 50% !important;
    min-height: 100%;
  }
}
</style>
