<template lang="pug">
.max-modal-w
  b-modal.max-modal-w(
    :id="id",
    size="lh",
    hide-header,
    hide-footer,
    dialog-class="getintouch-modal-dialog modal-dialog-centered")
    get-in-touch(:heroPhone="heroPhone")
</template>

<script>
import GetInTouch from "./GetInTouch.vue";

export default {
  name: "GetInTouchModal",
  components: {
    GetInTouch,
  },
  props: {
    id: {
      type: String,
      default: "get-in-touch-modal",
    },
    heroPhone: {
      type: String,
      default: ""
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.getintouch-modal-dialog {
  max-width: 1062px;
  .modal-body {
    padding: 0 !important;
  }
}
@include media-breakpoint-down(md) {
  .getintouch-modal-dialog {
    max-width: 768px;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.faq-modal-close-btn {
  position: absolute;
  right: -150px;
  top: 100px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.25);
}
</style>
