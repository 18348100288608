<template lang="pug">
.start-claim
  .overlay
    .container.my-4
      .row.mt-4(v-if="hasPolicies")
        .col-sm-11.offset-md-2.col-md-8
          .n-card
            .d-flex.w-99.p-3.justify-content-between.align-items-center
              i.pr-3
              h5.text-gray-600.font-hc.mt-3 Starting a claim
              i.text-gray-600.icon-cross.cursor-pointer(@click="$router.go(-1)")
            .offset-md-2.col-md-8.d-flex.flex-column.justify-conent-center.align-items-center.p-2
              b-avatar.mt-2.bg-blue-100(variant="none", size="3rem")
                n-icon(name="claims-1", variant="blue-400", :size="1.5")
              h3.h3.font-hergon.mt-3
                | Hang in there, buddy!
                br
                | We are here for you&nbsp;
                img.align-top(:src="require('@/assets/emojis/wave.svg')", width="25")
              p.text-gray-700.mt-3 Select the option that applies to you
              card-link.mt-2.p-2(
                icon="error",
                variant="danger",
                :to="{name: `emergency-claim-form`, query:{id: policyId}}")
                template(v-slot:header)
                  |  Need urgent help
                template(v-slot:body)
                  | Select this if you’re in an emergency and need urgent assistance related to hospitalization.
              card-link.mt-2.p-2(
                icon="checkup",
                variant="success",
                :to="{name: `claim-intimation-form`, query:{id: policyId}}")
                template(v-slot:header)
                  |  I’m planning to visit a hospital or already there
                template(v-slot:body)
                  | Select this if you’re planning to get treated at a hospital or if you're already at one.
              card-link.mt-2.p-2(
                icon="hospital",
                variant="secondary2",
                :to="{name: `reimbursement-form`, query:{id: policyId}}")
                template(v-slot:header)
                  |  I’ve been discharged from a hospital
                template(v-slot:body)
                  | Select this if you’ve recently been discharged and have paid any hospital bills.
              b-link(
                :class="[ \
                  'text-blue-600', \
                  'font-weight-medium', \
                  'text-decoration-none', \
                  'mt-3', \
                  'd-flex', \
                  'justify-content-between', \
                  'align-items-center' \
                ]",
                @click="showSalesIqChat")
                | None of these options apply to me
                i.ml-2.icon-chevron-right
              b-link(
                :class="[ \
                  'text-blue-600', \
                  'font-weight-medium', \
                  'text-decoration-none', \
                  'mt-5', \
                  'mb-3', \
                  'd-flex', \
                  'justify-content-between', \
                  'align-items-center' \
                ]",
                :to="{name: 'claim-process', params: contentId ? {contentId: contentId} : {}}")
                | I want to learn more about the claim process
                i.ml-2.icon-question
      .row.mt-4(v-else)
        .col-sm-11.offset-md-2.col-md-8
          .n-card
            .d-flex.w-99.p-3.justify-content-between.align-items-center
              i.pr-3
              h5.text-gray-600.font-hc.mt-3 Starting a claim
              i.text-gray-600.icon-cross.cursor-pointer(@click="$router.go(-1)")
            .offset-md-2.col-md-8.d-flex.flex-column.justify-conent-center.align-items-center.mt-3
              h3.h3.font-hergon
                | You dont have any policies to continue
              .d-flex.align-items-center.mt-3.mb-5
                n-button.mr-2(:buttonText="goBackText", variant="outline-primary", @click="$router.go(-1)")
                n-button(buttonText="Need Help", @click="showSalesIqChat")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import CardLink from "./components/CardLink.vue";

export default {
  components: {
    CardLink,
    NButton,
  },
  data() {
    return {
      policyId: null,
      goBackText: "<- Go Back",
      contentId: null,
    };
  },
  computed: {
    hasPolicies() {
      const policies = [...this.$store.state.user.benefits];
      return policies.length;
    },
  },
  mounted() {
    const policyId = this.$route.query?.id;
    const policies = [...this.$store.state.user.benefits];
    if (!policies.length) {
      this.$store.commit("addAlert", {
        variant: "danger",
        message:
          "Your account does not have any active policies at the moment. Please reach out to our chat support for any assistance",
      });
    } else {
      const gmcPolicy = policies.find((p) => p.node.type === "gmc");
      if (gmcPolicy) {
        this.contentId = gmcPolicy.node?.id;
      }
    }
    if (policyId) {
      this.policyId = policyId;
    }
  },
  methods: {
    showSalesIqChat() {
      this.$store.commit("toggleSupportContainer");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.start-claim {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fbfbfb;
    height: 100%;
    width: 100%;
    overflow: auto;
    z-index: 1000;
  }
  .n-card {
    background: #ffffff;
    border: 1px solid #d8e3f8;
    box-sizing: border-box;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
}
</style>
