<template lang="pug">
.container
  h1.text-dark.pt-4 Start offering better employee benefits with Nova
  b-card.shadow-none.mb-3(
    header-bg-variant="transparent",
    bg-variant="transparent",
    body-bg-variant="white",
    body-class="shadow-sm")
    b-form(@submit.prevent="submitEntry")
      vue-form-generator(:schema="resDef.schema", :model="resModel", :options="resOptions")
      NovaButton.col-md-2(variant="primary", type="submit", buttonText="Submit")
  NovaInfoModal(:id="'info-modal-1'")
</template>

<script>
import NovaButton from "@/components/NovaButton.vue";
import NovaInfoModal from "@/components/NovaInfoModal.vue";
import resDefs from "./definitions";
export default {
  components: {
    NovaButton,
    NovaInfoModal,
  },
  data() {
    return {
      resOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true,
      },
      resModel: {
        email: this.$route.query.email,
      },
      resDef: resDefs.starterForm,
    };
  },
  methods: {
    async submitEntry() {
      try {
        const result = await this.$apollo.mutate({
          mutation: this.resDef.createFormResponse,
          variables: {
            meta: {
              data: this.resModel,
              landing: "GetStartedPageForm",
            },
            formSlug: "website-leads",
          },
        });
        if (result) {
          this.$bvModal.show("info-modal-1");
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
.field-label {
  position: relative;
  top: -15px;
  font-size: 0.75rem;
  height: 10px;
}
.form-group {
  margin-bottom: 1.5rem;
}
</style>
