import { getListQuery } from "../queries";
import { PolicyRaterType } from "../../../../common/enums";
import utils from "@/utils";

export default {
  schema: {
    fields: [
      {
        model: "type",
        type: "select",
        label: "Policy Type",
        values: ["gmc", "gpa", "gtl", "covid", "workmen", "topup", "super-topup", "dcl", "ppc"],
        fieldClasses: ["form-select"],
        required: true,
      },
      {
        model: "insurer",
        type: "id-selector",
        queryName: "insurers",
        gqlQuery: getListQuery("Insurer"),
        getSubTitle: (i) => i.type,
        label: "Insurer",
        required: true,
        validator: "required",
      },
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Policy Name",
        required: true,
      },
      {
        model: "isPolicy",
        label: "Is Policy",
        type: "checkbox",
        default: false,
        visible: false,
      },
      {
        type: "checklist",
        model: "restrictedFields",
        label: "Restricted Fields",
        values: ["name", "dob", "gender"],
        listBox: true,
      },
      {
        model: "policyClaimContent",
        type: "id-selector",
        gqlQuery: getListQuery("StrapiContent"),
        queryVariables: () => ({
          filter: {
            collectionType: "Benefit",
          },
        }),
        label: "Policy Details Policy Claim Content (from strapi)",
        nodesFromData: utils.getOptionsMapperForStrapi("strapiContents"),
        getLink: utils.getLinkMapperForStrapi("Benefit"),
        required: true,
        validator: "required",
      },
      {
        model: "coverageParameters",
        type: "object",
        label: "Coverage Parameters (from strapi)",
        schema: {
          fields: [
            {
              model: "inclusions",
              type: "array",
              itemContainerComponent: "form-container",
              label: "Coverage Inclusions",
              items: {
                getTitle: (idx, item) =>
                  item?.parameter?.meta?.title
                    ? `Inclusion ${idx + 1}: ${item.parameter.meta.title}`
                    : "[Untitled Inclusion]",
                type: "object",
                schema: {
                  fields: [
                    {
                      model: "parameter",
                      type: "id-selector",
                      gqlQuery: getListQuery("StrapiContent"),
                      label: "Coverage Parameter Slug",
                      required: true,
                      getLink: utils.getLinkMapperForStrapi("CoverageParameter"),
                      getPreview: (item) =>
                        item ? `<strong>${item.meta.title}</strong><br><div>${item.meta.text}</div>` : "",
                      nodesFromData: utils.getOptionsMapperForStrapi("strapiContents", "inclusion"),
                      queryVariables: () => ({
                        filter: {
                          collectionType: "CoverageParameter",
                        },
                        limit: 5,
                        offset: 0,
                      }),
                    },
                  ],
                },
              },
            },
            {
              model: "exclusions",
              type: "array",
              itemContainerComponent: "form-container",
              label: "Coverage Exclusions",
              items: {
                getTitle: (idx, item) =>
                  item?.parameter?.meta?.title
                    ? `Exclusion ${idx + 1}: ${item.parameter.meta.title}`
                    : "[Untitled Exclusion]",
                type: "object",
                schema: {
                  fields: [
                    {
                      model: "parameter",
                      type: "id-selector",
                      gqlQuery: getListQuery("StrapiContent"),
                      label: "Coverage Parameter Slug",
                      getLink: utils.getLinkMapperForStrapi("CoverageParameter"),
                      required: true,
                      getPreview: (item) =>
                        item ? `<strong>${item.meta.title}</strong><br><div>${item.meta.text}</div>` : "",
                      nodesFromData: utils.getOptionsMapperForStrapi("strapiContents", "exclusion"),
                      queryVariables: () => ({
                        filter: {
                          collectionType: "CoverageParameter",
                        },
                        limit: 5,
                        offset: 0,
                      }),
                    },
                  ],
                },
              },
            },
          ],
        },
      },
      {
        model: "meta",
        type: "object",
        label: "Policy Meta",
        schema: {
          fields: [
            {
              type: "switch",
              label: "Is Flexi?",
              model: "isFlexi",
              default: false,
            },
            {
              model: "policyNumber",
              type: "input",
              inputType: "text",
              label: "Policy Number",
            },
            {
              type: "switch",
              label: "Do you want to hide the Sum Insured?",
              model: "hideSi",
            },
            {
              type: "switch",
              label: "Do you want to hide the Policy Document?",
              model: "hidePolicyDoc",
            },
            {
              model: "startDate",
              type: "datepicker",
              label: "Policy start date",
              min: "1900-01-01",
              max: "2100-12-31",
              required: false,
              hint: "Start date must be same or before the end date!",
            },
            {
              model: "endDate",
              type: "datepicker",
              label: "Policy end date",
              min: "1900-01-01",
              max: "2100-12-31",
              required: true,
            },
            {
              model: "depositBalance",
              type: "input",
              inputType: "number",
              label: "Cash Deposit Balance",
            },
            {
              model: "depositBalanceDate",
              type: "datepicker",
              min: "1900-01-01",
              max: "2100-12-31",
              label: "CD Balance Date",
            },
            {
              model: "totalClaimsAmount",
              type: "input",
              inputType: "number",
              label: "Total Claims Amount",
            },
            {
              model: "totalClaimsDate",
              type: "datepicker",
              min: "1900-01-01",
              max: "2100-12-31",
              label: "Claims Amount Date",
            },
          ],
        },
      },
      {
        model: "tpa",
        type: "id-selector",
        queryName: "tpas",
        gqlQuery: getListQuery("Tpa"),
        getSubTitle: (u) => u.name,
        label: "Tpa",
      },
    ],
  },
  raterSchema: {
    fields: [
      {
        model: "chargesConfig",
        type: "object",
        label: "Rater Configuration",
        schema: {
          fields: [
            {
              model: "rater.type",
              type: "inline-select",
              label: "Rater Type",
              labelClasses: "mb-2 pb-1",
              reduce: (v) => v.value,
              options: [
                { value: PolicyRaterType.AGE_RANGE, label: "Age Range" },
                { value: PolicyRaterType.FAMILY_FLOATER, label: "Family Floater" },
                { value: PolicyRaterType.PER_MILLE, label: "Per Mille" },
              ],
              fieldClasses: ["form-select"],
            },
            {
              model: "rater.gst",
              type: "input",
              inputType: "number",
              label: "GST Amount",
              labelClasses: "mb-2 pb-1",
            },
          ],
        },
      },
    ],
  },
  raterConfigOptions: {
    [PolicyRaterType.AGE_RANGE]: {
      model: "chargesConfig.rater.config",
      type: "array",
      itemContainerComponent: "form-container",
      label: "Configuration Details for Selected Rater",
      items: {
        type: "object",
        schema: {
          fields: [
            {
              model: "si",
              type: "input",
              inputType: "number",
              label: "Sum Insured",
              styleClasses: "d-inline-block w-25 px-2 mb-4 align-top",
              labelClasses: "mb-2 pb-1",
              required: true,
            },
            {
              model: "from",
              type: "input",
              inputType: "number",
              label: "From",
              styleClasses: "d-inline-block w-25 px-2 mb-4 align-top",
              labelClasses: "mb-2 pb-1",
              required: true,
            },
            {
              model: "to",
              type: "input",
              inputType: "number",
              label: "To",
              styleClasses: "d-inline-block w-25 px-2 mb-4 align-top",
              labelClasses: "mb-2 pb-1",
              required: true,
            },
            {
              model: "premium",
              type: "input",
              inputType: "number",
              label: "Amount",
              styleClasses: "d-inline-block w-25 px-2 mb-4 align-top",
              labelClasses: "mb-2 pb-1",
              step: "0.01",
              required: true,
            },
          ],
        },
      },
    },
    [PolicyRaterType.FAMILY_FLOATER]: {
      model: "chargesConfig.rater.config",
      type: "array",
      itemContainerComponent: "form-container",
      label: "Configuration Details for Selected Rater",
      items: {
        type: "object",
        schema: {
          fields: [
            {
              model: "si",
              type: "input",
              inputType: "number",
              label: "Sum Insured",
              styleClasses: "d-inline-block w-50 pr-2 mb-4",
              labelClasses: "mb-2 pb-1",
              required: true,
            },
            {
              model: "premium",
              type: "input",
              inputType: "number",
              label: "Premium",
              styleClasses: "d-inline-block w-50 pl-2 mb-2 align-top",
              labelClasses: "mb-2 pb-1",
              step: "0.01",
              required: true,
            },
          ],
        },
      },
    },
    [PolicyRaterType.PER_MILLE]: {
      model: "chargesConfig.rater.config.rateCoefficient",
      type: "input",
      itemContainerComponent: "form-container",
      label: "Rate Coefficient (range 0-1)",
      inputType: "number",
      styleClasses: "align-top",
      labelClasses: "mb-2 pb-1",
      step: "0.01",
      required: true,
      min: 0,
      max: 1,
    },
  },
};
