import gql from "graphql-tag";
import { orgGroupFileFragment } from "../fragments";
export default {
  upsertMutation: gql`
    mutation (
      $id: ID
      $file: Upload
      $useAwsS3: Boolean
      $action: String
      $meta: JSONObject
      $uploadingUserId: ID!
      $name: String
    ) {
      upsertFile(
        input: {
          id: $id
          upload: $file
          useAwsS3: $useAwsS3
          action: $action
          meta: $meta
          uploadingUserId: $uploadingUserId
          name: $name
        }
      ) {
        file {
          id
          name
          url
          meta
          action
          createdAt
          uploadingUser {
            org {
              name
            }
          }
        }
      }
    }
  `,

  upsertOrgGroupFileEdges: gql`
    mutation upsertOrgGroupFileEdges($fileId: ID!, $orgId: ID!, $fileGroupIds: [ID], $meta: JSONObject) {
      upsertOrgGroupFileEdges(input: { fileId: $fileId, orgId: $orgId, fileGroupIds: $fileGroupIds, meta: $meta }) {
        orgGroupFileEdge {
          ...OrgGroupFileEdge
        }
      }
    }
    ${orgGroupFileFragment}
  `,

  deleteOrgGroupFileEdges: gql`
    mutation deleteOrgGroupFileEdges($fileId: ID!, $orgId: ID!, $fileGroupIds: [ID]) {
      deleteOrgGroupFileEdges(input: { fileId: $fileId, orgId: $orgId, fileGroupIds: $fileGroupIds }) {
        success
      }
    }
  `,
};
