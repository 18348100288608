<template lang="pug">
.container
  .row
    b-card.col-md-8.offset-md-2.my-5
      form#biz-detail-form(v-if="showBizDetailForm", @submit="submitForm")
        .mt-2.text-center
          h4 About Your Organization
          | Please provide your details and we&apos;ll get back to you
        .mx-2
          hr
        .row.mt-3
          .col-sm-4
            label(for="biz-detail-name") Your Name
          .col-sm-8
            input#biz-detail-name.form-control(type="text", required="", placeholder="Your name", v-model="biz.name")
        .row.mt-3
          .col-sm-4
            label(for="biz-detail-phone") Mobile Number
          .col-sm-8
            input#biz-detail-phone.form-control(
              type="phone",
              required="",
              placeholder="Mobile number",
              v-model="biz.phone")
        .row.mt-3
          .col-sm-4
            label(for="biz-detail-email") Your Email
          .col-sm-8
            input#biz-detail-email.form-control(
              type="email",
              required="",
              placeholder="Email",
              v-model="biz.email")
        .row.mt-3
          .col-sm-4
            label(for="biz-detail-business") Company Name
          .col-sm-8
            input#biz-detail-business.form-control(
              type="text",
              required="",
              placeholder="Business name",
              v-model="biz.bizName")
        input.d-none(type="submit", ref="bizDetailSubmit")
      form#team-size-form(v-if="showTeamSizeForm", @submit="submitForm")
        .mt-2.text-center
          h4 Team Composition
          | Please provide age-wise approx number of team members in your company
        .mx-2
          hr
        .row
          .col-6.text-center Age
          .col-6.text-center Team members count
          .col-6.text-center.mt-3 18-35
          .col-6.text-center.mt-3
            input.form-control(type="number", min="0", v-model="teamSize[0]")
          .col-6.text-center.mt-3 36-45
          .col-6.text-center.mt-3
            input.form-control(type="number", min="0", v-model="teamSize[1]")
          .col-6.text-center.mt-3 46-55
          .col-6.text-center.mt-3
            input.form-control(type="number", min="0", v-model="teamSize[2]")
          .col-6.text-center.mt-3 56-60
          .col-6.text-center.mt-3
            input.form-control(type="number", min="0", v-model="teamSize[3]")
          .col-6.text-center.mt-3 61-65
          .col-6.text-center.mt-3
            input.form-control(type="number", min="0", v-model="teamSize[4]")
        .mx-2
          hr
        .row
          .col-6.text-center Total Team members
          .col-6.text-center.text-bold {{teamSizeTotal}}
        input.d-none(type="submit", ref="teamSizeSubmit")
      form#policy-form(v-if="showPolicyForm", @submit="submitForm")
        .mt-2.text-center
          h4 Policy Details
          | Your estimate is ready. Try changing some parameters and refresh it
          h4
            span.badge.badge-primary.bg-color2
              | &#x20B9;
              span#policy-quote {{quote}}
              |  per year&nbsp;
              br.d-sm-none
              |  for {{teamSizeTotal}} employees
            br
            .spinner.spinner-border.text-secondary(role="status", v-if="processing")
              span.sr-only Loading...
          span
            | (&#x20B9;{{quotePerEmployee}} per employee)
          br
          span.small
            | This is an estimated price to help you choose the right policy clauses, actual quotes might differ
        .mx-2
          hr
        .row.text-left.align-items-center
          .col-5.mt-3
            label(for="policy-cover", title="You can choose who all will be covered under the policy")
              | Cover Type
          .col-7.mt-3
            select#policy-cover.form-control(v-model="policy.cover")
              option(value="0") Employee Only
              option(value="1", selected="") Employee, spouse, children
              option(value="2") Employee, spouse, children and parents
          .col-5.mt-3
            label(
              for="policy-si",
              title="This is the maximum amount that will be covered for each employee and their familiy")
              | Sum Insured
          .col-7.mt-3.text-center
            .form-group
              .form-check.form-check-inline
                input#policy-si-1.form-check-input(type="radio", v-model="policy.si", value="1")
                label.form-check-label(for="policy-si-1") 1 lac
              br.d-sm-none
              .form-check.form-check-inline
                input#policy-si-2.form-check-input(type="radio", v-model="policy.si", value="2")
                label.form-check-label(for="policy-si-2") 2 lac
              br.d-sm-none
              .form-check.form-check-inline
                input#policy-si-3.form-check-input(type="radio", v-model="policy.si", value="3", checked="")
                label.form-check-label(for="policy-si-3") 3 lac
              br.d-sm-none
              .form-check.form-check-inline
                input#policy-si-5.form-check-input(type="radio", v-model="policy.si", value="5")
                label.form-check-label(for="policy-si-5") 5 lac
          .col-5.mt-3
            label(
              for="policy-wait",
              title="This is the time after which pre-existing diseases like kidney stones\
               and hernia are covered. Standard group health policies have no waiting periods")
              | Waiting period for pre-existing diseases
          .col-7.mt-3
            select#policy-wait.form-control(v-model="policy.wait")
              option(value="0", selected="") No waiting period
              option(value="1") 2 Years
              option(value="2") 4 years
          .col-5.mt-3
            label(for="policy-wait-initial") Initial 30 day waiting period for all diseases
          .col-7.mt-3
            select#policy-wait-initial.form-control(v-model="policy.waitInitial")
              option(value="0", selected="") No waiting period
              option(value="1") 30 day waiting period
          .col-5.mt-3
            label(for="policy-room") Room rent limits
          .col-7.mt-3
            select#policy-room.form-control(v-model="policy.room")
              option(value="0", selected="") Room 1%, ICU 2% of SI
              option(value="1") Room 2%, ICU 4% of SI
              option(value="2") Room &#x20B9;5000, ICU &#x20B9;10000
              option(value="3") No Capping
          .col-5.mt-3
            label(for="policy-maternity") Maternity cover
          .col-7.mt-3
            select#policy-maternity.form-control(v-model="policy.maternity")
              option(value="0") Not covered
              option(value="1") 30k normal, 40k caesarean
              option(value="2", selected="") 40k normal, 50k caesarean
              option(value="3") 60k normal, 75k caesarean
          .col-5.mt-3
            label(for="policy-wait-maternity") Waiting period for maternity benefits
          .col-7.mt-3
            select#policy-wait-maternity.form-control(v-model="policy.waitMaternity")
              option(value="0", selected="") No waiting period
              option(value="1") 9-month waiting period
          .col-5.mt-3
            label(for="policy-insurer") Preferred Insurer
          .col-7.mt-3
            select#policy-insurer.form-control(v-model="policy.insurer")
              option(value="0", selected="") No Preference
              option(value="1") HDFC Ergo
              option(value="2") ICICI Lombard
              option(value="2") Religare
              option(value="4") Max Bupa
              option(value="5") Star Health
              option(value="6") Aditya Birla
        input.d-none(type="submit", ref="policySubmit")
      .row.mt-2.text-center.justify-content-center
        .col-md.mt-3.order-md-1.order-3(v-if="!showTeamSizeForm")
          button.btn.btn-light.btn-block(v-on:click="backButton") Back
        .col-md.mt-3.order-md-2.order-2(v-if="showPolicyForm")
          button.btn.btn-success.btn-block(:disabled="processing", v-on:click="updateQuote") Refresh Quote
        .col-md.mt-3.order-md-3.order-1
          button#next-button.btn.btn-primary.bg-color2.btn-block(:disabled="processing", v-on:click="nextButton")
            span(v-if="showPolicyForm") Request Callback
            span(v-else) Continue
      .mt-3.alert.alert-primary(v-if="formMessage") {{formMessage}}

</template>

<style>
#team-size-form input {
  text-align: center;
  max-width: 80px;
  display: inline-block;
}
</style>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {
      teamSize: [0, 0, 0, 0, 0],
      quote: "...",
      state: 0,
      processing: false,
      formMessage: "",
      appNotMounted: true,
      biz: {
        name: "",
        email: "",
        bizName: "",
        phone: "",
        address: "",
      },
      policy: {
        cover: 1,
        si: 3,
        wait: 0,
        waitInitial: 0,
        room: 0,
        maternity: 1,
        waitMaternity: 0,
        insurer: 0,
      },
    };
  },
  computed: {
    showBizDetailForm: function () {
      return this.state === 1;
    },
    showTeamSizeForm: function () {
      return this.state === 0;
    },
    showPolicyForm: function () {
      return this.state === 2;
    },
    teamSizeTotal: function () {
      return this.teamSize.reduce((t, x) => t + parseInt(x || "0"), 0);
    },
    quotePerEmployee: function () {
      if (
        typeof this.quote === "number" &&
        !isNaN(this.quote) &&
        this.teamSizeTotal > 0
      )
        return Math.floor(this.quote / this.teamSizeTotal);
      else return this.quote;
    },
  },
  mounted: function () {
    var urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("prefill_email"))
      this.biz.email = urlParams.get("prefill_email");
    this.appNotMounted = false;
  },
  beforeDestroy: function () {},
  methods: {
    submitForm: function (e) {
      e.preventDefault();
      if (this.state === 0) {
        this.state = 1;
      } else if (this.state === 1) {
        this.updateQuote();
        this.state = 2;
      } else if (this.state === 2) {
        this.updateQuote();
        this.buyPolicy();
      }
    },
    buyPolicy: function () {
      this.processing = true;

      var ages = ["18-35", "36-45", "46-55", "56-60", "61-65"];
      var teamInfo = this.teamSize.map((x, idx) => ({
        age: ages[idx],
        count: parseInt(x),
      }));
      var policyInfo = {};
      var policyMap = {
        si: ["", "1 lac", "2 lac", "3 lac", "4 lac", "5 lac"],
        room: ["1%+2%", "2%+4%", "5k+10k", "no cap"],
        wait: ["no wait", "2 year wait", "4 year wait"],
        cover: ["employee only", "1+3", "1+5"],
        insurer: [
          "none",
          "hdfc-ergo",
          "icici-lombard",
          "religare",
          "max-bupa",
          "star-health",
          "aditya-birla",
        ],
        maternity: ["not covered", "30k+40k", "40k+50k", "60k+75k"],
        waitInitial: ["no initial wait", "30 day initial wait"],
        waitMaternity: ["no wait", "9 month wait"],
      };
      for (var policyParam in this.policy) {
        policyInfo[policyParam] =
          policyMap[policyParam][this.policy[policyParam]];
      }

      this.$apollo
        .mutate({
          mutation: gql`
            mutation CreateFormResp($meta: JSONObject!, $formSlug: String) {
              upsertFormResponse(input: { meta: $meta, formSlug: $formSlug }) {
                formResponse {
                  id
                  __typename
                }
                __typename
              }
            }
          `,
          variables: {
            meta: {
              email: this.biz.email,
              biz: this.biz,
              team: teamInfo,
              policy: policyInfo,
              landing: "group-premium-calc-old",
            },
            formSlug: "website-leads",
          },
        })
        .then((resp) => {
          this.processing = false;
          this.formMessage = "Thank you! We will get back to you shortly";
        })
        .catch((err) => {
          this.processing = false;
          this.formMessage =
            "There was an error while submitting the information. Please retry or check your internet connection";
          console.log(err);
        });
    },
    nextButton: function () {
      if (this.state === 0) {
        this.$refs.teamSizeSubmit.click();
      } else if (this.state === 1) {
        this.$refs.bizDetailSubmit.click();
      } else if (this.state === 2) {
        this.$refs.policySubmit.click();
      }
    },
    backButton: function () {
      if (this.state > 0) {
        this.state--;
        this.formMessage = "";
      }
    },
    updateQuote: function () {
      this.processing = true;
      this.quote = "...";

      var quote = 0;

      var coverType = [1, 1.75, 2.9][parseInt(this.policy.cover)];
      var si = parseInt(this.policy.si);

      var mat = [0, 1, 1.3, 1.8][parseInt(this.policy.maternity)];

      var waitMat = [1, 0.5][parseInt(this.policy.waitMaternity)];

      var wait = [1.3, 1.1, 1][parseInt(this.policy.wait)];

      var waitInitial = [1.1, 1][parseInt(this.policy.waitInitial)];

      var room = [1, 1.1, 1.15, 1.3][parseInt(this.policy.room)];

      var sizeFactor =
        1.7 - (this.teamSizeTotal + 4) / (this.teamSizeTotal + 16);

      quote = 0;

      const ages = [35, 45, 50, 55, 65];
      const c = [
        -2891.6390977475094,
        3.52099248e2,
        -1.00294737e1,
        9.80451128e-2,
        -1.70720301e3,
        1.65569173e2,
        -4.40842105,
        3.98496241e-2,
      ];
      ages.forEach((age, idx) => {
        const singleQuote =
          c[0] +
          c[1] * age +
          c[2] * age ** 2 +
          c[3] * age ** 3 +
          c[4] * si +
          c[5] * (si * age) +
          c[6] * (si * age ** 2) +
          c[7] * (si * age ** 3);
        quote += singleQuote * this.teamSize[idx];
      });

      quote *= coverType;

      quote *= wait * waitInitial * room;

      quote +=
        mat *
        waitMat *
        (this.teamSize[0] * 600 +
          this.teamSize[1] * 300 +
          this.teamSizeTotal * 300);

      quote = Math.floor(quote * sizeFactor);

      setTimeout(() => {
        this.processing = false;
        this.quote = quote;
      }, 600);
    },
  },
};
</script>
