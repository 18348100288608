<template lang="pug">
div
  .main-card-container.h-100(:class="`style-${bgColor}`")
    .card-text
      .value-prop-icon
        i.py-3(:class="`icon-${cardIcon}`")
      .py-3.card-text-title.hb4 {{ cardTitle }}
      .py-auto.card-text-lower.font-md {{ text }}
    slot(name="mobileCard")
</template>

<script>
export default {
  name: "ValuePropCard",
  props: {
    cardIcon: {
      type: String,
      default: "ambulance",
    },
    cardTitle: {
      type: String,
      default: "We help you find the best price",
    },
    text: {
      type: String,
      default:
        "Explore our customisable policy planner to find out what works best for your company",
    },
    bgColor: {
      type: String,
      default: "success",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.main-card-container {
  height: 100%;
  max-width: 342px;
  border-radius: 0.5rem;
  margin: 0 auto;
  @include media-breakpoint-down(lg) {
    width: 100%;
    max-width: 250px;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: 500px;
  }
}
.card-text {
  padding: 1rem;
}

.card-text-title {
  max-width: 50%;
  font-weight: 600;

  @include media-breakpoint-down(lg) {
    max-width: 60%;
  }
  @include media-breakpoint-down(md) {
    max-width: 50%;
  }
}

.card-text-lower {
  font-weight: 500;
  color: $gray-900;
}

.style-success {
  background: rgba(240, 255, 254, 0.5);
  div.card-text {
    div.value-prop-icon > i {
      color: $malibu-700;
    }
    div.card-text-title {
      color: $malibu-800;
    }
  }
}
.style-secondary {
  background: rgba(245, 242, 255, 0.5);
  div.card-text {
    div.value-prop-icon > i {
      color: $purple-500;
    }
    div.card-text-title {
      color: $purple-700;
    }
  }
}
.style-warning {
  background: rgba(255, 249, 236, 0.5);
  div.card-text {
    div.value-prop-icon > i {
      color: $mustard-600;
    }
    div.card-text-title {
      color: $mustard-800;
    }
  }
}
</style>
