import { getFilteredListQuery, getSingleQuery } from "../queries";
import benefitsSchema from "../schemas/benefits";
import benefitsMutations from "../mutations/benefits";

export default {
  gqlType: "Benefit",
  fields: ["name", "type"],
  singleQuery: getSingleQuery("Benefit"),
  listQuery: getFilteredListQuery("Benefit"),
  ...benefitsSchema,
  ...benefitsMutations,
  transform: (n) => {
    let policySpecificInfo = {};
    if (n.insurer) {
      policySpecificInfo = {
        insurerId: n.insurer.id,
        insurerName: n.insurer.name,
        isPolicy: true,
      };
    } else {
      n.isPolicy = false;
    }
    if (n.content?.meta?.id) {
      n.content = {
        ...n.content,
        id: n.content?.meta?.id,
        name: `${n.content?.slug} (${n.content?.meta?.id})`,
      };
    }
    return {
      ...n,
      ...policySpecificInfo,
      meta: {
        ...n.meta,
        strapiId: n.content?.meta?.id || n.content?.id,
      },
    };
  },
  getEdgesFromData: (data) => data.benefits.edges,
  getTotalNumberOfPolicies: (benefits) => {
    let count = 0;
    benefits.forEach((benefit) => {
      if (benefit.node.isPolicy) {
        count++;
      }
    });
    return count;
  },
  getTotalNumberOfBenefits: (benefits) => {
    let count = 0;
    benefits.forEach((benefit) => {
      if (!benefit.node.isPolicy) {
        count++;
      }
    });
    return count;
  },
}
