import gql from "graphql-tag";
import {
  userChallengeFragment,
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertUserChallenge(
      $id: ID
      $challengeTemplateId: ID!
      $segmentId: ID
      $orgId: ID
      $userId: ID
      $startDate: String
      $startTime: String
      $expiryDate: String
      $expiryTime: String
      $currentTotal: Float
      $challengeStatus: String
      $meta: JSONObject
      $duration: Float
      $enrollIntoChallenge: Boolean
    ) {
      upsertUserChallenge(
        input: {
          id: $id
          challengeTemplateId: $challengeTemplateId
          segmentId: $segmentId
          orgId: $orgId
          userId: $userId
          startDate: $startDate
          startTime: $startTime
          expiryDate: $expiryDate
          expiryTime: $expiryTime
          currentTotal: $currentTotal
          challengeStatus: $challengeStatus
          meta: $meta
          duration: $duration
          enrollIntoChallenge: $enrollIntoChallenge
        }
      ) {
        userChallenge {
          ...UserChallenge
        }
      }
    }
    ${userChallengeFragment}
  `,
  bulkDeleteMutation: gql`
    mutation bulkDeleteUserChallenges(
      $challengeTemplateId: ID!
      $segmentId: ID
      $orgId: ID
    ) {
      bulkDeleteChallenges(
        input: {
          challengeTemplateId: $challengeTemplateId
          segmentId: $segmentId
          orgId: $orgId
        }
      ) {
        success
      }
    }
  `,
}
