import { getListQuery } from "../queries";
import utils from "@/utils";
import { fitnessActivityList } from "../../fitness/constants";

export default {
  schema: {
    fields: [
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Challenge Name",
      },
      {
        model: "challenge",
        type: "id-selector",
        gqlQuery: getListQuery("StrapiContent"),
        label: "Challenge Slug",
        required: true,
        getLink: utils.getLinkMapperForStrapi("NgageChallengeContent"),
        nodesFromData: utils.getOptionsMapperForStrapi("strapiContents"),
        queryVariables: () => ({
          filter: {
            collectionType: "NgageChallengeContent",
          },
        }),
      },
      {
        model: "activityTypes",
        type: "inline-select",
        options: Object.keys(fitnessActivityList),
        label: "Activity Types",
        validator: "required",
        multiple: true,
        searchable: true,
      },
      {
        model: "duration",
        type: "input",
        inputType: "number",
        label: "Challenge Duration in Hours",
        step: "0.01",
        required: true,
      },
      {
        model: "challengeUnit",
        type: "select",
        label:
          "Challenge Unit to be displayed in fitness UI and in bot messages",
        values: ["km", "hr", "min", "count", "step", "lap", "ltr", "ml"],
      },
      {
        model: "goalValueByUnit",
        type: "input",
        inputType: "number",
        label:
          "Actual Goal for the given units (only for personal and enrollable challenges)",
        hint: "Fixed Actual Goal (only for personal and enrollable challenges)",
        step: "0.01",
      },
      {
        model: "baseGoalValue",
        type: "input",
        inputType: "number",
        label: "Challenge Goal (only for personal and enrollable challenges)",
        step: "0.01",
        hint: "Fixed Goal in kms based on challenge unit and goal for given units",
        readonly: true,
      },
      {
        model: "minGoalValue",
        type: "input",
        inputType: "number",
        label: " Minimum Challenge Goal (only for org challenges)",
        step: "0.01",
        hint: "Minimum value for the goal that the template can't go below",
      },
      {
        model: "incrementFactor",
        type: "input",
        inputType: "number",
        label: "Increment Factor (only for org challenges)",
        step: "0.01",
        hint: "The factor by which the goal is increased if previous goal in this template is achieved by an org (eg: 25(previous goal)*1.1=27.5 (Current goal))",
      },
      {
        model: "decrementFactor",
        type: "input",
        inputType: "number",
        label: "Decrement Factor (only for org challenges)",
        step: "0.01",
        hint: "The factor by which the goal is increased if previous goal in this template is NOT achieved by an org (eg: 25(previous goal)*0.9=22.5 (Current goal))",
      },
      {
        model: "points",
        type: "input",
        inputType: "number",
        label: "Points won on completion",
      },
      {
        model: "topScorerPoints",
        type: "input",
        inputType: "number",
        label: "Top scorer points",
        hint: "EXTRA points won by top 3 scorers on completion",
      },
      {
        model: "templateType",
        type: "select",
        label: "Challenge template type",
        values: ["org", "user", "enrollable"],
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        model: "templateType",
        type: "select",
        values: ["org", "user", "enrollable"],
        selectOptions: {
          noneSelectedText: "Template Type filter (none)",
        },
      },
    ],
  },
}
