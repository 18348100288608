import { getListQuery } from "../queries";

export default {
  schema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getListQuery("Org"),
        label: "Organization",
      },
      {
        model: "name",
        type: "select",
        label: "Property Name",
        values: [
          "FITNESS_RATES",
          "FEATURE_FLAGS",
          "LAST_BULK_EMAIL_SYNC",
          "LOGIN_OPTIONS",
          "ORG_SEGMENT",
          "RELATIONSHIP_MANAGER_MAPPING",
          "KENKO_ORG_MAPPING",
          "MEDIBUDDY_ORG_ENTITY_ID",
          "ORG_ONBOARDING_DEFAULT_ETA",
          "ZOHO_DESK_OAUTH_TOKEN",
          "ZOHO_CRM_OAUTH_TOKEN",
          "PLACEHOLDER_FITNESS_DATA",
          "ORG_ONBOARDING_EMAIL_DETAILS",
          "INSURER_TPA_HANDBOOK_MAPPING",
          "EKINCARE_PWA_ORG_ENTITY_ID",
        ],
        required: true,
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getListQuery("Org"),
        placeholder: "Organization filter (none)",
        showSelected: false,
      },
    ],
  },
};
