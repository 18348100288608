import gql from "graphql-tag";
import {
  formFragment,
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation m0($id: ID, $slug: String!, $type: String!, $meta: JSONObject!) {
      upsertForm(input: { id: $id, slug: $slug, type: $type, meta: $meta }) {
        form {
          ...Form
        }
      }
    }
    ${formFragment}
  `,
}
