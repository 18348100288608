<template lang="pug">
  b-sidebar(right, shadow, sidebar-class="nova-sidebar", v-bind="{...$props, ...$attrs}", @change="onChange", :id="ssId")
    template(#header)
      span.font-weight-medium.font-lg.text-gray-900 {{ headerName }}
      div.header-right-actions
        b-button-group.ctrl-btn-grp(v-if="showNavControls")
          n-button(variant="outline-dark", imageIcon="chevron-left", buttonText="", size="sm")
          n-button(variant="outline-dark", imageIcon="chevron-right", buttonText="", size="sm")
        n-button.close-btn.border-0.shadow-none(variant="outline-dark", imageIcon="cross", buttonText="", size="sm",  v-b-toggle:[ssId])
    b-tabs(content-class="h-100")
      slot(name="tabs")
        n-tab-item(navContent="Tab 1", active)
        n-tab-item(navContent="Tab 2")
        n-tab-item(navContent="Tab 3")
</template>

<script>
import NButton from "@/components/NovaButton";
import NTabItem from "@/components/NovaTabItem.vue";

export default {
  components: {
    NButton,
    NTabItem,
  },
  props: {
    headerName: {
      type: String,
      default: "",
    },
    showNavControls: {
      type: Boolean,
      default: false,
    },
    ssId: {
      type: String,
      required: true,
      default: "demo-ss",
    },
    onChange: {
      type: Function,
      default: () => null,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.b-sidebar {
  width: 37.5rem !important;
}

.nova-sidebar {
  .b-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding-bottom: 1rem;
    border-top-left-radius: 0.25rem;
    .header-right-actions {
      display: flex;
      gap: 1.625rem;
      .btn {
        i {
          color: $gray-600;
        }
      }
      .btn-group {

        .btn {
          border-width: 1px;
          border-color: $gray-400;
          padding: 0.25rem 0.45rem;
          box-shadow: $box-shadow-xs !important;

        }
      }

      .close-btn {
        color: $gray-600;

        &:hover {
          color: $gray-900;
        }
      }
    }
  }

  .b-sidebar-body {
    background: white;
    border-bottom-left-radius: 0.25rem;
    .nav {
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
    }
  }
}
</style>
