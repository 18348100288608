<template lang="pug">
div.py-7
  div
    .image-container 
      img(:src="require(`@/assets/images/${this.emptyState.image}.svg`)")
  div
    .empty-states-msg1.font-weight-semibold.font-xl {{this.emptyState.text}}
    .empty-states-msg2.font-lg.font-weight-medium.mx-auto  {{this.emptyState.subtext}}
</template>

<script>
export default {
  name: "EmptyStatesEndorsements",
  props: {
    tableType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      emptyState: this.getEmptyState(),
    };
  },
  methods: {
    getEmptyState() {
      switch (this.tableType) {
        case "enrollment-pending":
          return {
            text: "Woohoo!",
            subtext: "You don’t have any endorsements with missing data",
            image: "success",
          };
        case "approved":
          return {
            text: "No endorsements yet!",
            subtext: "You haven’t approved any endorsements for this month yet.",
            image: "approved",
          };
        case "previous-endorsements":
          return {
            text: "Woohoo!",
            subtext: "You don’t have any previous endorsements.",
            image: "success",
          };
        case "empty-dependent":
          return {
            text: "Nothing to show here!",
            subtext: "No dependent are added yet.",
            image: "empty-box",
          };
        case "unapproved":
        default:
          return {
            text: "Hooray!",
            subtext: "You have approved all endorsements for this batch!",
            image: "success",
          };
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.empty-states-msg1 {
  font-family: "Hergon Grotesk";
  font-style: normal;
  text-align: center;
  color: $gray-900;
  margin-bottom: 0.5rem;
  margin-top: 0.8rem;
}
.empty-states-msg2 {
  font-family: "Inter";
  font-style: normal;
  text-align: center;
  letter-spacing: 0.012em;
  color: $gray-700;
  max-width: 50%;
  @include media-breakpoint-down(sm) {
    max-width: 90%;
  }
}
.image-container {
  text-align: center;
}
</style>
