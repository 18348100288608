<template lang="pug">
div
  span.pr-2.align-sub(
    v-if="schema.icon",
    :class="[`\
      icon-${schema.icon}`, \
      `text-${schema.iconColor}`, \
      schema.iconOpacity?`ic-${schema.iconOpacity}`:'']")
  span.h5.font-lg(v-if="schema.fieldLabel") {{ schema.fieldLabel }}
  multi-select-cards.pt-1.pb-3(v-model="value", v-bind="schema")
</template>

<script>
import { abstractField } from "vue-form-generator";
import MultiSelectCards from "../MultiSelectCards.vue";

export default {
  components: {
    MultiSelectCards,
  },
  mixins: [abstractField],
  created() {},
};
</script>
