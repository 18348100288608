import gql from "graphql-tag";
import {
  insurerFragment,
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation m0(
      $id: ID
      $type: String
      $hospitalContentId: ID
      $name: String!
      $photoUrl: String
      $meta: JSONObject!
      $slug: String!
    ) {
      upsertInsurer(
        input: {
          id: $id
          hospitalContentId: $hospitalContentId
          name: $name
          type: $type
          photoUrl: $photoUrl
          meta: $meta
          slug: $slug
        }
      ) {
        insurer {
          ...Insurer
        }
      }
    }
    ${insurerFragment}
  `,
}
