import { genderList, dependentRelationList } from "../../../../utils";

export default {
  schema: {
    fields: [
      {
        model: "user",
        type: "object",
        label: "User's Old Details",
        schema: {
          fields: [
            {
              model: "email",
              type: "input",
              inputType: "email",
              label: "Email Address",
              required: true,
            },
            {
              model: "name",
              type: "input",
              inputType: "text",
              label: "Full Name",
              required: true,
            },
            {
              model: "displayName",
              type: "input",
              inputType: "text",
              label: "Display Name (nickname or first name)",
            },
            {
              model: "dob",
              type: "datepicker",
              label: "Date of Birth",
              min: "1900-01-01",
              max: "2100-12-31",
              required: true,
            },
            {
              model: "gender",
              type: "select",
              label: "Gender",
              values: genderList,
              fieldClasses: ["form-select"],
              required: true,
            },
            {
              model: "meta",
              type: "object",
              label: "Metadata",
              schema: {
                fields: [
                  {
                    model: "employeeId",
                    type: "input",
                    inputType: "text",
                    label: "Employee ID",
                    required: "true",
                  },
                  {
                    model: "jobGrade",
                    type: "input",
                    inputType: "text",
                    label: "Employee's Job Grade",
                    hint: "A grade like A, IC1, etc can be used to easily decide level of applicable benefits for the employee",
                  },
                  {
                    model: "dateOfJoining",
                    type: "datepicker",
                    label: "Date of Joining",
                    required: true,
                    hint: "Date of joining is required to determine start date of benefits",
                  },
                  {
                    model: "contactNumber",
                    type: "input",
                    inputType: "text",
                    label: "Employee's Contact Number",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        model: "dependent",
        type: "object",
        //          itemContainerComponent: "form-container",
        label: "Employee Dependents",
        schema: {
          fields: [
            {
              model: "name",
              type: "input",
              inputType: "text",
              label: "Dependent Name",
              required: true,
            },
            {
              model: "dob",
              type: "datepicker",
              label: "Dependent Date of Birth",
              min: "1900-01-01",
              max: "2100-12-31",
              required: true,
            },
            {
              model: "gender",
              type: "select",
              label: "Gender",
              values: genderList,
              fieldClasses: ["form-select"],
              required: true,
            },
            {
              model: "relation",
              type: "select",
              label: "Relation with Employee",
              values: dependentRelationList,
              fieldClasses: ["form-select"],
              required: true,
            },
          ],
        },
      },
    ],
  },
}
