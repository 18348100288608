import { getListQuery } from "../queries";
import utils from "@/utils";

export default {
  schema: {
    fields: [
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Name",
        required: true,
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        schema: {
          fields: [
            {
              model: "networkHospitalLink",
              type: "input",
              inputType: "text",
              label: "Network Hospital Link",
            },
            {
              model: "tpaGuideContent",
              type: "id-selector",
              gqlQuery: getListQuery("StrapiContent"),
              queryVariables: () => ({
                filter: {
                  collectionType: "ContentPage",
                },
              }),
              label: "TPA Guide Content (from strapi)",
              nodesFromData: utils.getOptionsMapperForStrapi("strapiContents"),
              getLink: utils.getLinkMapperForStrapi("ContentPage"),
            },
            {
              model: "contactNumber",
              type: "input",
              inputType: "text",
              label: "TPA Number",
            },
          ],
        },
      },
    ],
  },
};
