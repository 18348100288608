import gql from "graphql-tag";
import cliToolsMutations from "../mutations/cliTools";
import { getListQuery } from "../queries";
import utils from "@/utils";

export default {
  ...cliToolsMutations,
  getOptions: gql`
    query cliOptions($cmdName: String!) {
      cliOptions(cmdName: $cmdName)
    }
  `,
  cmds: {
    compareDate: {
      label: "Compare Date b/w two sheets",
      noFile: true,
      schema: {
        fields: [
          {
            model: "sheetNo",
            type: "select",
            values: () => [],
            label: "Select Sheet",
            placeholder: "Pick the sheet to import records from",
            selectOptions: {
              noneSelectedText: "Pick the sheet to import records from",
            },
          },
          {
            model: "dobColumn",
            type: "select",
            values: [],
            label: "Select column with date of birth",
            placeholder: "Pick the column with date",
            selectOptions: {
              noneSelectedText: "Pick the column with date",
            },
          },
          {
            label: " Upload excel file created for portal setup",
            type: "upload",
            model: "files",
            multiple: true,
            rawOutput: true,
            accept: "*",
            required: true,
            async onChanged(model, schema, event, instance) {
              model.files.portalSetupFile = await event.target.files[0];
            },
          },
        ],
      },
      transformArgs: (argsModel) => argsModel,
    },
    onboardOrg: {
      label: "Onboard org/import users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "sendOnboardingInvites",
            type: "checkbox",
            label: "Send onboarding invite email to these users",
            fieldClasses: ["mb-5"],
          },
          {
            type: "label",
            hint: "Email invites if selected will be sent to all users in the file",
          },
          {
            type: "label",
            label:
              "Note - This tool would only create new users and won't update the data of existing users" +
              "<br>It can however be used to link benefits/policies to existing users and dependents at any time",
          },
        ],
      },
      transformArgs: (argsModel) => {
        argsModel.policies = argsModel.policies?.map(({ policy }) => {
          return {
            policy: {
              id: policy.id,
              type: policy.type,
              name: policy.name,
              insurer: {
                id: policy.insurer?.id,
                name: policy.insurer?.name,
              },
            },
          };
        });
        argsModel.benefits = argsModel.benefits?.map(({ benefit }) => {
          return {
            benefit: {
              id: benefit.id,
              type: benefit.type,
              name: benefit.name,
            },
          };
        });
        return argsModel;
      },
    },
    setupOrgAndPolicies: {
      label: "Process Closing Slip",
      schema: {
        fields: [
          {
            model: "policyClaimContent",
            type: "id-selector",
            gqlQuery: getListQuery("StrapiContent"),
            queryVariables: () => ({
              filter: {
                collectionType: "Benefit",
              },
            }),
            label: "Policy Claim Content (from strapi)",
            nodesFromData: utils.getOptionsMapperForStrapi("strapiContents"),
            required: true,
            validator: "required",
          },
        ],
      },
      transformArgs: (argsModel) => argsModel,
    },
    benefitsBulkAddition: {
      label: "Benefits Bulk Addition",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "benefits",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit"),
            queryVariables: () => ({
              filter: {
                isPolicy: false,
              },
            }),
            label: "Benefits",
            multiple: true,
            required: true,
          },
          {
            type: "label",
            label: "Note - This tool will link selected benefits to all the employees of selected Org",
            visible: true,
          },
          {
            model: "linkSelectedUsers",
            label: "Link benefits to selected users only?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, benefits, ...args }) => ({
        ...args,
        orgId: org?.id,
        benefits: benefits.map((benefit) => {
          return { id: benefit.id, name: benefit.name };
        }),
      }),
    },
    importECards: {
      label: "Import ecards zip",
      schema: {
        fields: [
          {
            model: "insurerFormat",
            type: "select",
            label: "Insurer",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit"),
            queryVariables: () => ({
              filter: {
                isPolicy: true,
              },
            }),
            label: "Policy",
            required: true,
          },
        ],
      },
      getOptionsFromApi: true,
      transformArgs: ({ org, policy, ...args }) => ({
        ...args,
        orgId: org?.id,
        policyId: policy?.id,
      }),
    },
    fetchECards: {
      label: "Fetch E Cards",
      schema: {
        fields: [
          {
            model: "tpa",
            type: "select",
            label: "TPA / Insurer",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit"),
            getSubTitle: (u) => u.name,
            queryVariables: () => ({
              filter: {
                isPolicy: true,
              },
            }),
            label: "Policy",
            required: true,
          },
        ],
      },
      noFile: true,
      getOptionsFromApi: true,
      transformArgs: ({ org, policy, ...args }) => ({
        ...args,
        orgId: org?.id,
        org,
        policyId: policy?.id,
        policy,
      }),
    },
    claimsBulkUpload: {
      label: "Bulk Upload Claims",
      schema: {
        fields: [
          {
            model: "tpa",
            type: "select",
            label: "TPA / Insurer",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
        ],
      },
      getOptionsFromApi: true,
      transformArgs: (argsModel) => argsModel,
    },
    bulkUpdateDatabaseTable: {
      label: "Bulk Update Database Table",
      schema: {
        fields: [
          {
            model: "dbTable",
            type: "select",
            label: "Database Table Name",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
          {
            model: "fixRelationMapping",
            type: "checkbox",
            label: "Fix Relation Mapping",
            default: false,
            fieldClasses: ["mb-0"],
          },
          {
            type: "label",
            label:
              "(Additional columns to be added: Custom → crmRelation, Custom → crmPatientName, Custom → crmGender)",
            styleClasses: "mb-0 mt-n2",
          },
          {
            model: "dryRun",
            type: "checkbox",
            label: "Dry Run (does not write to the database)",
            default: false,
            fieldClasses: ["mb-0"],
          },
          {
            model: "file",
            type: "file",
            label: "File dump",
            required: true,
          },
        ],
      },
      getOptionsFromApi: true,
      transformArgs: (argsModel) => argsModel,
    },
    exportChanges: {
      label: "Export user changes",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "uploadToCloud",
            type: "select",
            label: "Upload xlsx to google cloud?",
            values: [true, false],
          },
        ],
      },
      transformArgs: ({ org, batch, ...args }) => ({
        ...args,
        orgId: org?.id,
        batchId: batch?.id,
      }),
    },
    nukeCache: {
      label: "Delete cached content",
      schema: {
        fields: [
          {
            model: "cacheKeyToBeNuked",
            type: "select",
            label: "Cache category to be deleted",
            values: [],
          },
        ],
      },
      noFile: true,
      getOptionsFromApi: true,
      transformArgs: (argsModel) => argsModel,
    },
    removeUserBenefits: {
      label: "Remove User Benefits",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "benefit",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit"),
            queryVariables: () => ({
              filter: {
                isPolicy: false,
              },
            }),
            label: "Select Benefit",
            required: true,
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, ...args }) => ({ ...args, orgId: org.id }),
    },
    sendBenefitDetailsEmails: {
      label: "Send Policy Parameters as Email",
      hint: "Sends emails to all users with an active policy whose status is not deleted",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "campaignId",
            type: "input",
            inputType: "text",
            label: "Campaign ID (optional)",
          },
          {
            model: "gmcBlockId",
            type: "input",
            inputType: "text",
            label: "Mailmodo GMC blockId",
            required: true,
          },
          {
            model: "gpaBlockId",
            type: "input",
            inputType: "text",
            label: "Mailmodo GPA/GTL blockId",
            required: true,
          },
          {
            type: "label",
            label: "Note - This tool will send emails to all the employees of selected Org",
            visible: true,
          },
          {
            model: "sendToSelectedUsers",
            label: "Send email to selected users only?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, policy, campaignId, ...args }) => ({
        ...args,
        orgId: org?.id,
        policyId: policy?.id,
        campaignId: campaignId || "",
      }),
    },
    sendPortalActivatedEmails: {
      label: "Send Portal activated email",
      hint: "Sends Portal activated email to org users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "campaignId",
            type: "input",
            inputType: "text",
            label: "Campaign ID (optional)",
          },
          {
            type: "label",
            label: "This tool will send emails to all the employees of selected Org",
            visible: true,
          },
          {
            model: "sendToSelectedUsers",
            label: "Send email to selected users only?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, campaignId, ...args }) => ({ ...args, orgId: org?.id, campaignId: campaignId || "" }),
    },
    sendEcardLinkedEmails: {
      label: "Send E-Card linked email",
      hint: "Sends portal E-Card linked email to org users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "campaignId",
            type: "input",
            inputType: "text",
            label: "Campaign ID (optional)",
          },
          {
            type: "label",
            label: "This tool will send emails to all the employees of selected Org",
            visible: true,
          },
          {
            model: "sendToSelectedUsers",
            label: "Send email to selected users only?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, campaignId, ...args }) => ({ ...args, orgId: org?.id, campaignId: campaignId || "" }),
    },
    vipBulkUpdate: {
      label: "VIP Bulk Update",
      hint: "Updates VIP status of users",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
        ],
      },
      transformArgs: ({ org, file, ...args }) => ({ ...args, orgId: org?.id, file }),
    },
    bulkMapUHID: {
      label: "Bulk Map UHID",
      hint: "Maps UHID to users",
      schema: {
        fields: [
          {
            model: "tpa",
            type: "select",
            label: "TPA / Insurer",
            values: [],
            fieldClasses: ["form-select"],
            required: true,
          },
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit"),
            getSubTitle: (u) => u.name,
            queryVariables: () => ({
              filter: {
                isPolicy: true,
              },
            }),
            label: "Policy",
            required: true,
          },
          {
            model: "file",
            type: "file",
            label: "File dump",
            required: true,
          },
        ],
      },
      getOptionsFromApi: true,
      transformArgs: ({ org, file, policy, ...args }) => ({ ...args, orgId: org?.id, policyId: policy?.id, file }),
    },
    bulkUpdateUserDetails: {
      label: "Bulk Update User and Dependents Details",
      hint: "Bulk updates details of users and dependents (dependents name mandatory)",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
        ],
      },
      transformArgs: ({ org, file, ...args }) => ({ ...args, orgId: org?.id, file }),
    },
    bulkUploadClaimsFromCrm: {
      label: "Bulk Upload Claims from CRM to Portal",
      transformArgs: (argsModel) => argsModel,
    },
    generateNovaEcards: {
      label: "Generate Nova Branded E-cards",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
            required: true,
          },
          {
            model: "policy",
            type: "id-selector",
            queryName: "benefits",
            gqlQuery: getListQuery("Benefit"),
            getSubTitle: (u) => u.name,
            queryVariables: () => ({
              filter: {
                isPolicy: true,
              },
            }),
            label: "Policy",
            required: true,
          },
          {
            model: "sendNotification",
            type: "checkbox",
            label: "Send Email Notifications",
            default: true,
            fieldClasses: ["mb-0"],
          },
          {
            type: "label",
            label: "Selecting this option will send an email to the users, even if they have received one before.",
            styleClasses: "mb-0",
          },
        ],
      },
      noFile: true,
      getOptionsFromApi: true,
      transformArgs: ({ org, policy, ...args }) => ({
        ...args,
        orgId: org?.id,
        policyId: policy?.id,
      }),
    },
    bulkImportDocFromCrm: {
      label: "Bulk import doc from CRM to Portal",
      schema: {
        fields: [
          {
            model: "org",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organization",
          },
          {
            model: "bulkImportCrmDocs",
            label: "Bulk import Docs?",
            type: "checkbox",
          },
        ],
      },
      noFile: true,
      transformArgs: ({ org, ...args }) => ({
        ...args,
        orgId: org?.id || "",
      }),
    },
    syncCxEntitiesToCrm: {
      label: "Sync CX mapping to CRM from Portal",
      schema: {
        fields: [
          {
            model: "orgs",
            type: "id-selector",
            queryName: "orgs",
            gqlQuery: getListQuery("Org"),
            label: "Organizations",
            hint: "Select the 'Global (Default for all orgs)' org to sync for all ACTIVE orgs",
            multiple: true,
            showSelected: false,
          },
        ],
      },
      noFile: true,
      transformArgs: ({ orgs, ...args }) => ({
        ...args,
        orgIds: orgs.map((org) => org?.id) || [],
      }),
    },
  },
  cmdsAdminReviewChanges: {
    exportChanges: {
      label: "Export user changes",
      schema: {
        fields: [
          {
            model: "uploadToCloud",
            type: "select",
            label: "Upload xlsx to google cloud?",
            values: [true, false],
          },
        ],
      },
    },
  },
};
