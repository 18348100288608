<template lang="pug">
  .content.pt-0(:class="contentSpacer")
    transition(name="fade", mode="out-in")
      router-view
</template>

<script>
export default {
  computed: {
    contentSpacer() {
      //TODO: refactor route to class association
      if (["onboarding_nova_email_link", "reset_password_verify"].includes(this.$route?.name)) return "no-header";
      if (this.$route?.fullPath?.includes("/org-admin/analytics")) return "org-admin-analytics-content-height";
      if (this.$route?.fullPath?.includes("/org-admin")) return "org-admin-content-height";
      return "";
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
