<template lang="pug">
  div
    router-view(:key="$route.path")
    n-alerts
    vue-progress-bar
    versioning-modal
</template>

<script>
import VersioningModal from "../components/VersioningModal";
import { onWheelNumInputBlur } from "../utils";
export default {
  components: {
    VersioningModal,
  },
  created() {
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach((to, from) => {
      this.$Progress.finish();
    });
  },
  mounted() {
    const calendlyScript = document.createElement("script");
    calendlyScript.setAttribute(
      "src",
      "https://calendly.com/assets/external/widget.js"
    );
    calendlyScript.setAttribute("type", "text/javascript");
    calendlyScript.defer = true;
    document.head.appendChild(calendlyScript);

    const calendlyStyles = document.createElement("link");
    calendlyStyles.setAttribute(
      "href",
      "https://calendly.com/assets/external/widget.css"
    );
    calendlyStyles.setAttribute("rel", "stylesheet");
    document.head.appendChild(calendlyStyles);

    const lottieScript = document.createElement("script");
    lottieScript.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.7.6/lottie.min.js"
    );
    lottieScript.setAttribute(
      "integrity",
      "sha512-BB7rb8ZBAxtdJdB7nwDijJH9NC+648xSzviK9Itm+5APTtdpgKz1+82bDl4znz/FBhd0R7pJ/gQtomnMpZYzRw=="
    );
    lottieScript.setAttribute("crossorigin", "anonymous");
    lottieScript.defer = true;
    document.head.appendChild(lottieScript);

    // to disable numeric input changes on scroll
    document.addEventListener("wheel", onWheelNumInputBlur);

    this.$Progress.finish();
  },
  beforeDestroy() {
    document.removeEventListener("wheel", onWheelNumInputBlur);
  },
};
</script>

<style lang="scss"></style>
