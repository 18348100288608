import moment from "moment";
import { UserAddSource, AcceptedRelations } from "../common/enums";

export default {
  userStatus: {
    UP_TO_DATE: "up-to-date",
    DELETE_PENDING: "delete-pending",
    SUSPENDED: "suspended",
    UPDATE_PENDING: "update-pending",
    BENEFITS_ADDITION_PENDING: "benefits-addition-pending",
    BENEFITS_DELETION_PENDING: "benefits-deletion-pending",
    BENEFITS_UPDATION_PENDING: "benefits-updation-pending",
  },
  getMembersCoveredForBenefit(user, benefitId, includeInReviewPolicies = false) {
    const membersCovered = [];
    const isSelfCovered =
      user.benefits?.find((benefit) => benefit.node.id === benefitId) ||
      user.userChanges?.find(
        (userChange) => userChange.benefit?.id === benefitId && this.isUserChangeAppropriate(userChange)
      );

    if (isSelfCovered) {
      membersCovered.push({
        id: user.id,
        name: user.name,
        dob: user.dob,
        gender: user.gender,
        relation: "self",
        meta: {},
      });
    }
    if (user.dependents) {
      membersCovered.push(
        ...user.dependents.filter((dependent) => {
          for (let i = 0; i < dependent.benefits.length; i++) {
            if (dependent.benefits[i].node.id === benefitId) {
              return true;
            }
          }
          return false;
        })
      );
    }

    if (!includeInReviewPolicies) {
      return membersCovered;
    }

    user.userChanges?.forEach((userChange) => {
      if (userChange.benefit?.id !== benefitId) {
        return;
      }
      if (userChange.dependent && this.isUserChangeAppropriate(userChange)) {
        membersCovered.push(userChange.dependent);
      }
    });

    return membersCovered;
  },
  getDependentsCoveredForBenefit(user, benefitId) {
    if (benefitId) {
      const dependentsCovered = user.dependents.filter((dependent) => {
        for (let i = 0; i < dependent.benefits.length; i++) {
          if (dependent.benefits[i].node.id === benefitId) {
            return true;
          }
        }
        return false;
      });
      return dependentsCovered;
    }
    return [];
  },
  getCoverageValue(user, policy, includeInReviewPolicies = false) {
    let coverageValue = null;
    if (user && policy) {
      let numDeps = 0;
      user.dependents?.forEach((dependent) => {
        dependent.benefits.forEach((benefit) => {
          if (benefit.node.id === policy.id) {
            numDeps += 1;
          }
        });
      });
      // include inreview policies
      if (includeInReviewPolicies && user.userChanges) {
        user.userChanges.forEach((userChange) => {
          if (
            userChange.benefit?.id === policy.id &&
            userChange.dependent &&
            this.isUserChangeAppropriate(userChange)
          ) {
            numDeps += 1;
          }
        });
      }
      let isSelfCovered = false;
      if (user.benefits) {
        isSelfCovered =
          user.benefits.findIndex((benefit) => benefit.node.id === policy.id) !== -1 ||
          user.userChanges.find(
            (userChange) => userChange.benefit?.id === policy.id && this.isUserChangeAppropriate(userChange)
          );
      }

      if (isSelfCovered !== false && policy?.type !== "ppc") {
        if (numDeps) coverageValue = `You + ${numDeps} dependent${numDeps > 1 ? "s" : ""}`;
        else coverageValue = "Just You";
      } else {
        coverageValue = `${numDeps} dependents`;
      }
    }
    return coverageValue;
  },
  requiresEndorsement(user) {
    if (user.benefits.length > 0 || user.userChanges.length > 0) {
      return true;
    }
    if (user.dependents.some((dependent) => dependent.benefits.length > 0)) {
      return true;
    }
  },
  extractUserDependentBenefits(me, policy = false) {
    const combinedUserDependentBenefits = {};

    // getting dependent benefits
    me.dependents?.forEach((dependent) => {
      dependent.benefits.forEach((benefit) => {
        if (benefit.node?.isPolicy === policy) {
          if (policy || (!policy && !(!benefit.node.content && !benefit.node.coverageParameters))) {
            combinedUserDependentBenefits[benefit.node.id] = benefit;
          }
        }
      });
    });

    // getting user benefits
    me.benefits.forEach((benefit) => {
      if (benefit.node?.isPolicy === policy) {
        if (policy || (!policy && !(!benefit.node.content && !benefit.node.coverageParameters))) {
          combinedUserDependentBenefits[benefit.node.id] = benefit;
        }
      }
    });

    return Object.values(combinedUserDependentBenefits);
  },
  getActivePolicyByType(allPolicies, policyType) {
    let policy = {};
    if (!allPolicies) return policy;

    policy = allPolicies.find(
      (p) => p.node.type === policyType && (!p.node?.meta?.endDate || moment(p.node.meta.endDate).isAfter(moment()))
    );
    return policy;
  },
  getPolicyType(policyType) {
    switch (policyType) {
      case "gmc":
        return "Health Insurance";
      case "gpa":
        return "Personal Accident Insurance";
      case "gtl":
        return "Life Insurance";
      case "covid":
        return "COVID-19 Insurance";
      case "topup":
        return "Top-up Insurance";
      case "super-topup":
        return "Super Top-up Insurance";
      case "ppc":
        return "Parental Policy";
    }
  },
  getCoverageType(coverageType) {
    switch (coverageType) {
      case "E":
        return "Employee Only";
      case "ES":
        return "Employee & Spouse";
      case "ESC":
        return "Employee, Spouse\n& Children";
      case "ESCP":
        return "Employee, Spouse,\nChildren & Parents";
      default:
        return "Data not found";
    }
  },
  numberOfChildrenCovered(familyDefinition) {
    if (familyDefinition.match(/^ES[\d]?C/)) {
      return parseInt(familyDefinition[2]) || 2;
    } else {
      return 0;
    }
  },
  // Returns the max count for children present in any of the passed familyDefinitions
  getMaxChildrenCount(familyDefinitions) {
    let count = 0;
    for (const def of familyDefinitions) {
      count = Math.max(count, this.numberOfChildrenCovered(def));
    }
    return count;
  },
  // Returns obj with covered dependents' details when a set of policies/ benefits is passed to it
  extractCoveredDependents(policies) {
    const depObj = {
      childrenCount: 0,
      coveredDependents: [],
      pOrILFlag: false,
    };
    // Generating an array of familyDefinitions from the passed set of policies/ benefits
    const familyDefinitions = policies.map((policy) => policy.meta.familyDefinition).filter((def) => def);
    if (!familyDefinitions.length) return depObj;
    // Checking if `S` is present in any of the familyDefinitions
    const isSpouseCovered = familyDefinitions.find((str) => str.includes("S"));
    if (isSpouseCovered) depObj.coveredDependents.push("spouse");
    // Fetching the count of children covered by the policies passed
    depObj.childrenCount = this.getMaxChildrenCount(familyDefinitions);
    if (depObj.childrenCount) depObj.coveredDependents.push("child");
    // Checking if `+` present in any of the familyDefinitions,
    // If present, it means both `parent` and `parent-in-law` relations are covered
    const pAndIL = familyDefinitions.find((str) => str.includes("+"));
    if (pAndIL) depObj.coveredDependents.push("parent", "parent-in-law");
    else {
      // Checking if `/` is present in any of the definitions when the previous condition wasn't satisfied
      // If present, it means either `parent` or `parent-in-law` is/ are covered
      // We set the `pOrILFlag` to true in such a case
      const pOrIL = familyDefinitions.find((str) => str.includes("/"));
      if (pOrIL) depObj.pOrILFlag = true;
      else {
        // Checking if just P is present in any of the definitions, when previous two conditions weren't satisfied
        // If present, it means just `parent` is/ are covered
        const onlyParentsCovered = familyDefinitions.find((str) => str.includes("P"));
        if (onlyParentsCovered) depObj.coveredDependents.push("parent");
      }
    }
    depObj.coveredDependents = [...new Set(depObj.coveredDependents)];
    return depObj;
  },
  // Returns text in the format `Rel1, Rel2, and Rel3` when an obj with covered dependents' details is passed to it
  getContent(depObj) {
    let content = "";
    if (depObj && depObj?.coveredDependents?.length) {
      // Capitalizing the first character for every string in the array
      let depArray = depObj.coveredDependents.map((rel) => rel.charAt(0).toUpperCase() + rel.substr(1));
      // If it is a case of `Parent` or `Parent-in-law` being covered
      // Pushing this string into the array
      if (depObj.pOrILFlag) depArray.push("Parents/ Parents-in-law");
      // If there exists a `childrenCount` i.e. children in the covered relation set
      // Appending the count value before the `Children` string in the array
      if (depObj.childrenCount) {
        depArray = depArray.map((rel) => (rel === "Child" ? `${depObj.childrenCount} ${rel}ren` : rel));
      }
      // Finally adding `,` and `, and` to right positions and where needed
      content =
        depArray.length > 1
          ? `${depArray.slice(0, depArray.length - 1).join(", ")}, and ${depArray.slice(-1)}.`
          : `${depArray[0]}.`;
    }
    return content;
  },
  getInReviewBenefitsFromUserChanges(userChanges, isPolicy) {
    if (!userChanges) {
      return [];
    }

    return userChanges
      .filter((userChange) => userChange.benefit?.isPolicy === isPolicy && this.isUserChangeAppropriate(userChange))
      .map((userChange) => ({
        node: userChange.benefit,
        meta: {
          ...(userChange.changedBenefitInfo?.meta || []),
          ...userChange.meta,
        },
      }));
  },
  getFamilyDefinition(familyDefinition) {
    if (familyDefinition) {
      const shortName = familyDefinition.replace(/\d+/g, "");
      switch (shortName) {
        case "E":
          return "Just You";
        case "ES":
          return "Your Spouse & You";
        case "ESC":
          return "Your Spouse, Children, & You";
        case "ESCP":
          return "Your Parents, Spouse,\nChildren & You";
        case "ESCP/IL":
          return "Parents or Parents-in-law, Spouse,\nChildren & You";
        case "ESCP+IL":
          return "Parents, Parents-in-law, Spouse,\nChildren & You";
        case "P":
          return "Your Parents only";
        case "P/IL":
          return "Your Parents or Parents-in-law only";
        default:
          return "Data not found";
      }
    }
    return "";
  },
  bundlePoliciesWithEdgeMeta(policies) {
    if (!policies.length) return policies;

    return policies.map(({ node, meta }) => {
      return {
        ...node,
        meta: {
          ...node.meta,
          ...meta,
        },
      };
    });
  },
  isUserChangeAppropriate(userChange) {
    const unwantedUserChangeStatus = ["rejected", "deleted", "archive"];
    return userChange.type === "add" && !unwantedUserChangeStatus.includes(userChange.status);
  },
  isUploadedUserDataCorrect(
    emails,
    employeesIds,
    user,
    exitDateInputFormats = ["DD-MM-YYYY", "YYYY-MM-DD", "DD-MMM-YYYY"]
  ) {
    const isUserDataCorrect = emails.includes(String(user.email)) || employeesIds.includes(String(user.employeeId));
    const isExitDateCorrect = user.doe && this.isDateValid(user.doe, exitDateInputFormats);
    return isUserDataCorrect && isExitDateCorrect;
  },
  isMidTermAddition(user, org) {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const areAllEligibleForEndorsements = moment(today).isSameOrBefore(org?.meta?.onboardingWindow);
    return Boolean(
      !(org?.meta?.onboardingWindow && areAllEligibleForEndorsements)
        ? (user?.benefits?.some((benefit) => benefit?.isPolicy) ||
            user?.benefits?.some((benefit) => benefit?.node?.isPolicy)) &&
            !(user?.meta?.source === UserAddSource.ONBOARD_CLI && user?.meta?.showOnboardingScreens)
        : false
    );
  },
  /* Showing dom field when -
  1. the Dep already has a dom present
  2. Relations is Spouse, and is a new dep addition, user has already linked benefits or has isMidTermAddition flag as true */
  showDOMField(user, dependent, org) {
    return (
      (dependent.relation === AcceptedRelations.SPOUSE &&
        ((!dependent.id && this.isMidTermAddition(user, org)) || dependent?.meta?.isMidTermAddition)) ||
      dependent?.meta?.dom
    );
  },
};
