import gql from "graphql-tag";
import {
  tpaFragment,
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation ($id: ID, $name: String, $photoUrl: String, $meta: JSONObject) {
      upsertTpa(
        input: { id: $id, name: $name, photoUrl: $photoUrl, meta: $meta }
      ) {
        tpa {
          ...Tpa
        }
      }
    }
    ${tpaFragment}
  `,
}
