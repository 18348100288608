<template lang="pug">
.wrapper
  .full-page.main-panel(
    :class="($route.path.includes('policy-calculator') \
      || $route.path.includes('policy-compare'))?'main-panel-light-gray':''")
    slot
      n-content(
        :class="($route.path.includes('policy-calculator') \
          && !$route.path.includes('pricing-table'))?'mb-0':''")
    n-home-page-footer(v-if="!$route.path.includes('policy-calculator')")
</template>

<script>
import NovaNavbar from "@/layout/NovaNavbar.vue";
import NHomePageFooter from "@/layout/HomePageFooter.vue";
import NContent from "@/layout/Content.vue";
import NovaButton from "@/components/NovaButton.vue";
export default {
  components: {
    NovaNavbar,
    NHomePageFooter,
    NContent,
    NovaButton,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.main-panel-light-gray {
  background: none;
  background-color: $website-bg;
  & > .footer {
    background-color: $website-bg;
  }
}
</style>
