<template lang="pug">
.banner.d-flex.px-4(:class="[`bg-${bannerBackground}`]")
  .banner-icon.d-flex.p-3.align-items-center.justify-content-center
    i(:class="[`icon-${icon}`, `text-${iconColor}`]")
  .banner-content.d-flex
    .banner-text.d-flex.flex-column.font-inter
      .info-title.font-weight-semibold.text-gray-800.pb-1 {{title || ""}}
      .info-text.font-weight-medium.text-gray-700 {{subtext || ""}}
    .coverage-status
      coverage-pills-group-wrapper(
        showDependentPopovers=false,
        :item="getPillsGroupItem()",
        triggers="hover",
        :statusText="getStatusChipData()?.text",
        :statusChipVariant="getStatusChipData()?.variant",
      )
</template>

<script>
import { PolicyTypes } from "@/common/enums";
import NChip from "@/components/NovaChip.vue";
import CoveragePillsGroupWrapper from "../../../endorsements/components/CoveragePillsGroupWrapper.vue";

export default {
  name: "ProfileInfoBanner",
  components: {
    NChip,
    CoveragePillsGroupWrapper,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    subtext: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: "refresh",
    },
    iconColor: {
      type: String,
      default: "mustard-600"
    },
    bannerBackground: {
      type: String,
      default: "mustard-100"
    },
    user: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getPillsGroupItem() {
      const arr = [];
      const changes = this.user.userChanges.reduce((arr, change) => {
        change.type === "update" && ["draft", "org-ok", "nova-ok"].includes(change.status) &&
        Object.values(PolicyTypes).includes(change.benefit.type) &&
        arr.push({
          ...change.benefit,
          benefitsType: change.benefit.type,
          status: change.status,
        });
        return arr;
      }, arr);
      return {
        user_type: "user",
        id: this.user.id,
        meta: changes,
        type: "update",
      };
    },
    getStatusChipData() {
      if (this.getPillsGroupItem().meta.some(
        (change) => change.status === "rejected"
      ))
        return {
          variant: "danger",
          text: "Rejected",
        };
      else if (
        this.getPillsGroupItem().meta.some((change) => ["draft", "org-ok", "nova-ok"].includes(change.status))
      )
        return {
          variant: "warning",
          text: "Approval Pending",
        };
      else if (!this.getPillsGroupItem().meta.length && !this.user.policies.length) {
        return {
          variant: "light",
          text: "No Policy Assigned",
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.banner {
  height: 5rem;
  align-items: center;
  @include media-breakpoint-down(sm) {
    align-items: start;
  }
}
.banner-icon {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: $white;
}
.banner-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 3px;
  padding: 0 3px 0 3px;
  width: 100%;
  .banner-text {
    width: 75%;
  }
  .info-title {
    font-size: 14px;
  }
  .info-text {
    font-size: small;
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: start;
    margin-left: 1px;
    .banner-text {
      width: 100%;
    }
    .info-title {
      font-size: small;
    }

    .info-text {
      font-size: x-small;
    }
  }
}
</style>
