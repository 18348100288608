import gql from "graphql-tag";
import {
  calendarEventFragment,
} from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertCalendarEvent(
      $id: ID
      $segmentId: ID
      $eventDate: String
      $name: String
      $schedule: [JSONObject]
      $meta: JSONObject
    ) {
      upsertCalendarEvent(
        input: {
          id: $id
          segmentId: $segmentId
          eventDate: $eventDate
          name: $name
          schedule: $schedule
          meta: $meta
        }
      ) {
        calendarEvent {
          ...CalendarEvent
        }
      }
    }
    ${calendarEventFragment}
  `,
}
