<template lang="pug">
div.py-7
  .d-flex.justify-content-center
    img(:src="require(`@/assets/images/magnifying-glass.svg`)")
  div
    .empty-states-msg1.font-weight-semibold.font-xl Nothing to see here!
</template>

<script>
export default {
  name: "Default",
};
</script>
