<template lang="pug">
  .bottom-navbar-container
    b-nav-item.btm-nav-item(
      to="/dashboard",
      v-if="isLoggedIn",
      :class="$route.path==='/dashboard'?'btm-nav-item-active':''")
      n-icon(name="home-1", :size="1.5")
      span.font-xs.pt-1 Home
    b-nav-item.btm-nav-item(
      to="/fitness",
      v-if="isLoggedIn && getFeatureFlags.FITNESS",
      :class="$route.path.startsWith('/fitness')?'btm-nav-item-active':''")
      n-icon(name="running", :size="1.5")
      span.font-xs.pt-1 Fitness
      #pill-new-mobile
    b-nav-item.btm-nav-item(
      to="/user/claims",
      v-if="isLoggedIn && getFeatureFlags.CLAIMS_PORTAL_EXPERIENCE",
      :class="$route.path.startsWith('/user/claim')?'btm-nav-item-active':''")
      n-icon(name="claims-1", :size="1.5")
      span.font-xs.pt-1 Claims
    b-nav-item.btm-nav-item(
      @click="showSalesIqChat",
      v-if="isLoggedIn",
      :class="$route.path==='/support'?'btm-nav-item-active':''")
      n-icon(name="support-1", :size="1.5")
      span.font-xs.pt-1 Support
    b-nav-item.btm-nav-item(
      to="/org-admin",
      v-if="isOrgAdmin",
      :class="$route.path.startsWith('/org-admin')?'btm-nav-item-active':''")
      n-icon(name="city", :size="1.5")
      span.font-xs.pt-1 Admin
    b-nav-item.btm-nav-item.d-none.d-sm-block(
      to="/admin", v-if="isAdmin",
      :class="$route.path.startsWith('/admin')?'btm-nav-item-active':''")
      n-icon(name="star", :size="1.5")
      span.font-xs.pt-1 Super Admin
    b-nav-item.btm-nav-item(
      to="/user/profile",
      v-if="isLoggedIn",
      :class="$route.path.startsWith('/user')?'btm-nav-item-active':''")
      n-avatar(
        :size="1.5",
        :name="user.name",
        :image="user.photoFile?user.photoFile.url:null",)
      span.font-xs.pt-1 Profile
</template>

<script>
import { mapState, mapGetters } from "vuex";
import NAvatar from "@/components/Avatar.vue";
export default {
  name: "BottomNavBar",
  components: {
    NAvatar,
  },
  computed: {
    ...mapGetters(["isLoggedIn", "isAdmin", "isOrgAdmin", "getFeatureFlags"]),
    ...mapState(["user"]),
  },
  methods: {
    showSalesIqChat() {
      this.$store.commit("toggleSupportContainer");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.bottom-navbar-container {
  width: 100%;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  min-height: 68px;
  background: $white;
  white-space: nowrap;
  z-index: 100;
}
.btm-nav-item {
  min-width: 50px;
  list-style-type: none;
  & > a {
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;

    text-decoration: none;
    color: $gray-600;
  }
}
.btm-nav-item-active {
  & > a {
    color: $gray-900;
  }
}
#pill-new-mobile {
  height: 2rem;
  width: 2.75rem;
  position: absolute;
  top: -1rem;
}
</style>
