<template>
  <component :is="type" v-bind="currentProps" v-on="$listeners"></component>
</template>

<script>
import * as components from "./index";

export default {
  components: {
    ...components,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    tableType: {
      type: String,
    },
    coveredDependents: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    currentProps() {
      return this.$props;
    },
  },
};
</script>
