<template lang="pug">
  .container
    b-card.shadow-none.my-3(
      :no-body="noBodyforClaims"
      header-bg-variant="transparent",
      bg-variant="transparent",
      body-bg-variant="white",
      body-class="shadow-sm px-0 pt-0 rounded")
      top-nav-tabs(v-if="tabs.length > 0", :tabs="tabs")
      transition(name="fade", mode="out-in")
        router-view.p-3(:key="$route.path")
</template>

<script>
import TopNavTabs from "../../../components/TopNavTabs.vue";
import { adminActiveTabTopNavData } from "./constants";
import { getAdminActiveTab } from "@/utils";

export default {
  components: {
    TopNavTabs,
  },
  computed: {
    noBodyforClaims() {
      return this.$route.name === "claim_create" || this.$route.name === "claim_edit";
    },
    activeTabName() {
      return getAdminActiveTab(this.$route.path).name;
    },
    tabs() {
      return adminActiveTabTopNavData[this.activeTabName] ? adminActiveTabTopNavData[this.activeTabName] : [];
    },
  },
};
</script>

<style>
.active > .home-nav-text {
  color: white !important;
}
</style>
