import moment from "moment";
import jwt_decode from "jwt-decode";

export default {
  getDate(date) {
    const givenDate = moment(date);
    return givenDate.format("DD");
  },
  getMonth(date) {
    const givenDate = moment(date);
    return givenDate.format("MMMM");
  },
  getYear(date) {
    const givenDate = moment(date);
    return givenDate.format("YYYY");
  },
  getMonthYear(date) {
    const givenDate = moment(date);
    return `${givenDate.format("MMMM")} ${givenDate.format("YYYY")}`;
  },
  getDateWithSuffix(date) {
    // Returns date in "20th October 2021" format
    const givenDate = moment(date);
    return givenDate.format("Do MMMM YYYY").replace(/(\d)(st|nd|rd|th)/g, "$1$2");
  },
  getLastDateOfMonth(dateOfInterest) {
    return new Date(dateOfInterest.getFullYear(), dateOfInterest.getMonth() + 1, 0);
  },
  getMonthShortForm(month) {
    const months = {
      january: "Jan",
      february: "Feb",
      march: "Mar",
      april: "Apr",
      may: "May",
      june: "Jun",
      july: "Jul",
      august: "Aug",
      september: "Sept",
      october: "Oct",
      november: "Nov",
      december: "Dec",
    };
    return months[month.toLowerCase()];
  },
  isExpired(date) {
    if (!date) return false;
    const today = moment(new Date()).format("YYYY-MM-DD");
    return moment(date).isBefore(today);
  },
  isFuture(date) {
    const today = moment();
    const selectedDate = moment(date);
    return selectedDate.isAfter(today);
  },
  getNormalizedDate(date) {
    if (!date) {
      return null;
    }
    if (moment(date, "DD/MM/YYYY").isValid()) {
      return moment(date, "DD/MM/YYYY");
    }
    if (moment(date).isValid()) {
      return moment(date);
    }
    return null;
  },
  getMonthFormattedDate(date) {
    if (!date) return null;
    const dateInFormat = moment(date, "YYYY-MM-DD", true);

    if (dateInFormat.isValid()) {
      return dateInFormat.format("DD-MMM-YYYY");
    } else return date;
  },
  isDateValid(date, formats = ["DD-MM-YYYY", "YYYY-MM-DD"]) {
    if (!date) return false;
    return moment(date, formats, true).isValid();
  },
  formatDate(date, inputFormats = ["DD-MM-YYYY", "YYYY-MM-DD"], outputFormat = "DD-MMM-YYYY") {
    return moment(date, inputFormats, true).format(outputFormat);
  },
  getFormattedDate(date) {
    if (!date) {
      return null;
    }
    return this.getNormalizedDate(moment(date, "YYYY-MM-DD"))?.format("Do MMMM,  YYYY");
  },
  getFormattedDateTime(date) {
    if (!date) {
      return null;
    }
    return moment(date, "YYYY-MM-DD hh:mm:ss A").format("Do MMM, YYYY hh:mm:ss A");
  },
  getDateDifference(date1, date2) {
    if (!date1) return false;
    const d1 = this.getNormalizedDate(date1);
    let d2;
    if (!date2) {
      d2 = moment();
    } else {
      d2 = this.getNormalizedDate(date2);
    }
    return Math.ceil(Math.abs(d2.diff(d1, "days", true)));
  },
  getFormattedDateDifferenceText(date) {
    const diff = this.getDateDifference(moment(date, "YYYY-MM-DD")) - 1;
    if (diff < 1) return "Today";
    const daysStr = diff >= 2 ? "days" : "day";
    return `${diff} ${daysStr} ago`;
  },
  getNextMonthDetails(date = 5) {
    // eslint-disable-next-line new-cap
    const today = new moment();
    const nextMonth = moment().add(1, "month").date(date);
    return {
      day: nextMonth.diff(today, "days"),
      month: nextMonth.format("MMMM"),
      year: nextMonth.format("YYYY"),
    };
  },
  convertLocalDateToUTC(localDate) {
    localDate = new Date(localDate).getTime();
    if (!localDate) return null;
    return moment(localDate).add(-moment().utcOffset(), "m").toISOString();
  },
};

export function validateDateRange(range, message) {
  message = !message || typeof message !== "string" ? ["Start date must be same or before the end date!"] : [message];
  if (!range?.startDate || !range?.endDate || moment(range.startDate).isSameOrBefore(moment(range.endDate))) return [];
  return message;
}

export function getFormattedTimeDifferenceFromNow(date, time) {
  if (!time) return "";
  const expiryDate = date ? moment(date) : moment();
  const expiryTime = time.split(":");

  expiryDate.set({
    hour: expiryTime[0],
    minute: expiryTime[1],
  });

  const diffInMinutes = expiryDate.diff(moment(), "minutes");
  if (diffInMinutes < 0) return "0";
  let timeString = (diffInMinutes % 60) + "mins";

  if (diffInMinutes >= 60 || diffInMinutes <= -60) {
    const diffHours = Math.floor(diffInMinutes / 60);
    timeString = (diffHours % 24) + "hrs";

    if (diffHours >= 24 || diffHours <= -24) {
      const diffDays = Math.floor(diffHours / 24);
      timeString = diffDays + "D " + timeString;
    }
  }
  return timeString;
}

export function getJWTExpiry(token) {
  const { expiry } = jwt_decode(token);
  return new Date(expiry);
}
