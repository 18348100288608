import { ClaimStage } from "../claimStage";
import { ProcessFlow } from "../processFlow";

export const processFlowToStages = new Map();

processFlowToStages.set(ProcessFlow.HARD_COPY_BEFORE_APPROVAL, [
  ClaimStage.CLAIM_INTIMATION_BY_EMPLOYEE,
  ClaimStage.NO_DOCUMENT_RECEIVED_REMINDER_1,
  ClaimStage.NO_DOCUMENT_RECEIVED_REMINDER_2,
  ClaimStage.PARTIAL_DOCUMENTS_RECEIVED_REMINDER_1,
  ClaimStage.PARTIAL_DOCUMENTS_RECEIVED_REMINDER_2,
  ClaimStage.DORMANT,
  ClaimStage.COMPLETE_DOCUMENTS_RECEIVED_AFTER_DEFICIENCY_CHECK_COURIER_PENDING,
  ClaimStage.DOCS_HARD_COPY_COURIERED_BY_EMPLOYEE,
  ClaimStage.DOCS_HARD_COPY_RECEIVED_BY_TPA,
  ClaimStage.CLAIM_REGISTERED,
  ClaimStage.DOCS_UNDER_VERIFICATION,
  ClaimStage.CLAIM_UNDER_QUERY,
  ClaimStage.QUERY_RESPONDED_BY_EMPLOYEE,
  ClaimStage.QUERY_DOCS_UNDER_VERIFICATION,
  ClaimStage.CLAIM_UNDER_QUERY_2,
  ClaimStage.QUERY_DOCS_UNDER_VERIFICATION_2,
  ClaimStage.CLAIM_UNDER_INVESTIGATION,
  ClaimStage.CLAIM_UNDER_QUERY_REMINDER_1,
  ClaimStage.CLAIM_UNDER_QUERY_REMINDER_2,
  ClaimStage.DORMANT_POST_REGISTRATION,
  ClaimStage.CLAIM_APPROVED_BY_TPA,
  ClaimStage.DOCS_HARD_COPY_VERIFICATION,
  ClaimStage.PAYMENT_UNDER_PROCESS,
  ClaimStage.CLAIM_SETTLED,
  ClaimStage.CLAIM_REOPENED,
  ClaimStage.CLAIM_REPUDIATED_BUT_WAITING_CONCURRENCE,
  ClaimStage.CLAIM_REPUDIATED,
  ClaimStage.INVALID,
]);

processFlowToStages.set(ProcessFlow.HARD_COPY_AFTER_APPROVAL, [
  ClaimStage.CLAIM_INTIMATION_BY_EMPLOYEE,
  ClaimStage.NO_DOCUMENT_RECEIVED_REMINDER_1,
  ClaimStage.NO_DOCUMENT_RECEIVED_REMINDER_2,
  ClaimStage.PARTIAL_DOCUMENTS_RECEIVED_REMINDER_1,
  ClaimStage.PARTIAL_DOCUMENTS_RECEIVED_REMINDER_2,
  ClaimStage.DORMANT,
  ClaimStage.DOCS_SOFT_COPY_UPLOADED,
  ClaimStage.CLAIM_REGISTERED,
  ClaimStage.DOCS_UNDER_VERIFICATION,
  ClaimStage.CLAIM_UNDER_QUERY,
  ClaimStage.QUERY_RESPONDED_BY_EMPLOYEE,
  ClaimStage.QUERY_DOCS_UNDER_VERIFICATION,
  ClaimStage.CLAIM_UNDER_QUERY_2,
  ClaimStage.QUERY_DOCS_UNDER_VERIFICATION_2,
  ClaimStage.CLAIM_UNDER_INVESTIGATION,
  ClaimStage.CLAIM_UNDER_QUERY_REMINDER_1,
  ClaimStage.CLAIM_UNDER_QUERY_REMINDER_2,
  ClaimStage.DORMANT_POST_REGISTRATION,
  ClaimStage.CLAIM_APPROVED_BY_TPA,
  ClaimStage.DOCS_HARD_COPY_COURIERED_BY_EMPLOYEE_POSTAPPROVAL,
  ClaimStage.DOCS_HARD_COPY_RECEIVED_BY_TPA_POSTAPPROVAL,
  ClaimStage.DOCS_HARD_COPY_VERIFICATION,
  ClaimStage.PAYMENT_UNDER_PROCESS,
  ClaimStage.CLAIM_SETTLED,
  ClaimStage.CLAIM_REOPENED,
  ClaimStage.CLAIM_REPUDIATED_BUT_WAITING_CONCURRENCE,
  ClaimStage.CLAIM_REPUDIATED,
  ClaimStage.INVALID,
]);

processFlowToStages.set(ProcessFlow.NO_HARD_COPY, [
  ClaimStage.CLAIM_INTIMATION_BY_EMPLOYEE,
  ClaimStage.NO_DOCUMENT_RECEIVED_REMINDER_1,
  ClaimStage.NO_DOCUMENT_RECEIVED_REMINDER_2,
  ClaimStage.PARTIAL_DOCUMENTS_RECEIVED_REMINDER_1,
  ClaimStage.PARTIAL_DOCUMENTS_RECEIVED_REMINDER_2,
  ClaimStage.DORMANT,
  ClaimStage.DOCS_SOFT_COPY_UPLOADED,
  ClaimStage.CLAIM_REGISTERED,
  ClaimStage.DOCS_UNDER_VERIFICATION,
  ClaimStage.CLAIM_UNDER_QUERY,
  ClaimStage.QUERY_RESPONDED_BY_EMPLOYEE,
  ClaimStage.QUERY_DOCS_UNDER_VERIFICATION,
  ClaimStage.CLAIM_UNDER_QUERY_2,
  ClaimStage.QUERY_DOCS_UNDER_VERIFICATION_2,
  ClaimStage.CLAIM_UNDER_INVESTIGATION,
  ClaimStage.CLAIM_UNDER_QUERY_REMINDER_1,
  ClaimStage.CLAIM_UNDER_QUERY_REMINDER_2,
  ClaimStage.DORMANT_POST_REGISTRATION,
  ClaimStage.CLAIM_APPROVED_BY_TPA,
  ClaimStage.PAYMENT_UNDER_PROCESS,
  ClaimStage.CLAIM_SETTLED,
  ClaimStage.CLAIM_REOPENED,
  ClaimStage.CLAIM_REPUDIATED_BUT_WAITING_CONCURRENCE,
  ClaimStage.CLAIM_REPUDIATED,
  ClaimStage.INVALID,
]);

processFlowToStages.set(ProcessFlow.NO_FLOW, [
  ClaimStage.UPCOMING,
  ClaimStage.INTIMATION,
  ClaimStage.PREAUTH_RECEIVED,
  ClaimStage.INFORMATION_AWAITED,
  ClaimStage.PREAUTH_APPROVED,
  ClaimStage.INTERIUM_APPROVAL_IN_PROGRESS,
  ClaimStage.INTERIUM_APPROVED,
  ClaimStage.APPROVED,
  ClaimStage.CANCELLED,
  ClaimStage.QUERY_TO_THE_HOSPITAL,
  ClaimStage.QUERY_RECEIVED_FROM_HOSPITAL,
  ClaimStage.DENIED,
]);
