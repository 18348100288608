<template lang="pug">
b-card
  span.text-gray-700.font-hc.font-weight-semibold Claim Journey
  .mt-3
  .claim-status-line(v-for="(claimStatus, index) in claimStatusLine")
    .pl-2.d-block.position-relative.ml-3(:class="getBorderClass(index)")
        n-icon.status-line-icon.bg-white.position-absolute(
          :name="getJourneyIcon(index).name",
          :variant="getJourneyIcon(index).variant",
          :pathCount="2",
          :size="2"
        )
        span.d-inline-block.font-weight-semibold.mt-2.pl-4 {{claimStatus}}
        .pb-4(v-if="index !== 3 || index === currStatusLineNo")

    .ongoing-claim-card.shadow-2.rounded.px-0.overflow-hidden.p-0(v-if="index === currStatusLineNo")
      .d-flex.align-items-center.p-3(:class="claim.displayContent.header_bg")
        b-avatar(:class="claim.displayContent.icon_bg", variant="none", size="3rem")
          i(:class="`icon-${claim.displayContent.icon} ${claim.displayContent.headingColor}`")
        .d-flex.flex-column.ml-3
          span.font-sm.font-weight-semibold(style="opacity:0.5", :class="claim.displayContent.headingColor") CLAIM STATUS
          span.font-weight-semibold(:class="claim.displayContent.headingColor") {{ claim.displayContent.heading }}
      .bg-white.p-3
        span.font-sm.font-weight-medium.text-gray-500.text-uppercase About
        p.text-gray-900.font-weight-medium.font-sm.main-content.mt-1 {{ claim.displayContent.content }}
        .down-content.mt-4
          n-button.px-4.w-100( variant="dark", buttonText="Need help?", @click="showSalesIqChat")

    .d-block.position-relative.ml-3.border-left-dashed(v-if="!isLastMainGroup(index)", :class="(index < currStatusLineNo)?'border-left-teal':'border-left-dashed'" )
      .pb-3
</template>

<script>
import { capitalize } from "lodash-es";
import { claimStatusLine, getCurrStatusLineNo } from "../../definitions";
import { ClaimStatus } from "../../../../../common/enums/claims/claimStatus";
import { ClaimType } from "../../../../../common/enums/claims/claimType";
import NButton from "@/components/NovaButton";

export default {
  components: {
    NButton,
  },
  props: {
    claim: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    currStatusLineNo() {
      return getCurrStatusLineNo(this.claim.meta.crm?.claimStage, this.claim.type);
    },
    claimStatusLine() {
      return claimStatusLine(this.claim.type);
    },
  },
  methods: {
    showSalesIqChat() {
      this.$store.commit("toggleSupportContainer");
    },
    getJourneyIcon(index) {
      const lastStages = [ClaimStatus.REJECTED, ClaimStatus.INVALID];
      let icon = {};
      if (index < this.currStatusLineNo) {
        icon = { name: "check-circle", variant: "teal-700" };
      } else if (index > this.currStatusLineNo) {
        icon = { name: "disabled-stage", variant: "gray-600" };
      } else if (index === this.currStatusLineNo && lastStages.includes(capitalize(this.claim.status))) {
        icon = { name: "check-circle", variant: "teal-700" };
      } else if (index === this.currStatusLineNo) {
        icon = { name: "active-stage", variant: "teal-600" };
      } else {
        icon = { name: "disabled-stage", variant: "gray-600" };
      }
      return icon;
    },
    getBorderClass(index) {
      if (index === 2 && this.claim.type === ClaimType.CASHLESS) return "";
      if (index < this.currStatusLineNo || (index === this.currStatusLineNo && this.claim.status !== "REJECTED")) {
        return "border-left-teal";
      } else {
        return "border-left-dashed";
      }
    },
    isLastMainGroup(index) {
      if (!this.claim.type) return true;
      if (index === 2 && this.claim.type === ClaimType.CASHLESS) return true;
      if (index === 3 && this.claim.type === ClaimType.REIMBURSEMENT) return true;
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.border-left-dashed {
  border-left: 0.3px dashed $gray-500;
}

.border-left-teal {
  border-left: 0.3px solid $teal-400;
}

.ongoing-claim-card {
  border: 1px solid $gray-300;
  box-sizing: border-box;
}

.status-line-icon {
  top: 0;
  left: 0;
  transform: translateX(-50%);
}

.main-content {
  white-space: pre-line;
}
</style>
