import { getListQuery, getSingleQuery } from "../queries";
import filesSchema from "../schemas/files";
import filesMutations from "../mutations/files";
export default {
  gqlType: "File",
  fields: ["name", "uploadingUserOrgName", "uploadingUser", "action", "createdAt"],
  singleQuery: getSingleQuery("File"),
  listQuery: getListQuery("File"),
  ...filesSchema,
  ...filesMutations,
  transform: (n) => {
    return {
      ...n,
      uploadingUserOrgName: n.uploadingUser?.org?.name,
      uploadingUser: n.uploadingUser?.name,
      fileGroupIds: n.fileGroups.map((f) => f.node.id),
      isDocInternal: n.fileGroups?.[0]?.meta?.isDocInternal,
      fileGroupNames: n.fileGroups.map(({ node }) => node.name).sort(),
    };
  },
  getTotalCountFromData: (data) => data.files.totalCount,
  getEdgesFromData: (data) => data.files.edges,
};
