import gql from "graphql-tag";

export const userSegmentFragment = gql`
  fragment UserSegment on UserSegment {
    id
    meta
    segment {
      id
      name
    }
    user {
      id
      name
      org {
        id
        name
      }
    }
    updatedAt
  }
`;
