import { render, staticRenderFns } from "./Base.vue?vue&type=template&id=fafe8bd2&scoped=true&lang=pug&"
import script from "./Base.vue?vue&type=script&lang=js&"
export * from "./Base.vue?vue&type=script&lang=js&"
import style0 from "./Base.vue?vue&type=style&index=0&id=fafe8bd2&prod&scoped=true&lang=css&"
import style1 from "./Base.vue?vue&type=style&index=1&id=fafe8bd2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fafe8bd2",
  null
  
)

export default component.exports