export const Base = require("./Base.vue").default;

export const routes = [
  { path: "", redirect: "about" },
  { path: "about", component: Base },
  { path: "custom-onboarding", component: Base },
  { path: "dependents", component: Base },
  { path: "contact", component: Base },
  { path: "policies", component: Base },
];
