<template lang="pug">
.bg-light-blue
  .container.py-4
    .row.align-items-center
      .col-md
        h5.text-light.my-1 {{title}}
      .col-md-5
        .input-group.my-2
          input#email.form-control(
            type="email",
            name="email",
            required="true",
            placeholder="Your Email",
            v-model="content")
          .input-group-append.d-none.d-sm-block
            button.btn.btn-warning.px-3(@click="$emit('formSubmit', {id, content})") {{ cta }}
        button.btn.btn-warning.px-4.d-inline-block.d-sm-none(@click="$emit('formSubmit', {id, content})") {{ cta }}
</template>

<script>
import NovaButton from "@/components/NovaButton.vue";
export default {
  components: {
    NovaButton,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    cta: {
      type: String,
      default: null,
    },
    redirect: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      content: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.bg-light-blue {
  background-color: $blue-400;
}
</style>

<style scoped>
/* temp font sizes override */
h2,
h4,
h5 {
  font-weight: 700;
}
h2 {
  font-size: 2.5rem !important;
}
h4 {
  font-size: 2rem !important;
}
h5 {
  font-size: 1.5rem !important;
}
</style>
