import gql from "graphql-tag";

export default {
  upsertMutation: gql`
    mutation CliToolsMutation(
      $file: Upload
      $cmdName: String!
      $args: JSONObject
    ) {
      invokeCliTool(input: { file: $file, cmdName: $cmdName, args: $args }) {
        job {
          id
          status
          logs
          result
        }
      }
    }
  `,
}
