<template lang="pug">
n-modal(
  dialog-class="add-emp-modal-dialog",
  :id="id",
  :showCross="true",
  centered,
  hide-header,
  hide-footer,
  body-class="add-emp-modal",
  modalOk=""
)
  .d-flex.justify-content-center.align-items-center.flex-column.px-2.py-4
    .h4.text-gray-900.font-xl.mb-4.modal-head How would you like to add the new employees?
    .emp-add-link.p-3(
      v-for="empLink of empAddLinks",
      v-if="filterLinks(empLink)"
    )
      div(@click="logEmployeeAddition(empLink.source)")
        b-link.d-flex.align-items-center.gap-1.text-decoration-none(
          :to="empLink.linkUrl",
          :disabled="bulkUploadJob.jobId && empLink.source === 'bulk_upload' ? true : false",
        )
          .left.d-flex.flex-column
            .link-head.d-flex
              img(
                :src="require(`@/assets/images/${empLink.linkIcon}`)",
                alt="nova main logo"
              )
              .d-flex.align-items-center.text-gray-900.font-weight-semibold.font-md.ml-2 {{ empLink.linkTitle }}
            .link-desc.mt-1
              .text-gray-800.font-sm.mt-1.font-weight-medium {{ empLink.linkDesc }}
          i.icon-chevron-right.text-gray-600
</template>

<script>
import { mapGetters, mapState } from "vuex";
import NIcon from "@/components/NovaIcon.vue";
import NModal from "@/components/NovaModal.vue";

export default {
  name: "EmployeeAdditionModal",
  components: {
    NIcon,
    NModal,
  },
  computed: {
    ...mapGetters(["getFeatureFlags", "user"]),
    ...mapState(["bulkUploadJob"]),
  },
  data() {
    return {
      id: "add-employee-modal",
      empAddLinks: [
        {
          linkIcon: "substract.svg",
          linkTitle: "Invite Employee to Add Details",
          linkDesc: "Select this if you want the employee to provide the required details",
          linkUrl: "/org-admin/members/invite",
          source: "invite_employee_to_fill_data",
        },
        {
          linkIcon: "cloud-upload.svg",
          linkTitle: "Upload Excel",
          linkDesc: "Select this if you have all the data of the new employees in an Excel sheet.",
          linkUrl: "/org-admin/members/onboard-users",
          source: "bulk_upload",
        },
        {
          linkIcon: "keyboard.svg",
          linkTitle: "Add single employee",
          linkDesc: "You’ll have to enter the required details of the new employees yourself.",
          linkUrl: "/org-admin/members/create",
          source: "provide_data_and_invite",
        },
      ],
    };
  },
  methods: {
    logEmployeeAddition(source) {
      window.posthog.capture("add_employee_click", {
        source: source,
        email: this.user?.email,
        day_of_month: new Date().getDate(),
        org_name: this.user?.org?.name,
      });
    },
    filterLinks(link) {
      // Not showing bulk upload card link when bulk upload feature is not enabled
      return !(!this.getFeatureFlags.BULK_UPLOAD_USERS && link.linkUrl === "/org-admin/members/onboard-users");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.modal-head {
  max-width: 60%;
  text-align: center;
}
.emp-add-link {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  box-shadow: $box-shadow-xs;
  border: 2px solid $gray-400;
  border-radius: $border-radius;

  img {
    max-width: 2rem;
  }

  i {
    font-size: 1.5rem;
  }
}
</style>
