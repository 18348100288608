<template lang="pug">
  .container(v-if="cardsData.length > 0")
    .row.mx-2
      .col-12.col-xl-6.addon-card-main-container(v-for="card in filteredArray")
        AddOn(
          :id="card.id",
          :icon="card.icon",
          :title="card.title",
          :description="card.description",
          :cost="card.cost",
          @removeAddOn="showDeletionConfirmModal(card)")
    n-addon-deletion-confirm-modal(
      :icon="cardToBeDeleted.icon",
      :title="cardToBeDeleted.title",
      :description="cardToBeDeleted.description",
      :cost="cardToBeDeleted.cost",
      @confirmRemoval="deleteAddon")
</template>

<script>
import { filter, includes } from "lodash-es";
import AddOn from "./AddOn.vue";
import NAddonDeletionConfirmModal from "./AddonDeletionConfirmModal.vue";
export default {
  name: "AddOnCard",
  components: {
    AddOn,
    NAddonDeletionConfirmModal,
  },
  props: {
    cardsData: {
      type: Array,
      default: () => [],
    },
    selectedAddons: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filteredArray: [],
      cardToBeDeleted: {},
    };
  },
  mounted() {
    this.filteredArray = filter(this.cardsData, (addon) =>
      includes(this.selectedAddons, addon.title)
    );
  },
  methods: {
    showDeletionConfirmModal(card) {
      this.cardToBeDeleted = card;
      this.$bvModal.show("addon-deletion-confirmation-modal");
    },
    deleteAddon(addonTitle) {
      if (this.selectedAddons.includes(addonTitle)) {
        const index = this.selectedAddons.indexOf(addonTitle);
        if (index > -1) {
          this.selectedAddons.splice(index, 1);
        }
      }
      this.submitSelection();
    },
    submitSelection() {
      this.filteredArray = filter(this.cardsData, (addon) =>
        includes(this.selectedAddons, addon.title)
      );
      this.$emit("submitAddOns", this.filteredArray);
    },
  },
};
</script>

<style lang="scss" scoped>
.addon-card-main-container {
  display: flex;
  padding: 0.5rem;
}
</style>
