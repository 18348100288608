<template lang="pug">
b-card.p-3(no-body)
  .row
    .col-md-3.col-sm-12.border-right.p-0.pl-3.d-none.d-md-block.pt-3
      h5.font-hc.text-gray-600 Edit Details
      profile-nav
    .col-md-9.col-sm-12.pt-3
      add-dependents-wrapper(
        :dependentsModel="selfDependents",
        :user="restUser", 
        :policies="orgPolicies",
        :isMidTermAddition="isMidTermAddition",
        @dependentsUpdated="saveDependents")
      success-modal(
        :relation="getTargetDepRelation",
        :policies="getRelevantPolicies()",
        :isNewAddition="checkIfNewAddition",
        :isMidTermAddition="isMidTermAddition")
</template>

<script>
import gql from "graphql-tag";
import resDefs from "../definitions";
import { orgBenefitFragment } from "../../admin/fragments";
import { BenefitTypes, DependentAddSource } from "../../../../common/enums";
import ProfileNav from "./components/ProfileNav.vue";
import SuccessModal from "./components/SuccessModal.vue";
import utils from "@/utils";
import NButton from "@/components/NovaButton.vue";
import NAvatar from "@/components/Avatar.vue";
import AddDependentsWrapper from "@/components/AddDependents/AddDependentsWrapper.vue";
const ACCEPTED_FIELDS = ["name", "dob", "relation", "gender"];
const ACCEPTED_META_FIELDS = ["dom"];
export default {
  components: {
    NButton,
    NAvatar,
    AddDependentsWrapper,
    ProfileNav,
    SuccessModal,
  },
  data() {
    return {
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: true,
      },
      restUser: {},
      policies: [],
      targetDepRelation: null,
      isNewAddition: false,
    };
  },
  computed: {
    selfDependents() {
      return utils.deepClone(this.restUser?.dependents || []);
    },
    orgPolicies() {
      if (this.restUser) return this.benefitsRelatedToUser;
      return [];
    },
    getTargetDepRelation() {
      return this.targetDepRelation;
    },
    checkIfNewAddition() {
      return this.isNewAddition;
    },
    getPolicySet() {
      const benefits = this.benefitsRelatedToUser || [];
      return benefits.map((benefit) => {
        return {
          type: benefit.type,
          coveredDependentsWrapper: utils.extractCoveredDependents([benefit]),
        };
      });
    },
    isMidTermAddition() {
      return utils.isMidTermAddition({ benefits: this.policies }, this.restUser.org);
    },
  },
  created() {
    this.$apollo.addSmartQuery("profileModel", {
      query: resDefs.users.singleQuery,
      update(data) {
        const user = { ...data.node };
        if (data.node.org) {
          user.orgId = data.node.org.id;
        }
        const { policies, __typename, benefits, ...restUser } = user;
        this.policies = utils.bundlePoliciesWithEdgeMeta(benefits);
        this.restUser = restUser;
        return this.restUser;
      },
    });
  },
  mounted() {
    this.$store.commit("updateSectionHeader", "Your Profile");
    this.$store.commit("updateNavHeader", {
      name: "Basic Details",
      back: true,
    });
  },
  methods: {
    getRelevantPolicies() {
      return this.getPolicySet.filter((benefit) => {
        return benefit.coveredDependentsWrapper.coveredDependents.includes(this.getTargetDepRelation);
      });
    },
    async saveDependents(dependents, targetDepRelation, isNewAddition) {
      this.targetDepRelation = targetDepRelation;
      this.isNewAddition = isNewAddition;
      await Promise.all(
        dependents.map((dependent) => {
          this.upsertDependent(this.restUser, dependent);
        })
      ).then(async (resolveStatuses) => {
        if (!resolveStatuses.includes(false)) {
          this.$store.commit("addAlert", {
            variant: "success",
            message: "Successfully requested update for your dependent(s) information",
          });
        }
        await this.mapUserAndDependentsToBenefits(this.restUser);
      });
      // TODO(NV-894): trigger success modal from here
      this.$bvModal.show("success-modal");
    },
    // TODO: Merge this function with that for orgAdmin and move to a common file/ place
    async upsertDependent(user, dependent) {
      try {
        if (dependent.id) {
          const changedDependentInfo = {};
          const changedBenefitInfo = {};
          changedBenefitInfo.overrides = {};
          changedBenefitInfo.benefits = dependent.benefits.map((item) => item.node.id);
          const oldDependentIdx = user.dependents.findIndex((p) => p.id === dependent.id);
          for (const key in dependent) {
            if (key === "meta") {
              for (const subKey in dependent[key]) {
                if (
                  ACCEPTED_META_FIELDS.includes(subKey) &&
                  dependent.meta[subKey] !== user.dependents[oldDependentIdx].meta[subKey]
                ) {
                  changedDependentInfo.meta = {
                    [subKey]: dependent.meta[subKey],
                  };
                  changedBenefitInfo.overrides.meta = {
                    [subKey]: user.dependents[oldDependentIdx].meta[subKey],
                  };
                }
              }
            } else if (ACCEPTED_FIELDS.includes(key) && dependent[key] !== user.dependents[oldDependentIdx][key]) {
              changedDependentInfo[key] = dependent[key];
              changedBenefitInfo.overrides[key] = user.dependents[oldDependentIdx][key];
            }
          }
          await this.$apollo.mutate({
            mutation: resDefs.userChanges.updateUserOrDependentInfo,
            variables: {
              orgId: user.orgId,
              userId: user.id,
              dependentId: dependent.id,
              changedDependentInfo,
              changedBenefitInfo,
              type: "update",
              status: "draft",
            },
            update: (store, { data }) => {
              // TODO: Fix in v3, when refactoring updateUserOrDependentInfo.
              this.updateUserChangesInStore(user.id, store, data);
            },
          });
        } else {
          const dependentClone = utils.deepClone(dependent);
          if (dependentClone.benefits) {
            dependent.benefits = utils.extractDependentBenefitEdge(dependent.benefits);
          }
          dependentClone.meta = {
            ...dependentClone.meta,
            source: dependentClone?.meta?.source || DependentAddSource.USER_PROFILE,
          };
          // FIXME: Remove nesting, clean up code
          if (utils.isMidTermAddition({ benefits: this.policies }, this.restUser.org)) {
            dependentClone.meta = {
              ...dependentClone.meta,
              isMidTermAddition: true,
            };
          }
          await this.$apollo.mutate({
            mutation: resDefs.users.addDependentMutation,
            variables: {
              userId: user.id,
              ...dependentClone,
            },
            update: (store, { data }) => {
              const oldUser = store.readFragment({
                id: user.id,
                fragment: resDefs.users.dependentsFragment,
                fragmentName: resDefs.users.dependentsFragmentName,
              });
              const dependent = resDefs.users.transformAddDependent(data);
              const oldDependent = oldUser.dependents.find((p) => p.id === dependent.id);
              if (oldDependent) oldDependent.meta = dependent.meta;
              else oldUser.dependents.push(dependent);
              store.writeFragment({
                id: user.id,
                fragment: resDefs.users.dependentsFragment,
                data: oldUser,
                fragmentName: resDefs.users.dependentsFragmentName,
              });
            },
          });
        }
        return true;
      } catch (err) {
        // Apollo errors are handled globally
        console.log(err);
      }
      return false;
    },
    // TODO: Merge this function with that for orgAdmin and move to a common file/ place
    updateUserChangesInStore(userId, store, data) {
      const oldUser = store.readFragment({
        id: userId,
        fragment: resDefs.userChanges.userChangesFragment,
        fragmentName: resDefs.userChanges.userChangesFragmentName,
      });
      const oldUserChanges = new Object();
      const userChanges = resDefs.userChanges.transformUpdateUserOrDependentInfo(data);
      const userChangeIds = userChanges.map((userChange) => userChange.id);
      oldUser.userChanges.forEach((userChange) => {
        if (userChangeIds.includes(userChange.id)) {
          oldUserChanges[userChange.id] = userChange;
        }
      });
      userChanges.forEach((userChange) => {
        const oldUserChange = oldUserChanges[userChange.id];
        if (oldUserChange) {
          oldUserChange.changedBenefitInfo = userChange.changedBenefitInfo;
          oldUserChange.changedUserInfo = userChange.changedUserInfo;
        } else oldUser.userChanges.push(userChange);
      });
      store.writeFragment({
        id: userId,
        fragment: resDefs.userChanges.userChangesFragment,
        data: oldUser,
        fragmentName: resDefs.userChanges.userChangesFragmentName,
      });
    },
    async mapUserAndDependentsToBenefits(user) {
      try {
        await this.$apollo.mutate({
          mutation: resDefs.users.mapUserAndDependentsToBenefits,
          variables: {
            userId: user.id,
          },
        });
        this.$store.commit("addAlert", {
          variant: "success",
          message: "Successfully requested sync to the policies/ benefits",
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
  apollo: {
    benefitsRelatedToUser: {
      skip() {
        return !this.restUser.id;
      },
      fetchPolicy: "no-cache",
      query: gql`
        query getBenefitsRelatedToUser($userId: ID!) {
          getBenefitsRelatedToUser(userId: $userId) {
            ...OrgBenefitEdge
          }
        }
        ${orgBenefitFragment}
      `,
      variables() {
        return { userId: this.restUser.id };
      },
      update(data) {
        return (
          data.getBenefitsRelatedToUser
            .map((orgBenefitEdge) => resDefs.benefits.transform(orgBenefitEdge.node))
            .filter((benefit) => benefit.isPolicy === true && benefit.type !== BenefitTypes.PPC) || []
        );
      },
    },
  },
};
</script>
