<template lang="pug">
  b-modal(:id="id", centered, hide-header, hide-footer, modal-class="nova-info-modal")
    .modal-bg
      img(:src="require(`@/assets/images/modal-bg.svg`)")
    .modal-check
      .bg-success
        n-icon(name="check-circle", variant="white", :size="1.75")
    .modal-info.px-4
      .text-center.text-dark.p-4.font-weight-medium
        span Your preferences have been recorded
      .hb3.text-center.p-4
        span Hold on and sit tight
      .text-center.text-dark.p-4.font-weight-medium
        span
          | We’ve recieved your requirements and someone from our team
          | will reach out to collect further details and answer any questions that you may have.
      .text-center.text-dark.p-4.font-weight-medium(v-if="existingUser")
        span Are you a nova member trying to log into the benefits portal?
          b-link(href=process.env.VUE_APP_BASE_PORTAL_URL+"/login", target="_blank")  Please click here!
      .text-center.p-2
        n-button.col-md-8(variant="light", buttonText="Go Back", @click="$bvModal.hide(id)")
      .text-center.p-2
        n-button.col-md-8(variant="dark", buttonText="Close", @click="goToPortalLogin()")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
export default {
  components: {
    NButton,
  },
  props: {
    id: {
      type: String,
      default: "info-modal",
    },
    variant: {
      type: String,
      default: "success",
    },
    existingUser: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goToPortalLogin() {
      window.open(`${process.env.VUE_APP_BASE_PORTAL_URL}`, "_self");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.nova-info-modal {
  .modal-body {
    padding: 0 !important;
  }
  .modal-bg {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    height: 105px;
    min-width: calc(100% + 1px) !important;
    background-color: $tiber;
    position: relative;
    top: -1px;
    & > img {
      width: 100%;
      height: 100%;
    }
  }
  .modal-content {
    border: 2px solid $tiber;
    border-top-left-radius: 0.35rem !important;
    border-top-right-radius: 0.35rem !important;
  }
  .modal-check {
    text-align: center;
    & > div {
      position: relative;
      top: -1.5rem;
      height: 3rem;
      width: 3rem;
      margin: auto;
      border-radius: 500px;
      & > i {
        position: relative;
        top: 0.6rem;
      }
    }
  }
  .modal-info {
    position: relative;
    top: -30px;
  }
}
.nova-info-modal + .modal-backdrop {
  background-color: white;
  opacity: 1 !important;
}
</style>
