<template lang="pug">
b-link(:class="[ \
    variant, \
    'text-decoration-none', \
    'rounded', \
    'm-1', \
    'p-2', \
    'd-flex', \
    'flex-row', \
    'justify-content-between', \
    'align-items-center', \
    'w-100', \
    'cursor-pointer' \
  ]",
  :to="to")
  .d-flex.flex-column.px-2.pt-3
    .d-flex
      i.mr-1(:class="`icon-${icon}`")
      .text-gray-900.font-weight-semibold
        slot(name="header")
    p.mt-2.font-weight-medium.font-sm.text-gray-800
      slot(name="body")
  .d-flex
    i.icon-chevron-right.text-gray-900
</template>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.danger {
  border: 2px solid $red-300;
  background-color: $red-100;
  color: $red-500;
}
.success {
  border: 2px solid $malibu-300;
  background-color: $malibu-100;
  color: $malibu-500;
}
.secondary2 {
  border: 2px solid $purple-300;
  background-color: $purple-100;
  color: $purple-500;
}
</style>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "",
    },
    to: {
      type: [String, Object],
      default: "",
    },
  },
};
</script>
