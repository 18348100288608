<template lang="pug">
.addon-card-container
  .addon-card.addon-card-box.pt-3
    .row.no-gutters
      .col-12
        div
          .d-flex.align-items-center.col.p-0.m-0
            i.icon-style(:class="[`icon-${icon}`,`icon-color-${icon}`]")
            span.h5.mb-0.text-gray-900 {{title}}
      .col.m-3
        .font-sm.font-weight-medium.text-gray-800 {{description}}
        .line-style
        .font-weight-medium.text-gray-900 {{cost | inr}} / Employee
        span.font-sm.font-weight-medium.text-gray-600 Annually
        div(v-if="!disableButton")
          NovaButton.mt-3(
            :disabled="false",
            @click="AddOnCardClick",
            :buttonText="'Remove'",
            block,
            :variant="'outline-danger'")

</template>

<script>
import NovaButton from "@/components/NovaButton.vue";
export default {
  name: "AddOn",
  components: {
    NovaButton,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    cost: {
      type: String,
      default: "",
    },
    disableButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    AddOnCardClick() {
      this.$emit("removeAddOn", this.title);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.addon-card-container {
  background: $white;
}
.addon-card-box {
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
}

.text-gray-900 {
  color: $gray-900;
}
.text-gray-800 {
  color: $gray-800;
}
.text-gray-600 {
  color: $gray-600;
}

.addon-card {
  min-width: 217px;
  height: 100%;

  .icon-style {
    margin: 0 10px 0 14px;
  }
  .color-teal {
    color: $teal-800;
  }
  .line-style {
    border: 1px solid #e2e8f0;
    background-color: #e2e8f0;
    margin: 20px 0px;
  }
}

.icon-color-heart {
  color: #ff6b6b;
}

.icon-color-teeth {
  color: $teal-700;
}
.icon-color-bandage {
  color: $mustard-500;
}
.icon-color-hospital {
  color: $blue-500;
}

@media (max-width: 576px) {
  .addon-card {
    min-width: 200px;
  }
}
</style>
