import { PolicyTypes } from "./policyTypes.enum";

export const BenefitTypes = {
  NO_POLICY_DEFINED: "no-policy-defined",
  ...PolicyTypes,
  DENTAL: "dental",
  WORKMEN: "workmen",
  DCL: "dcl",
  PPC: "ppc",
  PARTNER_SIGNUP: "partner-signup",
  OTHER: "other",
  FREE_COUPON: "free-coupon",
};
