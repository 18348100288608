import gql from "graphql-tag";
import { orgPropertyFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertOrgProperty(
      $id: ID
      $orgId: ID
      $name: String
      $meta: JSONObject
      $nodeId: ID
      $nodeIds: [ID]
    ) {
      upsertOrgProperty(
        input: {
          id: $id
          orgId: $orgId
          name: $name
          meta: $meta
          nodeId: $nodeId
          nodeIds: $nodeIds
        }
      ) {
        orgProperty {
          ...OrgProperty
        }
      }
    }
    ${orgPropertyFragment}
  `,
  deleteOrgSegmentFromOrgPropertyMutation: gql`
    mutation DeleteOrgSegmentFromOrgProperty($segmentId: ID!, $orgId: ID!) {
      deleteOrgSegmentFromOrgProperty(input: { segmentId: $segmentId, orgId: $orgId }) {
        status
      }
    }
  `,
  bulkAddOrgSegmentOrgPropertiesMutation: gql`
    mutation BulkAddOrgSegmentOrgProperties($orgIds: [ID]!, $segmentId: ID!) {
      bulkAddOrgSegmentOrgProperties(input: { orgIds: $orgIds, segmentId: $segmentId }) {
        status
      }
    }
  `,
};
