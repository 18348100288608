<template lang="pug">
b-input-group
  b-form-input(
    :id="schema.id",
    v-model="value",
    type="text",
    :disabled="schema.disabled",
    :readonly="schema.readonly",
    :formatter="formatter",
    lazy-formatter,
    :required="schema.required")
  b-input-group-append
    b-form-timepicker(
      v-model="value",
      locale="en",
      :disabled="schema.disabled || schema.readonly",
      button-only,
      hide-header,
      no-close-button)
</template>

<script>
import { abstractField } from "vue-form-generator";
import moment from "moment";
export default {
  mixins: [abstractField],
  methods:{
    formatter(value){
      const time = moment(value, "HH:mm:ss");
      if (!time.isValid()) {
        this.$emit("input", null);
        return null;
      }
      this.$emit("input", value);
      return time.format("HH:mm:ss");
      
    }
  }
};
</script>
