import { ApolloLink } from "apollo-link";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { store } from "./../../portal/store";
import { isTokenExpired, logoutActiveUser, renewTokens } from "./../auth";

const OPEN_QUERIES_AND_MUTATIONS = [
  "fetchLoginConfig",
  "LoginMutation",
  "sendMagicLink",
  "AuthenticateWithRefreshToken",
  "VerifyToken",
  "magicLinkValidation",
  "setPassword",
  "SendResetPasswordMail",
  "ResetPassword",
  "fetchAuthTokens",
];

export const authMiddleware = new ApolloLink((op, forward) => {
  const { operationName } = op;

  if (OPEN_QUERIES_AND_MUTATIONS.includes(operationName)) {
    return forward(op);
  }

  let token = localStorage.getItem("token");
  if (token) {
    op.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    return forward(op);
  } else {
    logoutActiveUser(false);
  }
});

export const refreshTokenMiddleware = new TokenRefreshLink({
  accessTokenField: "token",
  isTokenValidOrUndefined: () => {
    const { accessTokenExpiry } = store.state;

    // TEMP
    if (!accessTokenExpiry) {
      return true;
    }

    if (isTokenExpired(accessTokenExpiry)) {
      return false;
    }

    return true;
  },
  fetchAccessToken: renewTokens,
  handleResponse: (operation, accessTokenField) => (authenticateUser) => authenticateUser || {},
  handleFetch: (accessToken, operation) => {
    // do nothing
  },
  handleError: async (err) => {
    console.warn("Your refresh token is invalid. Try to relogin");
    console.error(err);
    // we're not calling logout here, since
    // it is already called once refreshing token
    // is failed, in renew token
  },
});
