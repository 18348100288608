export const shortDayNamesOfTheWeek = [
  "Su",
  "Mo",
  "Tu",
  "We",
  "Th",
  "Fr",
  "Sa",
];

export const unitSet = {
  km: { // kilometres
    min: "0",
    max: "25",
    step: "0.01",
  },
  m: { // metres
    min: "0",
    max: "25000",
    step: "0.1",
  },
  mil: { // miles
    min: "0",
    max: "10",
    step: "0.1",
  },
  step: { // steps
    min: "0",
    max: "25000",
    step: "1",
  },
  min: { // minutes
    min: "0",
    max: "240",
    step: "0.1",
  },
  hr: { // hours
    min: "0",
    max: "15",
    step: "0.01",
  },
  cal: { //calories
    min: "0",
    max: "5000",
    step: "1",
  },
  kcal: { // kilocalories
    min: "0",
    max: "5",
    step: "0.01",
  },
  count: { // count (reps)
    min: "0",
    max: "200",
    step: "1",
  },
  lap: { // laps
    min: "0",
    max: "100",
    step: "1",
  },
  ltr: { // litres
    min: "0",
    max: "25",
    step: "0.1",
  },
  ml: { // millilitres
    min: "0",
    max: "25000",
    step: "1",
  },
  glass: { // glasses of water
    min: "0",
    max: "25",
    step: "0.1",
  },
  kg: { // kilograms
    min: "0",
    max: "200",
    step: "0.1",
  },
  g: { // grams
    min: "0",
    max: "200000",
    step: "1",
  },
  lbs: { // pounds
    min: "0",
    max: "500",
    step: "0.1",
  },
};

export const fitnessActivityList = {
  cycle: {
    label: "Cycling",
    icon: "cycling",
    bgColor: "blue-900",
    text: "cycling",
    group: "run",
    legalUnits: ["min", "hr", "km", "m", "mil", "cal", "kcal"],
  },
  run: {
    label: "Running",
    icon: "run-fast",
    bgColor: "blue-700",
    text: "running",
    group: "run",
    legalUnits: ["min", "hr", "km", "m", "mil", "cal", "kcal", "step"],
  },
  jog: {
    label: "Jogging",
    icon: "running",
    bgColor: "blue-600",
    text: "jogging",
    group: "run",
    legalUnits: ["min", "hr", "km", "m", "mil", "cal", "kcal", "step"],
  },
  walk: {
    label: "Walking",
    icon: "walk",
    bgColor: "blue-500",
    text: "walking",
    group: "run",
    legalUnits: ["min", "hr", "km", "m", "mil", "cal", "kcal", "step"],
  },
  burn: {
    label: "Burning calories",
    icon: "dumbell",
    bgColor: "teal-900",
    text: "burned",
    group: "workout",
    legalUnits: ["cal", "kcal"],
  },
  workout: {
    label: "Workout",
    icon: "workout",
    bgColor: "malibu-900",
    text: "workout",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  "push-up": {
    label: "Push ups",
    icon: "planking",
    bgColor: "malibu-800",
    text: "push ups",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal", "count"],
  },
  squat: {
    label: "Squats",
    icon: "squat",
    bgColor: "malibu-800",
    text: "squats",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal", "count"],
  },
  "jumping-jack": {
    label: "Jumping jacks",
    icon: "jumping-jacks",
    bgColor: "malibu-700",
    text: "jumping jacks",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal", "count"],
  },
  skips: {
    label: "Skipping",
    icon: "skipping",
    bgColor: "malibu-600",
    text: "skips",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal", "count"],
  },
  kicks: {
    label: "Kicks",
    icon: "kicks",
    bgColor: "malibu-500",
    text: "kicks",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal", "count"],
  },
  sleep: {
    label: "Sleeping",
    icon: "night",
    bgColor: "purple-700",
    text: "climbing stairs",
    group: "meditate",
    legalUnits: ["min", "hr"],
  },
  yoga: {
    label: "Yoga",
    icon: "yoga",
    bgColor: "purple-600",
    text: "yoga",
    group: "meditate",
    legalUnits: ["min", "hr"],
  },
  meditate: {
    label: "Meditation",
    icon: "meditate",
    bgColor: "purple-500",
    text: "meditation",
    group: "meditate",
    legalUnits: ["min", "hr"],
  },
  read: {
    label: "Reading",
    icon: "document-important",
    bgColor: "purple-400",
    text: "deadlift",
    group: "meditate",
    legalUnits: ["min", "hr"],
  },
  surf: {
    label: "Surf",
    icon: "surfing",
    bgColor: "teal-900",
    text: "surf",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  swim: {
    label: "Swimming",
    icon: "swimming",
    bgColor: "teal-700",
    text: "swimming",
    group: "run",
    legalUnits: ["min", "hr", "cal", "kcal", "lap", "m", "mil", "km"],
  },
  hydrate: {
    label: "Hydration",
    icon: "water",
    bgColor: "teal-500",
    text: "water",
    group: "meditate",
    legalUnits: ["ml", "ltr", "glass"],
  },
  dance: {
    label: "Dancing",
    icon: "people",
    bgColor: "red-300",
    text: "dance",
    group: "meditate",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  
  football: {
    label: "Football",
    icon: "football",
    bgColor: "red-900",
    text: "football",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  cricket: {
    label: "Cricket",
    icon: "cricket",
    bgColor: "red-800",
    text: "cricket",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  badminton: {
    label: "Badminton",
    icon: "badminton",
    bgColor: "red-700",
    text: "badminton",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  tennis: {
    label: "Tennis",
    icon: "tennis",
    bgColor: "red-600",
    text: "tennis",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  basketball: {
    label: "Basketball",
    icon: "basketball",
    bgColor: "mustard-900",
    text: "basketball",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  "table-tennis": {
    label: "Table tennis",
    icon: "table-tennis",
    bgColor: "mustard-800",
    text: "table tennis",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  squash: {
    label: "Squash",
    icon: "squash",
    bgColor: "mustard-700",
    text: "squash",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  volleyball: {
    label: "Volleyball",
    icon: "volleyball",
    bgColor: "mustard-600",
    text: "volleyball",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  golf: {
    label: "Golf",
    icon: "golf",
    bgColor: "red-900",
    text: "golf",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  zumba: {
    label: "Zumba",
    icon: "dance",
    bgColor: "red-800",
    text: "zumba",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  hiit: {
    label: "HIIT",
    icon: "yoga",
    bgColor: "red-700",
    text: "HIIT",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  soccer: {
    label: "Soccer",
    icon: "soccer",
    bgColor: "red-600",
    text: "soccer",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  box: {
    label: "Boxing",
    icon: "boxing",
    bgColor: "mustard-900",
    text: "boxing",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  play: {
    label: "Playing",
    icon: "handball",
    bgColor: "mustard-800",
    text: "playing",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  handball: {
    label: "Handball",
    icon: "handball",
    bgColor: "mustard-700",
    text: "handball",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  "martial-art": {
    label: "Martial arts",
    icon: "martial-arts",
    bgColor: "mustard-600",
    text: "martialarts",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  "horse-ride": {
    label: "Horse riding",
    icon: "horse-riding",
    bgColor: "red-900",
    text: "horse riding",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  blink: {
    label: "Blink",
    icon: "eye",
    bgColor: "red-800",
    text: "blink",
    group: "meditate",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  eat: {
    label: "Eat",
    icon: "eating",
    bgColor: "red-700",
    text: "eat",
    group: "meditate",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  garden: {
    label: "Gardening",
    icon: "gardening",
    bgColor: "red-600",
    text: "gardening",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  aerobics: {
    label: "Aerobics",
    icon: "aerobics",
    bgColor: "mustard-900",
    text: "aerobics",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal"],
  },
  "weight-lift": {
    label: "Weight lifting",
    icon: "workout",
    bgColor: "mustard-800",
    text: "weightlifting",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal", "kg", "g", "lbs"],
  },
  chess: {
    label: "Chess",
    icon: "chess",
    bgColor: "mustard-700",
    text: "chess",
    group: "meditate",
    legalUnits: ["min", "hr"],
  },
  crunch: {
    label: "Crunches",
    icon: "crunches",
    bgColor: "mustard-600",
    text: "crunches",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal", "count"],
  },
  burpee: {
    label: "Burpees",
    icon: "jumping-jacks",
    bgColor: "red-900",
    text: "burpees",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal", "count"],
  },
  hike: {
    label: "Hiking",
    icon: "hiking",
    bgColor: "red-800",
    text: "hiking",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal", "count"],
  },
  trek: {
    label: "Trekking",
    icon: "hiking",
    bgColor: "red-700",
    text: "trekking",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal", "count"],
  },
  "ice-skate": {
    label: "Ice skating",
    icon: "skate",
    bgColor: "red-600",
    text: "ice skating",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal", "count"],
  },
  plank: {
    label: "Plank",
    icon: "planking",
    bgColor: "mustard-900",
    text: "plank",
    group: "workout",
    legalUnits: ["min", "hr", "sec", "cal", "kcal"],
  },
  "dead-lift": {
    label: "Deadlift",
    icon: "workout",
    bgColor: "mustard-800",
    text: "deadlift",
    group: "workout",
    legalUnits: ["min", "hr", "sec", "cal", "kcal"],
  },
  stairs: {
    label: "Climbing stairs",
    icon: "stairs",
    bgColor: "mustard-700",
    text: "climbing stairs",
    group: "workout",
    legalUnits: ["min", "hr", "cal", "kcal", "step", "count"],
  },
};
