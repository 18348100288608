import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import "./../common/auth"; // to initiate apolloClient for refresh token
import { errorMiddleware } from "./../common/middlewares/apolloError";
import { versioningMiddleware } from "./../common/middlewares/versioning";
import {
  authMiddleware,
  refreshTokenMiddleware
} from "./../common/middlewares/auth";
import { httpLink } from "./../common/middlewares/httpLink";
import { cache } from "./../common/apolloCache";

export const apolloClient = new ApolloClient({
  // Important: Please note the order here
  // If the order changes some of the middleware won't work
  link: ApolloLink.from(
    [
      errorMiddleware,
      refreshTokenMiddleware,
      authMiddleware,
      versioningMiddleware,
      httpLink
    ]
  ),
  cache,
  connectToDevTools: true,
});
