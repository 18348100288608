<template lang="pug">
  footer.py-4.footer.overflow-hidden
    .container.container-homepage
      .row.align-items-center.text-white.m-0.py-4
        .col-lg-auto.order-3.order-lg-1
          h2.hb4.d-inline-block Drop us a line at
          h2.hb4.text-teal-500 hello@getnovaapp.com
        .col-lg-auto.text-center.order-2
          img.mx-4.d-none.d-lg-inline(:src="require(`@/assets/images/footer-contact-divider.svg`)")
          img.my-4.d-lg-none(:src="require(`@/assets/images/footer-contact-divider-x.svg`)")
        .col-lg.d-inline-block.order-1.order-lg-3
          span.font-lg Want to know more about the best health insurance options for you?
          .py-3
            n-button.px-4(
              variant="warning",
              buttonText="Schedule a call",
              imageIcon="calendar",
              @click="openCalendlyPopup")
        .col-lg-4.col-xl-3.order-4.footer-links
          .row.no-gutters
            .col-lg-6.col-sm-4
              b-link(href="https://nova-benefits.skillate.com/", target="_blank") Careers
            .col-lg-6.col-sm-4
              b-link(href="https://blog.getnovaapp.com/", target="_blank") Our Blog
            .col-lg-6.col-sm-4
              b-link(href="https://blog.getnovaapp.com/terms-and-conditions/", target="_blank") Terms of use
            .col-lg-6.col-sm-4
              b-link(href="https://blog.getnovaapp.com/privacy-policy/", target="_blank") Privacy Policy
            .col-lg-6.col-sm-4
              b-link(href="https://www.linkedin.com/company/nova-benefits/", target="_blank") LinkedIn
            .col-lg-6.col-sm-4
              b-link(href="https://www.youtube.com/channel/UCXeubD17S937tzG9R2SI2hg/videos", target="_blank") Youtube

      .row.m-0.py-4.teal-bottom-separator.text-gray-600
        .col-lg.text-left.order-2.order-lg-1.py-1.py-lg-0 We’ve got you covered. Literally.
        .col-lg.text-lg-center.order-1.order-lg-2
          n-icon(name="nova-basic", :size="1.75")
        .col-lg.text-lg-right.order-3.py-1.py-lg-0 &copy; Nova Benefits Private Limited, 2021
      .row.m-0.py-4.text-gray-600.font-sm
        .col Disclaimer: Nova Benefits Private Limited is not an IRDAI registered entity.
          br
          | Insurance products are offered by NovaBenefits Insurance Brokers Pvt. Ltd (CIN U66020KA2020PTC141160),
          | Principal Place of Business: Second Floor, Flat no 1, No. 571, 1st Main, 8th Block, Koramangala, Bengaluru
          | - 560095, Registered Office: G-2, Gold Cornice, Charles Campbell Road, Cox Town, Bengaluru - 560005. IRDAI
          | Broking License Registration Code: IRDA/DB848/20, Certificate No. 753, License category- Direct Broker
          | (Life & General), valid till 12-07-2024.
          br
          | Product information is authentic and solely based on the information received from the insurers. For more
          | details on risk factors, associated terms and conditions and exclusions, please read the sales brochure of
          | the respective insurer carefully.

    .footer-leaf
      img(:src="require(`@/assets/images/footer-leaf.svg`)")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";
export default {
  components: {
    NButton,
  },
  methods: {
    openCalendlyPopup() {
      utils.openCalendlyPopup();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

.footer {
  background-color: $tiber !important;
  position: relative;
  z-index: 0;
}
.teal-bottom-separator {
  border-bottom: 1px solid $teal-900;
}
.footer-links a {
  text-decoration: underline;
  text-underline-offset: 2px;
  @include py(2);
  @include px(1);
  display: inline-block;
  color: white;
}
.footer-leaf {
  position: absolute;
  top: 50%;
  right: -10%;
  margin-right: -1rem;
  z-index: -1;
}

@media (min-width: 768px) {
  .footer-leaf {
    position: absolute;
    top: 10%;
    right: 0;
  }
}
</style>
