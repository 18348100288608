import { render, staticRenderFns } from "./NovaButton.vue?vue&type=template&id=4f9b34e2&scoped=true&lang=pug&"
import script from "./NovaButton.vue?vue&type=script&lang=js&"
export * from "./NovaButton.vue?vue&type=script&lang=js&"
import style0 from "./NovaButton.vue?vue&type=style&index=0&id=4f9b34e2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f9b34e2",
  null
  
)

export default component.exports