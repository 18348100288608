<template lang="pug">
.container
  b-card.shadow-none.mb-3(
    header-bg-variant="transparent",
    bg-variant="transparent",
    body-bg-variant="white",
    body-class="shadow-sm")
    transition(name="fade", mode="out-in")
      router-view(:key="$route.path")

</template>

<script>
export default {};
</script>
