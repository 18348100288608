<template lang="pug">
div
  div.wrapper
    div.main-panel.full-page
    
      transition(name="slide")
        b-link.bg-tiber.announcement.font-weight-medium.d-flex.align-items-center.justify-content-center(
          v-if="announcementShown",
          href="https://www.linkedin.com/pulse/nova-benefits-raises-10m-series-build-future-employee-saransh-garg",
          target="_blank")
          span.text-center.mx-5
            n-icon.align-text-bottom.pr-2(name="star", :size="1.1")
            span.text-teal-600 News ->
            span.text-white.pl-2 Announcing our Series A to bring better healthcare to employees all around India
            img.align-bottom.ml-2(:src="require('@/assets/emojis/tada.png')", width="18")
          span.hide-announcement-cross.mr-3.mr-lg-4.mr-xl-5.rounded-sides(@click.prevent="closeAnnouncement")
            n-icon(name="cross", :size="1.1")

      //- temporarily adding check for dark-nav in dynamic pages will be removed once dynamic nav is added
      n-navbar.slide-offset.mt-0(:logoImage="isDynamicPage ? 'logo-main-white' : 'logo-main'", :class="[isDynamicPage ? 'dark-navbar' : 'light-navbar', announcementShown ? 'announcement-shown' : '']")
        template(v-slot:right)
          .d-none.d-md-block
            //- temporary changes to disable our site and use unicorn/webflow
            //- b-link.d-inline-block.align-sub(:to="{name: 'homepage'}", exact)
            //-   h5.d-inline-block.nav-link(:class="isDynamicPage ? 'nav-link-gray' : ''") Home
            //- b-link.d-inline-block.align-sub(:to="{name: 'community'}")
            //-   h5.d-inline-block.nav-link(:class="isDynamicPage ? 'nav-link-gray' : ''") Community
            b-link.d-inline-block.align-sub(:href="blogsUrl || 'https://blog.novabenefits.com'", target="_blank", :class="isDynamicPage ? 'nav-link-gray' : ''")
              h5.d-inline-block.nav-link(:class="isDynamicPage ? 'nav-link-gray' : ''") Blog
            b-link.d-inline-block.align-sub(href="https://angel.co/company/nova-benefits/people", target="_blank")
              h5.d-inline-block.nav-link(:class="isDynamicPage ? 'nav-link-gray' : ''") About Us

          .right-0
            n-button.mr-2.px-4(
              buttonText="Login ->",
              variant="outline-secondary",
              :href="loginUrl || 'https://portal.novabenefits.com'")
            n-button.px-4(buttonText="Get in touch", variant="success", v-b-modal.get-in-touch-modal)
      
      div.slide-offset(style="margin-top: 75px;" :class="announcementShown ? 'announcement-shown-offset' : ''")
        router-view(:key="$route.path")
      get-in-touch-modal
      n-homepage-footer(v-if="!$route.path.includes('policy-calculator')")
</template>

<script>
import NNavbar from "@/layout/NovaNavbar.vue";
import NButton from "@/components/NovaButton.vue";
import NHomepageFooter from "@/layout/HomePageFooter.vue";
import utils from "@/utils";
import { mapGetters } from "vuex";
import LandingPage from "./LandingPage.vue";
import GetInTouchModal from "./components/GetInTouchModal.vue";

export default {
  components: {
    NNavbar,
    NHomepageFooter,
    NButton,
    LandingPage,
    GetInTouchModal,
  },
  computed: {
    ...mapGetters(["isAnnouncementShown"]),
    announcementShown() {
      return (
        this.isAnnouncementShown &&
        !this.$route.path.startsWith("/policy-calculator")
      );
    },
    isDynamicPage() {
      return this.$route.path.includes("/page");
    },
    loginUrl() {
      return process.env.VUE_APP_PORTAL_LOGIN_URL;
    },
    blogsUrl() {
      return process.env.VUE_APP_BLOG_URL;
    }
  },
  methods: {
    openCalendlyPopup() {
      utils.openCalendlyPopup();
    },
    closeAnnouncement() {
      this.$store.commit("closeAnnouncement");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
@import "@/assets/styles/_variables.scss";

.nav-link {
  color: $gray-700;
  margin-right: 2rem;
  vertical-align: text-top;
  @include media-breakpoint-down(md) {
    margin-right: 1.8rem;
  }
}

.modal-open {
  overflow-y: scroll;
  padding: 0 !important;
}

div.light-navbar {
  background-color: #ffffffbb !important;
  backdrop-filter: blur(24px);
  padding: 0 !important;
}
.right-0 {
  position: relative;
  right: 0;

  .btn {
    @include media-breakpoint-down(md) {
      padding: 0.5rem 0.6rem !important;
    }
  }
}
.announcement {
  position: fixed;
  top: 0;
  height: 60px;
  width: 100%;
  z-index: 3;
  overflow-y: hidden;
  .icon-star {
    color: $goldenrod;
  }
  .icon-cross {
    color: $teal-600;
  }
  &:hover,
  &:focus {
    background-color: $tiber !important;
    text-decoration: none;
  }
  @include media-breakpoint-down(sm) {
    font-size: 0.75rem;
  }
}

.announcement-shown {
  margin-top: 60px !important;
}
.announcement-shown-offset {
  margin-top: 135px !important;
}
.slide-offset {
  transition: all 0.3s ease !important;
}
.hide-announcement-cross {
  position: absolute;
  right: 0;
  height: 2rem;
  width: 2rem;
  padding: 0.45rem;
  transition: background-color 0.1s ease;
  &:hover {
    background-color: rgba($white, 0.15);
  }
}

.slide-leave-to {
  transform: translateY(-100px);
}
.slide-leave-active {
  transition: all 0.3s ease;
}
.nav-link-gray {
  color: $gray-600 !important;
}
</style>
