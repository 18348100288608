<template lang="pug">
div
  .plan-info-container.d-flex.align-items-center.rounded.overflow-hidden(
    :id="id"
    :class="`plan-info-${size}`",
    v-b-tooltip.hover.top="!familyDefinition ? planInfo : ''")
    .p-1.h-100
      img.img-style.img-fluid(v-if="imageUrl", :src="imageUrl")
      .icon-style(v-else, :class="`icon-${iconName}`")
    .right-side.p-1(v-if="planInfo", id="plan-info-text")
      .font-weight-medium(:class="textClass") {{ getTruncatedValue(planInfo) }}
  n-popover(
    v-if="familyDefinition",
    :target="id",
    triggers="hover",
    position="bottom")
    template(v-slot:title)
      .font-weight-semibold.font-md.py-2
        div {{ "Members covered by this policy" }}
    template(v-slot:default)
      .p-0
        template(v-for="content in coveredDependents")
          hr.my-0
          .text-gray-800.font-sm.my-2 {{ content }}
</template>

<script>
import utils from "@/utils";
import NPopover from "@/components/NovaPopover.vue";
export default {
  name: "PlanInfo",
  components: {
    NPopover,
  },
  props: {
    size: {
      type: String,
      default: "md",
    },
    icon: {
      type: String,
      default: null,
    },
    image: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    inrValue: {
      type: [String, Number],
      default: null,
    },
    familyDefinition: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      id: "",
    };
  },
  computed: {
    textClass() {
      if (this.inrValue) return ["font-md"];
      return "font-xs";
    },
    maxTextLength() {
      if (this.inrValue) return 10;
      return 25;
    },
    imageUrl() {
      if (this.familyDefinition) {
        const shortName = this.familyDefinition.replace(/\d+/g, "").replace(/\//g, "_");
        return require(`@/assets/images/coverage${shortName}.svg`);
      }
      return this.image;
    },
    iconName() {
      if (this.icon) return this.icon;
      if (this.inrValue !== null) {
        return "rupee";
      }
      return "star";
    },
    planInfo() {
      let value = null;
      if (this.value) value = this.value;
      if (this.familyDefinition) {
        const { count } = this.getDependentsCountAndList();
        if (this.familyDefinition === "P") value = "Your Parents";
        else if (this.familyDefinition === "P/IL") value = "Your Parents or Parents-in-law only";
        else if (count) value = `You +${count}`;
        else value = "Just You";
      }
      if (this.inrValue) {
        value = utils.getFormattedINRValue(this.inrValue);
      }
      return value;
    },
    coveredDependents() {
      const { dependents } = this.getDependentsCountAndList();
      return dependents;
    },
  },
  mounted() {
    this.id = this._uid.toString();
  },
  methods: {
    getTruncatedValue(value) {
      if (value.length > this.maxTextLength) {
        return value.slice(0, this.maxTextLength - 1) + "...";
      }
      return value;
    },
    getDependentsCountAndList() {
      let count = 0;
      const dependents = [];
      if (this.familyDefinition.startsWith("E")) {
        dependents.push("You");
      }
      if (this.familyDefinition.startsWith("ES")) {
        count += 1;
        dependents.push("Spouse");
      }
      if (this.familyDefinition.match(/^ES[\d]?C/)) {
        // taking max of 1 children and the number of children in family definition
        const childrenCount = Math.max(1, this.familyDefinition.match(/\d+/));
        count += childrenCount;
        if (childrenCount > 1) dependents.push(`Children (${childrenCount})`);
        else dependents.push("Child");
      }
      if (this.familyDefinition.endsWith("P")) {
        count += 2;
        dependents.push(`Parents (2)`);
      }
      if (this.familyDefinition.endsWith("P/IL")) {
        count += 2;
        dependents.push(`Parents or Parents-in-laws (2)`);
      }
      if (this.familyDefinition.endsWith("P+IL")) {
        count += 4;
        dependents.push(`Parents and Parents-in-laws (4)`);
      }
      return { count, dependents };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.plan-info-container {
  background: $alabaster;
  box-sizing: border-box;
  border: 1px solid $gray-300;
}

.plan-info-md {
  max-width: 155px;
  height: 40px;
  .img-style {
    min-width: 20px;
    max-width: 57px;
    max-height: 100%;
  }
}

.plan-info-lg {
  max-width: 205px;
  height: 60px;
  .img-style {
    min-width: 30px;
    max-width: 80px;
    max-height: 100%;
  }
}

.right-side {
  border-left: 1px solid $gray-300;
  min-width: 4.5rem;
  max-width: 6rem;
}

.img-style,
.icon-style {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
</style>
