import { getListQuery } from "../../queries";
import utils from "@/utils";

export default {
  schema: {
    fields: [
      {
        model: "user",
        type: "id-selector",
        queryName: "users",
        gqlQuery: getListQuery("User"),
        styleClasses: "d-inline-flex col-md-6 flex-column",
        queryVariables: () => ({
          limit: 10,
          offset: 0,
          filter: {
            fetchDeletedUsers: true,
          },
        }),
        label: `User Name & ID<span style="color:red">*</span>`,
        placeholder: "-None-",
        required: true,
        validator: "required",
        showSelected: true,
        getSubTitle: (value) => (value.status === "deleted" ? "deleted" : null),
      },
      {
        model: "user.meta.employeeId",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        label: `Employee ID<span style="color:red">*</span>`,
        placeholder: "-",
        required: true,
        readonly: true,
      },
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        gqlQuery: getListQuery("Org"),
        getSubTitle: (o) => o.name,
        label: `Brand & Organisation Name<span style="color:red">*</span>`,
        placeholder: "-None-",
        validator: "required",
        required: true,
      },
      {
        model: "user.name",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        label: `Primary Insured Name<span style="color:red">*</span>`,
        placeholder: "-",
        readonly: true,
        required: true,
      },
      {
        model: "patient",
        type: "inline-select",
        options: [],
        styleClasses: "d-inline-block col-md-6",
        label: `Patient Name<span style="color:red">*</span>`,
        placeholder: "Employee/Dependent name",
        validator: [utils.validateInlineSelect, "required"],
        required: true,
      },
      {
        model: "user.meta.contactNumber",
        type: "inline-input",
        label: "Registered Phone Number",
        inputType: "tel",
        styleClasses: "d-inline-block col-md-6",
        readonly: true,
        required: true,
      },
      {
        model: "patient.gender",
        type: "select-cards",
        label: "Patient Gender",
        styleClasses: "d-inline-block col-md-6",
        readOnly: true,
        required: true,
        cardsData: [
          {
            name: "gender",
            icon: "female",
            label: "Female",
            card_value: "female",
          },
          {
            name: "gender",
            icon: "male",
            label: "Male",
            card_value: "male",
          },
        ],
      },
      {
        model: "meta.contact",
        type: "inline-input",
        inputType: "tel",
        label: `Phone Number for this Claim<span style="color:red">*</span>`,
        validator: [utils.validateIndianMobileNumbersForVfg, "required"],
        styleClasses: "d-inline-block col-md-6",
        required: true,
      },
      {
        model: "patient.dob",
        type: "datepicker",
        label: "Date of Birth",
        min: "1900-01-01",
        max: "2100-12-31",
        styleClasses: "d-inline-block col-md-6",
        readonly: true,
        required: true,
      },
      {
        model: "meta.secondaryEmail",
        type: "inline-input",
        inputType: "email",
        label: "Email ID for this Claim",
        styleClasses: "d-inline-block col-md-6",
        validator: "email",
        required: false,
        placeholder: "-",
      },
      {
        model: "patient.relation",
        type: "inline-input",
        options: [],
        label: "Relationship to Primary",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        placeholder: "-",
        readonly: true,
        required: true,
        showSelected: false,
      },
      {
        model: "meta.endorsementNote",
        type: "textArea",
        rows: 2,
        label: "Notes for Nova Endorsement team",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        required: false,
        placeholder: "-",
      },
    ],
  },
};
