
import { benefitFragmentWithoutContent, benefitFragment } from ".";
import gql from "graphql-tag";

export const userBenefitFragment = gql`
  fragment UserBenefitEdge on UserBenefitEdge {
    node {
      ...Benefit
    }
    meta
  }
  ${benefitFragment}
`;

export const userBenefitsFragment = gql`
  fragment UserBenefits on User {
    id
    benefits {
      ...UserBenefitEdge
    }
  }
  ${userBenefitFragment}
`;

export const userBenefitFragmentWithoutContent = gql`
  fragment UserBenefitEdgeWithoutContent on UserBenefitEdge {
    node {
      ...BenefitWithoutContent
    }
    meta
  }
  ${benefitFragmentWithoutContent}
`;
