export const Base = require("./Base.vue").default;

export const routes = [
  {
    path: "get-started",
    component: require("./getStarted/Base.vue").default,
    children: require("./getStarted").routes,
    meta: { allowedRoles: ["*"] },
    name: "user_onboarding",
  },
  {
    path: "profile",
    component: require("./profile/Base.vue").default,
    children: require("./profile").profileRoutes,
    name: "user_profile",
    meta: { allowedRoles: ["org_member"] },
  },
  {
    path: "dependents",
    component: require("./profile/Base.vue").default,
    children: require("./profile").dependentRoutes,
    name: "user_dependents",
    meta: { allowedRoles: ["org_member"] },
  },
  {
    path: "purchases",
    component: require("./profile/Base.vue").default,
    children: require("./profile").purchaseRoutes,
    name: "user_purchases",
    meta: { allowedRoles: ["org_member"] },
  },
  {
    path: "customize",
    component: require("./Customize.vue").default,
    name: "user_customize",
    meta: { allowedRoles: ["org_member"] },
  },
  {
    path: "claims",
    component: require("./claims").Base,
    children: require("./claims").routes,
    name: "user_claims",
    meta: {
      allowedRoles: ["org_member"],
      featureFlags: ["CLAIMS_PORTAL_EXPERIENCE"],
    },
  },
];
