import gql from "graphql-tag";
import { challengeTemplateFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertChallengeTemplate(
      $id: ID
      $name: String!
      $challengeContentId: String!
      $challengeUnit: String
      $activityTypes: [String]
      $duration: Float
      $baseGoalValue: Float
      $goalValueByUnit: Float
      $points: Float
      $topScorerPoints: Float
      $minGoalValue: Float
      $incrementFactor: Float
      $decrementFactor: Float
      $meta: JSONObject
    ) {
      upsertChallengeTemplate(
        input: {
          id: $id
          name: $name
          challengeContentId: $challengeContentId
          challengeUnit: $challengeUnit
          activityTypes: $activityTypes
          duration: $duration
          baseGoalValue: $baseGoalValue
          goalValueByUnit: $goalValueByUnit
          points: $points
          topScorerPoints: $topScorerPoints
          minGoalValue: $minGoalValue
          incrementFactor: $incrementFactor
          decrementFactor: $decrementFactor
          meta: $meta
        }
      ) {
        challengeTemplate {
          ...ChallengeTemplate
        }
      }
    }
    ${challengeTemplateFragment}
  `,
}
