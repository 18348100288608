<template lang="pug">
.bg-dark.p-2.rounded
  .d-flex.align-items-center.justify-content-between
    .pl-3.pr-4
      span.text-gray-500(v-if="!action") {{numberOfChangesSelected}} Change{{numberOfChangesSelected>1 ? 's': ''}} Selected
      span.text-gray-500(v-else) {{numberOfChangesSelected}} Account{{numberOfChangesSelected>1 ? 's': ''}} Selected
    div(v-if="!action")
      .px-3.mx-3.border-left.border-right.border-secondary
        .d-flex.align-items-center.cursor-pointer(@click="$emit('reject')")
          i.icon-cancel.mx-1.text-danger
          span.text-white {{ "Delete Changes" }}
    div(v-else)
      .px-3.border-left.border-right.border-secondary(v-if="action === 'add'")
        .d-flex.align-items-center.cursor-pointer(@click="$emit('ignore')")
          i.icon-minus-circle.mx-1.text-white
          span.text-white Ignore Accounts
      .px-3.py-3.border-left.border-secondary(v-if="action === 'ignore'")
        .d-flex.align-items-center.cursor-pointer(@click="$emit('authorize')")
          i.icon-plus-circle.mx-1.text-white
          span.text-white Reconsider Accounts for addition
    .pl-3
      .d-flex(v-if="!action")
        n-button(v-if="tableType === 'enrollment-pending'" buttonText="Send Reminders", rightImageIcon="chevron-right", variant="primary", @click="$emit('approve')")
        n-button(v-else buttonText="Approve", imageIcon="check-circle", variant="primary", @click="$emit('approve')")
        n-button.ml-2(
          v-if="!this.$route.path.includes(`org-admin/`)",
          buttonText="Change batch",
          imageIcon="check-circle",
          variant="primary",
          @click="$emit('transferBatch')")
      div(v-else-if="action !== 'ignore'", variant="primary", @click="$emit('approve')")
        n-button(v-if="action === 'add'" buttonText="Add to Nova", imageIcon="add-user", variant="primary", @click="$emit('add')")
        n-button(
          v-if="action === 'remove'",
          buttonText="Remove from Nova",
          v-b-modal.hrms-bulk-modal,
          imageIcon="add-user",
          variant="danger",
          @click="$emit('remove')",
          :disabled="disableRemoveButton",
        )

</template>

<script>
// TODO: Refactor this component
import NButton from "@/components/NovaButton.vue";
export default {
  name: "FloatingToolbar",
  components: {
    NButton,
  },
  props: {
    tableType: {
      type: String,
      default: "",
    },
    numberOfChangesSelected: {
      type: Number,
      default: 0,
    },
    action: {
      type: String,
      default: null,
    },
    disableRemoveButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
</style>
