export const ClaimDocument = {
  CLAIM_FORM_PART_A: "Claim form: Part A",
  CLAIM_FORM_PART_B: "Claim form: Part B",
  ADHAAR_PRIMARY: "Adhaar Card of Primary Insured",
  ADHAAR_PATIENT: "Adhaar Card of the Patient",
  PAN_PRIMARY: "PAN Card of Primary Insured",
  CANCELLED_CHEQUE: "Cancelled cheque with name of primary insured",
  DISCHARGE_SUMMARY: "Detailed discharge summary",
  INVESTIGATION_REPORT: "Investigation reports",
  HOSPITAL_BILL: "Main hospital bill",
  PAID_RECEIPT: "Receipt of the amount paid",
  MED_INV_PRESCRIPTION: "Prescriptions for medicines & investigations",
  PHARMACY_BILL: "Pharmacy bills for medicines",
  INVESTIGATION_BILL: "Investigation bills",
  E_CARD: "E-Card",
};