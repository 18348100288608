import gql from "graphql-tag";

export const relationshipManagerFragment = gql`
  fragment RelationshipManager on RelationshipManager {
    id
    name
    email
    gender
    photoFile {
      id
      url
    }
  }
`;
