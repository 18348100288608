import { getListQuery } from "../../queries";
export default {
  schema: {
    fields: [
      {
        model: "claimOwner",
        type: "id-selector",
        styleClasses: "d-inline-block col-md-6 offset-md-right-6",
        queryName: "users",
        gqlQuery: getListQuery("User"),
        queryVariables: () => ({
          limit: 10,
          offset: 0,
          filter: { roles: ["account_admin"] },
        }),
        label: "Claim Owner",
        placeholder: "-None-",
        required: true,
      },
      {
        model: "createdBy",
        type: "inline-input",
        styleClasses: "d-inline-block col-md-6 ",
        inputType: "text",
        label: "Created by",
        placeholder: "-",
        validator: "regexp",
        pattern: "^[a-zA-Z]*$",
        required: true,
        readonly: true,
      },
      {
        model: "modifiedBy",
        type: "inline-input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: "Modified by",
        placeholder: "-",
        validator: "regexp",
        pattern: "^[a-zA-Z]*$",
        required: false,
        readonly: true,
      },
    ],
  },
};
