<template lang="pug">
b-form-group.radio-wrapper.mb-0(v-slot="{ ariaDescribedby }")
  b-form-radio-group.font-weight-medium(
    v-bind="{...$props, ...$attrs}",
    v-model="ctrlValue",
    v-b-tooltip,
    :title="toolTipTitle",
    :aria-describedby="ariaDescribedby",
    text-field="label")
</template>

<script>
export default {
  name: "NInlineRadio",
  props: {
    /* eslint-disable-next-line vue/require-prop-types, vue/require-default-prop */
    value: {},
    name: {
      type: String,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    stacked: {
      type: Boolean,
      default: false,
    },
    toolTipTitle: {
      type: String,
      default: null,
    },
  },
  computed: {
    ctrlValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss"></style>
