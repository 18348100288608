<template lang="pug">
  .line-container
    .row.no-gutters.d-flex
      .d-flex.align-items-center
        i.pr-2(:class="[`icon-${icon}`, `icon-color-${icon}`]")
        span(:class="isFinal ? ['total-line-text', 'font-md',]: ['font-sm', 'text-gray-900']") {{name}}
      .col
        hr.bill-line.mx-3
      .d-flex.align-items-center.justify-content-center
        span(:class="isFinal ? ['total-line-amount', 'font-md',]: ['font-sm', 'text-gray-900']") {{cost | inr}}
</template>

<script>
export default {
  name: "BillLine",
  props: {
    icon: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    cost: {
      type: String,
      default: "",
    },
  },
  computed: {
    isFinal: function () {
      return this.name.toLowerCase().includes("total annual premium");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.line-container {
  width: 100%;
  padding: 2px;
}

.text-gray-900 {
  letter-spacing: 0.01em;
  color: $gray-900;
}

.bill-line {
  border: 1px solid $gray-300;
}

.icon-color-heart {
  color: #ff6b6b;
}

.icon-color-nova-standard {
  color: $gray-900;
}
.icon-color-nova-prime {
  color: $teal-800;
}

.icon-color-teeth {
  color: $teal-700;
}
.icon-color-bandage {
  color: $mustard-500;
}
.icon-color-hospital,
.icon-color-nova-basic {
  color: $blue-500;
}

.total-line-text {
  font-weight: 600;
  color: $gray-900;
}
.total-line-amount {
  font-weight: bold;
  text-align: right;
  color: $teal-800;
}
</style>
