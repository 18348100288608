export default {
  schema: {
    fields: [
      { 
        model: "name",
        type: "input", 
        inputType: "text", 
        label: "Org Name",
        required: true, 
      },
      {
        model: "type",
        type: "input",
        inputType: "text",
        label: "Type",
        required: true,
      },
      {
        model: "slug",
        type: "input",
        inputType: "text",
        label: "Slug",
        required: true,
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        schema: {
          fields: [
            {
              model: "gstin",
              type: "input",
              inputType: "text",
              label: "GSTIN",
            },
            {
              model: "bank_acc",
              type: "input",
              inputType: "text",
              label: "Bank Account Number",
            },
            {
              model: "bank_ifsc",
              type: "input",
              inputType: "text",
              label: "Bank IFSC Code",
            },
            {
              model: "bank_bene",
              type: "input",
              inputType: "text",
              label: "Beneficiary Name",
            },
            {
              model: "contact_person",
              type: "input",
              inputType: "text",
              label: "Contact Person",
            },
            {
              model: "contact_number",
              type: "input",
              inputType: "text",
              label: "Contact Number",
            },
          ],
        },
      },
    ],
  },
};
