<template lang="pug">
.avatar-container
  .avatar-img
    img.img-style(:src="imgSrc")
  .avatar-text
    .user-name.font-md {{name}}
    .meta-info.font-md {{role}}, {{company}}
  .quotes-img.d-block.d-md-none
    img(:src="require(`@/assets/images/quote-end-${quoteVariant}.svg`)")

</template>

<script>
export default {
  name: "AvatarCard",
  props: {
    imgSrc: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "John Doe",
    },
    role: {
      type: String,
      default: "SDE",
    },
    company: {
      type: String,
      default: "Nova Benefits",
    },
    quoteVariant: {
      type: String,
      default: "success",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.avatar-container {
  max-width: 342px;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  @include media-breakpoint-down(md) {
    max-width: 100%;
    margin: 1rem 0;
  }
}
.img-style {
  height: 32px;
  width: 32px;
  margin-right: 0.5rem;
  border-radius: 55px;
}
.avatar-img {
  flex: 0 1 10%;
}
.avatar-text {
  font-weight: 500;
  flex: 0 1 80%;
  .user-name {
    color: $gray-900;
  }
  .meta-info {
    color: $gray-700;
  }
}
.quotes-img {
  flex: 0 1 15%;
}
</style>
