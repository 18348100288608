import { render, staticRenderFns } from "./PremiumDetails.vue?vue&type=template&id=9f47c7c6&scoped=true&lang=pug&"
import script from "./PremiumDetails.vue?vue&type=script&lang=js&"
export * from "./PremiumDetails.vue?vue&type=script&lang=js&"
import style0 from "./PremiumDetails.vue?vue&type=style&index=0&id=9f47c7c6&prod&lang=scss&scoped=true&"
import style1 from "./PremiumDetails.vue?vue&type=style&index=1&id=9f47c7c6&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f47c7c6",
  null
  
)

export default component.exports