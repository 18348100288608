export default {
  isAnyFilterApplied(filterModel) {
    for (const filter in filterModel) {
      if (filterModel[filter]) {
        return true;
      }
    }
    return false;
  },

  getFilterCountText(filterModel) {
    let count = 0;
    for (const filter in filterModel) {
      if (filter === "dates") {
        if (filterModel[filter].startDate && filterModel[filter].endDate) {
          count++;
        }
      } else if (filterModel[filter] && filterModel[filter] != "None selected") {
        count++;
      }
    }
    return `${count || "No"} Filters Applied`;
  },
};
