<template lang="pug">
div.py-7
  .d-flex.justify-content-center
    img(:src="require(`@/assets/images/magnifying-glass.svg`)")
  div
    .empty-states-msg1.font-weight-semibold.font-xl Nothing to see here!
    .empty-states-msg2.font-lg.font-weight-medium.mx-auto  Not for long though. Your documents will appear here as and when they are available ✨
</template>

<script>
export default {
  name: "Documents",
};
</script>
